import { Component, OnInit, ChangeDetectionStrategy,  ViewChild,  TemplateRef } from '@angular/core';
import { ApiService } from '../../../../services/api/api.service';
import { startOfDay,  endOfDay,  subDays,  addDays,  isSameDay,  isSameMonth,  addHours,  getHours,  startOfWeek,  endOfWeek,  startOfMonth,  endOfMonth,} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent,  CalendarEventAction,  CalendarEventTimesChangedEvent,  CalendarView,  DAYS_OF_WEEK } from 'angular-calendar';
import { environment } from '../../../../../environments/environment';
import { FlashMessagesService } from 'angular2-flash-messages';

const colors: any = [

  {//Libre
    primary: '#ad2121',
    secondary: '#d1d1d1',
  },
  {//Réservée
    primary: '#ffc107',
    secondary: '#75ecff',
  },
  {//Terminée
    primary: '#007bff',
    secondary: '#c8e2ff',
  },
  {//Reportée
    primary: '#17a2b8',
    secondary: '#FDF1BA',
  },
  {//Rémunéréz, 
    primary: '#28a745',
    secondary: '#BDFBCB',
  },
  {//Annulée
    primary: '#dc3545',
    secondary: '#FFB7B7',
  },
];

@Component({
  selector: 'app-counselors-agenda',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './counselors-agenda.component.html',
  styleUrls: ['./counselors-agenda.component.css'],
})
export class CounselorsAgendaComponent implements OnInit {

  environment = environment;
  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  locale: string = 'fr';

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  view: CalendarView = CalendarView.Week;

  // excludeDays: number[] = [0, 8];

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: any;
  };

  actions: CalendarEventAction[] = [{
    label: '<i class="far fa-trash-alt"></i>',
    onClick: ({ event }: { event: CalendarEvent }): void => {
      console.log("event", event);
      this.deleteEvent(event);
    },
  }];

  refresh: Subject<any> = new Subject();

  events: any[] = [];

  activeDayIsOpen: boolean = false;
  

  constructor(public api:ApiService, 
    private modal: NgbModal,
    private _flashMessagesService: FlashMessagesService,
  ) { 
    this.getAppointments();
  }

  ngOnInit() {
  }

  getAppointments(){
    this.events = [];
    let limits = {
      view: this.view,
      viewDate: this.viewDate.toISOString().slice(0,10),
      startOfWeek: subDays(startOfWeek(this.viewDate, {weekStartsOn: 1}),1).toISOString().slice(0,10) + " 00:00:00",
      endOfWeek: (endOfWeek(this.viewDate, {weekStartsOn: 1})).toISOString().slice(0,10) + " 00:00:00",
      startOfMonth: subDays(startOfMonth(this.viewDate),1).toISOString().slice(0,10) + " 00:00:00",
      endOfMonth: (endOfMonth(this.viewDate)).toISOString().slice(0,10) + " 00:00:00",
    }
    let offset = new Date().getTimezoneOffset();
    this.api.post('auth/appointments', limits).subscribe((data: {id, mage, due_date,individual, status_appointment_id, timezone}[]) =>{
      
      data.forEach((item)=>{
        let explD = item.due_date.split(" ")[0].split("-");
        let explT = item.due_date.split(" ")[1].split(":");
        let dueDate = new Date(new Date(explD[0], explD[1]-1, explD[2], explT[0], explT[1], explT[2]).getTime() - (offset * 60000));
        let endDate = new Date(dueDate.getTime() + 30 * 60000);
        let title = '';
        if(item.status_appointment_id != 1) {
          title = '<div class="individualInfo status' + item.status_appointment_id + '">' +
            '<img src="' + environment.uploadPath + "users/" + item.individual.image + '" class="rounded-circle" />' + 
            '<div class="individualName">' + item.individual.firstname + " " + item.individual.lastname + '</div>' +
            '</div>';
        } else{
          title = '<div class="status' + item.status_appointment_id + '">' + dueDate.toLocaleTimeString() + '</div>';
        }
        
        this.addEvent({
            title: title,
            start: dueDate,
            end: endDate,
            color: colors[item.status_appointment_id-1],
            actions: (item.status_appointment_id == 1) ? this.actions : null,
            appointment: item,
            id: item.id
        })
      });
    })
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
    }
    this.setView(CalendarView.Day);
  }

  hourSegmentClicked(event){
    let now = new Date().getTime();

    if(event.date.getTime() >= now) {
      let endDate = new Date(event.date.getTime() + 30 * 60000);
      
      this.api.post("store/appointment",{due_date:event.date, offset:new Date().getTimezoneOffset()}).subscribe(data=>{

      
      this.addEvent({
        title: '<div class="status1">' + event.date.toLocaleTimeString() + ' - disponible</div>',
        start: event.date,
        end: endDate,
        color: colors[0],
        actions: this.actions,
        appointment: data
      })
      })
    } else {
      this._flashMessagesService.show('La date est antérieur à aujourd\'hui.', { cssClass: 'alert-warning' });
    }
  }

  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action, event): void {
    console.log("event", event);
    if(event.appointment.status_appointment_id != 1) {
      const tmp = this.events.filter(obj=>{ return obj.appointment.id == event.appointment.id; });
      event = tmp[0];
      
      this.modalData = { event, action };
      this.modal.open(this.modalContent, { size: 'lg' });
    }
  }

  addEvent(event): void {
    this.events.push(event);
    this.refresh.next();
  }

  deleteEvent(eventToDelete) {
    this.api.get("delete/appointment/"+eventToDelete.appointment.id).subscribe(data=>{
      console.log("eventToDelete", eventToDelete);
      this.events = this.events.filter((event) => event.appointment.id !== eventToDelete.appointment.id);
      this.refresh.next();
    });
  }

  setView(view: CalendarView) {
    this.view = view;
    this.getAppointments();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getColorStatusAppointment(id) {
    const colors = ["secondary","info","primary","warning","success","danger"];

    return "bg-" + colors[id-1] + "-light";
  }
}
