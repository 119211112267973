import { AuthService } from './../../services/auth/auth.service';
import { SeoService } from './../../services/seo/seo.service';
import { OnInit } from '@angular/core';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api/api.service';
var TrainingsComponent = /** @class */ (function () {
    function TrainingsComponent(_activatedRoute, scrollTopService, api, auth, seo) {
        var _this = this;
        this._activatedRoute = _activatedRoute;
        this.scrollTopService = scrollTopService;
        this.api = api;
        this.auth = auth;
        this.seo = seo;
        this.environment = environment;
        this.fileCategory = {
            id: null, name: null
        };
        this.pageSeo = {
            "title": "",
            "description": "",
            "og:url": "",
            "og:title": "",
            "og:description": "",
            "og:image": "",
            "twitter:title": "",
            "twitter:description": "",
        };
        this.sliceLimit = 4;
        this.account = this.auth.authenticated();
        this._activatedRoute.paramMap.subscribe(function (param) {
            _this.selectedCat = param.get('slugCategory');
            if (_this.selectedCat) {
                _this.sliceLimit = 9999;
                _this.getTrainings('trainings/all/category/slug/' + _this.selectedCat);
            }
            else {
                _this.sliceLimit = 4;
                _this.getTrainings('trainings/all/byCategory');
            }
        });
    }
    TrainingsComponent.prototype.setSeoGlobal = function () {
        this.seo.set({
            "title": "Toutes les formations de FamiliPsy Academy",
            "description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
            "og:url": this.environment.siteUrl + '/trainigs',
            "og:title": "Toutes les formations de FamiliPsy Academy",
            "og:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
            "og:image": environment.uploadPath + 'trainings',
            "twitter:title": "Toutes les formations de FamiliPsy Academy",
            "twitter:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !"
        });
    };
    TrainingsComponent.prototype.setSeoCategory = function () {
        this.seo.set({
            "title": "Toutes les formations \"" + this.trainingsByCategories[0].name + "\" de FamiliPsy Academy",
            "description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
            "og:url": this.environment.siteUrl + '/trainigs',
            "og:title": "Toutes les formations \"" + this.trainingsByCategories[0].name + "\" de FamiliPsy Academy",
            "og:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
            "og:image": environment.uploadPath + 'trainings',
            "twitter:title": "Toutes les formations \"" + this.trainingsByCategories[0].name + "\" de FamiliPsy Academy",
            "twitter:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !"
        });
    };
    TrainingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.api.get("categories").subscribe(function (data) {
            _this.categories = data;
            _this.searchResults = data;
        });
        this.scrollTopService.setScrollTop();
    };
    TrainingsComponent.prototype.getTrainings = function (url) {
        var _this = this;
        this.api.get(url).subscribe(function (data) {
            _this.trainingsByCategories = data;
            if (_this.selectedCat) {
                _this.setSeoCategory();
            }
            else {
                _this.setSeoGlobal();
            }
        });
    };
    TrainingsComponent.prototype.search = function () {
        var _this = this;
        this.all = false;
        this.api.post("trainings/all/byName", { term: this.searchPlayload }).subscribe(function (data) {
            _this.searchResults = data;
        });
    };
    return TrainingsComponent;
}());
export { TrainingsComponent };
