/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page.component";
import * as i3 from "../../../services/api/api.service";
import * as i4 from "@angular/router";
var styles_PageComponent = [i0.styles];
var RenderType_PageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageComponent, data: {} });
export { RenderType_PageComponent as RenderType_PageComponent };
export function View_PageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "contentWrapper"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_co.page == null) ? null : _co.page.slug), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.page == null) ? null : _co.page.title); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.page == null) ? null : _co.page.content); _ck(_v, 5, 0, currVal_2); }); }
export function View_PageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page", [], null, null, null, View_PageComponent_0, RenderType_PageComponent)), i1.ɵdid(1, 114688, null, 0, i2.PageComponent, [i3.ApiService, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageComponentNgFactory = i1.ɵccf("app-page", i2.PageComponent, View_PageComponent_Host_0, {}, {}, []);
export { PageComponentNgFactory as PageComponentNgFactory };
