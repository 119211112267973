var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericProvider } from '../genericprovider.provider';
import { User } from '../../../model/user.model';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { CONFIGURATION } from '../../../configurations/configurations';
import { catchError, tap } from 'rxjs/operators';
import { FlashMessagesService } from 'angular2-flash-messages';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
var AuthenticationService = /** @class */ (function (_super) {
    __extends(AuthenticationService, _super);
    function AuthenticationService(http, cookieService, _flashMessagesService, router) {
        var _this = _super.call(this, _flashMessagesService) || this;
        _this.http = http;
        _this.cookieService = cookieService;
        _this._flashMessagesService = _flashMessagesService;
        _this.router = router;
        _this.userUpdated = new EventEmitter();
        return _this;
    }
    AuthenticationService.prototype.getCurrentUser = function () {
        return this.currentUser;
    };
    AuthenticationService.prototype.setCurrentUser = function (user) {
        this.currentUser = user;
        this.userUpdated.emit(user);
    };
    AuthenticationService.prototype.getCookie = function () {
        if (this.checkCookie()) {
            return this.cookieService.get(CONFIGURATION.cookie);
        }
        return null;
    };
    AuthenticationService.prototype.setCookie = function (cookieValue) {
        if (!this.checkCookie() || this.getCookie() !== cookieValue) {
            this.cookieService.set(CONFIGURATION.cookie, cookieValue);
        }
    };
    AuthenticationService.prototype.checkCookie = function () {
        return this.cookieService.check(CONFIGURATION.cookie);
    };
    AuthenticationService.prototype.deleteCookie = function () {
        this.cookieService.delete(CONFIGURATION.cookie);
    };
    AuthenticationService.prototype.signup = function (user) {
        return this.http.post(this.api_url + '/auth/signup', user).pipe(catchError(this.handleError('AuthenticationProvider : signup()', false)));
    };
    AuthenticationService.prototype.login = function (user, remember) {
        var _this = this;
        return this.http.post(this.api_url + '/auth/login', user, { observe: 'response' }).pipe(tap(function (obj) {
            var token = obj.headers.get(CONFIGURATION.jwt_token);
            _this.setCookie(token);
            sessionStorage.setItem(CONFIGURATION.session_storage, token);
        }), catchError(this.handleError('AuthenticationProvider : login()', 'error')));
    };
    AuthenticationService.prototype.getCurrentUserInfos = function () {
        var _this = this;
        var user = new User();
        user.email = 'error';
        return this.http.get(this.api_url + '/auth/getuserinformations').pipe(tap(function (obj) {
            _this.setCurrentUser(obj);
        }), catchError(this.handleError('AuthenticationProvider : getCurrentUser()', user)));
    };
    AuthenticationService.prototype.changePassword = function (password, newPassword) {
        return this.http.put(this.api_url + '/auth/password', { oldPassword: password, newPassword: newPassword }).pipe(catchError(this.handleError('AuthenticationProvider : changePassword()', false)));
    };
    AuthenticationService.prototype.logout = function () {
        this.setCurrentUser(undefined);
        this.deleteCookie();
        this.router.navigate(['accueil']);
    };
    return AuthenticationService;
}(GenericProvider));
export { AuthenticationService };
