<section id="newsletters-section" class="">
  <div class="container">
    <div class="sub-session center">
      <h1 class="subsession-title bold">Tenez moi au courant de toutes l'activité de FAMILIPSY<div></div>
      </h1>
      <div class="row">
        <p class="dark-color center">En vous inscrivant ici, vous recevrez dans votre boite mail toutes l'activité de FamiliPsy</p>
      </div>
      <div class="row">
        <form class="shadow-input" (submit)="subscribe()">
          <input type="text" class="dark-color white-color-bg" placeholder="Email" [(ngModel)]="email"
            [ngModelOptions]="{standalone: true}">
          <button class="main-button-square" type="submit">Je m'inscris</button>
        </form>
      </div>
    </div>
  </div>
</section>