import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  subjects = [
    {title: "Demande d'information"},
    {title: "Partenaire/contributeur"},
    {title: "Devenir formateur de Familipsy Academy"},
    {title: "Entreprises/Collectivités/formations professionnelles"},
    {title: "Bon cadeau : offrir une formation"},
    {title: "Conseiller/Psychologue"},
    {title: "Mon Abonnement"},
    {title: "Autre (merci de spécifier dans le message)"},
  ];

  contactForm: FormGroup;
  inSubmit = false;

  constructor(
    private _flashMessagesService: FlashMessagesService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.initContactForm();
  }

  initContactForm() {
    this.contactForm = new FormGroup({
      lastname: new FormControl('', [Validators.required]),
      firstname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', []),
      subject: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    })
  }

  get f() { return this.contactForm.controls; }

  private prepareMessage() {

    const messageContact = new FormData();
    messageContact.append('lastname', this.f.lastname.value);
    messageContact.append('firstname', this.f.firstname.value);
    messageContact.append('email', this.f.email.value);
    messageContact.append('phone', this.f.phone.value);
    messageContact.append('subject', this.f.subject.value);
    messageContact.append('message', this.f.message.value);

    return messageContact;
  }

  submitForm() {
    this.inSubmit=true;
    if(this.contactForm.controls['lastname'].errors) {
      this._flashMessagesService.show('Veulliez verifier votre nom', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }
    if(this.contactForm.controls['firstname'].errors) {
      this._flashMessagesService.show('Veulliez verifier votre prénom', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }
    if(this.contactForm.controls['email'].errors) {
      this._flashMessagesService.show('Veulliez verifier le format de votre adresse mail', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }
    if(this.contactForm.controls['subject'].errors) {
      this._flashMessagesService.show('Veulliez choisir un sujet', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }
    if(this.contactForm.controls['message'].errors) {
      this._flashMessagesService.show('Veulliez verifier votre message', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }

    const messageContact = this.prepareMessage();
    this.api.post('email/contact', messageContact).subscribe((data) => {
      this.inSubmit = false;
      this._flashMessagesService.show('Votre message a bien été envoyé.', { cssClass: 'alert-success', timeout: 4000 });
      return;
    })
  }
}
