import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Ressource } from '../../../model/ressource.model';
import { ProfileService } from '../../../shared/providers/profile/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../../shared/providers/modal/modal.service';
import { ApiService } from '../../../services/api/api.service';
import { AuthService } from '../../../services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';


@Component({
  selector: 'app-ressource-detail',
  templateUrl: './ressource-detail.component.html',
  styleUrls: ['./ressource-detail.component.css']
})
export class RessourceDetailComponent implements OnInit {
  
  environment = environment;
  ressource: Ressource;
  account: any;
  video: any;
  bodyText: any;
  isFavourite: Boolean = false;
  
  upgrade = false;
  authMembership: any = null;
  @ViewChild('memberOnlyModal') memberOnlyModal: TemplateRef<any>;
  @ViewChild('videoModal') videoModal: TemplateRef<any>;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private _sanitizer: DomSanitizer,
    private modalS: ModalService,
    private modal: NgbModal,
    private _flashMessagesService: FlashMessagesService,
  ) { }

  ngOnInit() {  
    this.account = this.auth.authenticated();
    this.authMembership = this.auth.membership();
    this._activatedRoute.paramMap.subscribe(param => {
      if(param.get('slugRessource')) {
        this.api.get('files/getBySlug/' + param.get('slugRessource')).subscribe((data: Ressource) => {
          this.ressource = data;
          this.checkIfIsFavourite();
          if(this.ressource.type == 'video') this.video = this._sanitizer.bypassSecurityTrustResourceUrl(data.value);
        });
      } else {
        this.router.navigate(['accueil']);
      }
    });
  }

  checkIfIsFavourite() {
    if(this.auth.authenticated()) {
      this.api.get('favourite/check/file_id/' + this.ressource.id).subscribe((data: Boolean)=>{
        this.isFavourite = data;
      })
    }
  }

  viewRessource() {
    if(this.checkAccessibility()) {
      const url = (this.ressource.type == 'file') ? environment.filePath : '';
      window.open(url + this.ressource.value);
    }  else {
      this.modal.open(this.memberOnlyModal, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
    }
  }

  openVideo() {
    let content;

    if(this.checkAccessibility()) {

      const text = '<iframe class="embed-responsive-item" src="' + this.ressource.value + '" id="video" allowscriptaccess="always" allow="autoplay" allowfullscreen></iframe>';
      this.bodyText = this._sanitizer.bypassSecurityTrustHtml(text);

      content = this.videoModal;
    } else {
      content = this.memberOnlyModal;
    }

    this.modal.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
    
  }

  checkAccessibility() {

    let access = false;
    
    if(this.ressource.membership_id == 0) { access = true; }
    else if(this.ressource.membership_id != 0 && this.authMembership == null) { access = false; this.upgrade = false; } 
    else if(this.ressource.membership_id > this.authMembership) { access = false; this.upgrade = true; }
    else if(this.ressource.membership_id <= this.authMembership) { access = true; this.upgrade = false; }

    return access;
  }

  closeModal(id: string) {
    this.stopVideo();
    this.modalS.close(id);
  }

  stopVideo() {
    var videos = document.querySelectorAll('iframe');
    Array.prototype.forEach.call(videos, function (video) {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause();
      } else {
        var src = video.src;
        video.src = src;
      }
    });
  };

  addToFavourites() {
    
    if(this.auth.isAuthenticated()) {
      this.api.post("favourite/file/create",{file_id: this.ressource.id}).subscribe(data=>{
        this.isFavourite = true;
        this._flashMessagesService.show('La ressource a bien été ajoutée à vos favoris.',
          { cssClass: 'alert-success' });
      })
    } else {
      this.modal.open(this.memberOnlyModal, { size: 'lg' });
    }
  }
}
