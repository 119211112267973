/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./counselors-menu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./counselors-menu.component";
var styles_CounselorsMenuComponent = [i0.styles];
var RenderType_CounselorsMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CounselorsMenuComponent, data: {} });
export { RenderType_CounselorsMenuComponent as RenderType_CounselorsMenuComponent };
export function View_CounselorsMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 45, "div", [["class", "space-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "col text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "a", [["class", "pl-3 pt-3 pb-3 d-block rounded"], ["routerLink", "/counselor/dashboard"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(5, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fas fa-columns mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Mon Tableau de bord "])), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "col text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "a", [["class", "pl-3 pt-3 pb-3 d-block rounded"], ["routerLink", "/counselor/my-profile"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(14, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "far fa-user mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Mon profil "])), (_l()(), i1.ɵeld(19, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 7, "div", [["class", "col text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 6, "a", [["class", "pl-3 pt-3 pb-3 d-block rounded"], ["routerLink", "/counselor/consultations"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(23, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [["class", "far fa-list-alt mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Mes consultations "])), (_l()(), i1.ɵeld(28, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 7, "div", [["class", "col text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 6, "a", [["class", "pl-3 pt-3 pb-3 d-block rounded"], ["routerLink", "/counselor/agenda"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 31).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(31, 671744, [[8, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(32, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 7, { links: 1 }), i1.ɵqud(603979776, 8, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(35, 0, null, null, 0, "i", [["class", "far fa-calendar-alt mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Mes disponibilit\u00E9s "])), (_l()(), i1.ɵeld(37, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 7, "div", [["class", "col text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 6, "a", [["class", "pl-3 pt-3 pb-3 d-block rounded"], ["routerLink", "/counselor/avis"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 40).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(40, 671744, [[10, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(41, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 9, { links: 1 }), i1.ɵqud(603979776, 10, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(44, 0, null, null, 0, "i", [["class", "far fa-star mr-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Mes avis "]))], function (_ck, _v) { var currVal_2 = "/counselor/dashboard"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 5, 0, currVal_3); var currVal_6 = "/counselor/my-profile"; _ck(_v, 13, 0, currVal_6); var currVal_7 = "active"; _ck(_v, 14, 0, currVal_7); var currVal_10 = "/counselor/consultations"; _ck(_v, 22, 0, currVal_10); var currVal_11 = "active"; _ck(_v, 23, 0, currVal_11); var currVal_14 = "/counselor/agenda"; _ck(_v, 31, 0, currVal_14); var currVal_15 = "active"; _ck(_v, 32, 0, currVal_15); var currVal_18 = "/counselor/avis"; _ck(_v, 40, 0, currVal_18); var currVal_19 = "active"; _ck(_v, 41, 0, currVal_19); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 13).target; var currVal_5 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 22).target; var currVal_9 = i1.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_8, currVal_9); var currVal_12 = i1.ɵnov(_v, 31).target; var currVal_13 = i1.ɵnov(_v, 31).href; _ck(_v, 30, 0, currVal_12, currVal_13); var currVal_16 = i1.ɵnov(_v, 40).target; var currVal_17 = i1.ɵnov(_v, 40).href; _ck(_v, 39, 0, currVal_16, currVal_17); }); }
export function View_CounselorsMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-counselors-menu", [], null, null, null, View_CounselorsMenuComponent_0, RenderType_CounselorsMenuComponent)), i1.ɵdid(1, 114688, null, 0, i4.CounselorsMenuComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CounselorsMenuComponentNgFactory = i1.ɵccf("app-counselors-menu", i4.CounselorsMenuComponent, View_CounselorsMenuComponent_Host_0, {}, {}, []);
export { CounselorsMenuComponentNgFactory as CounselorsMenuComponentNgFactory };
