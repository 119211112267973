<section id="conferences-section" class="" #scrollChatBody>
  <div class="container">
    <h1 class="session-title">Recherche guidée<div></div></h1>
    <div id="chatBox">
      <div id="bodyChatBox" class="body">
        <ng-container *ngFor="let message of messages; let last = last">
          <div [ngClass]="message.who">
            <span *ngIf="message.bubble"><img src="assets/imgs/chat-loading.gif"></span>
            <span *ngIf="!message.options && !message.bubble" [innerHTML]="message.human ? message.human : message.text"></span>
            <ng-container *ngIf="message.options && !message.bubble && !message.checkboxes">
              <button class="choice" *ngFor="let option of message.options"
                (click)="makeChoice(option)">{{ option.text }}</button>
            </ng-container>
            <ng-container *ngIf="message.checkboxes && !message.options && !message.bubble">
              <ng-container *ngFor="let checkbox of message.checkboxes">
                <button *ngIf="checkbox.level && !checkbox.next" [class]="toggleClassAnswer(checkbox.level, checkbox.id)" (click)="toggleAnwser(checkbox.level, checkbox.id, checkbox.text)">{{ checkbox.text }}</button>
                <br *ngIf="checkbox.next"/>
                <br *ngIf="checkbox.next"/>
                <button *ngIf="checkbox.next" class="checkbox" (click)="makeChoice(checkbox)">{{ checkbox.text }}</button>
              </ng-container>
            </ng-container>
          </div>
          {{last ? scrollToBottom() : ''}}
        </ng-container>
      </div>
    </div>
  </div>
</section>