import { Injectable, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { FlashMessagesService } from 'angular2-flash-messages';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: any;
  url = [null,null,"individual","counselor"];
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private router: Router,
    private _flashMessagesService: FlashMessagesService,
  ) { }

  /**
   * this is used to clear anything that needs to be removed
   */
  clear(): void {
    localStorage.clear();
  }

  /**
   * retrieve user authenticated
   * @return user object
   */
  authenticated(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  /**
   * retrieve user authenticated
   * @return user object
   */
  membership(): any {
    return localStorage.getItem('membership_id');
  }

  updateAuthenticated() {
    this.change.emit(this.user);
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    return localStorage.getItem('user') != null;
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isIndividual(): boolean {
    return (localStorage.getItem('user_type_id') == '2') ? true : false;
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isCounselor(): boolean {
    return (localStorage.getItem('user_type_id') == '3') ? true : false;
  }

  login(data): void {
    this.http.post(API_URL + 'login', data).subscribe((data: {id:null,lastname:null,firstname:null,email:null,activated:null,image:null,user_type_id:string,membership_id:string,token:null}) => {
        this.user = data;
        this.updateAuthenticated();
          localStorage.setItem('user', JSON.stringify(data));
          localStorage.setItem('token', data.token);
          localStorage.setItem('user_type_id', data.user_type_id);
          localStorage.setItem('membership_id', data.membership_id);
          localStorage.setItem('email', data.email);
          localStorage.setItem('activated', data.activated);
          // localStorage.setItem('baseUrl', this.baseUrl[0]);
          /* If there is many user_type_id use below */
          this.api.setHeaderOptions();
          // this.router.navigateByUrl("test");
          /* If there is many user_type_id use below */
          this.router.navigate([this.url[data.user_type_id]+'/dashboard']);
          
        // }
      }, err => {
        this._flashMessagesService.show("Il semblerait qu'il y ait une erreur sur vos codes d'accès. Veuillez vérifier et réessayer  à nouveau.", { cssClass: 'alert-danger', timeout: 4000 });
      }
    );
  }

  /**
   * this is used to clear local storage and also the route to login
   */
  logout(): any {
    this.http.get(API_URL + "logout").subscribe(data => {
      this.clear();
      this.user = undefined;
      this.change.emit(this.user);
      this.router.navigate(["accueil"]);
    })
  }
}
