import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/api/api.service';

@Component({
  selector: 'app-counselors-zoom-account',
  templateUrl: './counselors-zoom-account.component.html',
  styleUrls: ['./counselors-zoom-account.component.css']
})
export class CounselorsZoomAccountComponent implements OnInit {

  zoom: any;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
  }

  init() {
    this.getMyCounselorZoomInfos();
  }

  getMyCounselorZoomInfos() {
    this.api.get("user/getMyCounselorZoomInfos").subscribe(data=>{
      this.zoom = data;
    })
  }

}
