import { Routes } from '@angular/router';
import { CounselorsComponent } from './counselors.component';
import { CounselorsDashboardComponent } from './pages/counselors-dashboard/counselors-dashboard.component';
import { CounselorsProfileComponent } from './pages/counselors-profile/counselors-profile.component';
import { CounselorsConsultationsComponent } from './pages/counselors-consultations/counselors-consultations.component';
import { CounselorsAgendaComponent } from './pages/counselors-agenda/counselors-agenda.component';
import { ZoomComponent } from './pages/zoom/zoom.component';
import { CounselorsFeedbacksComponent } from './pages/counselors-feedbacks/counselors-feedbacks.component';
var routes = [
    {
        path: '',
        component: CounselorsComponent,
        children: [
            { path: '', redirectTo: '/counselor/dashboard', pathMatch: 'full' },
            {
                path: 'dashboard',
                component: CounselorsDashboardComponent
            },
            {
                path: 'my-profile',
                component: CounselorsProfileComponent
            },
            {
                path: 'consultations',
                component: CounselorsConsultationsComponent
            },
            {
                path: 'avis',
                component: CounselorsFeedbacksComponent
            },
            {
                path: 'agenda',
                component: CounselorsAgendaComponent
            },
            {
                path: 'appointment/:id',
                component: ZoomComponent
            },
        ]
    },
];
var CounselorsRoutingModule = /** @class */ (function () {
    function CounselorsRoutingModule() {
    }
    return CounselorsRoutingModule;
}());
export { CounselorsRoutingModule };
