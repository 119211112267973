/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./counselors.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./counselors-sidebar/counselors-sidebar.component.ngfactory";
import * as i3 from "./counselors-sidebar/counselors-sidebar.component";
import * as i4 from "../../services/auth/auth.service";
import * as i5 from "../../services/api/api.service";
import * as i6 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i7 from "angular2-flash-messages/module/flash-messages.service";
import * as i8 from "@angular/router";
import * as i9 from "./counselors.component";
var styles_CounselorsComponent = [i0.styles];
var RenderType_CounselorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CounselorsComponent, data: {} });
export { RenderType_CounselorsComponent as RenderType_CounselorsComponent };
export function View_CounselorsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "space "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "col-12 col-sm-6 col-md-4 col-lg-3"], ["id", "sidebar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["id", "counselors-sidebar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-counselors-sidebar", [], null, null, null, i2.View_CounselorsSidebarComponent_0, i2.RenderType_CounselorsSidebarComponent)), i1.ɵdid(6, 114688, null, 0, i3.CounselorsSidebarComponent, [i4.AuthService, i5.ApiService, i6.NgbModal, i7.FlashMessagesService, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-12 col-sm-6 col-md-8 col-lg-9"], ["id", "content-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 6, 0); _ck(_v, 9, 0); }, null); }
export function View_CounselorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-counselors", [], null, null, null, View_CounselorsComponent_0, RenderType_CounselorsComponent)), i1.ɵdid(1, 114688, null, 0, i9.CounselorsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CounselorsComponentNgFactory = i1.ɵccf("app-counselors", i9.CounselorsComponent, View_CounselorsComponent_Host_0, {}, {}, []);
export { CounselorsComponentNgFactory as CounselorsComponentNgFactory };
