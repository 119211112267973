import { ChangeDetectorRef, OnInit, TemplateRef } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api/api.service';
import { AuthService } from '../../../services/auth/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var CounselorsSidebarComponent = /** @class */ (function () {
    function CounselorsSidebarComponent(auth, api, modal, _flashMessagesService, cdRef) {
        this.auth = auth;
        this.api = api;
        this.modal = modal;
        this._flashMessagesService = _flashMessagesService;
        this.cdRef = cdRef;
        this.environment = environment;
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.roundCropper = true;
        this.autoCrop = false;
        this.random = Math.floor(Math.random() * 100);
    }
    CounselorsSidebarComponent.prototype.ngOnInit = function () {
        this.me = this.auth.authenticated();
        // this.croppedImage = this.environment.uploadPath + 'users/' + this.me?.image;
    };
    CounselorsSidebarComponent.prototype.submitMyAccount = function () {
        var _this = this;
        this.api.get('submitCounselorAccount/' + this.me.id).subscribe(function (data) {
            _this._flashMessagesService.show('Votre compte a bien été soumis à validation.', { cssClass: 'alert-success' });
            localStorage.setItem('activated', "3");
            _this.me.activated = "3";
            localStorage.setItem('user', JSON.stringify(_this.me));
        });
    };
    CounselorsSidebarComponent.prototype.openModalImage = function () {
        this.modalOpened = this.modal.open(this.imageModal, { size: 'lg' });
    };
    CounselorsSidebarComponent.prototype.fileChangeEvent = function (event) {
        this.imageChangedEvent = event;
        if (event.target.files && event.target.files[0]) {
            var file = event.target.files[0];
            this.imageToUpload = file;
        }
    };
    CounselorsSidebarComponent.prototype.imageCropped = function (event) {
        this.croppedImage = event.base64;
    };
    CounselorsSidebarComponent.prototype.imageLoaded = function () {
        // show cropper
    };
    CounselorsSidebarComponent.prototype.cropperReady = function () {
        // cropper ready
    };
    CounselorsSidebarComponent.prototype.loadImageFailed = function () {
        // show message
    };
    CounselorsSidebarComponent.prototype.base64ToFile = function (data, filename) {
        var arr = data.split(',');
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    CounselorsSidebarComponent.prototype.updateImage = function (croppedImage) {
        var _this = this;
        var image = this.base64ToFile(this.croppedImage, this.imageToUpload.name);
        var dataToSave = new FormData();
        dataToSave.append('image', image, image.name);
        this.api.post('user/updateImage', dataToSave).subscribe(function (data) {
            _this.random = Math.floor(Math.random() * 100);
            _this.modalOpened.close();
        });
    };
    return CounselorsSidebarComponent;
}());
export { CounselorsSidebarComponent };
