import { OnInit } from '@angular/core';
var CounselorsProfileComponent = /** @class */ (function () {
    function CounselorsProfileComponent() {
        this.openedCards = -1;
    }
    CounselorsProfileComponent.prototype.ngOnInit = function () {
    };
    CounselorsProfileComponent.prototype.openCard = function (cardIdx, comp) {
        this.openedCards = (this.openedCards == cardIdx) ? -1 : cardIdx;
        comp.init();
    };
    return CounselorsProfileComponent;
}());
export { CounselorsProfileComponent };
