import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-counselors-profile',
  templateUrl: './counselors-profile.component.html',
  styleUrls: ['./counselors-profile.component.css']
})
export class CounselorsProfileComponent implements OnInit {
  openedCards = -1;

  constructor() { }

  ngOnInit() {
  }

  openCard(cardIdx: number, comp) {
    this.openedCards = (this.openedCards == cardIdx) ? -1 : cardIdx;
    comp.init();
  }

}
