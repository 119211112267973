import { OnInit } from '@angular/core';
import { CITATION } from '../../../mocks/citation.mock';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api/api.service';
var QuoteComponent = /** @class */ (function () {
    function QuoteComponent(api) {
        this.api = api;
        this.environment = environment;
        this.quoteToShow = CITATION[0];
        this.quote = { content: null, author: null, image: "Citation.jpg" };
    }
    QuoteComponent.prototype.ngOnInit = function () {
        this.getAll();
    };
    QuoteComponent.prototype.getAll = function () {
        var _this = this;
        this.api.get("quotes/today/getOne").subscribe(function (data) {
            _this.quote = data;
        });
    };
    return QuoteComponent;
}());
export { QuoteComponent };
