import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
var FeedbackComponent = /** @class */ (function () {
    function FeedbackComponent(_activatedRoute, router, api, _flashMessagesService) {
        this._activatedRoute = _activatedRoute;
        this.router = router;
        this.api = api;
        this._flashMessagesService = _flashMessagesService;
        this.env = environment;
        this.feedbackForm = new FormGroup({
            message: new FormControl('', [Validators.required]),
        });
        this.star = 3;
        this.feedbackDone = false;
    }
    FeedbackComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._activatedRoute.paramMap.subscribe(function (param) {
            if (param.get('token') && param.get('appointmentId')) {
                _this.token = param.get('token');
                _this.appointmentId = param.get('appointmentId');
                _this.checkFeedbackAvailability();
            }
            else {
                _this.router.navigate(['**']);
            }
        });
    };
    FeedbackComponent.prototype.checkFeedbackAvailability = function () {
        var _this = this;
        this.api.get("checkFeedbackAvailability/" + this.token + "/" + this.appointmentId).subscribe(function (data) {
            if (!data)
                _this.router.navigate(['page-introuvable']);
            else {
                var offset = new Date().getTimezoneOffset();
                _this.appointment = data;
                var explD = data.due_date.split(" ")[0].split("-");
                var explT = data.due_date.split(" ")[1].split(":");
                var dueDate = new Date(new Date(explD[0], explD[1] - 1, explD[2], explT[0], explT[1], explT[2]).getTime() - (offset * 60000));
                _this.appointment.due_date = dueDate;
                // this.initFeedbackForm();
            }
        });
    };
    FeedbackComponent.prototype.initFeedbackForm = function () {
        this.feedbackForm = new FormGroup({
            message: new FormControl('', [Validators.required]),
        });
    };
    FeedbackComponent.prototype.prepareMessage = function () {
        var messageContact = new FormData();
        messageContact.append('message', this.feedbackForm.controls.message.value);
        messageContact.append('token', this.token);
        messageContact.append('appointmentId', this.appointmentId);
        messageContact.append('rate', this.star.toString());
        return messageContact;
    };
    FeedbackComponent.prototype.subtmitFeedback = function () {
        var _this = this;
        if (this.feedbackForm.controls.message.value == "") {
            this._flashMessagesService.show('Veulliez verifier votre message', { cssClass: 'alert-danger', timeout: 4000 });
            return;
        }
        var messageContact = this.prepareMessage();
        this.api.post('submitFeedback', messageContact).subscribe(function (data) {
            _this.feedbackDone = true;
            _this._flashMessagesService.show('Votre message a bien été envoyé.', { cssClass: 'alert-success', timeout: 4000 });
        });
    };
    return FeedbackComponent;
}());
export { FeedbackComponent };
