import { AuthService } from './../../../services/auth/auth.service';

import { Component, OnInit } from '@angular/core';
import { ScrollTopService } from '../../../shared/providers/scrolltop/scroll-top-service.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ProfileService } from '../../../shared/providers/profile/profile.service';
import { AuthenticationService } from '../../../shared/providers/authentication/authentication.service';
import { ApiService } from '../../../services/api/api.service';
import { SeoService } from '../../../services/seo/seo.service';

@Component({
  selector: 'app-ressource-list',
  templateUrl: './ressource-list.component.html',
  styleUrls: ['./ressource-list.component.css']
})
export class RessourceListComponent implements OnInit {

  environment = environment;
  categories: any;
  searchPlayload: string;
  all: boolean;
  selectedCat: string;
  searchResults: any;
  ressourcesByCategories: any;
  
  account: any;
  sliceLimit: number;
  pageSeo = {
    "title": "",
    "description": "",
    "og:url": "",
    "og:title": "",
    "og:description": "",
    "og:image": "",
    "twitter:title": "",
    "twitter:description": "",
  };

  constructor(
    private _activatedRoute: ActivatedRoute, private scrollTopService: ScrollTopService,
    private api: ApiService,
    private auth: AuthService,
    private seo: SeoService
  ) { 
    this.sliceLimit = 4;
    this.account = this.auth.authenticated();
    this._activatedRoute.paramMap.subscribe(param => {
      this.selectedCat = param.get('slugCategory');
      if(this.selectedCat){ 
        this.sliceLimit = 9999;
        this.getRessources('files/all/category/slug/'+this.selectedCat);
      } else {
        this.sliceLimit = 4; this.getRessources('files/all/byCategory');
      }
    });
  }

  allCategories(){
    this.searchResults = this.categories;
  }

  getFileCategories(){
    this.api.get("list/fileCategories").subscribe(data=>{
      this.categories = data;
    })
  }

  ngOnInit() {
    this.getFileCategories();
    
    this.scrollTopService.setScrollTop();
  }

  setSeoGlobal(){
    this.seo.set({
      "title": "Toutes les ressources de FamiliPsy Academy",
      "description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
      "og:url": this.environment.siteUrl + '/ressources',
      "og:title": "Toutes les ressources de FamiliPsy Academy",
      "og:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
      "og:image": environment.uploadPath + 'trainings',
      "twitter:title": "Toutes les ressources de FamiliPsy Academy",
      "twitter:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !"
    })
  }

  setSeoCategory(){
    this.seo.set({
      "title": "Toutes les ressources \"" + this.ressourcesByCategories[0].name + "\" de FamiliPsy Academy",
      "description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
      "og:url": this.environment.siteUrl + '/trainigs',
      "og:title": "Toutes les ressources \"" + this.ressourcesByCategories[0].name + "\" de FamiliPsy Academy",
      "og:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
      "og:image": environment.uploadPath + 'trainings',
      "twitter:title": "Toutes les ressources \"" + this.ressourcesByCategories[0].name + "\" de FamiliPsy Academy",
      "twitter:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !"
    })
  }

  getRessources(url){
    this.api.get(url).subscribe((data) => {
      this.ressourcesByCategories = data;

      if(this.selectedCat){ this.setSeoCategory();
      } else {
        this.setSeoGlobal();
      }
    })
  }

}
