<div id="faqs-section" class="contentWrapper">
  <div class="container">
    <h1>Foire aux questions</h1>
    <div class="row">
      <div class="col-md-4">
        <ul id="faqCategories">
          <li class="p-3 mb-1 cursor-pointer" [ngClass]="{'dark-color-bg':this.faqCategoryId == item.id}" *ngFor="let item of faqCategories; let i = index" (click)="this.faqCategoryId = item.id; getFaqs()">
            {{ item?.name }}
          </li>
        </ul>
      </div>
      <div class="col-md-8">

        <div class="row">
          <div class="col-md-12 mb-2" *ngFor="let q of faqs; let i=index">
            <button id="{{q?.id}}" type="button" class="btn btn-block p-3 text-left rounded-0 faqQuestion" [class.active]="faqActive == i"
              (click)="viewIncrement(i, q?.id);faqActive = i; " [attr.aria-expanded]="faqActive != i"
              [attr.aria-controls]="'Q' +q?.id">{{ q?.question }}</button>
            <div id="Q{{q?.id}}" class="faqContent p-5" [ngbCollapse]="faqActive != i">
              {{ q?.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>