import { SeoService } from './../../services/seo/seo.service';
import { OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { environment } from './../../../environments/environment';
import { ApiService } from '../../services/api/api.service';
import { AuthService } from '../../services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
var TrainingDetailsComponent = /** @class */ (function () {
    function TrainingDetailsComponent(_sanitizer, _activatedRoute, router, api, auth, scrollTopService, modal, seo, _flashMessagesService) {
        var _this = this;
        this._sanitizer = _sanitizer;
        this._activatedRoute = _activatedRoute;
        this.router = router;
        this.api = api;
        this.auth = auth;
        this.scrollTopService = scrollTopService;
        this.modal = modal;
        this.seo = seo;
        this._flashMessagesService = _flashMessagesService;
        this.environment = environment;
        this.isFavourite = false;
        this.tags = [];
        this.upgrade = false;
        this.authMembership = null;
        this.account = this.auth.authenticated();
        this.authMembership = parseInt(this.auth.membership());
        this._activatedRoute.paramMap.subscribe(function (param) {
            var slugCategory = param.get('slugCategory');
            var slugTraining = param.get('slugTraining');
            _this.api.get("trainings/getBySlug/" + slugTraining).subscribe(function (res) {
                _this.training = res;
                _this.checkIfIsFavourite();
                _this.setSeo();
                _this.training.problematics.forEach(function (item) {
                    item.tags.forEach(function (element) {
                        if (_this.tags.indexOf(element.name) == -1)
                            _this.tags.push(element.name);
                    });
                });
                if (_this.training.freeTrailer !== null) {
                    _this.videoPreview = _this._sanitizer.bypassSecurityTrustResourceUrl(_this.training.trailer);
                }
            });
        });
    }
    TrainingDetailsComponent.prototype.checkIfIsFavourite = function () {
        var _this = this;
        if (this.auth.authenticated()) {
            this.api.get('favourite/check/training_id/' + this.training.id).subscribe(function (data) {
                _this.isFavourite = data;
            });
        }
    };
    TrainingDetailsComponent.prototype.setSeo = function () {
        this.seo.set({
            'keywords': this.training.problematics.map(function (item) { return item.name; }).join(","),
            'title': this.training.title,
            'description': this.training.synopsis,
            'og:title': this.training.title,
            'og:url': this.environment.siteUrl + 'formation/' + this.training.category.slug + '/' + this.training.slug,
            'og:description': this.training.synopsis,
            'og:image': this.environment.uploadPath + 'trainings/' + this.training.image,
            'twitter:title': this.training.title,
            'twitter:description': this.training.synopsis,
        });
    };
    TrainingDetailsComponent.prototype.ngOnInit = function () {
        this.scrollTopService.setScrollTop();
    };
    TrainingDetailsComponent.prototype.addToFavourites = function () {
        var _this = this;
        if (this.auth.isAuthenticated()) {
            this.api.post("favourite/training/create", { training_id: this.training.id }).subscribe(function (data) {
                _this.isFavourite = true;
                _this._flashMessagesService.show('La formation a bien été ajoutée à vos favoris.', { cssClass: 'alert-success' });
            });
        }
        else {
            this.modal.open(this.memberOnlyModal, { size: 'lg' });
        }
    };
    return TrainingDetailsComponent;
}());
export { TrainingDetailsComponent };
