import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Slider } from '../../../model/slider.model';
import { SLIDER } from '../../../mocks/slider.mock';
import { DomSanitizer } from '@angular/platform-browser';
// import { ModalService } from '../../providers/modal/modal.service';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.css']
})
export class HomeSliderComponent implements OnInit {

  environment = environment;
  @Input() slides: any;
  @Input() zone: any;
  bodyText: any;
  private element: any;

  constructor(private _sanitizer: DomSanitizer,
    // private modal: ModalService,
    private modal: NgbModal,
    private api: ApiService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.getAll();
    
    // close modal on background click
    this.element.addEventListener('click', function (e: any) {
        if (e.target.className === 'modal') {
          this.modal.close();
        }
    });
  }

  getAll(){
    this.api.get('slides/getBySlug/'+this.zone).subscribe(data => {
      this.slides = data;
    })
  }
  
  openModal(videoModal: string, url) {
      const text = '<iframe class="embed-responsive-item" src="' + url + '" id="video" allowscriptaccess="always" allow="autoplay" allowfullscreen></iframe>';
      this.bodyText = this._sanitizer.bypassSecurityTrustHtml(text);
      this.modal.open(videoModal, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
  }

  stopVideo() {
    var videos = document.querySelectorAll('iframe');
    Array.prototype.forEach.call(videos, function (video) {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause();
      } else {
        var src = video.src;
        video.src = src;
      }
    });
  };
}
