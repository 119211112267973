import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../../../services/api/api.service';

@Component({
  selector: 'app-counselors-payment',
  templateUrl: './counselors-payment.component.html',
  styleUrls: ['./counselors-payment.component.css']
})
export class CounselorsPaymentComponent implements OnInit {
  newForm: FormGroup;

  constructor(
    private api: ApiService
  ) {
      this.initForm();
  }

  initForm() {
    this.newForm = new FormGroup({
      iban: new FormControl('', [Validators.required,]),
      pricing: new FormControl('', [Validators.required,]),
    });
  }

  ngOnInit() {
  }

  init() {
    this.getMyCounselorPayment();
  }

  getMyCounselorPayment() {
    this.api.get("user/getMyCounselorPaymentInfo").subscribe((data: any)=>{
      this.newForm.get("iban").setValue(data.iban);
      this.newForm.get("pricing").setValue(data.pricing);
    })
  }

  submit(){

    const dataToSend = {
      iban: this.newForm.get("iban").value,
      pricing: this.newForm.get("pricing").value,
    }
    this.api.post('users/counselor/update/payment', dataToSend).subscribe( data => {
    })
  }

}
