import { Component, OnInit, Input } from '@angular/core';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent implements OnInit {

  @Input()
  showText: Boolean;


  constructor(private scrollTopService: ScrollTopService) { }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
  }

}
