var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { StripeService } from "ngx-stripe";
import { ApiService } from '../../services/api/api.service';
var SignupComponent = /** @class */ (function () {
    function SignupComponent(_flashMessagesService, router, scrollTopService, activatedRoute, api, cd, stripeService) {
        var _this = this;
        this._flashMessagesService = _flashMessagesService;
        this.router = router;
        this.scrollTopService = scrollTopService;
        this.activatedRoute = activatedRoute;
        this.api = api;
        this.cd = cd;
        this.stripeService = stripeService;
        this.step = 1;
        // optional parameters
        this.elementsOptions = {
            locale: 'fr'
        };
        this.activatedRoute.paramMap.subscribe(function (param) {
            _this.offerId = param.get('offerType');
            _this.period = param.get('period');
            _this.title = _this.offerId === '0' ? 'Devenir Conseiller' : 'Création de compte';
            if (_this.offerId == '1') {
                _this.router.navigate(['/inscription/decouverte']);
            }
            if (_this.offerId !== '0') {
                _this.api.get("memberships/" + _this.offerId).subscribe(function (data) {
                    _this.offer = data;
                });
            }
        });
    }
    SignupComponent.prototype.ngOnInit = function () {
        this.scrollTopService.setScrollTop();
        this.signupForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            firstname: new FormControl('', Validators.required),
            lastname: new FormControl('', Validators.required),
            nickname: new FormControl('', Validators.required),
            newsletter: new FormControl(''),
            cgu: new FormControl('', Validators.required),
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
            confirmpassword: new FormControl('', [Validators.required]),
        });
    };
    Object.defineProperty(SignupComponent.prototype, "f", {
        get: function () { return this.signupForm.controls; },
        enumerable: true,
        configurable: true
    });
    SignupComponent.prototype.formSubmit = function () {
        if (this.step == 1)
            this.createUser();
    };
    SignupComponent.prototype.checkForm = function () {
        if (this.f.email.value === "") {
            this._flashMessagesService.show('Veulliez verifier le format de votre adresse mail', { cssClass: 'alert-danger', timeout: 4000 });
            return false;
        }
        if (this.f.password.value === "") {
            this._flashMessagesService.show('Le mot de passe doit contenir au moins 8 caractères', { cssClass: 'alert-danger', timeout: 5000 });
            return false;
        }
        if (this.f.password.value !== this.f.confirmpassword.value) {
            this._flashMessagesService.show('Les mots de passe ne sont pas identiques', { cssClass: 'alert-danger', timeout: 5000 });
            return false;
        }
        if (this.f.lastname.value === "" ||
            this.f.firstname.value === "" ||
            this.f.nickname.value === "") {
            this._flashMessagesService.show('Veuillez remplir les champs obligatoires', { cssClass: 'alert-danger', timeout: 5000 });
            return false;
        }
        if (this.f.cgu.value === "") {
            this._flashMessagesService.show("Veuillez accepter les conditions générales d'utilisation", { cssClass: 'alert-danger', timeout: 5000 });
            return false;
        }
        return true;
    };
    SignupComponent.prototype.createUser = function () {
        var _this = this;
        if (this.checkForm()) {
            var userToSave = new FormData();
            userToSave.append('firstname', this.f.firstname.value);
            userToSave.append('lastname', this.f.lastname.value);
            userToSave.append('nickname', this.f.nickname.value);
            userToSave.append('email', this.f.email.value);
            userToSave.append('newsletter', (this.f.newsletter.value) ? "1" : "0");
            userToSave.append('cgu', this.f.cgu.value);
            userToSave.append('password', this.f.password.value);
            userToSave.append('c_password', this.f.confirmpassword.value);
            userToSave.append('membership_id', "2");
            this.api.post("users/create", userToSave).subscribe(function (res) {
                if (res.error)
                    _this._flashMessagesService.show(res.message, { cssClass: 'alert-danger', timeout: 5000 });
                else {
                    _this.clientSecret = res.pm.client_secret;
                    _this.user = res.user;
                    _this.step = 2;
                    _this.initCard();
                }
            });
        }
    };
    SignupComponent.prototype.initCard = function () {
        var _this = this;
        document.body.scrollTop = 250;
        setTimeout(function (font) {
            _this.card = elements.create('card', {
                style: {
                    base: {
                        iconColor: '#666EE8',
                        color: '#31325F',
                        lineHeight: '40px',
                        fontWeight: 300,
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSize: "14px",
                        '::placeholder': {
                            color: '#CFD7E0'
                        }
                    }
                }
            });
            _this.card.mount('#card-element');
            _this.makePayment();
        }, 3000, false);
    };
    SignupComponent.prototype.makePayment = function () {
        var _this = this;
        var cardButton = document.getElementById('card-button');
        var clientSecret = cardButton.dataset.secret;
        cardButton.addEventListener('click', function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, setupIntent, error;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, stripe.confirmCardSetup(this.clientSecret, {
                            payment_method: {
                                card: this.card,
                                billing_details: { name: this.f.lastname.value + ' ' + this.f.firstname.value }
                            }
                        })];
                    case 1:
                        _a = _b.sent(), setupIntent = _a.setupIntent, error = _a.error;
                        if (error) {
                            // Display "error.message" to the user...
                            this._flashMessagesService.show("Une erreur s'est produit lors du paiement. Veuillez réessayer!", { cssClass: 'alert-danger', timeout: 5000 });
                        }
                        else {
                            // The card has been verified successfully...
                            this.api.post("users/payment", { userId: this.user.id, paymentMethod: setupIntent.payment_method, offer: this.offer, duration: this.period, registration: true }).subscribe(function (res) {
                                _this.step = 3;
                                _this.finalMessage = res.message;
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return SignupComponent;
}());
export { SignupComponent };
