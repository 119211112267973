import { Component, OnInit, Input } from '@angular/core';
import { PriceService } from '../../../../services/price/price.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.css']
})
export class BookComponent implements OnInit {

  @Input() modalData: any;
  @Input() closeAction: any;
  @Input() events: any[];

  buying: boolean;
  paymentIsDone: boolean = false;
  
  constructor(
    private priceService: PriceService
  ) { }

  buy() {
    this.buying = !this.buying;
  }

  ngOnInit() {
  }

}
