import { AuthService } from './../../services/auth/auth.service';
import { SeoService } from './../../services/seo/seo.service';

import { Component, OnInit } from '@angular/core';
import { TrainingService } from '../../shared/providers/trainings/training.service';
import { WrappedTrainings } from '../../model/wrapped-trainings.model';
import { CategoryService } from '../../shared/providers/category/category.service';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../shared/providers/profile/profile.service';
import { AuthenticationService } from '../../shared/providers/authentication/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { Category } from '../../model/category.model';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.css']
})
export class TrainingsComponent implements OnInit {

  environment = environment;
  trainings: WrappedTrainings[];

  searchResults: any;

  categories: Category[];
  trainingsByCategories: Category[];

  searchPlayload: string;

  all: boolean;
  selectedCat: string;

  ressources: any;
  fileCategory = {
    id: null, name: null
  };
  account: any;
  sliceLimit: number;
  pageSeo = {
    "title": "",
    "description": "",
    "og:url": "",
    "og:title": "",
    "og:description": "",
    "og:image": "",
    "twitter:title": "",
    "twitter:description": "",
  };

  constructor(
    private _activatedRoute: ActivatedRoute,
    private scrollTopService: ScrollTopService,
    private api: ApiService,
    private auth: AuthService,
    private seo: SeoService
  ) {
    this.sliceLimit = 4;
    this.account = this.auth.authenticated();
    this._activatedRoute.paramMap.subscribe(param => {
      this.selectedCat = param.get('slugCategory');
      if(this.selectedCat){ 
        this.sliceLimit = 9999;
        this.getTrainings('trainings/all/category/slug/'+this.selectedCat);
      } else {
        this.sliceLimit = 4; this.getTrainings('trainings/all/byCategory');
      }
    });
  }

  setSeoGlobal(){
    this.seo.set({
      "title": "Toutes les formations de FamiliPsy Academy",
      "description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
      "og:url": this.environment.siteUrl + '/trainigs',
      "og:title": "Toutes les formations de FamiliPsy Academy",
      "og:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
      "og:image": environment.uploadPath + 'trainings',
      "twitter:title": "Toutes les formations de FamiliPsy Academy",
      "twitter:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !"
    })
  }

  setSeoCategory(){
    this.seo.set({
      "title": "Toutes les formations \"" + this.trainingsByCategories[0].name + "\" de FamiliPsy Academy",
      "description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
      "og:url": this.environment.siteUrl + '/trainigs',
      "og:title": "Toutes les formations \"" + this.trainingsByCategories[0].name + "\" de FamiliPsy Academy",
      "og:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
      "og:image": environment.uploadPath + 'trainings',
      "twitter:title": "Toutes les formations \"" + this.trainingsByCategories[0].name + "\" de FamiliPsy Academy",
      "twitter:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !"
    })
  }
  
  ngOnInit() {
    this.api.get("categories").subscribe((data:Category[]) => {
      this.categories = data;
      this.searchResults = data;
    })
    
    this.scrollTopService.setScrollTop();
  }

  getTrainings(url){
    this.api.get(url).subscribe((data: Category[]) => {
      this.trainingsByCategories = data;

      if(this.selectedCat){ this.setSeoCategory();
      } else {
        this.setSeoGlobal();
      }
    })
  }

  search() {
    this.all = false;

    this.api.post("trainings/all/byName", {term:this.searchPlayload}).subscribe((data)=>{
      this.searchResults = data;
    })
  }
  

}
