<div class="row">
  <div class="col">
    <form [formGroup]="newForm" class="mt-4">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="lastname" class="bold dark-color">Nom *</label>
                <input type="text" class="form-control dark-color" id="lastname" formControlName="lastname">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="nickname" class="bold dark-color">Pseudo *</label>
                <input type="text" class="form-control dark-color" id="nickname" formControlName="nickname">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="new-password" class="bold dark-color">Mot de passe</label>
                <input type="password" class="form-control dark-color" id="new-password"
                  [(ngModel)]="passwordForm.newPassword" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="firstname" class="bold dark-color">Prénom *</label>
                <input type="text" class="form-control dark-color" id="firstname" formControlName="firstname">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="email" class="bold dark-color">Email *</label>
                <input type="text" class="form-control dark-color" id="email" formControlName="email">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="new-password-confirm" class="bold dark-color">Confirmation mot de passe</label>
                <input type="password" class="form-control dark-color" id="new-password-confirm"
                  [(ngModel)]="passwordForm.newPasswordConfirmation" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="video" class="bold dark-color">Lien Vidéo (Lien Youtube)</label>
                <input type="text" class="form-control dark-color" id="video" formControlName="video">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="shortDescription" class="bold dark-color">Titre et ancienneté *</label>
                <textarea class="form-control dark-color short-description"
                  formControlName="shortDescription"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="longDescription" class="bold dark-color">Description longue *</label>
                <textarea class="form-control dark-color long-description" formControlName="longDescription"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <button type="submit" class="main-button-round float-right" (click)="checkPersonalData()">Sauvegarder</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>