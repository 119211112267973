  <div class="modal-header dark-color-bg">
    <h4 class="modal-title" id="modal-basic-title">Accès membre uniquement</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="closeAction()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row m-5">
      <div class="col-3">
        <img src="{{ environment.uploadPath + 'contents/mascotte-big.png' }}" class="w-100">
      </div>
      <div class="col-9">
        <div *ngIf="!upgrade">
          <p>
            Cette section est accessible uniquement aux abonnés de FamiliPsy Academy.
          </p>
          <p>
            Nous vous invitons à vous <a routerLink="/connexion" (click)="closeAction()">connecter</a>.
          </p>
          <p>Vous n'avez pas de compte? Créez <a routerLink="/offres" (click)="closeAction()">votre compte</a> et
            débloquez toutes les fonctionnalités de FamiliPsy Academy.
          </p>
        </div>
        <div *ngIf="upgrade">
          <p>
            Pour accéder à ce contenu, vous devez être abonné "sérénité" (ou plus).
            Si vous avez déjà souscrit à cet abonnement, nous vous invitons à vous <a routerLink="/connexion"
              (click)="closeAction()">connecter</a>.
          </p>
          <p>
            Si votre abonnement est insuffisant, débloquez <a routerLink="/offre/2" (click)="closeAction()">ICI</a>
            toutes les fonctionnalités de Familipsy Academy en créant votre compte abonné.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="closeAction()">Fermer</button>
  </div>