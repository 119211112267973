/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home-slider.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap/carousel/carousel";
import * as i4 from "../modal/modal.component.ngfactory";
import * as i5 from "../modal/modal.component";
import * as i6 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/carousel/carousel.ngfactory";
import * as i7 from "@ng-bootstrap/ng-bootstrap/carousel/carousel-config";
import * as i8 from "./home-slider.component";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@ng-bootstrap/ng-bootstrap/modal/modal";
import * as i11 from "../../../services/api/api.service";
var styles_HomeSliderComponent = [i0.styles];
var RenderType_HomeSliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeSliderComponent, data: {} });
export { RenderType_HomeSliderComponent as RenderType_HomeSliderComponent };
function View_HomeSliderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "slide-content"], ["style", "height: calc( 100vh - 153px );"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { background: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "background": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [["class", "main-button-round"], ["style", "margin: 0 auto;"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, (((("url(" + _co.environment.uploadPath) + "slides/") + _v.parent.context.$implicit.image) + ") center / cover")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "slideContentText ", _v.parent.context.$implicit.position_text, ""); var currVal_2 = _ck(_v, 5, 0, _v.parent.context.$implicit.background); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.title; _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.link, ""); _ck(_v, 10, 0, currVal_4); var currVal_5 = _v.parent.context.$implicit.button; _ck(_v, 11, 0, currVal_5); }); }
function View_HomeSliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, null, View_HomeSliderComponent_2)), i1.ɵdid(1, 16384, [[1, 4]], 0, i3.NgbSlide, [i1.TemplateRef], null, null)], null, null); }
function View_HomeSliderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, i4.View_ModalComponent_0, i4.RenderType_ModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.ModalComponent, [], { modalContent: [0, "modalContent"], closeAction: [1, "closeAction"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bodyText; var currVal_1 = _v.context.close; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_HomeSliderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["id", "carousel-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ngb-carousel", [["class", "carousel slide"], ["id", "slider-home"], ["interval", "5000"], ["tabIndex", "0"]], [[4, "display", null]], [[null, "mouseenter"], [null, "mouseleave"], [null, "keydown.arrowLeft"], [null, "keydown.arrowRight"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("keydown.arrowLeft" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).keyPrev() !== false);
        ad = (pd_2 && ad);
    } if (("keydown.arrowRight" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).keyNext() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i6.View_NgbCarousel_0, i6.RenderType_NgbCarousel)), i1.ɵdid(2, 2867200, null, 1, i3.NgbCarousel, [i7.NgbCarouselConfig], { interval: [0, "interval"] }, null), i1.ɵqud(603979776, 1, { slides: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeSliderComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, [["videoModal", 2]], null, 0, null, View_HomeSliderComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "5000"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.slides; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = "block"; _ck(_v, 1, 0, currVal_0); }); }
export function View_HomeSliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home-slider", [], null, null, null, View_HomeSliderComponent_0, RenderType_HomeSliderComponent)), i1.ɵdid(1, 114688, null, 0, i8.HomeSliderComponent, [i9.DomSanitizer, i10.NgbModal, i11.ApiService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeSliderComponentNgFactory = i1.ɵccf("app-home-slider", i8.HomeSliderComponent, View_HomeSliderComponent_Host_0, { slides: "slides", zone: "zone" }, {}, []);
export { HomeSliderComponentNgFactory as HomeSliderComponentNgFactory };
