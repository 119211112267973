import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../../services/api/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(_activatedRoute, router, _flashMessagesService, api) {
        this._activatedRoute = _activatedRoute;
        this.router = router;
        this._flashMessagesService = _flashMessagesService;
        this.api = api;
        this.timer = 5;
        this.isSend = false;
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._activatedRoute.paramMap.subscribe(function (param) {
            if (param.get('userId')) {
                _this.token = param.get('userId');
            }
        });
        this.resetForm = new FormGroup({
            password: new FormControl('', [Validators.required]),
            password_confirmation: new FormControl('', [Validators.required])
        });
    };
    ChangePasswordComponent.prototype.resetPassword = function () {
        var _this = this;
        if (this.resetForm.controls['password'].errors) {
            this._flashMessagesService.show('Veuillez remplir les champs obligatoires', { cssClass: 'alert-danger', timeout: 4000 });
            return;
        }
        if (this.resetForm.controls['password_confirmation'].errors) {
            this._flashMessagesService.show('Veuillez remplir les champs obligatoires', { cssClass: 'alert-danger', timeout: 4000 });
            return;
        }
        if (this.resetForm.get('password').value != this.resetForm.get('password_confirmation').value) {
            this._flashMessagesService.show('Les mots de passes sont différents!', { cssClass: 'alert-danger', timeout: 4000 });
            return;
        }
        var data = {
            id: this.token,
            password: this.resetForm.get('password').value,
            password_confirmation: this.resetForm.get('password_confirmation').value
        };
        this.api.post('user/reset_password', data).subscribe(function (res) {
            _this.isSend = true;
            _this._flashMessagesService.show('Le mot de passe a bien été changé!', { cssClass: 'alert-success', timeout: 4000 });
            setTimeout(function () {
                var x = setInterval(function () {
                    _this.timer = _this.timer - 1;
                    if (_this.timer < 0) {
                        clearInterval(x);
                        _this.router.navigateByUrl('/connexion');
                    }
                }, 1000);
            }, 5000);
        });
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
