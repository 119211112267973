<div class="infoAppointment text-center" *ngIf="!displayMeeting">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4 p-0">
            <div class="shadow">
                <div class="individualInfos position-relative float-left">
                    <img src="{{ environment.uploadPath + 'users/' + appointment?.individual.image }}" class="float-left rounded w-100" />
                    <div class="position-absolute w-100 center text-white bold pl-2 pr-2 pt-3 pb-3" style="bottom: 0px; background: rgba(0,0,0,0.4)">
                    {{ appointment?.individual.lastname }}<br />
                    {{ appointment?.individual.firstname }}
                    </div>
                </div>
                <ul class="list-group float-left w-100">
                    <li class="list-group-item text-left"><i class="far fa-calendar-alt"></i> le {{ appointment?.due_date | date: 'dd/MM/yyyy':'fr' }}</li>
                    <li class="list-group-item text-left"><i class="far fa-clock"></i> à {{ appointment?.due_date | date: 'HH:mm':'fr' }}</li>
                    <li class="list-group-item text-center"><a href="javascript:void(0)" (click)="showAppointmentMessage(appointment?.comment)" class="main-button-round btn-block"><i class="far fa-comment"></i> Voir le message</a></li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-8 col-lg-8" *ngIf="!isSafari">
            Pour lancer la consultation, nous vous proposons de la suivre sur cette page (si vous n'êtes pas sur un appareil mobile) ou directement sur l'application ZOOM<br/><br/>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                    <a class="btn bg-white border-pink btn-block" href="{{ appointment?.counselor?.url_visio }}" target="_blank">Utiliser l'application ZOOM</a>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                    <a class="btn dark-color-bg btn-block" href="javascript:void(0)" (click)="startMeeting()">Lancer la consultation</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-8 col-lg-8" *ngIf="isSafari">
            Pour lancer la consultation cliquez sur le bouton ci-dessous<br/><br/>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
                    <a class="btn bg-white border-pink btn-block" href="{{ appointment?.counselor?.url_visio }}" target="_blank">Lancer la consultation</a>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Message</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
    <div class="p-4">
      <div class="row">
        <div class="col">
          {{ appointmentMessage }}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="main-button-round white-button" (click)="close()">Fermer</button>
  </div>
</ng-template>
<div id="zmWrapper" class="position-relative"></div>