import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../../services/api/api.service';
var VerifyComponent = /** @class */ (function () {
    function VerifyComponent(_activatedRoute, router, _flashMessagesService, api) {
        this._activatedRoute = _activatedRoute;
        this.router = router;
        this._flashMessagesService = _flashMessagesService;
        this.api = api;
    }
    VerifyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._activatedRoute.paramMap.subscribe(function (param) {
            if (param.get('userId')) {
                _this.api.get("email/verify/" + param.get('userId')).subscribe(function (data) {
                    _this.result = data;
                    if (data == 1) {
                        _this._flashMessagesService.show('Votre adresse email est bien vérifiée.', { cssClass: 'alert-success' });
                        _this.router.navigate(['connexion']);
                    }
                    else {
                        _this.router.navigate(['connexion']);
                    }
                });
            }
        });
    };
    return VerifyComponent;
}());
export { VerifyComponent };
