import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ScrollTopService } from '../../../shared/providers/scrolltop/scroll-top-service.service';
var SignupCounselorComponent = /** @class */ (function () {
    function SignupCounselorComponent(_flashMessagesService, api, scrollTopService) {
        this._flashMessagesService = _flashMessagesService;
        this.api = api;
        this.scrollTopService = scrollTopService;
        this.finalMessage = '';
    }
    SignupCounselorComponent.prototype.ngOnInit = function () {
        this.scrollTopService.setScrollTop();
        this.scrollTopService.setScrollTop();
        this.signupForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            firstname: new FormControl('', Validators.required),
            lastname: new FormControl('', Validators.required),
            nickname: new FormControl('', Validators.required),
            newsletter: new FormControl(''),
            cgu: new FormControl('', Validators.required),
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
            confirmpassword: new FormControl('', [Validators.required]),
        });
    };
    Object.defineProperty(SignupCounselorComponent.prototype, "f", {
        get: function () { return this.signupForm.controls; },
        enumerable: true,
        configurable: true
    });
    SignupCounselorComponent.prototype.checkForm = function () {
        if (this.f.email.value === "") {
            this._flashMessagesService.show('Veulliez verifier le format de votre adresse mail', { cssClass: 'alert-danger', timeout: 4000 });
            return false;
        }
        if (this.f.password.value === "") {
            this._flashMessagesService.show('Le mot de passe doit contenir au moins 8 caractères', { cssClass: 'alert-danger', timeout: 5000 });
            return false;
        }
        if (this.f.password.value !== this.f.confirmpassword.value) {
            this._flashMessagesService.show('Les mots de passe ne sont pas identiques', { cssClass: 'alert-danger', timeout: 5000 });
            return false;
        }
        if (this.f.lastname.value === "" ||
            this.f.firstname.value === "" ||
            this.f.nickname.value === "") {
            this._flashMessagesService.show('Veuillez remplir les champs obligatoires', { cssClass: 'alert-danger', timeout: 5000 });
            return false;
        }
        if (this.f.cgu.value === "") {
            this._flashMessagesService.show("Veuillez accepter les conditions générales d'utilisation", { cssClass: 'alert-danger', timeout: 5000 });
            return false;
        }
        return true;
    };
    SignupCounselorComponent.prototype.formSubmit = function () {
        var _this = this;
        if (this.checkForm()) {
            var userToSave = new FormData();
            userToSave.append('firstname', this.f.firstname.value);
            userToSave.append('lastname', this.f.lastname.value);
            userToSave.append('nickname', this.f.nickname.value);
            userToSave.append('email', this.f.email.value);
            userToSave.append('newsletter', (this.f.newsletter.value) ? "1" : "0");
            userToSave.append('cgu', this.f.cgu.value);
            userToSave.append('password', this.f.password.value);
            userToSave.append('c_password', this.f.confirmpassword.value);
            this.api.post("users/create/counselor", userToSave).subscribe(function (res) {
                if (res.error)
                    _this._flashMessagesService.show(res.message, { cssClass: 'alert-danger', timeout: 5000 });
                else {
                    _this.finalMessage = res.message;
                }
            });
        }
    };
    return SignupCounselorComponent;
}());
export { SignupCounselorComponent };
