import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(_auth, router) {
        this._auth = _auth;
        this.router = router;
        this.events = [];
        this.firstOffer = 1;
    }
    HeaderComponent.prototype.goToProfile = function () {
        var url = (this._auth.isCounselor()) ? 'counselor' : 'individual';
        this.router.navigate([url]);
    };
    HeaderComponent.prototype.deconnexion = function () {
        this._auth.logout();
    };
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentUser = this._auth.authenticated();
        this.innerWidth = window.innerWidth;
        this._auth.change.subscribe(function (user) {
            _this.currentUser = user;
        });
    };
    HeaderComponent.prototype.onResize = function (event) {
        this.innerWidth = window.innerWidth;
    };
    return HeaderComponent;
}());
export { HeaderComponent };
