<section id="step-section">
  <div class="">
    <div class="container">
      <h1 class="session-title">Comment ça marche<div></div>
      </h1>
      <div class="row" *ngIf="showText">
        <p class="dark-color center">Familipsy vous accompagne jour après jour dans l’art subtil de l’éducation et vous
          aide à vivre
          une vie de famille sereine et épanouie. Accédez autant que vous le souhaitez aux conférences,
          formations et aux conseils personnalisés de nos experts.<br>
          Pour en profiter, c’est simple :</p>
      </div>
      <div class="row">
        <div class="flex dark-color col-md-12">
          <div class="row">
            <div class="col-lg-3 col-sm-12">
              <div class="step-image text-center mb-4"><img src="assets/imgs/1_1.png" style="width: 50px;" /></div>
              <h2 class="bold center">Étape 1</h2>
              <div class="bold center">Remplissez un formulaire de candidature</div>
              <p class="center">
                Nous vous contactons rapidement avec des informations détaillées pour définir notre partenariat. Nous nous gardons de droit de vérifier la correspondance de votre profil avec les valeurs, exigences et missions de Familipsy et le cas échéant de refuser votre candidature.  
              </p>
            </div>
            <div class="col-lg-3 col-sm-12">
              <div class="step-image text-center mb-4"><img src="assets/imgs/2_1.png" style="width: 50px;" /></div>
              <h2 class="bold center">Étape 2</h2>
              <div class="bold center">Familiarisation avec la plateforme</div>
              <p class="center">
                Vous prenez le temps de prendre en main l’outil, nous répondons à vos questions pour commencer dans de bonnes conditions. Vous fixez vos disponibilités.
              </p>
            </div>
            <div class="col-lg-3 col-sm-12">
              <div class="step-image text-center mb-4"><img src="assets/imgs/3_1.png" style="width: 50px;" /></div>
              <h2 class="bold center">Étape 3</h2>
              <div class="bold center">Mise en relation</div>
              <p class="center">
                Nous vous mettons en lien avec vos premiers patients/clients qui recherchent votre profil.
              </p>
            </div>
            <div class="col-lg-3 col-sm-12">
              <div class="step-image text-center mb-4"><img src="assets/imgs/4_1.png" style="width: 50px;" /></div>
              <h2 class="bold center">Étape 4</h2>
              <div class="bold center">Gérez votre pratique en ligne</div>
              <p class="center">
                Définissez votre charge de travail, vos horaires en toute liberté à l’aide de votre tableau de bord. Suivez les demandes en cours en vous connectant quotidiennement sur votre espace. 
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>