<section id="quote-section" class="" [style.backgroundImage]="'url('+ environment.uploadPath + 'quotes/' + quote?.image +')'">
  <div class="layer">
    <div class="container">
      <h1 class="session-title uppercase">Citation<div></div>
      </h1>
      <div class="row">
        <p class="dark-color center white-color" [innerHtml]="quote.content"></p>
      </div>
      <div class="row">
        <p class="dark-color center white-color" [innerHtml]="quote.author"></p>
      </div>
    </div>
  </div>
</section>