import { OnInit } from '@angular/core';
import { MembershipService } from '../../shared/providers/memberships/membership.service';
import { ActivatedRoute } from '@angular/router';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { AuthenticationService } from '../../shared/providers/authentication/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { AuthService } from '../../services/auth/auth.service';
var OfferDetailsComponent = /** @class */ (function () {
    function OfferDetailsComponent(_activatedRoute, _membershipService, scrollTopService, _authService, api, auth) {
        var _this = this;
        this._activatedRoute = _activatedRoute;
        this._membershipService = _membershipService;
        this.scrollTopService = scrollTopService;
        this._authService = _authService;
        this.api = api;
        this.auth = auth;
        this.connectedUser = null;
        this._activatedRoute.paramMap.subscribe(function (param) {
            var id = param.get('offerId');
            // this._membershipService.getMembershipById(id).subscribe(res => {
            //   this.offer = res;
            // }
            // );
            _this.api.get("memberships/" + id).subscribe(function (res) {
                _this.offer = res;
            });
        });
    }
    OfferDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.scrollTopService.setScrollTop();
        // this._membershipService.getAllMemberships().subscribe(res => {
        //   this.offers = res;
        // })
        this.api.get("list/memberships").subscribe(function (data) {
            _this.offers = data;
        });
        this.connectedUser = this._authService.getCurrentUser();
    };
    return OfferDetailsComponent;
}());
export { OfferDetailsComponent };
