import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CounselorsRoutingModule } from './counselors-routing.module';
import { CounselorsComponent } from './counselors.component';
import { CounselorsMenuComponent } from './counselors-menu/counselors-menu.component';
import { CounselorsSidebarComponent } from './counselors-sidebar/counselors-sidebar.component';
import { CounselorsDashboardComponent } from './pages/counselors-dashboard/counselors-dashboard.component';
import { CounselorsProfileComponent } from './pages/counselors-profile/counselors-profile.component';
import { CounselorsConsultationsComponent } from './pages/counselors-consultations/counselors-consultations.component';
import { CounselorsAgendaComponent } from './pages/counselors-agenda/counselors-agenda.component';
import { CounselorsInfosComponent } from './pages/counselors-profile/counselors-infos/counselors-infos.component';
import { CounselorsSituationComponent } from './pages/counselors-profile/counselors-situation/counselors-situation.component';
import { CounselorsDiplomasComponent } from './pages/counselors-profile/counselors-diplomas/counselors-diplomas.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr,'fr');

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CounselorsPaymentComponent } from './pages/counselors-profile/counselors-payment/counselors-payment.component';
import { ZoomComponent } from './pages/zoom/zoom.component';
import { CounselorsZoomAccountComponent } from './pages/counselors-profile/counselors-zoom-account/counselors-zoom-account.component';
import {CalendarNativeDateFormatter, DateFormatterParams, CalendarDateFormatter } from 'angular-calendar';

import { DatePipe } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CounselorsFeedbacksComponent } from './pages/counselors-feedbacks/counselors-feedbacks.component';


export class CustomDateFormatter extends CalendarNativeDateFormatter {

  
  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'HH:mm', locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }

}

@NgModule({
  declarations: [
    CounselorsComponent, 
    CounselorsMenuComponent, 
    CounselorsSidebarComponent, 
    CounselorsDashboardComponent, 
    CounselorsProfileComponent, 
    CounselorsConsultationsComponent, 
    CounselorsAgendaComponent, 
    CounselorsInfosComponent, 
    CounselorsSituationComponent, 
    CounselorsDiplomasComponent,
    CounselorsPaymentComponent,
    ZoomComponent,
    CounselorsZoomAccountComponent,
    CounselorsFeedbacksComponent
  ],
  exports: [CounselorsComponent, CounselorsMenuComponent, CounselorsSidebarComponent, CounselorsDashboardComponent, CounselorsProfileComponent, CounselorsConsultationsComponent, CounselorsAgendaComponent, CounselorsInfosComponent, CounselorsSituationComponent, CounselorsDiplomasComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbModalModule,
    ReactiveFormsModule,
    CounselorsRoutingModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgbModule.forRoot(),
    ImageCropperModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "fr-FR" },
    {provide: CalendarDateFormatter, useClass: CustomDateFormatter}
  ]
})
export class CounselorsModule { }
