import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AuthIndividualsGuard = /** @class */ (function () {
    function AuthIndividualsGuard(routes) {
        this.routes = routes;
    }
    AuthIndividualsGuard.prototype.canActivate = function (next, state) {
        if (localStorage.getItem('user_type_id') == '2') {
            return true;
        }
        else {
            this.routes.navigate(['/accueil']);
            return false;
        }
    };
    AuthIndividualsGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthIndividualsGuard_Factory() { return new AuthIndividualsGuard(i0.inject(i1.Router)); }, token: AuthIndividualsGuard, providedIn: "root" });
    return AuthIndividualsGuard;
}());
export { AuthIndividualsGuard };
