import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { User } from '../../model/user.model';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { ApiService } from '../../services/api/api.service';
import { AuthService } from '../../services/auth/auth.service';
var SigninComponent = /** @class */ (function () {
    function SigninComponent(_flashMessagesService, auth, router, scrollTopService, api) {
        this._flashMessagesService = _flashMessagesService;
        this.auth = auth;
        this.router = router;
        this.scrollTopService = scrollTopService;
        this.api = api;
        this.isForget = false;
    }
    SigninComponent.prototype.formSubmit = function () {
        var user = new User();
        if (this.loginForm.controls['email'].errors) {
            this._flashMessagesService.show('Veulliez verifier le format de votre adresse mail', { cssClass: 'alert-danger', timeout: 4000 });
            return;
        }
        if (this.loginForm.controls['password'].errors) {
            this._flashMessagesService.show('Veuillez remplir les champs obligatoires', { cssClass: 'alert-danger', timeout: 4000 });
            return;
        }
        user.email = this.loginForm.get('email').value;
        user.password = this.loginForm.get('password').value;
        this.auth.login(user);
    };
    SigninComponent.prototype.ngOnInit = function () {
        this.scrollTopService.setScrollTop();
        this.loginForm = new FormGroup({
            email: new FormControl('', [
                Validators.required,
                Validators.email
            ]),
            password: new FormControl('', [Validators.required])
        });
        this.forgetForm = new FormGroup({
            email: new FormControl('', [
                Validators.required,
                Validators.email
            ])
        });
    };
    SigninComponent.prototype.requestReinitialisationPassword = function () {
        var _this = this;
        if (this.forgetForm.controls['email'].errors) {
            this._flashMessagesService.show('Veulliez verifier le format de votre adresse mail', { cssClass: 'alert-danger', timeout: 4000 });
            return;
        }
        var data = {
            email: this.forgetForm.get('email').value
        };
        this.api.post('email/reset_password', data).subscribe(function (res) {
            _this._flashMessagesService.show('Veuillez vérifier votre boite mail pour continuer la procédure de réinitialisation', { cssClass: 'alert-success', timeout: 4000 });
            localStorage.setItem('myEmail', data.email);
        });
    };
    return SigninComponent;
}());
export { SigninComponent };
