export class Offer {
    id: number;
    title: string;
    intro: string;
    description: string;
    advantages: string;
    bullet_points: string;
    image: string;
    monthly_price: string;
    monthly_stripe_plan: string;
    annualy_price: string;
    annualy_stripe_plan: string;
    compare: string;
}