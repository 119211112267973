<div class="row">
  <div class="col">
    <form [formGroup]="newForm" class="mt-4">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="pricing" class="bold dark-color">Votre tarif de consultation en € (tarif suggéré : 25€, 30€) *</label>
                <input type="text" class="form-control dark-color" id="pricing" formControlName="pricing">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="iban" class="bold dark-color">IBAN *</label>
                <input type="text" class="form-control dark-color" id="iban" formControlName="iban">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <button type="submit" class="main-button-round float-right" (click)="submit()">Sauvegarder</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>