<div class="row">
  <div class="col-12 col-md-4">
    <div class="shadow bg-white rounded p-3 mb-3">
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <span class="text-warning display-5">
            <i class="fas fa-wallet" style="font-size: 3rem;"></i>
          </span>
        </div>
        <div>
          <span>Revenus</span>
          <h3 class="font-medium mt-0">€{{ profile?.appointments_paid_sum }}</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4">
    <div class="shadow bg-white rounded p-3 mb-3">
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <span class="text-success display-5">
            <i class="far fa-calendar-check" style="font-size: 3rem;"></i>
          </span>
        </div>
        <div>
          <span>Consultations effectuées</span>
          <h3 class="font-medium mt-0">{{ profile?.appointments_done_count}}</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4">
    <div class="shadow bg-white rounded p-3 mb-3">
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <span class="text-primary display-5">
            <i class="far fa-calendar-alt" style="font-size: 3rem;"></i>
          </span>
        </div>
        <div>
          <span>Consultations réservées</span>
          <h3 class="font-medium mt-0">{{ profile?.appointments_reserved_count}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-8">
    <h2>Mes prochaines consultations</h2>
    <div class="card">
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-3">Patients</div>
            <div class="col">Date</div>
            <div class="col-4 text-right">Actions</div>
          </div>
        </li>
        <li class="list-group-item" *ngFor="let appointment of appointments | slice:1:5">
          <div class="row">
            <div class="col-3">
              <img src="{{ environment.uploadPath + 'users/' + appointment?.individual?.image }}"
                *ngIf="appointment?.individual?.image" class="rounded-circle float-left mr-3" width="50"
                alt="{{ appointment?.individual?.firstname + ' ' + appointment?.individual?.lastname }}" />
              <div class="float-left">
                {{ appointment?.individual?.firstname }}<br />{{ appointment?.individual?.lastname }}
              </div>
            </div>
            <div class="col">
              {{ appointment?.due_date | date: 'dd/MM/yyyy':'fr' }}<br />{{ appointment?.due_date | date: 'HH:mm':'fr' }}
            </div>
            <div class="col-4 text-right">
              <a href="javascript:void(0)" (click)="showAppointmentMessage(appointment?.comment)" class="main-button-round bg-primary uppercase mr-2 p-2"><i class="far fa-comment"></i></a>
              <a routerLink="/counselor/appointment/{{ appointment.id }}" class="main-button-round bg-primary uppercase mr-2 p-2"><i class="fas fa-rocket"></i></a>
              <a class="main-button-round bg-secondary uppercase p-2" (click)="cancelAppointment(appointment?.id)"><i class="fas fa-trash"></i></a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-md-4 mt-2">
    <div class="card" *ngIf="appointments.length">
          <a routerLink="/counselor/appointment/{{appointments[0]?.id}}" class="card bg-primary text-center text-white"
            style="padding: 62px 0;">
            <i class="far fa-play-circle mb-3" style="font-size: 50px"></i>
            Commencer ma consultation
          </a>
          <ul class="list-group">
            <li class="list-group-item">
              <img src="{{ environment.uploadPath + 'users/' + appointments[0]?.individual?.image }}"
                class="rounded-circle float-left mr-3" width="50"
                alt="{{ appointments[0]?.individual?.firstname + ' ' + appointments[0]?.individual?.lastname }}" />
              <div class="float-left">
                {{ appointments[0]?.individual?.firstname }}<br />{{ appointments[0]?.individual?.lastname }}
              </div>
            </li>
            <li class="list-group-item"><i class="far fa-calendar-alt"></i> Date prévue : {{ appointments[0]?.due_date | date: 'dd/MM/yyyy' }}</li>
            <li class="list-group-item"><i class="far fa-clock"></i> Heure prévue : {{ appointments[0]?.due_date | date: 'HH:mm' }}</li>
            <li class="list-group-item text-center"><a href="javascript:void(0)" (click)="showAppointmentMessage(appointments[0]?.comment)" class="main-button-round btn-block"><i class="far fa-comment"></i> Voir le message</a></li>
          </ul>
    </div>
    <div class="card" *ngIf="!appointments.length">
      <span class="card bg-secondary text-center text-white" style="padding: 62px 20px;">
        Vous n'avez pas de consultations à venir
      </span>
    </div>
  </div>
</div>

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Message</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
    <div class="p-4">
      <div class="row">
        <div class="col">
          {{ appointmentMessage }}
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="main-button-round white-button" (click)="close()">Fermer</button>
  </div>
</ng-template>