import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../../services/api/api.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {

  result: any;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private _flashMessagesService: FlashMessagesService,
    private api: ApiService) { }

  ngOnInit() {
    
    this._activatedRoute.paramMap.subscribe(param => {
      if(param.get('userId')) {
          this.api.get("email/verify/"+param.get('userId')).subscribe(data => {
            this.result = data;
            if(data == 1) {
              this._flashMessagesService.show('Votre adresse email est bien vérifiée.',
                { cssClass: 'alert-success' });
              this.router.navigate(['connexion']);
            }else{
              this.router.navigate(['connexion']);
            }
          })
        }
    });
  }

}
