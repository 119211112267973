import { Component, ViewChild, ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { StripeService } from '../../shared/providers/stripe/stripe.service';
import { PremiumService } from '../../shared/providers/premium/premium.service';
import { User } from '../../model/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../shared/providers/authentication/authentication.service';
import { MembershipService } from '../../shared/providers/memberships/membership.service';
import { Offer } from '../../model/offer.model';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-subscribe-premium',
  templateUrl: './subscribe-premium.component.html',
  styleUrls: ['./subscribe-premium.component.css']
})
export class SubscribePremiumComponent implements OnInit {


  stripe: any;
  elements: any;

  creatingUser: User;
  offerId: number;

  offer: Offer;

  ngOnInit(): void {
    this._premiumService.currentCreatingUser.subscribe(u => {
      this.creatingUser = u;
      if (this.creatingUser === null) {
        if (!this._authService.checkCookie()) {
          this._router.navigate(['inscription/' + this.offerId]);
        }
      }
    })
    this._membershipService.getMembershipById(this.offerId.toString()).subscribe(o => {
      this.offer = o;
    });
  }



  @ViewChild('cardInfo') cardInfo: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  constructor(private cd: ChangeDetectorRef,
    private _stripeService: StripeService,
    private _flashMessagesService: FlashMessagesService,
    private _premiumService: PremiumService,
    private _router: Router,
    private _membershipService: MembershipService,
    private activatedRoute: ActivatedRoute,
    private _authService: AuthenticationService,
    private api: ApiService
  ) {
    this.activatedRoute.paramMap.subscribe(param => {
      this.offerId = parseInt(param.get('offerType'));
    });
  }

  ngAfterViewInit() {
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    const { token, error } = await stripe.createToken(this.card);
    if (error) {
    } else {
      const stripeToken = token.id;
      if (this._authService.checkCookie()) {
        const user = this._authService.getCurrentUser();
        user.stripeToken = stripeToken;
        user.stripePlan = this.offer.monthly_stripe_plan;
        this._stripeService.upgradeToPremium(user).subscribe(res => {
          this._flashMessagesService.show('Votre abonnement a été mis à jour, veuillez vous reconnecter.',
            { cssClass: 'alert-success' });
          this._authService.logout();
        })
      } else if (this.creatingUser !== null) {
        const user = this.creatingUser;
        user.stripeToken = stripeToken;
        user.stripePlan = this.offer.monthly_stripe_plan;
        this._authService.signup(user).subscribe(res => {
          if (res === true) {
            this._flashMessagesService.show('Votre compte a bien été créé.',
              { cssClass: 'alert-success' });
              this.api.post('email/subscribe', {'email':this.creatingUser.email, 'firstname': this.creatingUser.firstName, 'lastname' : this.creatingUser.lastName, 'offerId': this.offerId}).subscribe((data) => {
              })
            this._router.navigate(['connexion']);
          }
        });
      }
    }
  }

}
