import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ApiService } from '../../../services/api/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  token;
  resetForm: FormGroup;
  timer = 5;
  isSend = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private _flashMessagesService: FlashMessagesService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this._activatedRoute.paramMap.subscribe(param => {
      if(param.get('userId')) {
        this.token = param.get('userId');
      }
    });

    this.resetForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      password_confirmation: new FormControl('', [Validators.required])
    });
  }

  resetPassword(){
    if (this.resetForm.controls['password'].errors) {
      this._flashMessagesService.show('Veuillez remplir les champs obligatoires', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }
    if (this.resetForm.controls['password_confirmation'].errors) {
      this._flashMessagesService.show('Veuillez remplir les champs obligatoires', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }
    if (this.resetForm.get('password').value != this.resetForm.get('password_confirmation').value) {
      this._flashMessagesService.show('Les mots de passes sont différents!', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }

    const data = {
      id : this.token,
      password : this.resetForm.get('password').value,
      password_confirmation : this.resetForm.get('password_confirmation').value
    }
    this.api.post('user/reset_password', data).subscribe( res => {
      this.isSend = true;
      this._flashMessagesService.show('Le mot de passe a bien été changé!', { cssClass: 'alert-success', timeout: 4000 });
      setTimeout(() => {
        var x = setInterval(() => {
          this.timer = this.timer - 1;

          if(this.timer < 0){
            clearInterval(x);
            this.router.navigateByUrl('/connexion');
          }
        },1000)
      }, 5000);
    })

  }

}
