import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var PremiumService = /** @class */ (function () {
    function PremiumService() {
        this.creatingUserSource = new BehaviorSubject(null);
        this.currentCreatingUser = this.creatingUserSource.asObservable();
    }
    PremiumService.prototype.changeUser = function (creatingUser) {
        this.creatingUserSource.next(creatingUser);
    };
    PremiumService.ngInjectableDef = i0.defineInjectable({ factory: function PremiumService_Factory() { return new PremiumService(); }, token: PremiumService, providedIn: "root" });
    return PremiumService;
}());
export { PremiumService };
