<div class="counselorAgenda">

  <div class="row text-center">
    <div class="col-md-4 text-left">
      <div class="btn-group">
        <div class="btn" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()" (click)="getAppointments()">
          <i class="fas fa-chevron-left"></i>
        </div>
        <div class="btn" mwlCalendarToday [(viewDate)]="viewDate" (click)="getAppointments()">
          Aujourd'hui
        </div>
        <div class="btn" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()" (click)="getAppointments()">
          <i class="fas fa-chevron-right"></i>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'fr' }}</h3>
    </div>
    <div class="col-md-4">
      <div class="btn-group">
        <div class="btn btn-outline-secondary" (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month">
          Mois
        </div>
        <div class="btn btn-outline-secondary" (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week">
          Semaine
        </div>
        <div class="btn btn-outline-secondary" (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day">
          Jour
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col">
      <span>Légende : </span>
      <span class="badge bg-secondary-light mr-2"> Disponible</span>
      <span class="badge bg-info-light mr-2"> Réservé</span>
      <span class="badge bg-primary-light mr-2"> Terminé</span>
      <span class="badge bg-warning-light mr-2"> Reporté</span>
      <span class="badge bg-success-light mr-2"> Rémunéré</span>
      <span class="badge bg-danger-light mr-2"> Annulé</span>
    </div>
  </div>
  <div class="shadow mt-5 mb-5 bg-white rounded">
    <div [ngSwitch]="view">
      <ng-template #cellTooltipTemplate let-event="event">
        <div class="cal-tooltip cal-tooltip-top">
          <div class="cal-tooltip-arrow"></div>
          <div class="cal-tooltip-inner text-center">
            <div [innerHtml]="event.title" *ngIf="event?.appointment?.status_appointment_id != 1"></div>
            <span class="badge text-dark" [ngClass]="getColorStatusAppointment(event?.appointment?.status_appointment?.id)">{{ event?.appointment?.status_appointment?.name }}</span><br/>
            {{ event?.start | date: 'dd/MM/yyyy à HH:mm':'fr' }}
          </div>
        </div>
      </ng-template>
      <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [locale]="locale" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
        [weekStartsOn]="1" [tooltipTemplate]="cellTooltipTemplate">
      </mwl-calendar-month-view>
      <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [locale]="locale" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)" (hourSegmentClicked)="hourSegmentClicked($event)"
        [weekStartsOn]="1" [tooltipTemplate]="cellTooltipTemplate">
      </mwl-calendar-week-view>
      <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [locale]="locale" [viewDate]="viewDate" [events]="events"
        [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"  (hourSegmentClicked)="hourSegmentClicked($event)" [tooltipTemplate]="cellTooltipTemplate">
      </mwl-calendar-day-view>
    </div>
  </div>
</div>
<ng-template #modalContent let-close="close">
  <div class="modal-body">
    
    <div class="p-4">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 mt-2">
          <h4>Votre patient</h4>
          <div class="counselorInfos position-relative float-left">
            <img src="{{ environment.uploadPath + 'users/' + modalData?.event?.appointment?.individual.image }}" class="float-left rounded w-100" />
            <div class="position-absolute w-100 center text-white bold pl-2 pr-2 pt-3 pb-3" style="bottom: 0px; background: rgba(0,0,0,0.4)">
              {{ modalData?.event?.appointment?.individual.lastname }}<br />
              {{ modalData?.event?.appointment?.individual.firstname }}
            </div>
          </div>
          <ul class="list-group float-left w-100">
            <li class="list-group-item"><i class="far fa-calendar-alt"></i> Statut : <span class="badge mr-2" [ngClass]="getColorStatusAppointment(modalData?.event?.appointment?.status_appointment?.id)">{{ modalData?.event?.appointment?.status_appointment?.name }}</span></li>
            <li class="list-group-item"><i class="far fa-calendar-alt"></i> Début : {{ modalData?.event?.start | date: 'dd/MM/yyyy':'fr' }}</li>
            <li class="list-group-item"><i class="far fa-calendar-alt"></i> Heure : {{ modalData?.event?.start | date: 'HH:mm':'fr' }}</li>
          </ul>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 mt-2">
          <h4>Message</h4>
          <div>
            {{ modalData?.event?.appointment?.comment }}
          </div>
          <div class="row mt-3" *ngIf="modalData?.event?.appointment?.status_appointment_id == 2">
            <div class="col text-center">
              <a class="main-button-round" (click)="close()" routerLink="/counselor/appointment/{{ modalData?.event?.appointment?.id }}"><i class="fas fa-rocket"></i> Lancer la consultation</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="main-button-round white-button" (click)="close()">Fermer</button>
  </div>
</ng-template>