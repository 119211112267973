import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../../../services/api/api.service';
var CounselorsPaymentComponent = /** @class */ (function () {
    function CounselorsPaymentComponent(api) {
        this.api = api;
        this.initForm();
    }
    CounselorsPaymentComponent.prototype.initForm = function () {
        this.newForm = new FormGroup({
            iban: new FormControl('', [Validators.required,]),
            pricing: new FormControl('', [Validators.required,]),
        });
    };
    CounselorsPaymentComponent.prototype.ngOnInit = function () {
    };
    CounselorsPaymentComponent.prototype.init = function () {
        this.getMyCounselorPayment();
    };
    CounselorsPaymentComponent.prototype.getMyCounselorPayment = function () {
        var _this = this;
        this.api.get("user/getMyCounselorPaymentInfo").subscribe(function (data) {
            _this.newForm.get("iban").setValue(data.iban);
            _this.newForm.get("pricing").setValue(data.pricing);
        });
    };
    CounselorsPaymentComponent.prototype.submit = function () {
        var dataToSend = {
            iban: this.newForm.get("iban").value,
            pricing: this.newForm.get("pricing").value,
        };
        this.api.post('users/counselor/update/payment', dataToSend).subscribe(function (data) {
        });
    };
    return CounselorsPaymentComponent;
}());
export { CounselorsPaymentComponent };
