import { OnInit, ElementRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppComponent } from '../../app.component';
import { ApiService } from '../../services/api/api.service';
var GuideComponent = /** @class */ (function () {
    function GuideComponent(api, deviceService, appComp) {
        this.api = api;
        this.deviceService = deviceService;
        this.appComp = appComp;
        this.chatBoxOpen = false;
        this.activateChatbox = true;
        this.step = 0;
        this.chatScript = require('../../../assets/guideScript.json');
        this.chatBoxHeight = "500px";
        this.chatBoxBodyHeight = "400px";
        this.isMobile = this.deviceService.isMobile();
        this.messages = [];
        this.answers = [];
        this.problematics = [];
    }
    GuideComponent.prototype.ngOnInit = function () {
        this.appComp.activateChatbox = false;
        window.scrollTo(0, 166);
        this.getProblematics();
        this.sendMessageBot();
    };
    GuideComponent.prototype.scrollToBottom = function () {
        // this.scrollFrame.nativeElement.scrollTop = this.scrollFrame.nativeElement.scrollHeight;
        window.scrollTo(0, 300);
        document.getElementById("bodyChatBox").scrollTop = document.getElementById("bodyChatBox").scrollHeight;
    };
    GuideComponent.prototype.printChatLoader = function () {
        this.printMessageToChatBox({
            "text": "<img src='assets/imgs/chat-loading.gif' />",
            "who": "bot bubble",
            "bubble": true
        });
    };
    GuideComponent.prototype.sendMessageBot = function () {
        var _this = this;
        this.printChatLoader();
        setTimeout(function () {
            _this.messages.pop(); //remove bubble
            var message = _this.chatScript[_this.step];
            _this.printMessageToChatBox(message);
            setTimeout(function () {
                if (message.next) {
                    _this.step = message.next;
                    _this.sendMessageBot();
                }
                else if (message.method) {
                    _this.methodToLaunch(message);
                }
            }, 1000);
        }, 2000);
    };
    GuideComponent.prototype.printMessageToChatBox = function (message) {
        this.messages.push(message);
    };
    GuideComponent.prototype.makeChoice = function (choice) {
        console.log("makeChoice");
        console.log("choice", choice);
        if (choice.link) {
            window.location = choice.link;
        }
        else if (choice.checkboxesChoice) {
            console.log("checkboxesChoice");
            console.log("this.answers", this.answers);
            var result = this.answers.filter(function (answer) { return answer.level == choice.level; });
            console.log("result", result);
            var text = [];
            result.forEach(function (element) {
                text.push(element.text);
            });
            choice.text = text.join(", ");
            this.messages.pop(); //remove bubble
            this.printNext(choice);
        }
        else if (choice.level) {
            this.answers.push({ "level": choice.level, "id": choice.id, "text": choice.text });
            console.log("this.answers", this.answers);
            this.messages.pop(); //remove bubble
            this.printNext(choice);
        }
        else {
            this.messages.pop(); //remove bubble
            this.printNext(choice);
        }
    };
    GuideComponent.prototype.printNext = function (choice) {
        var _this = this;
        this.step = choice.next;
        this.printMessageToChatBox(choice);
        setTimeout(function () {
            _this.sendMessageBot();
        }, 1000);
    };
    GuideComponent.prototype.checkifAnswerIsSelected = function (level, id) {
        return this.answers.findIndex(function (answer) { return answer.level === level && answer.id === id; });
    };
    GuideComponent.prototype.toggleClassAnswer = function (level, id) {
        if (this.checkifAnswerIsSelected(level, id) != -1)
            return 'checkbox selected';
        else
            return 'checkbox';
    };
    GuideComponent.prototype.toggleAnwser = function (level, id, text) {
        var indexExist = this.checkifAnswerIsSelected(level, id);
        if (indexExist != -1)
            this.answers.splice(indexExist, 1);
        else
            this.answers.push({ "level": level, "id": id, "text": text });
    };
    GuideComponent.prototype.getProblematics = function () {
        var _this = this;
        this.api.get("list/problematics").subscribe(function (data) {
            console.log("problematics", data);
            _this.problematics.push(data.slice(0, data.length / 2));
            _this.problematics.push(data.slice((data.length / 2) + 1));
        });
    };
    GuideComponent.prototype.methodToLaunch = function (choice) {
        console.log("methodToLaunch");
        console.log("choice", choice);
        switch (choice.method) {
            case "problematics":
                this.printProblematics(choice);
                break;
            case "counsellorType":
                this.printCounsellorType(choice);
                break;
            case "counsellorGender":
                this.printCounsellorGender(choice);
                break;
        }
    };
    GuideComponent.prototype.printProblematics = function (choice) {
        var newChoice = {
            "who": "checkboxes",
            "checkboxes": null
        };
        var checkboxes = [];
        this.problematics[choice.index].forEach(function (element) {
            checkboxes.push({
                "text": element.user_equivalent,
                "level": choice.level,
                "id": element.id,
                "who": "human"
            });
        });
        checkboxes.push({
            "text": "Je valide mes choix",
            "next": choice.next,
            "who": "human",
            "level": choice.level,
            "checkboxesChoice": 1
        });
        newChoice.checkboxes = checkboxes;
        this.printMessageToChatBox(newChoice);
    };
    GuideComponent.prototype.printCounsellorType = function (choice) {
        console.log("printCounsellorType");
        this.answers.push({ "level": choice.level, "id": choice.id, "text": choice.text });
        console.log("this.answers", this.answers);
        this.messages.pop(); //remove bubble
        this.printNext(choice);
    };
    GuideComponent.prototype.printCounsellorGender = function (choice) {
        console.log("printCounsellorType");
        this.answers.push({ "level": choice.level, "id": choice.id, "text": choice.text });
        console.log("this.answers", this.answers);
        this.messages.pop(); //remove bubble
        this.printNext(choice);
    };
    return GuideComponent;
}());
export { GuideComponent };
