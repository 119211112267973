import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  env = environment;
  token: any;
  appointmentId: any;
  appointment: any;
  feedbackForm = new FormGroup({
    message: new FormControl('', [Validators.required]),
  });
  star = 3;
  feedbackDone = false;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private _flashMessagesService: FlashMessagesService,
  ) { }

  ngOnInit() {
    this._activatedRoute.paramMap.subscribe(param => {
      if(param.get('token') && param.get('appointmentId')) {
        this.token = param.get('token');
        this.appointmentId = param.get('appointmentId');
        this.checkFeedbackAvailability();
      } else {
        this.router.navigate(['**']);
      }
    });
  }

  checkFeedbackAvailability() {
    this.api.get("checkFeedbackAvailability/" + this.token + "/" + this.appointmentId).subscribe((data: any)=>{
      if(!data) this.router.navigate(['page-introuvable']);
      else {
        const offset = new Date().getTimezoneOffset();
        this.appointment = data;
        let explD = data.due_date.split(" ")[0].split("-");
        let explT = data.due_date.split(" ")[1].split(":");
        let dueDate = new Date(new Date(explD[0], explD[1]-1, explD[2], explT[0], explT[1], explT[2]).getTime() - (offset * 60000));
        this.appointment.due_date = dueDate;
        // this.initFeedbackForm();
      }
    })
  }

  initFeedbackForm() {
    this.feedbackForm = new FormGroup({
      message: new FormControl('', [Validators.required]),
    })
  }

  private prepareMessage() {

    const messageContact = new FormData();
    messageContact.append('message', this.feedbackForm.controls.message.value);
    messageContact.append('token', this.token);
    messageContact.append('appointmentId', this.appointmentId);
    messageContact.append('rate', this.star.toString());

    return messageContact;
  }

  subtmitFeedback() {
    if(this.feedbackForm.controls.message.value == "") {
      this._flashMessagesService.show('Veulliez verifier votre message', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }

    const messageContact = this.prepareMessage();
    this.api.post('submitFeedback', messageContact).subscribe((data) => {
      this.feedbackDone = true;
      this._flashMessagesService.show('Votre message a bien été envoyé.', { cssClass: 'alert-success', timeout: 4000 });
    })
  }

}
