import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../../../services/api/api.service';
var CounselorsInfosComponent = /** @class */ (function () {
    function CounselorsInfosComponent(api) {
        this.api = api;
        this.passwordForm = {
            actualPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
        };
        this.initForm();
    }
    CounselorsInfosComponent.prototype.initForm = function () {
        this.newForm = new FormGroup({
            lastname: new FormControl("", [Validators.required,]),
            firstname: new FormControl("", [Validators.required,]),
            nickname: new FormControl("", [Validators.required,]),
            email: new FormControl("", [Validators.required, Validators.email]),
            shortDescription: new FormControl("", []),
            longDescription: new FormControl("", []),
            video: new FormControl("", []),
        });
    };
    CounselorsInfosComponent.prototype.ngOnInit = function () {
    };
    CounselorsInfosComponent.prototype.init = function () {
        this.getMyCounselorInfos();
    };
    CounselorsInfosComponent.prototype.getMyCounselorInfos = function () {
        var _this = this;
        this.api.get("myCounselorProfil").subscribe(function (data) {
            _this.account = data;
            _this.setNewForm();
        });
    };
    CounselorsInfosComponent.prototype.setNewForm = function () {
        this.newForm = new FormGroup({
            lastname: new FormControl(this.account.lastname, [
                Validators.required,
            ]),
            firstname: new FormControl(this.account.firstname, [
                Validators.required,
            ]),
            nickname: new FormControl(this.account.nickname, [
                Validators.required,
            ]),
            email: new FormControl(this.account.email, [
                Validators.required,
                Validators.email
            ]),
            shortDescription: new FormControl(this.account.short_description, []),
            longDescription: new FormControl(this.account.long_description, []),
            video: new FormControl(this.account.video, []),
        });
    };
    CounselorsInfosComponent.prototype.checkPersonalData = function () {
        var _this = this;
        if (this.newForm.get('lastname').invalid) {
            alert('Champs Nom à remplir');
            return;
        }
        else if (this.newForm.get('firstname').invalid) {
            alert('Champs Prénom à remplir');
            return;
        }
        else if (this.newForm.get('nickname').invalid) {
            alert('Champs pseudo à remplir');
            return;
        }
        else if (this.newForm.get('email').invalid) {
            alert('Champs email à remplir');
            return;
        }
        else if (this.newForm.get('shortDescription').invalid) {
            alert('Champs description courte à remplir');
            return;
        }
        else if (this.newForm.get('longDescription').invalid) {
            alert('Champs description longue à remplir');
            return;
        }
        else if (this.passwordForm.newPassword) {
            if (this.passwordForm.newPassword != this.passwordForm.newPasswordConfirmation) {
                alert('Les deux mots de passe sont différents');
                return;
            }
        }
        var data = this.prepareDataToSave();
        this.api.post('users/counselor/update', data).subscribe(function (data) {
            _this.account = data;
        });
    };
    Object.defineProperty(CounselorsInfosComponent.prototype, "f", {
        get: function () { return this.newForm.controls; },
        enumerable: true,
        configurable: true
    });
    CounselorsInfosComponent.prototype.prepareDataToSave = function () {
        var recordToSave = new FormData();
        recordToSave.append('lastname', this.f.lastname.value);
        recordToSave.append('firstname', this.f.firstname.value);
        recordToSave.append('nickname', this.f.nickname.value);
        recordToSave.append('email', this.f.email.value);
        recordToSave.append('short_description', this.f.shortDescription.value);
        recordToSave.append('long_description', this.f.longDescription.value);
        recordToSave.append('video', this.f.video.value);
        if (this.passwordForm.newPassword)
            recordToSave.append('password', this.passwordForm.newPassword);
        return recordToSave;
    };
    return CounselorsInfosComponent;
}());
export { CounselorsInfosComponent };
