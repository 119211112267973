import {
    Citation
} from '../model/citation.model';

export const CITATION: Citation[] = [
    {
        text: "Fuir ses problèmes ne fait qu\'augmenter la distance de la solution. La meilleure façon d\'échapper à ses problèmes est de les résoudre.",
        author: "Annu Tiwari",
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "La gentillesse spontanée est le facteur le plus puissant, le moins couteux et le plus sous-estimé pour l'évolution de a l'homme.",
        author: "Bob Kerrey",
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: '"Ohana" signifie "famille". "Famille signifie que personne ne doit être abandonné, ni oublié."',
        author: 'Lilo & Stitch',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "Si tu veux que tes rêves de viennent réalité, la première chose à faire est de te réveiller.",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "L'amour rend meilleur : ce que vous faites, ce que vous dites, ce que vous êtes.",
        author: "",
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "Arrête d'être effrayé par ce qui pourrait aller mal et pense à uniquement à ce qui va bien.",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "Sois fort et prends courage quand tu es dans la tempête : il ne peut pas toujours pleuvoir...",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "Les câlins et autres gestes de tendresse sont d'escellents médicaments.",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "Je ne suis pas certain(e) de savoir où je vais, mais ce que j'espère, c'est aller loin...",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "La vie, ce n'est pas attendre que l'orage passe, c'est apprendre à danser sous la pluie...",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "Un oiseau posé sur une branche ne craint pas qu'elle se brise parce qu'il ne met pas sa confiance dans la branche, mais dans ses propres ailes. Crois toujours en toi...",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "Ne t'inquiètes pas, sois heureux comme si aujourd'hui était le dernier jour de ta vie...",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "Sois et deviens la meilleure version de toi-même !",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "Accepte ce qui est, lâche prise sur ce qui a été et garde confiance pour ce qui vient...",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    },
    {
        text: "Fais ce qui te rend heureux,reste avec ceux qui te font sourire, ris autant que tu le peux et aime tant que tu es en vie...",
        author: 'Familipsy',
        img: '/assets/imgs/Citation.jpg'
    }
];