var Child = /** @class */ (function () {
    function Child() {
    }
    return Child;
}());
export { Child };
var Particulier = /** @class */ (function () {
    function Particulier() {
    }
    return Particulier;
}());
export { Particulier };
