import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ){

  }


  set(item) {
    this.titleService.setTitle(item.title);
    
    this.metaService.updateTag({name: "description", content: item.description});
    this.metaService.updateTag({name: "og:title", content: item.title});
    this.metaService.updateTag({name: "og:url", content: item.url});
    this.metaService.updateTag({name: "og:description", content: item.description});
    this.metaService.updateTag({name: "og:image", content: item.image});
    this.metaService.updateTag({name: "twitter:title", content: item.title});
    this.metaService.updateTag({name: "twitter:description", content: item.description});

    if(item.keywords) this.metaService.updateTag({name: "keywords", content: item.keywords});
  }
}
