import * as i0 from "@angular/core";
var PriceService = /** @class */ (function () {
    function PriceService() {
        this.commission = 0.2;
        this.tax = 0.13;
    }
    PriceService.prototype.generateTTC = function (counselorPrice) {
        var ttc = Math.ceil(counselorPrice * (1 + this.commission)) * (1 + this.tax);
        return Math.ceil(ttc);
    };
    PriceService.ngInjectableDef = i0.defineInjectable({ factory: function PriceService_Factory() { return new PriceService(); }, token: PriceService, providedIn: "root" });
    return PriceService;
}());
export { PriceService };
