<div class="shadow mb-5 bg-white rounded">
  <div class="profile-section">
    <div id="infos-section">
      <h5 class="title-section bold uppercase  m-0 p-3 cursor-pointer col-md-12"
        [class.active]="openedCards == 0" ngClass="openedCards == 0 ? 'text-white' : 'black-color'" (click)="openCard(0,infos)">Mes informations</h5>
      <div class="content-section" [class.active]="openedCards == 0">
        <app-counselors-infos #infos></app-counselors-infos>
      </div>
    </div>
    <hr class="m-0">
    <div id="infos-section">
      <h5 class="title-section bold uppercase  m-0 p-3 cursor-pointer col-md-12"
        [class.active]="openedCards == 4" ngClass="openedCards == 4 ? 'text-white' : 'black-color'" (click)="openCard(4,zoom)">Mon compte ZOOM</h5>
      <div class="content-section" [class.active]="openedCards == 4">
        <app-counselors-zoom-account #zoom></app-counselors-zoom-account>
      </div>
    </div>
    <hr class="m-0">
    <div id="situation-section">
      <h5 class="title-section bold uppercase  m-0 p-3 cursor-pointer col-md-12"
        [class.active]="openedCards == 1" ngClass="openedCards == 1 ? 'text-white' : 'black-color'" (click)="openCard(1,situation);">Ma situation</h5>
      <div class="content-section" [class.active]="openedCards == 1">
        <app-counselors-situation #situation></app-counselors-situation>
      </div>
    </div>
    <hr class="m-0">
    <div id="diplomas-section">
      <h5 class="title-section bold uppercase  m-0 p-3 cursor-pointer col-md-12"
        [class.active]="openedCards == 2" ngClass="openedCards == 2 ? 'text-white' : 'black-color'" (click)="openCard(2,diplomas)">Mes diplômes</h5>
      <div class="content-section" [class.active]="openedCards == 2">
        <app-counselors-diplomas #diplomas></app-counselors-diplomas>
      </div>
    </div>
    <hr class="m-0">
    <div id="payment-section">
      <h5 class="title-section bold uppercase  m-0 p-3 cursor-pointer col-md-12"
        [class.active]="openedCards == 3" ngClass="openedCards == 3 ? 'text-white' : 'black-color'" (click)="openCard(3,payment)">Paiement</h5>
      <div class="content-section" [class.active]="openedCards == 3">
        <app-counselors-payment #payment></app-counselors-payment>
      </div>
    </div>
  </div>
</div>