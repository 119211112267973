import { Title, Meta } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var SeoService = /** @class */ (function () {
    function SeoService(titleService, metaService) {
        this.titleService = titleService;
        this.metaService = metaService;
    }
    SeoService.prototype.set = function (item) {
        this.titleService.setTitle(item.title);
        this.metaService.updateTag({ name: "description", content: item.description });
        this.metaService.updateTag({ name: "og:title", content: item.title });
        this.metaService.updateTag({ name: "og:url", content: item.url });
        this.metaService.updateTag({ name: "og:description", content: item.description });
        this.metaService.updateTag({ name: "og:image", content: item.image });
        this.metaService.updateTag({ name: "twitter:title", content: item.title });
        this.metaService.updateTag({ name: "twitter:description", content: item.description });
        if (item.keywords)
            this.metaService.updateTag({ name: "keywords", content: item.keywords });
    };
    SeoService.ngInjectableDef = i0.defineInjectable({ factory: function SeoService_Factory() { return new SeoService(i0.inject(i1.Title), i0.inject(i1.Meta)); }, token: SeoService, providedIn: "root" });
    return SeoService;
}());
export { SeoService };
