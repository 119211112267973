import { OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api/api.service';
import { ScrollTopService } from '../../../shared/providers/scrolltop/scroll-top-service.service';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../../shared/providers/modal/modal.service';
import { AuthService } from '../../../services/auth/auth.service';
import { isBefore, parse } from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
var ConferencesDetailComponent = /** @class */ (function () {
    function ConferencesDetailComponent(_activatedRoute, api, scrollTopService, _sanitizer, modalS, modal, auth, _flashMessagesService) {
        var _this = this;
        this._activatedRoute = _activatedRoute;
        this.api = api;
        this.scrollTopService = scrollTopService;
        this._sanitizer = _sanitizer;
        this.modalS = modalS;
        this.modal = modal;
        this.auth = auth;
        this._flashMessagesService = _flashMessagesService;
        this.environment = environment;
        this.today = new Date();
        this.before = true;
        this.isFavourite = false;
        this.tags = [];
        this.upgrade = false;
        this.authMembership = null;
        this.account = this.auth.authenticated();
        this.authMembership = parseInt(this.auth.membership());
        this._activatedRoute.paramMap.subscribe(function (param) {
            var slug = param.get('slug');
            _this.api.get("conferences/getBySlug/" + slug).subscribe(function (res) {
                _this.conference = res;
                _this.conference.problematics.forEach(function (item) {
                    item.tags.forEach(function (element) {
                        if (_this.tags.indexOf(element.name) == -1)
                            _this.tags.push(element.name);
                    });
                });
                var os = new Date();
                var offset = os.getTimezoneOffset();
                if (offset != 600) {
                    var dt = Date.parse(_this.conference.date_due_to.toString() + 'T' + _this.conference.hour_due_to.toString() + '.000-10:00');
                    _this.conference.date_due_to = new Date(dt);
                }
                else {
                    var dt = Date.parse(_this.conference.date_due_to.toString() + 'T' + _this.conference.hour_due_to.toString() + '.000-10:00');
                    _this.conference.date_due_to = new Date(dt);
                }
                _this.checkIfIsFavourite();
                _this.checkDate();
            });
        });
    }
    ConferencesDetailComponent.prototype.ngOnInit = function () {
        console.log("conferencesDetails");
    };
    ConferencesDetailComponent.prototype.checkIfIsFavourite = function () {
        var _this = this;
        if (this.account) {
            this.api.get('favourite/check/conference_id/' + this.conference.id).subscribe(function (data) {
                _this.isFavourite = data;
            });
        }
    };
    ConferencesDetailComponent.prototype.checkDate = function () {
        this.before = isBefore(this.today, parse(this.conference.date_due_to));
    };
    ConferencesDetailComponent.prototype.openModal = function (memberOnlyModal, videoModal) {
        var content = memberOnlyModal;
        var show = false;
        if (this.conference.membership_id == 0) {
            show = true;
        }
        else if (this.conference.membership_id != 0 && this.authMembership == null) {
            show = false;
            this.upgrade = false;
        }
        else if (this.conference.membership_id > this.authMembership) {
            show = false;
            this.upgrade = true;
        }
        else if (this.conference.membership_id <= this.authMembership) {
            show = true;
            this.upgrade = false;
        }
        if (show) {
            var text = '<iframe class="embed-responsive-item" src="' + this.conference.link + '" id="video" allowscriptaccess="always" allow="autoplay" allowfullscreen></iframe>';
            this.bodyText = this._sanitizer.bypassSecurityTrustHtml(text);
            content = videoModal;
        }
        this.modal.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    };
    ConferencesDetailComponent.prototype.addToFavourites = function () {
        var _this = this;
        if (this.auth.isAuthenticated()) {
            this.api.post("favourite/conference/create", { conference_id: this.conference.id }).subscribe(function (data) {
                _this.isFavourite = true;
                _this._flashMessagesService.show('La conférence a bien été ajoutée à vos favoris.', { cssClass: 'alert-success' });
            });
        }
        else {
            this.modal.open(this.memberOnlyModal, { size: 'lg' });
        }
    };
    return ConferencesDetailComponent;
}());
export { ConferencesDetailComponent };
