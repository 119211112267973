<div class="p-2">
  <div class="row mt-3">
    <div class="col">
      <div class="mt-3 p-3">
        Vous trouverez ci-dessous vos informations de connexion à utiliser sur l'application ZOOM. Nous vous rappelons que vous pouvez lancer et suivre la consultation directement de votre tableau de bord.
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <div class="bold dark-color">Adresse email</div>
      <div class="mt-3 p-3 bg-light text-secondary">{{ zoom?.email_zoom }}</div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <div class="bold dark-color">Mot de passe</div>
      <div class="mt-3 p-3 bg-light text-secondary">{{ zoom?.password_zoom }}</div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <div class="bold dark-color">Lien de la visioconférence</div>
      <div class="mt-3 p-3 bg-light text-secondary">{{ zoom?.url_visio }}</div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <div class="bold dark-color">Code secret</div>
      <div class="mt-3 p-3 bg-light text-secondary">{{ zoom?.secret_code }}</div>
    </div>
  </div>
</div>