import { Injectable, NgModule } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from '../shared/providers/authentication/authentication.service';
import { CONFIGURATION } from '../configurations/configurations';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
    ): Observable<HttpEvent<any>> {
    let dupReq;
      const cookie = this.authService.getCookie();
      const session = sessionStorage.getItem(CONFIGURATION.session_storage);
      if (cookie !== null) {
        dupReq = req.clone({ headers: req.headers.set(CONFIGURATION.jwt_token, cookie) });
      } else if (session !== null) {
        dupReq = req.clone({ headers: req.headers.set(CONFIGURATION.jwt_token, session) });
      } else {
        dupReq = req.clone();
      }
    return next.handle(dupReq);
  }
}

export class InterceptorModule { }
