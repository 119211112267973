import { OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api/api.service';
import { environment } from '../../../../../environments/environment';
import { FlashMessagesService } from 'angular2-flash-messages';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
var CounselorsConsultationsComponent = /** @class */ (function () {
    function CounselorsConsultationsComponent(api, modalService, _flashMessagesService) {
        this.api = api;
        this.modalService = modalService;
        this._flashMessagesService = _flashMessagesService;
        this.environment = environment;
        this.openedCards = -1;
        this.currentPage = 1;
        this.url = "auth/counselor/appointments/statusAppointment/";
        this.status = "all";
        this.page = 1;
        this.closeResult = '';
    }
    CounselorsConsultationsComponent.prototype.ngOnInit = function () {
        this.offset = new Date().getTimezoneOffset();
        this.getAppointments();
    };
    CounselorsConsultationsComponent.prototype.getAppointments = function (page) {
        var _this = this;
        if (page === void 0) { page = 0; }
        if (page)
            this.page = page;
        var url = this.url + this.status + ((this.page) ? ("?page=" + this.page) : "");
        this.api.get(url).subscribe(function (data) {
            _this.appointments = data;
            _this.appointments.data.forEach(function (item, key) {
                var explD = item.due_date.split(" ")[0].split("-");
                var explT = item.due_date.split(" ")[1].split(":");
                var dueDate = new Date(new Date(explD[0], explD[1] - 1, explD[2], explT[0], explT[1], explT[2]).getTime() - (_this.offset * 60000));
                _this.appointments.data[key].due_date = dueDate;
            });
        });
    };
    CounselorsConsultationsComponent.prototype.cancelAppointment = function (id) {
        var _this = this;
        this.api.get("cancel/appointment/" + id).subscribe(function (data) {
            _this.getAppointments();
        });
    };
    CounselorsConsultationsComponent.prototype.finishAppointment = function (id) {
        var _this = this;
        this.api.get("appointments/finish/" + id).subscribe(function (data) {
            _this.getAppointments();
        });
    };
    CounselorsConsultationsComponent.prototype.open = function (content, id, index) {
        var _this = this;
        console.log("this.appointments.data[index]", this.appointments.data[index]);
        this.appointmentId = id;
        this.myIndex = index;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(function (result) {
            _this.closeResult = "Closed with: " + result;
        }, function (reason) {
            _this.closeResult = "Dismissed " + _this.getDismissReason(reason);
        });
    };
    CounselorsConsultationsComponent.prototype.getDismissReason = function (reason) {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        }
        else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        }
        else {
            return "with: " + reason;
        }
    };
    CounselorsConsultationsComponent.prototype.reportAppointment = function (reason) {
        var _this = this;
        var data = new FormData();
        data.append('from_appointment_id', this.appointmentId);
        data.append('reason', reason);
        this.api.post('store/reported_appointment', data).subscribe(function (data) {
            _this._flashMessagesService.show('Votre demande a bien été envoyé, veuillez patientez avant de recevoir votre nouvelle date de consultation!', { cssClass: 'alert-success', timeout: 4000 });
        });
    };
    return CounselorsConsultationsComponent;
}());
export { CounselorsConsultationsComponent };
