import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppComponent } from '../../app.component';
import { ApiService } from '../../services/api/api.service';
var SearchComponent = /** @class */ (function () {
    function SearchComponent(api, _activatedRoute, appComp, router) {
        this.api = api;
        this._activatedRoute = _activatedRoute;
        this.appComp = appComp;
        this.router = router;
        this.environment = environment;
        this.results = {
            conferences: [],
            counselors: [],
            faqs: [],
            files: [],
            trainings: [],
        };
        this.term = this._activatedRoute.snapshot.queryParamMap.get('query');
        if (this.term)
            this.search();
    }
    SearchComponent.prototype.ngOnInit = function () {
        // this.appComp.activateChatbox = false;
    };
    SearchComponent.prototype.search = function () {
        var _this = this;
        console.log("this.term", this.term);
        this.router.navigate(['/recherche'], { queryParams: { query: this.term } });
        this.api.post("search", { search: this.term }).subscribe(function (data) {
            console.log("data", data);
            _this.results = data;
        });
    };
    return SearchComponent;
}());
export { SearchComponent };
