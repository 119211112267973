<section id="trainings-section">
  <div class="container">
    <h1 class="session-title">Nos ressources<div></div></h1>
  </div>
</section>

<div id="search">
  <div class="container">
    <div class="row mt-5 mb-5">
      <div class="col text-center">
        <a routerLink="/ressources" [class.dark-color-bg]="!selectedCat" class="btn bg-white rounded shadow mr-3 black-color">Toutes les ressources</a>
        <a routerLink="/ressources/{{ category.slug }}" routerLinkActive="dark-color-bg" *ngFor="let category of categories" class="btn bg-white rounded shadow mr-3 black-color">{{category.name}}</a>
      </div>
    </div>
  </div>
</div>

<div class="trainings">
  <div class="container">
    <div *ngFor="let category of ressourcesByCategories">
      <div class="row training" *ngIf="category?.files?.length">
        <div class="training-head flex mb-5">
          <h2 class="dark-color training-title">{{category.name}}</h2>
          <a routerLink="/ressources/{{ category.slug }}" class="more bold dark-color cursor-pointer" *ngIf="sliceLimit != 9999">Tout voir</a>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let item of category?.files | slice:0:sliceLimit; let i=index">
            <article class="conferenceCustomCard custom-card position-relative">
              <a routerLink="/ressources/{{ category.slug }}/{{ item.slug }}">
                <img src="{{ environment.uploadPath + 'files/' + item.image }}" alt="{{item.title}}" class="w-100" />
              </a>
              <div class="custom-card-content">
                <h4 class="dark-color bold" style="font-size: 18px;">
                  <a class="dark-color" routerLink="/ressources/{{ category.slug }}/{{ item.slug }}">{{item.title}}</a>
                </h4>
                <p class="dark-color">{{item.authors}}</p>
              </div>
              <!-- <div *ngIf="account?.membership_id < item?.membership_id" class="unautorized-content">
                <div class="explic">
                  Votre abonnement n'est pas suffisant pour voir cette formation<br/>
                  <a href="/offres" class="main-button-round uppercase bold">Voir Nos Offres</a>
                </div>
                <div class="lock">
                  <i class="material-icons">
                    lock
                    </i><br/>
                  Accès abonnés
                </div>
              </div> -->
            </article>
        </div>
      </div>
    </div>
  </div>
</div>
