import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api/api.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  page: any;
  slug: any;

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.paramMap.subscribe(param => {
      this.slug = param.get('slug');
      this.api.get("page/slug/"+this.slug).subscribe(data => {
        this.page = data;
      })
    });
  }

  ngOnInit(): void {
  }

}
