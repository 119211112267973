import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { ApiService } from './../../../../../services/api/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-counselors-diplomas',
  templateUrl: './counselors-diplomas.component.html',
  styleUrls: ['./counselors-diplomas.component.css']
})
export class CounselorsDiplomasComponent implements OnInit {

  newForm: FormGroup;

  categories: any;
  diplomas: any;

  constructor(
    private api: ApiService,
    private _flashMessagesService: FlashMessagesService,
  ) {
    this.initForm();
  }
      
  get f(){
    return this.newForm.controls;
  }

  ngOnInit() {
  }

  init() {
    this.getDiplomasCategories();
    this.getMyCounselorDiplomas();
  }

  initForm() {
    this.newForm = new FormGroup({
      file: new FormControl('', [Validators.required,]),
      fileSource: new FormControl('', [Validators.required,]),
      date_obtained: new FormControl('', [Validators.required,]),
      category_diploma_id: new FormControl('', [Validators.required,]),
      speciality: new FormControl('', [Validators.required,]),
      authorized_number: new FormControl('', []),
    });
  }
     
  onFileChange(event) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.newForm.patchValue({
        fileSource: file
      });
    }
  }

  getMyCounselorDiplomas() {
    this.api.get("myDiplomas").subscribe(data=>{
      this.diplomas = data;
    })
  }

  getDiplomasCategories() {
    this.api.get("categories_diploma/list").subscribe(data=>{
      this.categories = data;
    })
  }

  submit() {

    const formData = new FormData();
    formData.append('file', this.newForm.get('fileSource').value);
    formData.append('date_obtained', this.newForm.get('date_obtained').value);
    formData.append('category_diploma_id', this.newForm.get('category_diploma_id').value);
    formData.append('authorized_number', this.newForm.get('authorized_number').value);
    formData.append('speciality', this.newForm.get('speciality').value);
   
    this.api.post('store/diploma', formData)
      .subscribe(res => {
        this.getMyCounselorDiplomas();
        this._flashMessagesService.show('Le diplôme a bien été ajouté.',
          { cssClass: 'alert-success' });
      })
  }

  editDiploma(id) {
    this.api.get("diploma/" + id).subscribe((data: any) => {
      console.log("data", data);
      this.newForm = new FormGroup({
        file: new FormControl(data.file, [Validators.required,]),
        fileSource: new FormControl('', [Validators.required,]),
        date_obtained: new FormControl(data.date_obtained, [Validators.required,]),
        category_diploma_id: new FormControl(data.category_diploma_id, [Validators.required,]),
        speciality: new FormControl(data.speciality, [Validators.required,]),
        authorized_number: new FormControl(data.authorized_number, []),
      });
    })
  }

  deleteDiploma(id) {
    this.api.get("delete/diploma/" + id).subscribe(data=>{
      this.getMyCounselorDiplomas();
      this._flashMessagesService.show('Le diplôme a bien été supprimé.',
        { cssClass: 'alert-success' });
    })
  }
}
