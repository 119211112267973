var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericProvider } from '../genericprovider.provider';
import { HttpClient } from '@angular/common/http';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { Offer } from '../../../model/offer.model';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular2-flash-messages/module/flash-messages.service";
import * as i3 from "@angular/router";
var MembershipService = /** @class */ (function (_super) {
    __extends(MembershipService, _super);
    function MembershipService(http, _flashMessagesService, router) {
        var _this = _super.call(this, _flashMessagesService) || this;
        _this.http = http;
        _this._flashMessagesService = _flashMessagesService;
        _this.router = router;
        return _this;
    }
    MembershipService.prototype.getAllMemberships = function () {
        return this.http.get(this.api_url + '/memberships').pipe(catchError(this.handleError('SessionService : getSessionsHistory()', [])));
    };
    MembershipService.prototype.getMembershipById = function (id) {
        return this.http.get(this.api_url + '/memberships/' + id).pipe(catchError(this.handleError('SessionService : getSessionsHistory()', new Offer())));
    };
    MembershipService.ngInjectableDef = i0.defineInjectable({ factory: function MembershipService_Factory() { return new MembershipService(i0.inject(i1.HttpClient), i0.inject(i2.FlashMessagesService), i0.inject(i3.Router)); }, token: MembershipService, providedIn: "root" });
    return MembershipService;
}(GenericProvider));
export { MembershipService };
