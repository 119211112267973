import { AfterViewInit } from '@angular/core';
import 'hammerjs';
import { AuthenticationService } from './shared/providers/authentication/authentication.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './services/api/api.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(_authenticationService, _flashMessagesService, deviceService, titleService, metaService, router, api) {
        this._authenticationService = _authenticationService;
        this._flashMessagesService = _flashMessagesService;
        this.deviceService = deviceService;
        this.titleService = titleService;
        this.metaService = metaService;
        this.router = router;
        this.api = api;
        this.environment = environment;
        this.title = 'app';
        this.chatBoxOpen = false;
        this.activateChatbox = true;
        this.step = 0;
        this.chatScript = require('../assets/chatScript.json');
        this.chatBoxHeight = "500px";
        this.chatBoxBodyHeight = "400px";
        this.isMobile = this.deviceService.isMobile();
        this.messages = [];
        this.contentModal = '';
        this.router.events.subscribe(function (evt) {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            document.body.scrollTop = 0;
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        if (this.isMobile) {
            this.chatBoxHeight = window.innerHeight + "px";
            this.chatBoxBodyHeight = (window.innerHeight - 100) + "px";
        }
        this.setSeo();
        this.activateChatbox = this.environment.activateChatbox;
    };
    AppComponent.prototype.ngAfterViewInit = function () {
    };
    AppComponent.prototype.setSeo = function () {
        var title = "Familipsy - Site d'information et de formation pour promouvoir les bonnes pratiques en matière d'éducation et de parentalité et améliorer positivement les relations intra-familiales";
        var description = "Information et formation en ligne pour aider les parents à développer leurs compétences en parentalité positive et réussir leur vie de famille.";
        this.titleService.setTitle(title);
        this.metaService.addTag({ name: "description", content: description });
        this.metaService.addTag({ name: "og:title", content: title });
        this.metaService.addTag({ name: "og:url", content: environment.siteUrl });
        this.metaService.addTag({ name: "og:description", content: description });
        this.metaService.addTag({ name: "og:image", content: environment.uploadPath + 'images/familipsy-logo.jpg', });
        this.metaService.addTag({ name: "twitter:title", content: title });
        this.metaService.addTag({ name: "twitter:description", content: description });
    };
    AppComponent.prototype.scrollToBottom = function () {
        document.getElementById("bodyChatBox").scrollTop = document.getElementById("bodyChatBox").scrollHeight;
    };
    AppComponent.prototype.openChat = function () {
        var _this = this;
        if (this.isMobile)
            this.activateChatbox = false;
        this.chatBoxOpen = (this.chatBoxOpen) ? false : true;
        setTimeout(function () {
            _this.sendMessageBot();
        }, 1000);
    };
    AppComponent.prototype.closeChat = function () {
        var _this = this;
        this.printMessageToChatBox({
            "text": "Je vous dis à bientôt",
            "who": "bot"
        });
        setTimeout(function () {
            _this.step = 0;
            _this.chatBoxOpen = false;
            _this.messages = [];
            if (_this.isMobile)
                _this.activateChatbox = true;
        }, 2000);
    };
    AppComponent.prototype.printChatLoader = function () {
        this.printMessageToChatBox({
            "text": "<img src='assets/imgs/chat-loading.gif' />",
            "who": "bot bubble",
            "bubble": true
        });
    };
    AppComponent.prototype.sendMessageBot = function () {
        var _this = this;
        this.printChatLoader();
        setTimeout(function () {
            _this.messages.pop(); //remove bubble
            var message = _this.chatScript[_this.step];
            _this.printMessageToChatBox(_this.chatScript[_this.step]);
            setTimeout(function () {
                if (message.next) {
                    _this.step = message.next;
                    _this.sendMessageBot();
                }
            }, 1000);
        }, 1000);
    };
    AppComponent.prototype.printMessageToChatBox = function (message) {
        this.messages.push(message);
        this.scrollToBottom();
    };
    AppComponent.prototype.makeChoice = function (choice) {
        if (choice.link) {
            // this.closeChat();
            if (choice.external)
                window.open(choice.link);
            else
                window.location = choice.link;
        }
        else {
            this.printNext(choice);
        }
    };
    AppComponent.prototype.printNext = function (choice) {
        var _this = this;
        this.step = choice.next;
        this.messages.pop(); //remove bubble
        this.printMessageToChatBox(choice);
        setTimeout(function () {
            _this.sendMessageBot();
        }, 1000);
    };
    AppComponent.prototype.search = function (term) {
        console.log("term", term);
        // this.closeChat();
        this.router.navigate(['/recherche'], { queryParams: { query: term } });
    };
    return AppComponent;
}());
export { AppComponent };
