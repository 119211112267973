import { ReferentialLabel } from './referential-label.model';

export class Diplome {
    id: number;
    fileURL: String;
    obtentionDate: Date;
    title: ReferentialLabel;
    otherTitle: String;
    authorizationNumber: String;
    authorizationCountries: ReferentialLabel[];
}
