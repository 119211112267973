import { AfterViewInit, Component, ViewChild, ElementRef } from '@angular/core';
import 'hammerjs';
import { AuthenticationService } from './shared/providers/authentication/authentication.service';
import { FlashMessagesService } from 'angular2-flash-messages';

import { DeviceDetectorService } from 'ngx-device-detector';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './services/api/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit{

  environment = environment;
  title = 'app';
  chatBoxOpen = false;
  activateChatbox = true;
  step = 0;
  chatScript = require('../assets/chatScript.json');
  chatBoxHeight = "500px";
  chatBoxBodyHeight = "400px";
  isMobile = this.deviceService.isMobile();
  messages = [];
  previousUrl: string;

  public contentModal: any = '';

  private scrollContainer: any;

  constructor(
    private _authenticationService: AuthenticationService,
    private _flashMessagesService: FlashMessagesService,
    private deviceService: DeviceDetectorService,
    private titleService: Title,
    private metaService: Meta,
    private router: Router,
    private api: ApiService
  ) {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      document.body.scrollTop = 0;
  });
  }

  ngOnInit() {
    if(this.isMobile) {
      this.chatBoxHeight = window.innerHeight + "px";
      this.chatBoxBodyHeight = (window.innerHeight - 100) + "px";
    }
    this.setSeo();
    this.activateChatbox = this.environment.activateChatbox;
  }

  ngAfterViewInit() {
    
  }

  setSeo() {
    let title = "Familipsy - Site d'information et de formation pour promouvoir les bonnes pratiques en matière d'éducation et de parentalité et améliorer positivement les relations intra-familiales";
    let description = "Information et formation en ligne pour aider les parents à développer leurs compétences en parentalité positive et réussir leur vie de famille.";

    this.titleService.setTitle(title);
    
    this.metaService.addTag({name: "description", content: description});
    this.metaService.addTag({name: "og:title", content: title});
    this.metaService.addTag({name: "og:url", content: environment.siteUrl});
    this.metaService.addTag({name: "og:description", content: description});
    this.metaService.addTag({name: "og:image", content: environment.uploadPath + 'images/familipsy-logo.jpg',});
    this.metaService.addTag({name: "twitter:title", content: title});
    this.metaService.addTag({name: "twitter:description", content: description});
  }

  scrollToBottom() {
    document.getElementById("bodyChatBox").scrollTop = document.getElementById("bodyChatBox").scrollHeight;
  }

  openChat() {
    if(this.isMobile) this.activateChatbox = false;
    
    this.chatBoxOpen = (this.chatBoxOpen) ? false : true;
    setTimeout(() => {
      this.sendMessageBot();
    }, 1000)
  }

  closeChat() {
    
    this.printMessageToChatBox({
      "text": "Je vous dis à bientôt",
      "who": "bot"
    });

    setTimeout(() => {
      this.step = 0;
      this.chatBoxOpen = false;
      this.messages = [];
      if(this.isMobile) this.activateChatbox = true;
    },2000)
  }

  printChatLoader() {
    this.printMessageToChatBox({
        "text": "<img src='assets/imgs/chat-loading.gif' />",
        "who": "bot bubble",
        "bubble": true
    })
  }

  sendMessageBot() {
    this.printChatLoader();

    setTimeout(() => {
      this.messages.pop(); //remove bubble

      var message = this.chatScript[this.step];

      this.printMessageToChatBox(this.chatScript[this.step]);

      setTimeout(() => {
        if(message.next){
          this.step = message.next;
          this.sendMessageBot();
        }
      }, 1000)
    },1000);
  }

  printMessageToChatBox(message) {
    this.messages.push(message);
    this.scrollToBottom();
  }

  makeChoice(choice) {
    if(choice.link){
      // this.closeChat();
      
      if(choice.external) window.open(choice.link);
      else window.location = choice.link;
    } else {
      this.printNext(choice);
    }
  }

  printNext(choice){
    this.step = choice.next;
    this.messages.pop(); //remove bubble
    
    this.printMessageToChatBox(choice);

    setTimeout(() => {
      this.sendMessageBot();
    }, 1000)
  }


  search(term){
    console.log("term", term);
    // this.closeChat();
    this.router.navigate(['/recherche'], {queryParams: {query: term}});
  }
}
