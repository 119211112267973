import { AuthService } from './../../../services/auth/auth.service';
import { OnInit } from '@angular/core';
import { ScrollTopService } from '../../../shared/providers/scrolltop/scroll-top-service.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api/api.service';
import { SeoService } from '../../../services/seo/seo.service';
var RessourceListComponent = /** @class */ (function () {
    function RessourceListComponent(_activatedRoute, scrollTopService, api, auth, seo) {
        var _this = this;
        this._activatedRoute = _activatedRoute;
        this.scrollTopService = scrollTopService;
        this.api = api;
        this.auth = auth;
        this.seo = seo;
        this.environment = environment;
        this.pageSeo = {
            "title": "",
            "description": "",
            "og:url": "",
            "og:title": "",
            "og:description": "",
            "og:image": "",
            "twitter:title": "",
            "twitter:description": "",
        };
        this.sliceLimit = 4;
        this.account = this.auth.authenticated();
        this._activatedRoute.paramMap.subscribe(function (param) {
            _this.selectedCat = param.get('slugCategory');
            if (_this.selectedCat) {
                _this.sliceLimit = 9999;
                _this.getRessources('files/all/category/slug/' + _this.selectedCat);
            }
            else {
                _this.sliceLimit = 4;
                _this.getRessources('files/all/byCategory');
            }
        });
    }
    RessourceListComponent.prototype.allCategories = function () {
        this.searchResults = this.categories;
    };
    RessourceListComponent.prototype.getFileCategories = function () {
        var _this = this;
        this.api.get("list/fileCategories").subscribe(function (data) {
            _this.categories = data;
        });
    };
    RessourceListComponent.prototype.ngOnInit = function () {
        this.getFileCategories();
        this.scrollTopService.setScrollTop();
    };
    RessourceListComponent.prototype.setSeoGlobal = function () {
        this.seo.set({
            "title": "Toutes les ressources de FamiliPsy Academy",
            "description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
            "og:url": this.environment.siteUrl + '/ressources',
            "og:title": "Toutes les ressources de FamiliPsy Academy",
            "og:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
            "og:image": environment.uploadPath + 'trainings',
            "twitter:title": "Toutes les ressources de FamiliPsy Academy",
            "twitter:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !"
        });
    };
    RessourceListComponent.prototype.setSeoCategory = function () {
        this.seo.set({
            "title": "Toutes les ressources \"" + this.ressourcesByCategories[0].name + "\" de FamiliPsy Academy",
            "description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
            "og:url": this.environment.siteUrl + '/trainigs',
            "og:title": "Toutes les ressources \"" + this.ressourcesByCategories[0].name + "\" de FamiliPsy Academy",
            "og:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !",
            "og:image": environment.uploadPath + 'trainings',
            "twitter:title": "Toutes les ressources \"" + this.ressourcesByCategories[0].name + "\" de FamiliPsy Academy",
            "twitter:description": "Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !"
        });
    };
    RessourceListComponent.prototype.getRessources = function (url) {
        var _this = this;
        this.api.get(url).subscribe(function (data) {
            _this.ressourcesByCategories = data;
            if (_this.selectedCat) {
                _this.setSeoCategory();
            }
            else {
                _this.setSeoGlobal();
            }
        });
    };
    return RessourceListComponent;
}());
export { RessourceListComponent };
