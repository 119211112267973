/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./content.component";
import * as i3 from "../../../services/api/api.service";
var styles_ContentComponent = [i0.styles];
var RenderType_ContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContentComponent, data: {} });
export { RenderType_ContentComponent as RenderType_ContentComponent };
export function View_ContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "contentBlock"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_co.content == null) ? null : _co.content.slug), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.content == null) ? null : _co.content.content); _ck(_v, 1, 0, currVal_1); }); }
export function View_ContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-content", [], null, null, null, View_ContentComponent_0, RenderType_ContentComponent)), i1.ɵdid(1, 114688, null, 0, i2.ContentComponent, [i3.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContentComponentNgFactory = i1.ɵccf("app-content", i2.ContentComponent, View_ContentComponent_Host_0, { slug: "slug" }, {}, []);
export { ContentComponentNgFactory as ContentComponentNgFactory };
