import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api/api.service';
import { ScrollTopService } from '../../../shared/providers/scrolltop/scroll-top-service.service';
import { Conference } from '../../../model/conference.model';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../../shared/providers/modal/modal.service';
import { AuthService } from '../../../services/auth/auth.service';
import { isBefore, parse} from 'date-fns';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-conferences-detail',
  templateUrl: './conferences-detail.component.html',
  styleUrls: ['./conferences-detail.component.css']
})
export class ConferencesDetailComponent implements OnInit {

  environment = environment;
  conference: Conference;
  today = new Date();
  bodyText: any;
  account: any;
  before = true;
  isFavourite: Boolean = false;
  tags = [];
  upgrade = false;
  authMembership: any = null;

  @ViewChild('memberOnlyModal') memberOnlyModal: TemplateRef<any>;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private api: ApiService,
    private scrollTopService: ScrollTopService,
    private _sanitizer: DomSanitizer,
    private modalS: ModalService,
    private modal: NgbModal,
    private auth: AuthService,
    private _flashMessagesService: FlashMessagesService,
  ) {
    this.account = this.auth.authenticated();
    this.authMembership = parseInt(this.auth.membership());

    this._activatedRoute.paramMap.subscribe(param => {
      const slug = param.get('slug');
      this.api.get("conferences/getBySlug/" + slug).subscribe((res: Conference) => {
        this.conference = res;
        this.conference.problematics.forEach((item: any)=>{
          item.tags.forEach((element:any) => {
            if(this.tags.indexOf(element.name) == -1) this.tags.push(element.name);
          });
        })
        let os = new Date();
        let offset = os.getTimezoneOffset();
        if(offset != 600) {
          let dt = Date.parse(this.conference.date_due_to.toString() + 'T' + this.conference.hour_due_to.toString() + '.000-10:00');
          this.conference.date_due_to = new Date(dt); 
        } else {
          let dt = Date.parse(this.conference.date_due_to.toString() + 'T' + this.conference.hour_due_to.toString() + '.000-10:00');
          this.conference.date_due_to = new Date(dt);
        }
        this.checkIfIsFavourite()
        this.checkDate();
      })
    })
  }

  ngOnInit() {
    console.log("conferencesDetails");
  }

  checkIfIsFavourite() {
    if(this.account) {
      this.api.get('favourite/check/conference_id/' + this.conference.id).subscribe((data: Boolean)=>{
        this.isFavourite = data;
      })
    }
  }

  checkDate() {
    this.before = isBefore(this.today,parse(this.conference.date_due_to));
  }

  openModal(memberOnlyModal, videoModal) {

    let content = memberOnlyModal;
    let show = false;
    
    if(this.conference.membership_id == 0) { show = true; }
    else if(this.conference.membership_id != 0 && this.authMembership == null) { show = false; this.upgrade = false; } 
    else if(this.conference.membership_id > this.authMembership) { show = false; this.upgrade = true; }
    else if(this.conference.membership_id <= this.authMembership) { show = true; this.upgrade = false; }

    if(show) {
      const text = '<iframe class="embed-responsive-item" src="' + this.conference.link + '" id="video" allowscriptaccess="always" allow="autoplay" allowfullscreen></iframe>';
      this.bodyText = this._sanitizer.bypassSecurityTrustHtml(text);
      content = videoModal;
    }

    this.modal.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
  }

  addToFavourites() {
    
    if(this.auth.isAuthenticated()) {
      this.api.post("favourite/conference/create",{conference_id: this.conference.id}).subscribe(data=>{
        this.isFavourite = true;
        this._flashMessagesService.show('La conférence a bien été ajoutée à vos favoris.',
          { cssClass: 'alert-success' });
      })
    } else {
      this.modal.open(this.memberOnlyModal, { size: 'lg' });
    }
  }

}
