import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  currentUser: any;

  innerWidth: any;

  events: string[] = [];
  opened: boolean;
  firstOffer = 1;

  constructor(
    private _auth: AuthService,
    private router: Router
  ) { }

  goToProfile() {
    const url = (this._auth.isCounselor()) ? 'counselor' : 'individual';
    this.router.navigate([url]);
  }

  deconnexion() {
    this._auth.logout();
  }

  ngOnInit() {
    this.currentUser = this._auth.authenticated();
    this.innerWidth = window.innerWidth;
    this._auth.change.subscribe(user => {
      this.currentUser = user;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

}
