import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { map, take, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { MethodCall } from '@angular/compiler';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  
  constructor(private httpClient: HttpClient) {
    this.setHeaderOptions();
  }

  // Http Options
  httpOptions = {}  

  setHeaderOptions() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }  
  }

  get(url) {
    return this.httpClient.get(API_URL+url, this.httpOptions);
  }

  post(url, data): any {
    return this.httpClient.post(API_URL + url, data, this.httpOptions);
  }

  delete(url): any {
    return this.httpClient.delete(API_URL + url, this.httpOptions);
  }

  // Error handling 
  handleError(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     window.alert(errorMessage);
     return throwError(errorMessage);
  }
}
