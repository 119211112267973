<!-- <div class="space-nav">
  <div class="row">
    <div class="col text-center"><a routerLink="/counselor/dashboard" routerLinkActive="active" class="pt-3 pb-3 d-block">Mon Tableau de bord</a></div>
    <div class="col text-center"><a routerLink="/counselor/my-profile" routerLinkActive="active" class="pt-3 pb-3 d-block">Mon profil</a></div>
    <div class="col text-center"><a routerLink="/counselor/consultations" routerLinkActive="active" class="pt-3 pb-3 d-block">Mes consultations</a></div>
    <div class="col text-center"><a routerLink="/counselor/agenda" routerLinkActive="active" class="pt-3 pb-3 d-block">Mes disponibilités</a></div>
  </div>
</div> -->
<div class="space-nav">
  <div class="row">
    <div class="col text-left">
      <a routerLink="/counselor/dashboard" routerLinkActive="active" class="pl-3 pt-3 pb-3 d-block rounded">
        <i class="fas fa-columns mr-2"></i> Mon Tableau de bord
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col text-left">
      <a routerLink="/counselor/my-profile" routerLinkActive="active" class="pl-3 pt-3 pb-3 d-block rounded">
        <i class="far fa-user mr-2"></i> Mon profil
      </a>
  </div>
  </div>
  <div class="row">
    <div class="col text-left">
      <a routerLink="/counselor/consultations" routerLinkActive="active" class="pl-3 pt-3 pb-3 d-block rounded">
        <i class="far fa-list-alt mr-2"></i> Mes consultations
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col text-left">
      <a routerLink="/counselor/agenda" routerLinkActive="active" class="pl-3 pt-3 pb-3 d-block rounded">
        <i class="far fa-calendar-alt mr-2"></i> Mes disponibilités
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col text-left">
      <a routerLink="/counselor/avis" routerLinkActive="active" class="pl-3 pt-3 pb-3 d-block rounded">
        <i class="far fa-star mr-2"></i> Mes avis
      </a>
    </div>
  </div>
</div>