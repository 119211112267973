import { Injectable } from '@angular/core';
import { GenericProvider } from '../genericprovider.provider';
import { HttpClient } from '@angular/common/http';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { Session } from '../../../model/session.model';
import { Observable } from 'rxjs/index';
import { catchError } from 'rxjs/operators';
import { ReserveSlot } from '../../../model/reserveSlot.model';
import { User } from '../../../model/user.model';

@Injectable({
  providedIn: 'root'
})

export class StripeService extends GenericProvider {

  constructor(
    public http: HttpClient,
    public _flashMessagesService: FlashMessagesService,
    private router: Router
    ) {
       super(_flashMessagesService);
  }

  createPaymentIntent(data: ReserveSlot) {
    return this.http.post<Object>(this.api_url + '/consultations/createintent', data).pipe(
      catchError(
        this.handleError('SessionService : buyConsultation(' + data + ')', new Session())
      )
    );
  }

  buyConsultation(data: ReserveSlot): Observable<Session> {
    return this.http.post<Session>(this.api_url + '/consultations', data).pipe(
      catchError(
        this.handleError('SessionService : buyConsultation(' + data + ')', new Session())
      )
    );
  }

  upgradeToPremium(user: User): Observable<Object> {
    return this.http.put<Object>(this.api_url + '/customers/upgrade-premium', user).pipe(
      catchError(
        this.handleError('SessionService : buyConsultation(' + user + ')', {})
      )
    );
  }
}
