/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./conferences.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/lazy-img.directive";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/pagination/pagination.ngfactory";
import * as i6 from "@ng-bootstrap/ng-bootstrap/pagination/pagination";
import * as i7 from "@ng-bootstrap/ng-bootstrap/pagination/pagination-config";
import * as i8 from "./conferences.component";
import * as i9 from "../../shared/providers/scrolltop/scroll-top-service.service";
import * as i10 from "../../shared/providers/newsletters/newsletter.service";
import * as i11 from "angular2-flash-messages/module/flash-messages.service";
import * as i12 from "../../services/api/api.service";
var styles_ConferencesComponent = [i0.styles];
var RenderType_ConferencesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConferencesComponent, data: {} });
export { RenderType_ConferencesComponent as RenderType_ConferencesComponent };
function View_ConferencesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "author float-left position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [["class", "rounded-circle"], ["width", "60"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.LazyImgDirective, [i1.ElementRef], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_co.environment.uploadPath + "users/") + ((_v.context.$implicit == null) ? null : _v.context.$implicit.image)), ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", ((((_v.context.$implicit == null) ? null : _v.context.$implicit.firstname) + " ") + ((_v.context.$implicit == null) ? null : _v.context.$implicit.lastname)), ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ConferencesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "novelty bg-success text-white badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nouveaut\u00E9"]))], null, null); }
function View_ConferencesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "col-12 col-md-12 col-lg-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "article", [["class", "conferenceCustomCard custom-card position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "custom-card-image position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "authors position-absolute"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConferencesComponent_2)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "a", [["class", "d-block"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "img", [["class", "w-100"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i2.LazyImgDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "custom-card-content mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "topContent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "h4", [["class", "title dark-color bold"], ["style", "height: 50px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "a", [["class", "dark-color"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConferencesComponent_3)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "w-100 float-left mt-4 mb-4 text-justify"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.authors); _ck(_v, 5, 0, currVal_0); var currVal_3 = i1.ɵinlineInterpolate(1, "/conferences/", ((_v.context.$implicit == null) ? null : _v.context.$implicit.slug), ""); _ck(_v, 7, 0, currVal_3); var currVal_8 = i1.ɵinlineInterpolate(1, "/conferences/", ((_v.context.$implicit == null) ? null : _v.context.$implicit.slug), ""); _ck(_v, 14, 0, currVal_8); var currVal_10 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.novelty); _ck(_v, 17, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 7).target; var currVal_2 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵinlineInterpolate(1, "", ((_co.environment.uploadPath + "conferences/") + ((_v.context.$implicit == null) ? null : _v.context.$implicit.image)), ""); var currVal_5 = i1.ɵinlineInterpolate(1, "", ((_v.context.$implicit == null) ? null : _v.context.$implicit.title), ""); _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵnov(_v, 14).target; var currVal_7 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_6, currVal_7); var currVal_9 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.title); _ck(_v, 15, 0, currVal_9); var currVal_11 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.intro); _ck(_v, 18, 0, currVal_11); }); }
export function View_ConferencesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "contentWrapper"], ["id", "conferences-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nos conf\u00E9rences"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "dark-color center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Participez gratuitement \u00E0 nos webconf\u00E9rences et suivez depuis chez vous un large choix de th\u00E8mes qui vous aideront \u00E0 embellir vos relations familiales. Anim\u00E9es par des experts des relations familiales, vous y trouverez des clefs pour mieux comprendre votre enfant, communiquer efficacement, \u00E9duquer avec bienveillance, g\u00E9rer vos \u00E9motions et bien d\u2019autres th\u00E8mes ! "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "mt-5 row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConferencesComponent_1)), i1.ɵdid(10, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "ngb-pagination", [["class", "d-flex justify-content-center"], ["role", "navigation"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.currentPage = $event) !== false);
        ad = (pd_0 && ad);
    } if (("pageChange" === en)) {
        var pd_1 = (_co.getAll($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_NgbPagination_0, i5.RenderType_NgbPagination)), i1.ɵdid(12, 573440, null, 0, i6.NgbPagination, [i7.NgbPaginationConfig], { boundaryLinks: [0, "boundaryLinks"], rotate: [1, "rotate"], collectionSize: [2, "collectionSize"], maxSize: [3, "maxSize"], page: [4, "page"], pageSize: [5, "pageSize"] }, { pageChange: "pageChange" }), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", ""], ["id", "newsletters-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "iframe", [["allowfullscreen", ""], ["frameborder", "0"], ["height", "480"], ["scrolling", "auto"], ["src", "https://e5730db9.sibforms.com/serve/MUIEAGu9WtA2teFBMElcMHKuXabvBTIhzb5GL9YJiIfg7opTkNgW5MtB8dZFsT9geIqqvP0ZA3gptlrUGcovZT5OPS6fBZuYklu50FovtktupqwZQAMfqLDn8k7ikvK6gJIGxDuNY6nWN7DE1-ytxnXtSewdTS4BFHnOpo7JlZdI-jW7PPqqPCwjL3oOIfTQeq1Qooh0OJRpMyQb"], ["style", "display: block;margin-left: auto;margin-right: auto;max-width: 100%;"], ["width", "100%"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.conferencesList == null) ? null : _co.conferencesList.data); _ck(_v, 10, 0, currVal_0); var currVal_1 = true; var currVal_2 = true; var currVal_3 = ((_co.conferencesList == null) ? null : _co.conferencesList.total); var currVal_4 = 10; var currVal_5 = _co.currentPage; var currVal_6 = ((_co.conferencesList == null) ? null : _co.conferencesList.per_page); _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ConferencesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-conferences", [], null, null, null, View_ConferencesComponent_0, RenderType_ConferencesComponent)), i1.ɵdid(1, 114688, null, 0, i8.ConferencesComponent, [i4.ActivatedRoute, i9.ScrollTopService, i10.NewsletterService, i11.FlashMessagesService, i12.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConferencesComponentNgFactory = i1.ɵccf("app-conferences", i8.ConferencesComponent, View_ConferencesComponent_Host_0, {}, {}, []);
export { ConferencesComponentNgFactory as ConferencesComponentNgFactory };
