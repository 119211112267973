import { AuthService } from './../../../services/auth/auth.service';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScrollTopService } from '../../../shared/providers/scrolltop/scroll-top-service.service';
import { environment } from './../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { stringify } from '@angular/compiler/src/util';
import { SeoService } from './../../../services/seo/seo.service';
import { ApiService } from './../../../services/api/api.service';
import { startOfDay, endOfDay, subDays, addDays, isSameDay, isSameMonth, addHours, format,
  getHours,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth, } from 'date-fns';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, DAYS_OF_WEEK, } from 'angular-calendar';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { PriceService } from '../../../services/price/price.service';

const color = {
  primary: '#ff0098',
  secondary: '#d1d1d1',
};

@Component({
  selector: 'app-counselors-detail',
  templateUrl: './counselors-detail.component.html',
  styleUrls: ['./counselors-detail.component.css'],
})
export class CounselorsDetailComponent implements OnInit {

  environment = environment;
  counselor: any;
  video: any;

  sectionSelected = 1;
  
  agenda = false;
  isFavourite: Boolean = false;

  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  @ViewChild('modalBook') modalBook: TemplateRef<any>;

  locale: string = 'fr';

  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  weekendDays: number[] = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<div class="btn bg-info text-white btn-sm ml-3" style="font-size: 10px; margin-top: -10px;"><i class="fas fa-cart-plus"></i> RÉSERVER</div>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;
  // excludeDays: number[] = [0, 6];
  

  random = Math.floor(Math.random() * 100);

  constructor(
    private _activatedRoute: ActivatedRoute,
    private api: ApiService,
    private auth: AuthService,
    private scrollTopService: ScrollTopService,
    private _flashMessagesService: FlashMessagesService,
    private _sanitizer: DomSanitizer,
    private seo: SeoService,
    private modal: NgbModal,
    private priceService: PriceService
  ) {

    this._activatedRoute.paramMap.subscribe(param => {
      const slug = param.get('slug');
      this.api.get("users/counselor/" + slug).subscribe((res: any) => {
        this.counselor = res;
        let offset = new Date().getTimezoneOffset();
        this.counselor.diplomas_user.forEach((item,index)=>{
          let explD = item.date_obtained.split(" ")[0].split("-");
          this.counselor.diplomas_user[index].date_obtained = new Date(new Date(explD[0], explD[1]-1, explD[2], 0,0,0).getTime() - (offset * 60000));
        })

        this.checkIfCounselorIsInFavourite();
        if (this.counselor.video !== null) {
          this.video = this._sanitizer.bypassSecurityTrustResourceUrl(stringify(this.counselor.video));
        }
        this.checkIfIsFavourite();
        this.setSeo();
      })
    })
  }

  ngOnInit() {
  }

  checkIfIsFavourite() {
    if(this.auth.authenticated()) {
      this.api.get('favourite/check/counselor_id/' + this.counselor.id).subscribe((data: Boolean)=>{
        this.isFavourite = data;
      })
    }
  }

  setSeo(){
    this.seo.set({
      "title": this.counselor.firstname + ' ' + this.counselor.lastname,
      "description": this.counselor.short_description,
      "og:url": this.environment.siteUrl + '/conseillers/' + this.counselor.slug,
      "og:title": this.counselor.firstname + ' ' + this.counselor.lastname,
      "og:description": this.counselor.short_description,
      "og:image": environment.uploadPath + 'users/' + this.counselor.image,
      "twitter:title": this.counselor.firstname + ' ' + this.counselor.lastname,
      "twitter:description": this.counselor.short_description
    })
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
    // this.setView(CalendarView.Day);
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalBook, { size: 'lg' });
  }

  addEvent(event): void {
    this.events.push(event);
    this.refresh.next();

  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  showAgenda(){
    if(this.auth.isAuthenticated()) {
      this.getAppointments();
      this.agenda = true;
    } else {
      this.modal.open(this.modalContent, { size: 'lg' });
    }
  }


  getAppointments(){
    this.events = [];
    let offset = new Date().getTimezoneOffset();
    let viewDate = new Date(new Date().getTime() - (offset * 60000));
    
    let limits = {
      view: this.view,
      viewDate: viewDate,
      endOfMonth: (endOfMonth(this.viewDate)).toISOString().slice(0,10) + " 00:00:00",
    };
    
    this.api.post('users/counselor/' + this.counselor.slug + '/appointments', limits).subscribe((data) =>{
      
      let offset = new Date().getTimezoneOffset();
      
      data.forEach(item => {
        const ttc = this.priceService.generateTTC(item.pricing);
        let explD = item.due_date.split(" ")[0].split("-");
        let explT = item.due_date.split(" ")[1].split(":");
        let dueDate = new Date(new Date(explD[0], explD[1]-1, explD[2], explT[0], explT[1], explT[2]).getTime() - (offset * 60000));
        let endDate = new Date(dueDate.getTime() + 30 * 60000);
        
        this.addEvent({
          title: '<div class="status' + item.status_appointment_id + '">' + dueDate.toLocaleTimeString('fr-FR', {hour: '2-digit', minute: '2-digit'}) + ' - ' + ttc + '€</div>',
          start: dueDate,
          end: endDate,
          color: color,
          price: ttc,
          id: item.id,
          appointment: item,
          counselor: this.counselor,
          actions: this.actions
        })
      })
    })
  }

  addToFavourites() {
    
    if(this.auth.isAuthenticated()) {
      this.api.post("favourite/counselor/create",{counselor_id: this.counselor.id}).subscribe(data=>{
        this.isFavourite = true;
        this._flashMessagesService.show('Le conseiller a bien été ajouté à vos favoris.',
          { cssClass: 'alert-success' });
      })
    } else {
      this.modal.open(this.modalContent, { size: 'lg' });
    }
  }

  checkIfCounselorIsInFavourite() {
    
    if(this.auth.isAuthenticated()) {
      this.api.post("favourites/counselor/checkIfIsInFavourite",{counselor_id: this.counselor.id}).subscribe(data=>{
        if(data) this.isFavourite = true;
      })
    }
  }
}
