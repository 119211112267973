var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { AuthService } from './../../../services/auth/auth.service';
import { OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScrollTopService } from '../../../shared/providers/scrolltop/scroll-top-service.service';
import { environment } from './../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { stringify } from '@angular/compiler/src/util';
import { SeoService } from './../../../services/seo/seo.service';
import { ApiService } from './../../../services/api/api.service';
import { isSameDay, isSameMonth, endOfMonth, } from 'date-fns';
import { Subject } from 'rxjs';
import { CalendarView, DAYS_OF_WEEK, } from 'angular-calendar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
import { PriceService } from '../../../services/price/price.service';
var color = {
    primary: '#ff0098',
    secondary: '#d1d1d1',
};
var CounselorsDetailComponent = /** @class */ (function () {
    function CounselorsDetailComponent(_activatedRoute, api, auth, scrollTopService, _flashMessagesService, _sanitizer, seo, modal, priceService) {
        var _this = this;
        this._activatedRoute = _activatedRoute;
        this.api = api;
        this.auth = auth;
        this.scrollTopService = scrollTopService;
        this._flashMessagesService = _flashMessagesService;
        this._sanitizer = _sanitizer;
        this.seo = seo;
        this.modal = modal;
        this.priceService = priceService;
        this.environment = environment;
        this.sectionSelected = 1;
        this.agenda = false;
        this.isFavourite = false;
        this.locale = 'fr';
        this.weekStartsOn = DAYS_OF_WEEK.MONDAY;
        this.weekendDays = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
        this.view = CalendarView.Month;
        this.CalendarView = CalendarView;
        this.viewDate = new Date();
        this.actions = [
            {
                label: '<div class="btn bg-info text-white btn-sm ml-3" style="font-size: 10px; margin-top: -10px;"><i class="fas fa-cart-plus"></i> RÉSERVER</div>',
                onClick: function (_a) {
                    var event = _a.event;
                    _this.handleEvent('Edited', event);
                },
            },
        ];
        this.refresh = new Subject();
        this.events = [];
        this.activeDayIsOpen = false;
        // excludeDays: number[] = [0, 6];
        this.random = Math.floor(Math.random() * 100);
        this._activatedRoute.paramMap.subscribe(function (param) {
            var slug = param.get('slug');
            _this.api.get("users/counselor/" + slug).subscribe(function (res) {
                _this.counselor = res;
                var offset = new Date().getTimezoneOffset();
                _this.counselor.diplomas_user.forEach(function (item, index) {
                    var explD = item.date_obtained.split(" ")[0].split("-");
                    _this.counselor.diplomas_user[index].date_obtained = new Date(new Date(explD[0], explD[1] - 1, explD[2], 0, 0, 0).getTime() - (offset * 60000));
                });
                _this.checkIfCounselorIsInFavourite();
                if (_this.counselor.video !== null) {
                    _this.video = _this._sanitizer.bypassSecurityTrustResourceUrl(stringify(_this.counselor.video));
                }
                _this.checkIfIsFavourite();
                _this.setSeo();
            });
        });
    }
    CounselorsDetailComponent.prototype.ngOnInit = function () {
    };
    CounselorsDetailComponent.prototype.checkIfIsFavourite = function () {
        var _this = this;
        if (this.auth.authenticated()) {
            this.api.get('favourite/check/counselor_id/' + this.counselor.id).subscribe(function (data) {
                _this.isFavourite = data;
            });
        }
    };
    CounselorsDetailComponent.prototype.setSeo = function () {
        this.seo.set({
            "title": this.counselor.firstname + ' ' + this.counselor.lastname,
            "description": this.counselor.short_description,
            "og:url": this.environment.siteUrl + '/conseillers/' + this.counselor.slug,
            "og:title": this.counselor.firstname + ' ' + this.counselor.lastname,
            "og:description": this.counselor.short_description,
            "og:image": environment.uploadPath + 'users/' + this.counselor.image,
            "twitter:title": this.counselor.firstname + ' ' + this.counselor.lastname,
            "twitter:description": this.counselor.short_description
        });
    };
    CounselorsDetailComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
        // this.setView(CalendarView.Day);
    };
    CounselorsDetailComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        this.events = this.events.map(function (iEvent) {
            if (iEvent === event) {
                return __assign({}, event, { start: newStart, end: newEnd });
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    };
    CounselorsDetailComponent.prototype.handleEvent = function (action, event) {
        this.modalData = { event: event, action: action };
        this.modal.open(this.modalBook, { size: 'lg' });
    };
    CounselorsDetailComponent.prototype.addEvent = function (event) {
        this.events.push(event);
        this.refresh.next();
    };
    CounselorsDetailComponent.prototype.deleteEvent = function (eventToDelete) {
        this.events = this.events.filter(function (event) { return event !== eventToDelete; });
    };
    CounselorsDetailComponent.prototype.setView = function (view) {
        this.view = view;
    };
    CounselorsDetailComponent.prototype.closeOpenMonthViewDay = function () {
        this.activeDayIsOpen = false;
    };
    CounselorsDetailComponent.prototype.showAgenda = function () {
        if (this.auth.isAuthenticated()) {
            this.getAppointments();
            this.agenda = true;
        }
        else {
            this.modal.open(this.modalContent, { size: 'lg' });
        }
    };
    CounselorsDetailComponent.prototype.getAppointments = function () {
        var _this = this;
        this.events = [];
        var offset = new Date().getTimezoneOffset();
        var viewDate = new Date(new Date().getTime() - (offset * 60000));
        var limits = {
            view: this.view,
            viewDate: viewDate,
            endOfMonth: (endOfMonth(this.viewDate)).toISOString().slice(0, 10) + " 00:00:00",
        };
        this.api.post('users/counselor/' + this.counselor.slug + '/appointments', limits).subscribe(function (data) {
            var offset = new Date().getTimezoneOffset();
            data.forEach(function (item) {
                var ttc = _this.priceService.generateTTC(item.pricing);
                var explD = item.due_date.split(" ")[0].split("-");
                var explT = item.due_date.split(" ")[1].split(":");
                var dueDate = new Date(new Date(explD[0], explD[1] - 1, explD[2], explT[0], explT[1], explT[2]).getTime() - (offset * 60000));
                var endDate = new Date(dueDate.getTime() + 30 * 60000);
                _this.addEvent({
                    title: '<div class="status' + item.status_appointment_id + '">' + dueDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }) + ' - ' + ttc + '€</div>',
                    start: dueDate,
                    end: endDate,
                    color: color,
                    price: ttc,
                    id: item.id,
                    appointment: item,
                    counselor: _this.counselor,
                    actions: _this.actions
                });
            });
        });
    };
    CounselorsDetailComponent.prototype.addToFavourites = function () {
        var _this = this;
        if (this.auth.isAuthenticated()) {
            this.api.post("favourite/counselor/create", { counselor_id: this.counselor.id }).subscribe(function (data) {
                _this.isFavourite = true;
                _this._flashMessagesService.show('Le conseiller a bien été ajouté à vos favoris.', { cssClass: 'alert-success' });
            });
        }
        else {
            this.modal.open(this.modalContent, { size: 'lg' });
        }
    };
    CounselorsDetailComponent.prototype.checkIfCounselorIsInFavourite = function () {
        var _this = this;
        if (this.auth.isAuthenticated()) {
            this.api.post("favourites/counselor/checkIfIsInFavourite", { counselor_id: this.counselor.id }).subscribe(function (data) {
                if (data)
                    _this.isFavourite = true;
            });
        }
    };
    return CounselorsDetailComponent;
}());
export { CounselorsDetailComponent };
