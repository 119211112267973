<section id="signup-section" class="">
    <div class="container">
        <h1 class="session-title">{{title}}<div></div></h1>
        <div class="row bg-light rounded  shadow">
            <p class="dark-color center info mt-4 p-2">Vous pourrez modifier ces informations dans votre espace profil</p>
            <form #formRegister class="custom-form container" [formGroup]="signupForm" (ngSubmit)="formSubmit()">
              <div class="row mt-5 mb-5" id="stepper">
                <div class="line step{{ step }}"></div>
                <div class="col text-center">
                  <div class="step">
                    <div class="number"><span [className]="step >=1 ? 'active' : ''">1</span></div>
                    <div class="title mt-4">Informations personnelles</div>
                  </div>
                </div>
                <div class="col text-center">
                  <div class="step">
                    <div class="number"><span [className]="step >=2 ? 'active' : ''">2</span></div>
                    <div class="title mt-4">Paiement</div>
                  </div>
                </div>
                <div class="col text-center">
                  <div class="step">
                    <div class="number"><span [className]="step ==3 ? 'active' : ''">3</span></div>
                    <div class="title mt-4">Confirmation</div>
                  </div>
                </div>
              </div>
              <div class="pt-4 pb-4">
                <div *ngIf="step == 1" class="mt-5 mb-5">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="lastname" class="bold dark-color">Nom*</label>
                      <input type="text" class="form-control dark-color" id="lastname" formControlName="lastname" placeholder="">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="firstname" class="bold dark-color">Prénom*</label>
                      <input type="text" class="form-control dark-color" id="firstname" formControlName="firstname" placeholder="">
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="email" class="bold dark-color">Mail*</label>
                      <input type="email" class="form-control dark-color" id="email" formControlName="email" placeholder="">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="nick" class="bold dark-color">Pseudo*</label>
                      <input type="text" class="form-control dark-color" id="nick" formControlName="nickname" placeholder="">
                    </div>
                  </div>
  
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="pwd" class="bold dark-color">Mot de passe*</label>
                      <input type="password" class="form-control dark-color" id="pwd" formControlName="password" placeholder="">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="confirm-pwd" class="bold dark-color">Confirmation du mot de passe*</label>
                      <input type="password" class="form-control dark-color" id="confirm-pwd" formControlName="confirmpassword" placeholder="">
                    </div>
                  </div>
  
                  <!-- <div class="row">
                    <div class="checkbox col-md-6">
                      <input id="news" type="checkbox" formControlName="newsletter">
                      <label for="news" class="checkbox-input"></label>
                      <label class="dark-color">Je souhaite recevoir les nouveautés</label>
                    </div>
                  </div> -->
  
                  <div class="row">
                    <div class="checkbox col-md-6">
                      <input id="cgu" type="checkbox" formControlName="cgu">
                      <label for="cgu" class="checkbox-input"></label>
                      <label class="dark-color">J'ai lu et j'accepte <a routerLink="/conditions-generales-de-vente-et-de-service">les conditions générales d'utilisation</a></label>
                    </div>
                  </div>
  
                  <div class="button-section mt-5">
                    <button class="main-button-round" type="submit">Créer mon compte</button>
                  </div>
                </div>
                <div *ngIf="step == 2" class="mt-5 mb-5">
                  <div class="row">
                    <div class="col">
                      <label for="card-info">Votre carte</label>
                      <div id="form-field">
                        <div id="card-element" class="field"></div>
                      </div>
                    </div>
                  </div>
  
                  <div class="button-section mt-5">
                    <button id="card-button" class="main-button-round">Valider le paiement</button>
                  </div>
                </div>
                <div *ngIf="step == 3" class="mt-5 mb-5">
                  <p class="p-4 dark-color-bg text-center rounded">
                    Votre inscription est presque terminée.<br/><br/>Par mesure de sécurité, ainsi que votre paiement.<br/><br/> Par mesure de sécurité nous venons de vous envoyer un email afin que vous validiez votre adresse email.
                  </p>
                </div>
              </div>
            </form>
        </div>
    </div>
  </section>

  <section id="discovery-section" class="" *ngIf="offerId !== '0'">
    <div class="container">
        <h1 class="session-title">Découvrez nos offres !<div></div></h1>
        <span class="dark-color">&#x2304;</span>
    </div>
  </section>

  <app-offers *ngIf="offerId !== '0'"></app-offers>
