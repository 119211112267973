import { Component, OnInit } from '@angular/core';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { ApiService } from '../../services/api/api.service';
import { isNull } from 'util';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {

  faqs;
  faqCategories: any[];
  faqCategoryId = 1;
  faqActive = 0;
  
  constructor(
    private scrollTopService: ScrollTopService,
    private api : ApiService
    ) { }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
    this.getFaqs();
    this.getFaqsCategories();
  }
  
  getFaqs() {
    this.api.get("faqs/category/" + this.faqCategoryId).subscribe((data: any[])=>{
      this.faqs = data;
    })
  }
  
  getFaqsCategories() {
    this.api.get("list/faqCategories").subscribe((data: any[])=>{
      this.faqCategories = data;
    })
  }

  viewIncrement(faqActive, faqId){
    if(faqActive != this.faqActive){
      this.api.get('faqsFO/' + faqId).subscribe(data =>{
      })
    }
  }

}
