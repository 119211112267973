import { SeoService } from './../../services/seo/seo.service';
import { OnInit } from '@angular/core';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api/api.service';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(api, scrollTopService, seo) {
        this.api = api;
        this.scrollTopService = scrollTopService;
        this.seo = seo;
        this.environment = environment;
        this.images = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(function () { return "https://picsum.photos/900/500?random&t=" + Math.random(); });
        this.account = {
            membershipType: { id: null }
        };
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.scrollTopService.setScrollTop();
        this.today = new Date();
        this.currentTime = new Date();
        this.currentTime = this.currentTime.toISOString();
        this.api.get('conferences/getLastForHome').subscribe(function (data) {
            _this.conferencesList = data;
        });
        this.getPartners();
        this.getCategories();
        this.setSeo();
        this.getTestimonies();
    };
    HomeComponent.prototype.getTestimonies = function () {
        var _this = this;
        this.api.get('testimonies/category/2/random').subscribe(function (data) {
            _this.testimonies = data;
        });
    };
    HomeComponent.prototype.setSeo = function () {
        var title = "Familipsy - Site d'information et de formation pour promouvoir les bonnes pratiques en matière d'éducation et de parentalité et améliorer positivement les relations intra-familiales";
        var description = "Information et formation en ligne pour aider les parents à développer leurs compétences en parentalité positive et réussir leur vie de famille.";
        this.seo.set({
            'title': title,
            'description': description,
            'og:title': title,
            'og:url': environment.siteUrl,
            'og:description': description,
            'og:image': environment.uploadPath + 'images/familipsy-logo.jpg',
            'twitter:title': title,
            'twitter:description': description,
        });
    };
    HomeComponent.prototype.getPartners = function () {
        var _this = this;
        this.api.get('list/partners').subscribe(function (data) {
            _this.partners = data;
        });
    };
    HomeComponent.prototype.getCategories = function () {
        var _this = this;
        this.api.get('list/categories').subscribe(function (data) {
            _this.categories = data;
        });
    };
    return HomeComponent;
}());
export { HomeComponent };
