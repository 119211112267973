/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./book.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/payment/payment.component.ngfactory";
import * as i3 from "../../../../shared/components/payment/payment.component";
import * as i4 from "../../../../shared/providers/scrolltop/scroll-top-service.service";
import * as i5 from "../../../../services/api/api.service";
import * as i6 from "../../../../services/auth/auth.service";
import * as i7 from "ng-http-loader";
import * as i8 from "angular2-flash-messages/module/flash-messages.service";
import * as i9 from "@angular/common";
import * as i10 from "./book.component";
import * as i11 from "../../../../services/price/price.service";
var styles_BookComponent = [i0.styles];
var RenderType_BookComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BookComponent, data: {} });
export { RenderType_BookComponent as RenderType_BookComponent };
function View_BookComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cr\u00E9neau : "])), (_l()(), i1.ɵted(4, null, ["", " "])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", "h", " - ", "h", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Prix : "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " \u20AC "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), ((_co.modalData == null) ? null : _co.modalData.event.start), "dd/MM/yyyy")); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((_co.modalData == null) ? null : _co.modalData.event.start.getHours()); var currVal_2 = ((((_co.modalData == null) ? null : _co.modalData.event.start.getMinutes()) == 0) ? "00" : ((_co.modalData == null) ? null : _co.modalData.event.start.getMinutes())); var currVal_3 = ((_co.modalData == null) ? null : _co.modalData.event.end.getHours()); var currVal_4 = ((((_co.modalData == null) ? null : _co.modalData.event.end.getMinutes()) == 0) ? "00" : ((_co.modalData == null) ? null : _co.modalData.event.end.getMinutes())); _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = ((_co.modalData == null) ? null : ((_co.modalData.event == null) ? null : _co.modalData.event.price)); _ck(_v, 13, 0, currVal_5); }); }
function View_BookComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-payment", [], null, [[null, "buyDone"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buyDone" === en)) {
        var pd_0 = (_co.paymentIsDone !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PaymentComponent_0, i2.RenderType_PaymentComponent)), i1.ɵdid(2, 4440064, null, 0, i3.PaymentComponent, [i4.ScrollTopService, i5.ApiService, i6.AuthService, i1.ChangeDetectorRef, i7.SpinnerVisibilityService, i8.FlashMessagesService], { modalData: [0, "modalData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.modalData == null) ? null : _co.modalData.event); _ck(_v, 2, 0, currVal_0); }, null); }
function View_BookComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "main-button-round"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["R\u00E9server"]))], null, null); }
export function View_BookComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i9.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header dark-color-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Prise de rendez-vous"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "p-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BookComponent_1)), i1.ɵdid(10, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BookComponent_2)), i1.ɵdid(12, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BookComponent_3)), i1.ɵdid(15, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "main-button-round white-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.buying = false;
        var pd_0 = (_co.closeAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Fermer"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.buying; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.buying; _ck(_v, 12, 0, currVal_1); var currVal_2 = !_co.buying; _ck(_v, 15, 0, currVal_2); }, null); }
export function View_BookComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-book", [], null, null, null, View_BookComponent_0, RenderType_BookComponent)), i1.ɵdid(1, 114688, null, 0, i10.BookComponent, [i11.PriceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookComponentNgFactory = i1.ɵccf("app-book", i10.BookComponent, View_BookComponent_Host_0, { modalData: "modalData", closeAction: "closeAction", events: "events" }, {}, []);
export { BookComponentNgFactory as BookComponentNgFactory };
