import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../services/api/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-counselors-dashboard',
  templateUrl: './counselors-dashboard.component.html',
  styleUrls: ['./counselors-dashboard.component.css']
})
export class CounselorsDashboardComponent implements OnInit {

  environment = environment;
  appointments: any[] = [];
  profile: any;
  appointmentMessage: string;
  offset: any;

  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  constructor(
    private api: ApiService,
    private modal: NgbModal
  ) { }

  ngOnInit() {
    document.getElementById('zmmtg-root').style.display = 'none';
    document.getElementById('header').style.zIndex = "1060";
    this.offset = new Date().getTimezoneOffset();
    this.getMyLastAppointmentsToCome();
    this.getMyProfile();
  }

  processDate(dt) {
    return new Date(new Date(dt).getTime() - (this.offset * 60000))
  }

  getMyLastAppointmentsToCome() {
    this.api.get("auth/counselor/appointments/last").subscribe((data: any[]) => {
      this.appointments = data;
      this.appointments.forEach((item,key)=>{
        let explD = item.due_date.split(" ")[0].split("-");
        let explT = item.due_date.split(" ")[1].split(":");
        let dueDate = new Date(new Date(explD[0], explD[1]-1, explD[2], explT[0], explT[1], explT[2]).getTime() - (this.offset * 60000));

        // this.appointments[key].due_date = new Date(new Date(item.due_date).getTime() - (this.offset * 60000))
        this.appointments[key].due_date = dueDate;
      })
    })
    
  }

  getMyProfile() {
    this.api.get("myCounselorProfil").subscribe((data: any[]) => {
      this.profile = data;
    })
  }

  showAppointmentMessage(comment) {
    this.appointmentMessage = comment;
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  cancelAppointment() {
    
  }
}
