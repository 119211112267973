import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppComponent } from '../../app.component';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  environment = environment;
  term: String;
  results = {
    conferences: [],
    counselors: [],
    faqs: [],
    files: [],
    trainings: [],
  };

  constructor(
    private api: ApiService,
    private _activatedRoute: ActivatedRoute,
    private appComp: AppComponent,
    private router: Router,
  ) {
    this.term = this._activatedRoute.snapshot.queryParamMap.get('query');
    if(this.term) this.search();
  }

  ngOnInit() {
    // this.appComp.activateChatbox = false;
  }

  search() {
    console.log("this.term", this.term);
    this.router.navigate(['/recherche'], {queryParams: {query: this.term}});
    this.api.post("search", {search: this.term}).subscribe(data=>{
      console.log("data", data);
      this.results = data;
    })
  }
}
