import { OnInit } from '@angular/core';
import { NewsletterService } from '../../providers/newsletters/newsletter.service';
import { FlashMessagesService } from 'angular2-flash-messages';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(_newsletterService, _flashMessagesService) {
        this._newsletterService = _newsletterService;
        this._flashMessagesService = _flashMessagesService;
    }
    FooterComponent.prototype.ngOnInit = function () {
    };
    FooterComponent.prototype.subscribe = function () {
        var _this = this;
        this._newsletterService.subscribe(this.email).subscribe(function (res) {
            _this._flashMessagesService.show('Merci de votre intérêt pour notre newsletter.', { cssClass: 'alert-success' });
        });
    };
    return FooterComponent;
}());
export { FooterComponent };
