import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var API_URL = environment.apiUrl;
var ApiService = /** @class */ (function () {
    function ApiService(httpClient) {
        this.httpClient = httpClient;
        // Http Options
        this.httpOptions = {};
        this.setHeaderOptions();
    }
    ApiService.prototype.setHeaderOptions = function () {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
    };
    ApiService.prototype.get = function (url) {
        return this.httpClient.get(API_URL + url, this.httpOptions);
    };
    ApiService.prototype.post = function (url, data) {
        return this.httpClient.post(API_URL + url, data, this.httpOptions);
    };
    ApiService.prototype.delete = function (url) {
        return this.httpClient.delete(API_URL + url, this.httpOptions);
    };
    // Error handling 
    ApiService.prototype.handleError = function (error) {
        var errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        }
        else {
            // Get server-side error
            errorMessage = "Error Code: " + error.status + "\nMessage: " + error.message;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
