import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../../../services/api/api.service';

@Component({
  selector: 'app-counselors-infos',
  templateUrl: './counselors-infos.component.html',
  styleUrls: ['./counselors-infos.component.css']
})
export class CounselorsInfosComponent implements OnInit {

  newForm: FormGroup;
  account: {
    lastname,
    firstname,
    nickname,
    email,
    short_description,
    long_description,
    video
  };

  passwordForm: any = {
    actualPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  };

  constructor(
    private api: ApiService) {
    this.initForm();
  }

  initForm() {
    this.newForm = new FormGroup({
      lastname: new FormControl("", [Validators.required,]),
      firstname: new FormControl("", [Validators.required,]),
      nickname: new FormControl("", [Validators.required,]),
      email: new FormControl("", [Validators.required, Validators.email]),
      shortDescription: new FormControl("", []),
      longDescription: new FormControl("", []),
      video: new FormControl("", []),
    });
  }

  ngOnInit() {
  }

  init() {
    this.getMyCounselorInfos();
  }

  getMyCounselorInfos() {
    this.api.get("myCounselorProfil").subscribe((data:{
      lastname,
      firstname,
      nickname,
      email,
      short_description,
      long_description,
      video
    })=>{
      this.account = data;
      this.setNewForm();
    })
  }

  setNewForm() {
    this.newForm = new FormGroup({
      lastname: new FormControl(this.account.lastname, [
        Validators.required,
      ]),
      firstname: new FormControl(this.account.firstname, [
        Validators.required,
      ]),
      nickname: new FormControl(this.account.nickname, [
        Validators.required,
      ]),
      email: new FormControl(this.account.email, [
        Validators.required,
        Validators.email
      ]),
      shortDescription: new FormControl(this.account.short_description, []),
      longDescription: new FormControl(this.account.long_description, []),
      video: new FormControl(this.account.video, []),
    });
  }

  checkPersonalData()
  {
    if(this.newForm.get('lastname').invalid) {alert('Champs Nom à remplir'); return}
    else if(this.newForm.get('firstname').invalid) {alert('Champs Prénom à remplir'); return}
    else if(this.newForm.get('nickname').invalid) {alert('Champs pseudo à remplir'); return}
    else if(this.newForm.get('email').invalid) {alert('Champs email à remplir'); return}
    else if(this.newForm.get('shortDescription').invalid) {alert('Champs description courte à remplir'); return}
    else if(this.newForm.get('longDescription').invalid) {alert('Champs description longue à remplir'); return}
    else if(this.passwordForm.newPassword)
    {
      if(this.passwordForm.newPassword != this.passwordForm.newPasswordConfirmation)
      {
        alert('Les deux mots de passe sont différents');
        return;
      }
      
    }
   
    
    const data = this.prepareDataToSave();
    this.api.post('users/counselor/update', data).subscribe( data =>
      {
        this.account = data;
      })

  }

  get f() { return this.newForm.controls; }


  private prepareDataToSave() {

    const recordToSave = new FormData();
    recordToSave.append('lastname', this.f.lastname.value);
    recordToSave.append('firstname', this.f.firstname.value);
    recordToSave.append('nickname', this.f.nickname.value);
    recordToSave.append('email', this.f.email.value);
    recordToSave.append('short_description', this.f.shortDescription.value);
    recordToSave.append('long_description', this.f.longDescription.value);
    recordToSave.append('video', this.f.video.value);
    if(this.passwordForm.newPassword) recordToSave.append('password', this.passwordForm.newPassword);
 
    return recordToSave;
  }

}
