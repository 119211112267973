import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-works-advisor',
  templateUrl: './how-it-works-advisor.component.html',
  styleUrls: ['./how-it-works-advisor.component.css']
})
export class HowItWorksAdvisorComponent implements OnInit {

  showText: Boolean;
  constructor() { }

  ngOnInit() {
  }

}
