import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../../../../services/api/api.service';
var CounselorsSituationComponent = /** @class */ (function () {
    function CounselorsSituationComponent(api, fb) {
        this.api = api;
        this.fb = fb;
        this.initForm();
    }
    CounselorsSituationComponent.prototype.ngOnInit = function () {
    };
    CounselorsSituationComponent.prototype.init = function () {
        this.getMyCounselorSituation();
    };
    CounselorsSituationComponent.prototype.getMyCounselorSituation = function () {
        var _this = this;
        this.api.get("user/getMyCounselorSituation").subscribe(function (data) {
            _this.dataToSend = {
                "Specialities": data.specialities.map(function (item) { return item.id; }),
                "Problematics": data.problematic_user.map(function (item) { return item.id; }),
                "Theoricals": data.theorical_approaches.map(function (item) { return item.id; }),
                "Languages": data.language_user.map(function (item) { return item.id; }),
            };
            _this.getSpecialities();
            _this.getTheoricalApproches();
            _this.getProblematics();
            _this.getLanguages();
        });
    };
    CounselorsSituationComponent.prototype.initForm = function () {
        this.newFormSpecialities = this.fb.group({
            checkArray: this.fb.array([])
        });
        this.newFormProblematics = this.fb.group({
            checkArray: this.fb.array([])
        });
        this.newFormTheoricalApproaches = this.fb.group({
            checkArray: this.fb.array([])
        });
        this.newFormLanguages = this.fb.group({
            checkArray: this.fb.array([])
        });
    };
    CounselorsSituationComponent.prototype.getSpecialities = function () {
        var _this = this;
        this.api.get("list/specialities").subscribe(function (data) {
            _this.specialities = data;
        });
    };
    CounselorsSituationComponent.prototype.getTheoricalApproches = function () {
        var _this = this;
        this.api.get("list/theoricalApproaches").subscribe(function (data) {
            _this.theoricalApproaches = data;
        });
    };
    CounselorsSituationComponent.prototype.getProblematics = function () {
        var _this = this;
        this.api.get("list/problematics").subscribe(function (data) {
            _this.problematics = data;
        });
    };
    CounselorsSituationComponent.prototype.getLanguages = function () {
        var _this = this;
        this.api.get("list/languages").subscribe(function (data) {
            _this.languages = data;
        });
    };
    CounselorsSituationComponent.prototype.onCheckboxChange = function (e, form) {
        var _this = this;
        if (e.target.checked) {
            this.dataToSend[form].push(e.target.value);
        }
        else {
            var i_1 = 0;
            this.dataToSend[form].forEach(function (item) {
                if (item == e.target.value) {
                    _this.dataToSend[form].removeAt(i_1);
                    return;
                }
                i_1++;
            });
        }
    };
    CounselorsSituationComponent.prototype.submit = function (url) {
        this.api.post("update/auth" + url, { data: this.dataToSend[url].join(',') }).subscribe(function (data) {
        });
    };
    return CounselorsSituationComponent;
}());
export { CounselorsSituationComponent };
