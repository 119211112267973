export const environment = {
  production: true,
  siteUrl: 'https://test.familipsy.academy/',
  filePath: 'https://cdn.test.familipsy.academy/uploads/files/',
  uploadPath: 'https://cdn.test.familipsy.academy/uploads/',
  apiUrl: 'https://api.test.familipsy.academy/api/',
  zoomApiKey: '1ieHVvmKQV-VFCzrG0nv4g',
  zoomApiSecret: 'hQoEVIJzyTbSuFeflh9IkESFJow6qEECreyS',
  activateChatbox: true
};
