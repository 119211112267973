import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../../../services/api/api.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  @Input() slug: any;
  content: any;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
    this.api.get('content/getBySlug/'+this.slug).subscribe(data => {
      this.content = data;
    })
  }

}
