<div class="modal-header dark-color-bg">
  <h5 class="modal-title">Prise de rendez-vous</h5>
  <button type="button" class="close" (click)="closeAction()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="p-4">
    <section *ngIf="!buying">
      <div>
        <span class="bold">Créneau : </span>{{ modalData?.event.start | date: 'dd/MM/yyyy' }}
        <span>
          {{modalData?.event.start.getHours()}}h{{modalData?.event.start.getMinutes() == 0 ? "00" : modalData?.event.start.getMinutes()}}
          -
          {{modalData?.event.end.getHours()}}h{{modalData?.event.end.getMinutes() == 0 ? "00" : modalData?.event.end.getMinutes()}}
        </span>
      </div>
      <br />
      <div>
        <span class="bold">Prix : </span>
        <span>
          {{ modalData?.event?.price }} €
          <!-- (dont {{ modalData?.event?.price - modalData?.event?.price / 1.15 | number : '1.0-0'}} € de frais techniques) -->
        </span>
      </div>
    </section>
    <section *ngIf="buying">
      <app-payment [modalData]="modalData?.event" (buyDone)="paymentIsDone"></app-payment>
    </section>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="main-button-round" *ngIf="!buying" (click)="buy()">Réserver</button>
  <button type="button" class="main-button-round white-button" (click)="buying = false; closeAction(); ">Fermer</button>
</div>