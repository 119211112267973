import { SeoService } from './../../../services/seo/seo.service';
import { ApiService } from './../../../services/api/api.service';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-counselors-list',
  templateUrl: './counselors-list.component.html',
  styleUrls: ['./counselors-list.component.css']
})
export class CounselorsListComponent implements OnInit {

  environment = environment;
  counselors: any[];

  random = Math.floor(Math.random() * 100);
  
  constructor(
    private api: ApiService,
    private seo: SeoService
  ) { }

  ngOnInit() {
    this.setSeo();
    this.api.get('users/allCounselorsNoPag/activated').subscribe((data: any[])=>{
      this.counselors = data;
    })
  }

  setSeo() {

    this.seo.set({
      "title": "Les conseillers, psychologues et coachs de Familipsy Academy",
      "description": "Diplômés et sélectionnés pour leurs expertises et leurs qualités éthiques et relationnelles, nos conseillers vous aideront à vous sentir plus heureux et serein. Pratique, simple et plus économique que des séances traditionnelles, l’accompagnement en ligne Familipsy, est la solution adaptée aux besoins des parents d’aujourd’hui.",
      "og:url": this.environment.siteUrl + '/conseillers',
      "og:title": "Les conseillers, psychologues et coachs de Familipsy Academy",
      "og:description": "Diplômés et sélectionnés pour leurs expertises et leurs qualités éthiques et relationnelles, nos conseillers vous aideront à vous sentir plus heureux et serein. Pratique, simple et plus économique que des séances traditionnelles, l’accompagnement en ligne Familipsy, est la solution adaptée aux besoins des parents d’aujourd’hui.",
      "og:image": environment.uploadPath + 'images/familipsy-logo.jpg',
      "twitter:title": "Les conseillers, psychologues et coachs de Familipsy Academy",
      "twitter:description": "Diplômés et sélectionnés pour leurs expertises et leurs qualités éthiques et relationnelles, nos conseillers vous aideront à vous sentir plus heureux et serein. Pratique, simple et plus économique que des séances traditionnelles, l’accompagnement en ligne Familipsy, est la solution adaptée aux besoins des parents d’aujourd’hui."
    })
  }

  ceilAmount(amount) {
    return Math.ceil(amount);
  }

}
