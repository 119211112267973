<ng-http-loader
[backgroundColor]="'#ff0098'"
[debounceDelay]="0"
[minDuration]="300"></ng-http-loader>
<!--The content below is only a placeholder and can be replaced.-->
<app-header></app-header>
<flash-messages class="flash_message"></flash-messages>
<div class="mainContent">
    <router-outlet></router-outlet>

</div>
<div class="row">
    
    <div id="chatBox" *ngIf="chatBoxOpen" [style.height]="chatBoxHeight" [class]="isMobile ? 'mobile' : ''">
        <div class="head">
            <img src="assets/imgs/mascotte-mini.png" />
            <span>Discutons<br/>ensemble!</span>
            <i class="material-icons" (click)="closeChat()">close</i>
        </div>
        <div id="bodyChatBox" class="body" #scrollChatBody [style.height]="chatBoxBodyHeight">
            <ng-container *ngFor="let message of messages; let last = last">
                <div [ngClass]="message.who">
                    <span *ngIf="message.bubble"><img src="assets/imgs/chat-loading.gif"></span>
                    <span *ngIf="!message.options && !message.bubble" [innerHTML]="message.human ? message.human : message.text"></span>
                    <ng-container *ngIf="message.options && !message.bubble">
                        <button class="choice" *ngFor="let option of message.options" (click)="makeChoice(option)">{{ option.text }}</button>
                    </ng-container>
                    <ng-container *ngIf="message.form && !message.bubble">
                        <textarea #term class="w-100 border-pink p-2 rounded" rows="10"></textarea>
                        <button class="choice" (click)="search(term.value)">valider</button>
                    </ng-container>
                </div>
                {{last ? scrollToBottom() : ''}}
            </ng-container>
        </div>
    </div>
    <div *ngIf="activateChatbox" id="mascotte" (click)="openChat()"><img src="assets/imgs/mascotte-mini.png" /></div>
</div>
<div class="row">
    <div class="col p-0">

        <app-footer></app-footer>
    </div>
</div>

