import { Component, OnInit } from '@angular/core';
import { NewsletterService } from '../../shared/providers/newsletters/newsletter.service';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {

  constructor(private _newsletterService: NewsletterService,
    private _flashMessagesService: FlashMessagesService) { }

  ngOnInit() {
  }

  email: string;
  subscribe ()  {
    this._newsletterService.subscribe(this.email).subscribe(res=>{
      this._flashMessagesService.show('Merci de votre intérêt pour notre newsletter.',
            { cssClass: 'alert-success' });
    });
  }

}
