import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../shared/providers/authentication/authentication.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { User } from '../../model/user.model';
import { CONFIGURATION } from '../../configurations/configurations';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { Offer } from '../../model/offer.model';
import { MembershipService } from '../../shared/providers/memberships/membership.service';
import { ApiService } from '../../services/api/api.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  loginForm: FormGroup;
  forgetForm: FormGroup;
  isForget = false;
  firstOffer: Offer;

  rememberMe: Boolean;

  constructor(
    private _flashMessagesService: FlashMessagesService,
    private auth: AuthService,
    private router: Router,
    private scrollTopService: ScrollTopService,
    private api: ApiService) { }

  formSubmit() {
    const user: User = new User();
    if (this.loginForm.controls['email'].errors) {
      this._flashMessagesService.show('Veulliez verifier le format de votre adresse mail', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }
    if (this.loginForm.controls['password'].errors) {
      this._flashMessagesService.show('Veuillez remplir les champs obligatoires', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }
    user.email = this.loginForm.get('email').value;
    user.password = this.loginForm.get('password').value;

    this.auth.login(user);
  }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
    ]),
      password: new FormControl('', [Validators.required])
    });
    this.forgetForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
    ])
    });
  }

  requestReinitialisationPassword(){
    if(this.forgetForm.controls['email'].errors){
      this._flashMessagesService.show('Veulliez verifier le format de votre adresse mail', { cssClass: 'alert-danger', timeout: 4000 });
      return;
    }
    const data = {
      email : this.forgetForm.get('email').value
    }
    this.api.post('email/reset_password',data).subscribe( res => {
      this._flashMessagesService.show('Veuillez vérifier votre boite mail pour continuer la procédure de réinitialisation', { cssClass: 'alert-success', timeout: 4000 });
      localStorage.setItem('myEmail', data.email);
    })
  }



}
