import { AuthService } from './../../../services/auth/auth.service';
import { ApiService } from './../../../services/api/api.service';
import {
  Component,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { format } from 'date-fns';

import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ScrollTopService } from '../../providers/scrolltop/scroll-top-service.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { environment } from '../../../../environments/environment';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {

  environment = environment;
  @Input() modalData: any;
  @Input() closeModal: any;
  @Output() buyDoneChange = new EventEmitter<boolean>();
  
  me: any;
  elements: Elements;
  card: StripeElement;
  newForm: FormGroup;
  cardHandler = this.onChange.bind(this);

  @ViewChild('cardElement') cardElement: ElementRef;

  @ViewChild('cardButton') cardButton: ElementRef;

  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: 'fr'
  };
  step = 1;
  finalMessage: String;
  error: string;

  constructor(
    private scrollTopService: ScrollTopService,
    private api: ApiService,
    private auth: AuthService,
    private cd: ChangeDetectorRef,
    private spinner: SpinnerVisibilityService,
    private _flashMessagesService: FlashMessagesService,
  ) { }

  ngOnInit() {
    this.me = this.auth.authenticated();
    this.scrollTopService.setScrollTop();
    this.newForm = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });
  }
  ngAfterViewInit() {
    this.initCard()
  }

  ngOnDestroy() {
    // this.card.removeEventListener('change', this.cardHandler);
    // this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  initCard() {
    this.finalMessage = "";
    // setTimeout(()=>{
        this.card = elements.create('card', {
          style: {
            base: {
              iconColor: '#666EE8',
              color: '#333',
              lineHeight: '40px',
              fontWeight: 300,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: '20px',
              '::placeholder': {
                color: '#333'
              }
            }
          }
        }
        );

        this.card.mount('#card-element');
        // this.card.addEventListener('change', this.cardHandler);
        this.makePayment();
    // },2000);
  }
  
  makePayment() {
    
    const cardButton = document.getElementById('card-button');

    cardButton.addEventListener('click', async (e) => {
      
      if(!this.newForm.get('comment').invalid){
        this.spinner.show();
        const { paymentMethod, error } = await stripe.createPaymentMethod(
            'card', this.card, {
                billing_details: { name: this.me.email }
            }
        );
        
        if (error) {
          // Display "error.message" to the user...
          this.spinner.hide();
        } else {
            // The card has been verified successfully...
          let data = {paymentMethod: paymentMethod, appointment: this.modalData, comment: this.newForm.get('comment').value};
          this.api.post("appointment/payment",data).subscribe(res => {
  
            this.spinner.hide();
            this.step = 3;
            this.finalMessage = "";

            this.buyDoneChange.emit(true);
          });
        }
      } else {
        this._flashMessagesService.show('Merci de décrire vos besoins.', { cssClass: 'alert-warning' });
      }
    });
  }

  formSubmit() {

  }

  icsGoogle() {

    let params = {
      text: "Consultation avec " + this.modalData.counselor.firstname + " " + this.modalData.counselor.lastname,
      details: '<p><br></p><p>Voici les modalités pratiques de votre séance à distance.</p><p><br></p><p>La séance démarrera à la date et heure prévue. (Merci de prendre vos dispositions pour vous connecter un peu avant, afin de commencer dès l\'heure convenue)</p><p><br></p><p>Votre session vidéo sera accessible à partir de ce lien : <a href=\"' + this.modalData.counselor.url_visio + '\">lien ZOOM</a> (Note : Gardez bien ce mail jusqu\'à la date de votre séance).</p><p>Si vous le souhaitez, vous pourrez couper la vidéo et ne garder que l\'audio.</p><p>Sinon, assurez-vous que votre vidéo et votre micro soient bien paramétrés.</p><p><br></p><p>Astuce : Vous pouvez prévoir une notification dans votre agenda pour être sûr(e) de ne pas rater votre rendez-vous.</p><p>Voici quelques consignes pour profiter pleinement de votre séance :</p><p>Si vous avez besoin de me poser quelques questions avant de démarrer, je vous répondrai.</p><p><br></p><p>Vous trouverez également des réponses aux questions les plus fréquentes dans le FAQ (lien URL).</p><p><br></p><p>Prévoyez de quoi prendre des notes si besoin et si vous le souhaitez, notez par avance les questions principales que vous vous posez.</p><p><br></p><p>Coupez toutes les sources de distraction et de bruit autour de vous.</p><p>Installez-vous confortablement.</p><p>Cette séance est une sorte de \"rendez-vous\" avec vous-même, nous mettons tout en oeuvre pour que ce soit une expérience des plus bénéfiques pour vous.</p><p><br></p><p>Notre support est aussi à votre service pour toute question technique.</p><p><br></p><p>Au succès de votre famille,</p><p><br></p><p>FamiliPsy Academy</p>',
      location: '',
      dates: format(this.modalData.start,'YYYYMMDDTHHmmss') + '/' + format(this.modalData.end,'YYYYMMDDTHHmmss') //'20200806T133000/20200806T140000'
    };
    
    var queryString = Object.keys(params).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&');

    return 'https://calendar.google.com/calendar/render?action=TEMPLATE&'+queryString;

    // https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent
    // &startdt=2020-08-06T13%3A30%3A00
    // &enddt=2020-08-06T14%3A00%3A00
    // &subject=Consultation%20avec%20Teremu%20MARO
    // &body=Lorem%20ipsum%20dolor%20sit%20amet%2C%20consectetur%20adipiscing%20elit.%20Aenean%20ut%20pulvinar%20eros.%20Nulla%20ullamcorper%20auctor%20convallis.%20Etiam%20fermentum%20fermentum%20lorem%2C%20vitae%20porta%20ante%20scelerisque%20et.%20Morbi%20blandit%20arcu%20nec%20quam%20blandit%20placerat.%20Duis%20pharetra%2C%20justo%20faucibus%20mollis%20placerat%2C%20leo%20risus%20fringilla%20neque%2C%20id%20laoreet%20lectus%20orci%20nec%20ipsum.%20Ut%20eget%20egestas%20neque%2C%20at%20facilisis%20nulla.%20Proin%20gravida%20ligula%20id%20gravida%20dignissim.%0A%0ADonec%20ut%20arcu%20faucibus%2C%20dignissim%20quam%20ac%2C%20fermentum%20lacus.%20Ut%20congue%20mi%20sed%20fringilla%20ullamcorper.%20Sed%20libero%20quam%2C%20scelerisque%20vitae%20maximus%20nec%2C%20semper%20at%20purus.%20Nulla%20massa%20nunc%2C%20varius%20in%20tellus%20vitae%2C%20sodales%20porta%20dui.%20Vestibulum%20vulputate%20dolor%20ac%20urna%20ullamcorper%20hendrerit.%20Nam%20finibus%20bibendum%20ligula%20sodales%20dapibus.%20Proin%20lacinia%20ullamcorper%20blandit.%20Cras%20scelerisque%2C%20risus%20blandit%20facilisis%20tristique%2C%20est%20dolor%20tempor%20sem%2C%20in%20ornare%20arcu%20urna%20ac%20sem.%20Quisque%20eleifend%20laoreet%20pharetra.%20Sed%20lacinia%20quam%20felis.%20Aenean%20elit%20ante%2C%20congue%20ac%20consectetur%20eget%2C%20vestibulum%20eget%20lectus.%20Etiam%20et%20urna%20et%20mauris%20luctus%20tincidunt%20convallis%20vitae%20ipsum.%20Ut%20maximus%20diam%20eu%20sem%20scelerisque%20consequat.%20Nam%20massa%20metus%2C%20condimentum%20eu%20dapibus%20vel%2C%20aliquam%20vitae%20eros.
    // &location=
    // &allday=false
  }
}
