import { OnInit } from '@angular/core';
import { PriceService } from '../../../../services/price/price.service';
var BookComponent = /** @class */ (function () {
    function BookComponent(priceService) {
        this.priceService = priceService;
        this.paymentIsDone = false;
    }
    BookComponent.prototype.buy = function () {
        this.buying = !this.buying;
    };
    BookComponent.prototype.ngOnInit = function () {
    };
    return BookComponent;
}());
export { BookComponent };
