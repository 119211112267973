import { ReferentialLabel } from './referential-label.model';

export class Child {
    firstName: String;
    gender: any;
    birthDate: Date;
}

export class Particulier {
    profilePic: String;
    profile: {
        id: String;
        lastName: String;
        firstName: String;
        nickName: String;
        email: String;
        password: String;
        description: String;
    };
    situation: {
        familySituation: ReferentialLabel;
        birthDate: Date;
        country: ReferentialLabel;
        nbChildren: Number;
        children: Child[];
        interestedBy: ReferentialLabel[];
    };
    membershipType: any;
}
