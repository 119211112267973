<section id="contact-section" class="">
  <div class="container">
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h1 class="pink-color">404</h1>
        </div>
        <h2>Oops, La page que vous cherchez est introuvable!</h2>
        <!-- <form class="notfound-search">
          <input type="text" placeholder="Search...">
          <button type="button">Search</button>
        </form> -->
        <a href="#" class="main-button-round uppercase bold white-color mt-4">Page d'accueil</a>
      </div>
    </div>
  </div>
</section>