import { OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { ModalService } from '../../providers/modal/modal.service';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var HomeSliderComponent = /** @class */ (function () {
    function HomeSliderComponent(_sanitizer, 
    // private modal: ModalService,
    modal, api, el) {
        this._sanitizer = _sanitizer;
        this.modal = modal;
        this.api = api;
        this.el = el;
        this.environment = environment;
        this.element = el.nativeElement;
    }
    HomeSliderComponent.prototype.ngOnInit = function () {
        this.getAll();
        // close modal on background click
        this.element.addEventListener('click', function (e) {
            if (e.target.className === 'modal') {
                this.modal.close();
            }
        });
    };
    HomeSliderComponent.prototype.getAll = function () {
        var _this = this;
        this.api.get('slides/getBySlug/' + this.zone).subscribe(function (data) {
            _this.slides = data;
        });
    };
    HomeSliderComponent.prototype.openModal = function (videoModal, url) {
        var text = '<iframe class="embed-responsive-item" src="' + url + '" id="video" allowscriptaccess="always" allow="autoplay" allowfullscreen></iframe>';
        this.bodyText = this._sanitizer.bypassSecurityTrustHtml(text);
        this.modal.open(videoModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    };
    HomeSliderComponent.prototype.stopVideo = function () {
        var videos = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(videos, function (video) {
            if (video.tagName.toLowerCase() === 'video') {
                video.pause();
            }
            else {
                var src = video.src;
                video.src = src;
            }
        });
    };
    ;
    return HomeSliderComponent;
}());
export { HomeSliderComponent };
