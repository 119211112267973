import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../../../../services/api/api.service';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-counselors-situation',
  templateUrl: './counselors-situation.component.html',
  styleUrls: ['./counselors-situation.component.css']
})
export class CounselorsSituationComponent implements OnInit {

  newFormSpecialities: FormGroup;
  newFormProblematics: FormGroup;
  newFormTheoricalApproaches: FormGroup;
  newFormLanguages: FormGroup;

  problematics: any[];
  specialities: any[];
  theoricalApproaches: any[];
  languages: any[];

  dataToSend: any;

  constructor(private api: ApiService, private fb: FormBuilder) {
    this.initForm();
  }

  ngOnInit() {
  }

  init() {
    this.getMyCounselorSituation();
  }
  
  getMyCounselorSituation() {
    this.api.get("user/getMyCounselorSituation").subscribe((data: any)=>{
      this.dataToSend = {
        "Specialities" : data.specialities.map(item => item.id),
        "Problematics" : data.problematic_user.map(item => item.id),
        "Theoricals" : data.theorical_approaches.map(item => item.id),
        "Languages" : data.language_user.map(item => item.id),
      };
      this.getSpecialities();
      this.getTheoricalApproches();
      this.getProblematics();
      this.getLanguages();
    })
  }

  
  initForm() {
    this.newFormSpecialities = this.fb.group({
      checkArray: this.fb.array([])
    })
    this.newFormProblematics = this.fb.group({
      checkArray: this.fb.array([])
    })
    this.newFormTheoricalApproaches = this.fb.group({
      checkArray: this.fb.array([])
    })
    this.newFormLanguages = this.fb.group({
      checkArray: this.fb.array([])
    })
  }

  getSpecialities() {
    this.api.get("list/specialities").subscribe((data: any[])=>{
      this.specialities = data;
    })
  }

  getTheoricalApproches() {
    this.api.get("list/theoricalApproaches").subscribe((data: any[])=>{
      this.theoricalApproaches = data;
    })
  }

  getProblematics() {
    this.api.get("list/problematics").subscribe((data: any[])=>{
      this.problematics = data;
    })
  }

  getLanguages() {
    this.api.get("list/languages").subscribe((data: any[])=>{
      this.languages = data;
    })
  }

  onCheckboxChange(e, form) {
    if (e.target.checked) {
      this.dataToSend[form].push(e.target.value);
    } else {
      let i: number = 0;
      this.dataToSend[form].forEach((item) => {
        if (item == e.target.value) {
          this.dataToSend[form].removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submit(url) {
    this.api.post("update/auth"+url,{data:this.dataToSend[url].join(',')}).subscribe(data=>{

    })
  }
}
