<div class="container page">

  <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col">
            <span *ngIf="!isFavourite" class="float-left mr-3 pink-color cursor-pointer" style="font-size: 25px" (click)="addToFavourites()" placement="top" ngbTooltip="Ajouter aux favoris"><i class="far fa-heart"></i></span>
            <h1 class="text-left mb-0 p-0">
              {{ ressource?.title }}
              <div></div>
            </h1>
          </div>
        </div>
        <div class="row" *ngIf="ressource?.author">
          <div class="col">
            <div class="black-color">par {{ ressource?.author }}</div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <div [innerHtml]="ressource?.description"></div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <button *ngIf="ressource?.type === 'link'" (click)="viewRessource()" class="main-button-round uppercase bold"><span *ngIf='account?.membership_id < ressource?.membership_id && ressource?.membership_id != 0'><i class="fas fa-lock"></i>&nbsp;</span>Consulter la page</button>
            <button *ngIf="ressource?.type === 'video'" (click)="openVideo()" class="main-button-round uppercase bold"><span *ngIf='account?.membership_id < ressource?.membership_id && ressource?.membership_id != 0'><i class="fas fa-lock"></i>&nbsp;</span>Lancer la vidéo</button>
            <button *ngIf="ressource?.type === 'file'" (click)="viewRessource()" class="main-button-round uppercase bold"><span *ngIf='account?.membership_id < ressource?.membership_id && ressource?.membership_id != 0'><i class="fas fa-lock"></i>&nbsp;</span>Télécharger le fichier</button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <img src="{{ environment.filePath + ressource?.image }}" class="w-100" />
      </div>
  </div>
</div>

<ng-template #videoModal let-close="close">
  <app-modal [modalContent]="bodyText" [closeAction]="close"></app-modal>
</ng-template>

<ng-template #memberOnlyModal id="memberOnlyModal" let-close="close">
  <app-member-only [closeAction]="close" [upgrade]="upgrade"></app-member-only>
</ng-template>