<div id="counselors-section" class="contentWrapper">
  <div class="container">
    <h1>Nos conseillers<div></div></h1>
    <div class="row">
      <p class="dark-color center">
        Diplômés et sélectionnés pour leurs expertises et leurs qualités éthiques et relationnelles, nos conseillers vous aideront à vous sentir plus heureux et serein. Pratique, simple et plus économique que des séances traditionnelles, l’accompagnement en ligne Familipsy, est la solution adaptée aux besoins des parents d’aujourd’hui.
      </p>
    </div>
    <div class="row">
        <div class="col">
            <p class="dark-color text-center">
                <a routerLink="/guide" class="main-button-round">Je me laisse guider</a>
            </p>
        </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4" *ngFor="let item of counselors">
        <div class="card text-center shadow rounded">
          <a routerLink="/conseillers/{{ item?.slug }}">
            <img src="{{ environment.uploadPath + 'users/' + item?.image + '?random=' + random }}" class="card-img-top" alt="{{ item?.firstname + ' ' + item?.lastname }}" />
          </a>
          <div class="card-body">
            <h5 class="card-title">{{ item?.firstname }}<br/>{{ item?.lastname }}</h5>
            <p class="card-text">
              <i class="fa-star mr-2" [class.text-secondary-light]="item?.rate<1" [class.text-warning]="item?.rate>=1" [class.far]="item?.rate<1" [class.fas]="item?.rate>=1"></i>
              <i class="fa-star mr-2" [class.text-secondary-light]="item?.rate<2" [class.text-warning]="item?.rate>=2" [class.far]="item?.rate<2" [class.fas]="item?.rate>=2"></i>
              <i class="fa-star mr-2" [class.text-secondary-light]="item?.rate<3" [class.text-warning]="item?.rate>=3" [class.far]="item?.rate<3" [class.fas]="item?.rate>=3"></i>
              <i class="fa-star mr-2" [class.text-secondary-light]="item?.rate<4" [class.text-warning]="item?.rate>=4" [class.far]="item?.rate<4" [class.fas]="item?.rate>=4"></i>
              <i class="fa-star" [class.text-secondary-light]="item?.rate<5" [class.text-warning]="item?.rate>=5" [class.far]="item?.rate<5" [class.fas]="item?.rate>=5"></i>
            </p>
            <p class="card-text" style="min-height: 120px;">{{ item?.short_description }}</p>
            <a routerLink="/conseillers/{{ item?.slug }}" class="rounded white-button pink-color block mb-3">Voir le profil</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>