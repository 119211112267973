import { OnInit, TemplateRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../services/api/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
var CounselorsDashboardComponent = /** @class */ (function () {
    function CounselorsDashboardComponent(api, modal) {
        this.api = api;
        this.modal = modal;
        this.environment = environment;
        this.appointments = [];
    }
    CounselorsDashboardComponent.prototype.ngOnInit = function () {
        document.getElementById('zmmtg-root').style.display = 'none';
        document.getElementById('header').style.zIndex = "1060";
        this.offset = new Date().getTimezoneOffset();
        this.getMyLastAppointmentsToCome();
        this.getMyProfile();
    };
    CounselorsDashboardComponent.prototype.processDate = function (dt) {
        return new Date(new Date(dt).getTime() - (this.offset * 60000));
    };
    CounselorsDashboardComponent.prototype.getMyLastAppointmentsToCome = function () {
        var _this = this;
        this.api.get("auth/counselor/appointments/last").subscribe(function (data) {
            _this.appointments = data;
            _this.appointments.forEach(function (item, key) {
                var explD = item.due_date.split(" ")[0].split("-");
                var explT = item.due_date.split(" ")[1].split(":");
                var dueDate = new Date(new Date(explD[0], explD[1] - 1, explD[2], explT[0], explT[1], explT[2]).getTime() - (_this.offset * 60000));
                // this.appointments[key].due_date = new Date(new Date(item.due_date).getTime() - (this.offset * 60000))
                _this.appointments[key].due_date = dueDate;
            });
        });
    };
    CounselorsDashboardComponent.prototype.getMyProfile = function () {
        var _this = this;
        this.api.get("myCounselorProfil").subscribe(function (data) {
            _this.profile = data;
        });
    };
    CounselorsDashboardComponent.prototype.showAppointmentMessage = function (comment) {
        this.appointmentMessage = comment;
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    CounselorsDashboardComponent.prototype.cancelAppointment = function () {
    };
    return CounselorsDashboardComponent;
}());
export { CounselorsDashboardComponent };
