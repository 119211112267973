import { CONFIGURATION } from '../../configurations/configurations';
import { Observable } from 'rxjs/Rx';
import { of } from 'rxjs/observable/of';
import { FlashMessagesService } from 'angular2-flash-messages';

export class GenericProvider {

  protected api_url: string = CONFIGURATION.url_api;

  constructor(public _flashMessagesService: FlashMessagesService) {
  }

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      if (error.error.message !== undefined) {
        this.log(`${error.error.message}`);
      } else {
        this.log(`${error.error}`);
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  protected log(message: string) {
    this._flashMessagesService.show(message, { cssClass: 'alert-danger', timeout: 9000 });

  }
}
