var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { FlashMessagesService } from 'angular2-flash-messages';
import { GenericProvider } from '../genericprovider.provider';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular2-flash-messages/module/flash-messages.service";
var NewsletterService = /** @class */ (function (_super) {
    __extends(NewsletterService, _super);
    function NewsletterService(http, _flashMessagesService) {
        var _this = _super.call(this, _flashMessagesService) || this;
        _this.http = http;
        _this._flashMessagesService = _flashMessagesService;
        return _this;
    }
    NewsletterService.prototype.subscribe = function (email) {
        return this.http.post(this.api_url + '/newsletter', { email: email }).pipe(catchError(this.handleError('NewsletterService : subscribe(' + email + ')', '')));
    };
    NewsletterService.ngInjectableDef = i0.defineInjectable({ factory: function NewsletterService_Factory() { return new NewsletterService(i0.inject(i1.HttpClient), i0.inject(i2.FlashMessagesService)); }, token: NewsletterService, providedIn: "root" });
    return NewsletterService;
}(GenericProvider));
export { NewsletterService };
