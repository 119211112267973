/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verify.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./verify.component";
import * as i3 from "@angular/router";
import * as i4 from "angular2-flash-messages/module/flash-messages.service";
import * as i5 from "../../../services/api/api.service";
var styles_VerifyComponent = [i0.styles];
var RenderType_VerifyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerifyComponent, data: {} });
export { RenderType_VerifyComponent as RenderType_VerifyComponent };
export function View_VerifyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" verify works!\n"]))], null, null); }
export function View_VerifyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-verify", [], null, null, null, View_VerifyComponent_0, RenderType_VerifyComponent)), i1.ɵdid(1, 114688, null, 0, i2.VerifyComponent, [i3.ActivatedRoute, i3.Router, i4.FlashMessagesService, i5.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerifyComponentNgFactory = i1.ɵccf("app-verify", i2.VerifyComponent, View_VerifyComponent_Host_0, {}, {}, []);
export { VerifyComponentNgFactory as VerifyComponentNgFactory };
