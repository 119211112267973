<app-how-it-works [showText]="true"></app-how-it-works>

<section id="offers-section" class="contentWrapper">
  <div class="container">
    <h1 class="session-title">Nos offres<div></div>
    </h1>
    <div class="row">
      <p class="dark-color center">
        Quelle que soit votre situation familiale,  Familipsy met 20 ans d’expérience et son expertise à votre service pour vous accompagner : couple, communication, éducation …
      </p>
    </div>
    <div class="row">
      <p class="dark-color center">Que vous souhaitiez un soutien ponctuel ou un accompagnement plus long, un module
        spécifique ou une formation plus longue; que vous souhaitiez découvrir Familipsy ou profiter de nos services
        premium, il y a une formule qui vous convient.
        En vous inscrivant, vous bénéficierez de nos formations à la carte, nos conseils professionnels ou
        psychothérapies en ligne que vous pourrez gérer aisément à partir de votre tableau de bord.
      </p>
    </div>
    <div class="row offers-container">
      <div class="flex dark-color">
        <div *ngFor="let item of offers" class="offer bg-white">
          <h2 class="center bold">{{ item.title }}</h2>
          <p class="price bold center m-0">{{item.monthly_price}} €<span>/mois</span></p>
          <div class="from"><span *ngIf="item.monthly_price != 0">ou {{item.annualy_price}} €/an</span></div>
          <div [innerHtml]="item.bullet_points"></div>
          <a href="/offre/{{item.id}}" class="center dark-color bold">En savoir plus</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="video-section">
  <div class="">
    <div class="container">
      <div class="frame">
        <iframe class="video" [src]='video' frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</section>

<app-content [slug]="'engagements-section'"></app-content>
<!-- <app-engagements></app-engagements> -->