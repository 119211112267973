import { Injectable } from '@angular/core';
import { GenericProvider } from '../genericprovider.provider';
import { HttpClient } from '@angular/common/http';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { Session } from '../../../model/session.model';
import { Observable } from 'rxjs/index';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionService  extends GenericProvider {

  constructor(
    public http: HttpClient,
    public _flashMessagesService: FlashMessagesService,
    private router: Router
    ) {
       super(_flashMessagesService);
  }

  getSessionsHistory(): Observable<Session[]> {
    return this.http.get<Session[]>(this.api_url + '/consultations/previous').pipe(
      catchError(
        this.handleError('SessionService : getSessionsHistory()', [] )
      )
    );
  }

  getNextSessions(): Observable<Session[]> {
    return this.http.get<Session[]>(this.api_url + '/consultations/coming-up').pipe(
      catchError(
        this.handleError('SessionService : getNextSessions()', [])
      )
    );
  }

  updateSessionStatus(id: Number, status: String): Observable<Session> {
    return this.http.put<Session>(this.api_url + '', status).pipe(
      catchError(
        this.handleError('SessionService : updateSessionStatus(' + id + ',' + status + ')', new Session())
      )
    );
  }
}
