import { ElementRef } from '@angular/core';
var LazyImgDirective = /** @class */ (function () {
    function LazyImgDirective(_a) {
        var nativeElement = _a.nativeElement;
        var supports = 'loading' in HTMLImageElement.prototype;
        if (supports) {
            nativeElement.setAttribute('loading', 'lazy');
        }
    }
    return LazyImgDirective;
}());
export { LazyImgDirective };
