import { environment } from './../../../../environments/environment';
import { AuthService } from './../../../services/auth/auth.service';
import { OnInit } from '@angular/core';
var MemberOnlyComponent = /** @class */ (function () {
    function MemberOnlyComponent(auth) {
        this.auth = auth;
        this.environment = environment;
        this.closeAction = "";
        this.upgrade = false;
    }
    MemberOnlyComponent.prototype.ngOnInit = function () {
    };
    return MemberOnlyComponent;
}());
export { MemberOnlyComponent };
