import { Injectable } from '@angular/core';
import { GenericProvider } from '../genericprovider.provider';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { catchError, tap } from 'rxjs/operators';
import { Conseiller } from '../../../model/conseiller.model';
import { Diplome } from '../../../model/diplome.model';
import { Slot } from '../../../model/slot.model';
import { Particulier } from '../../../model/particulier.model';

@Injectable()
export class ProfileService extends GenericProvider {

  constructor(
    public http: HttpClient,
    public cookieService: CookieService,
    public _flashMessagesService: FlashMessagesService,
    private router: Router
  ) {
    super(_flashMessagesService);
  }

  loadProfileInformations(advisors: Boolean): Observable<any> {
    if (advisors) {
      return this.http.get<any>(this.api_url + '/advisors/authenticated').pipe(
        catchError(this.handleError('ProfileProvider : loadProfileInformations(' + advisors + ')', new Conseiller()))
      );
    } else {
      return this.http.get<any>(this.api_url + '/customers/authenticated').pipe(
        catchError(this.handleError('ProfileProvider : loadProfileInformations(' + advisors + ')'))
      );
    }
  }

  uploadProfilePic(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.put<any>(this.api_url + '/advisors/profile-pic', formData).pipe(
      catchError(this.handleError('ProfileProvider : uploadProfilePic(' + file + ')', ''))
    )
  }

  updateAdivsorProfileInformation(profile: Conseiller): Observable<Conseiller> {
    return this.http.put<Conseiller>(this.api_url + '/advisors', profile).pipe(
      catchError(this.handleError('ProfileProvider : updateAdivsorProfileInformation(' + profile + ')', new Conseiller()))
    );
  }

  updateCustomerProfileInformation(profile: Particulier): Observable<Particulier> {
    return this.http.put<Particulier>(this.api_url + '/customers', profile).pipe(
      catchError(this.handleError('ProfileProvider : updateCustomerProfileInformation(' + profile + ')', new Particulier()))
    );
  }

  createDiploma(diplome: Diplome, file: any): Observable<Diplome> {
    let formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('diplomaJson', JSON.stringify(diplome));
    return this.http.post<Diplome>(this.api_url + '/advisors/diplomas', formData).pipe(
      catchError(this.handleError('ProfileProvider : createDiploma(' + formData + ')', new Diplome()))
    );
  }

  updateDiploma(diplome: Diplome, file: any): Observable<Diplome> {

    if (file != undefined) {
      let formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('diplomaJson', JSON.stringify(diplome));
      return this.http.post<Diplome>(this.api_url + '/advisors/diplomas/with-file', formData).pipe(
        catchError(this.handleError('FormationService : updateFormation(' + formData + ')', new Diplome()))
      );
    }
    else {
      return this.http.put<Diplome>(this.api_url + '/advisors/diplomas', diplome).pipe(
        catchError(this.handleError('FormationService : updateFormation(' + diplome + ')', new Diplome()))
      );
    }
  }

  getSlots(): Observable<Slot[]> {
    return this.http.get<Slot[]>(this.api_url + '/advisors/slots').pipe(
      catchError(this.handleError('ProfileProvider : getSlots()', []))
    );
  }

  addSlot(slot: Slot): Observable<Slot> {
    return this.http.post<Slot>(this.api_url + '/advisors/slots', slot).pipe(
      catchError(this.handleError('ProfileProvider : addSlot(' + slot + ')', new Slot()))
    );
  }

  deleteSlot(id: Number): Observable<Object> {
    return this.http.delete(this.api_url + '/advisors/slots/' + id).pipe(
      catchError(this.handleError('ProfileProvider : deleteSlot(' + id + ')'))
    );
  }

  deleteProfile(): Observable<Object> {
    return this.http.delete(this.api_url + '/advisors').pipe(
      catchError(this.handleError('ProfileProvider : deleteSlot()'))
    );
  }

}
