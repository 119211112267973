/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quote.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./quote.component";
import * as i3 from "../../../services/api/api.service";
var styles_QuoteComponent = [i0.styles];
var RenderType_QuoteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuoteComponent, data: {} });
export { RenderType_QuoteComponent as RenderType_QuoteComponent };
export function View_QuoteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", ""], ["id", "quote-section"]], [[4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "layer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h1", [["class", "session-title uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Citation"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "p", [["class", "dark-color center white-color"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "p", [["class", "dark-color center white-color"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((("url(" + _co.environment.uploadPath) + "quotes/") + ((_co.quote == null) ? null : _co.quote.image)) + ")"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.quote.content; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.quote.author; _ck(_v, 9, 0, currVal_2); }); }
export function View_QuoteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-quote", [], null, null, null, View_QuoteComponent_0, RenderType_QuoteComponent)), i1.ɵdid(1, 114688, null, 0, i2.QuoteComponent, [i3.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuoteComponentNgFactory = i1.ɵccf("app-quote", i2.QuoteComponent, View_QuoteComponent_Host_0, {}, {}, []);
export { QuoteComponentNgFactory as QuoteComponentNgFactory };
