<app-home-slider [slides]="slides" [zone]="'counselor'"></app-home-slider>
<section class=" engagements-section">
    <div class="container">
        <h1 class="session-title">Pourquoi des psychologues et des conseillers rejoignent Familipsy<div></div></h1>
        <div class="flex">

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Horaires flexibles</h2>
                <p class="dark-color center">Offrez la même qualité de service quand vous le voulez, au rythme que vous
                    souhaitez. Pour mieux concilier vie familiale et professionnelle</p>
            </div>

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Gagnez un revenu supplémentaire</h2>
                <p class="dark-color center">Sans charges liées à un cabinet et en fonction de votre charge de travail
                    et des demandes des personnes.</p>
            </div>

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Formation continue</h2>
                <p class="dark-color center">Béneficiez d'une supervision et de formations continues pour accroitre vos
                    compétences</p>
            </div>

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Visibilité accrue</h2>
                <p class="dark-color center">Étendez votre notoriété à la francophonie et faites-vous connaitre auprès
                    de milliers d'internautes en recherche d'aide</p>
            </div>

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Pas de contraintes administratives</h2>
                <p class="dark-color center">À l'inverse d'un cabinet privé, ici, pas de complications et frais
                    administratifs, d'assurances ou de charges fixes pour un local. La dépense est minime et maitrisée
                </p>
            </div>

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Communauté active</h2>
                <p class="dark-color center">Rejoignez un pôle de professionnels réunis pour leurs valeurs communes et
                    leurs expertises</p>
            </div>

        </div>
    </div>
</section>

<section class=" engagements-section">
    <div class="container">
        <h3 class="session-title">Rejoignez notre plateforme à la pointe de l'innovation<div></div></h3>
        <div class="flex">

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Facile à utiliser</h2>
                <p class="dark-color center">Un tableau de bord vous permettra de gérer simplement vos demandes, vos
                    suivis, vos informations personnelles et tous les accès dont vous avez besoin</p>
            </div>

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Sécurisé</h2>
                <p class="dark-color center">Les sessions sont confidentielles et vous êtes le seul a pouvoir y acceder
                </p>
            </div>

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Éthique</h2>
                <p class="dark-color center">Nous respectons les droits des personnes, le Code de déontologie des
                    psychologues et suivons des règles éthiques pour assurer la qualité du service et le bon déroulement
                    des séances</p>
            </div>

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Multimédia</h2>
                <p class="dark-color center">Pour plus de confort et d’adaptation aux besoins des personnes, nous proposons des modalités d’échanges textes, audios et vidéos; accessibles de n’importe quel écran connecté</p>
            </div>

            <div class="engagement">
                <div class="picto"></div>
                <h2 class="dark-color bold center">Support technique</h2>
                <p class="dark-color center">Vous rencontrez un problème technique ? Vous avez une question spécifique ?
                    Un support technique vous répond et vous guide.</p>
            </div>

        </div>
    </div>
</section>

<app-how-it-works-advisor></app-how-it-works-advisor>
<section class="video-section">
    <div class="">
        <div class="container">
            <div class="frame">
                <iframe class="video" [src]='video' frameborder="0" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</section>

<section class=" engagements-section advisors_advices">
    <div class="container">
        <h3 class="session-title">Ce que les conseillers en disent<div></div></h3>
        
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4" *ngFor="let item of testimonies">
                <div class="card text-center shadow rounded">
                    <img src="{{ environment.uploadPath + 'users/' + item?.image }}" class="card-img-top" alt="{{ item?.name }}" />
                    <div class="card-body">
                        <h5 class="card-title">{{ item?.name }}</h5>
                        <p class="card-text" style="min-height: 120px;">{{ item?.content }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class=" engagements-section">
    <div class="container">
        <h3 class="session-title">La collaboration avec Familipsy<div></div></h3>
        <div class="container">
            <div class="row black-color">
                <div class="col-md-6">
                    <h6 class="bold">Les exigences</h6>
                    <ul class="requirements">
                        <li>
                            <p>Être diplômé dans leur specialité. Les psychologues doivent avoir obtenu le titre
                                officiel à l'issue de leur formation universitaire (N° ADELI pour la métropole).</p>
                        </li>
                        <li>
                            <p>Être en règle par rapport aux obligations professionnelles et administratives dans leur
                                pays pour pouvoir exercer de façon indépendante.</p>
                        </li>
                        <li>
                            <p>Être spécialisés dans le domaine de la famille ou dans un domaine connexe (psychologie du
                                développement, psychologie sociale et systémique, conseil conjugal, sexologie, mediation
                                familiale...)</p>
                        </li>
                        <li>
                            <p>Avoir un minimum de 3 années d'expérience professionnelle.</p>
                        </li>
                        <li>
                            <p>Avoir souscrit à une police d'assurance de responsabilité civile professionnelle.</p>
                        </li>
                        <li>
                            <p>Avoir un justificatif attestant de l'expérience et de l'expertise professionnelle (copie de
                                diplôme, attestation...).</p>
                        </li>
                        <li>
                            <p>Être en accord avec les valeurs portées par Familipsy</p>
                        </li>
                    </ul>
                </div>
                <div class="col-md-6">
                    <h6 class="bold">Le processuss de candidature</h6>
                    <p><span class="bold">Répondez en 5-10 minutes</span> au formulaire bref de demande qui permettra de
                        vérifier si vous remplissez les conditions préalables pour utiliser la plateforme Familipsy</p>
                    <p><span class="bold">Un entretien plus approfondi </span>(20-30 minutes) permettra d'affiner les
                        modalités, conditions pour valider votre inscription.</p>
                    <div class="button-section postuler-button">
                        <a routerLink="/inscription/conseiller" class="main-button-round">Postuler maintenant</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class=" questions-section">
    <div class="container">
        <h3 class="session-title">Vous avez des questions<div></div></h3>
        <div class="container">
            <p class="center">N'hésitez pas à consulter notre FAQ ou à nous contacter à contact@familipsy.com</p>
        </div>
    </div>
</section>