import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { HomeSliderComponent } from './shared/components/slider/home-slider.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuoteComponent } from './components/home/quote/quote.component';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { ConferencesComponent } from './components/conferences/conferences.component';
import { OffersComponent } from './components/offers/offers.component';
import { EngagementsComponent } from './shared/components/engagements/engagements.component';
import { SignupComponent } from './components/signup/signup.component';
import { SigninComponent } from './components/signin/signin.component';
import { TrainingDetailsComponent } from './components/training-details/training-details.component';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { AuthenticationService } from './shared/providers/authentication/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { HttpsRequestInterceptor } from './interceptor/interceptor.module';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { LocationStrategy, HashLocationStrategy, CommonModule, PathLocationStrategy } from '@angular/common';
import { ProfileComponent } from './components/profile/profile.component';
import { AsideConsComponent } from './components/profile/conseiller/aside-cons/aside-cons.component';
import { ProfileConsComponent } from './components/profile/conseiller/profile-cons/profile-cons.component';
import { InfosConsComponent } from './components/profile/conseiller/profile-cons/includes/infos-cons/infos-cons.component';
import { DiplomeComponent } from './components/profile/conseiller/profile-cons/includes/infos-cons/mes-diplomes/diplome/diplome.component';
import { MonProfilComponent } from './components/profile/conseiller/profile-cons/includes/infos-cons/mon-profil/mon-profil.component';
import { MaSituationComponent } from './components/profile/conseiller/profile-cons/includes/infos-cons/ma-situation/ma-situation.component';
import { CheckboxGroupComponent } from './shared/components/checkbox-group/checkbox-group.component';
import { CheckboxComponent } from './shared/components/checkbox/checkbox.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule, MatSelectModule, MatFormFieldModule } from '@angular/material';
import { BecomeConsulterComponent } from './components/become-consulter/become-consulter.component';
import { ProfileService } from './shared/providers/profile/profile.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { CalendarComponent } from './components/profile/conseiller/profile-cons/includes/calendar/calendar.component';
// tslint:disable-next-line
import { ModalCalendarComponent } from './components/profile/conseiller/profile-cons/includes/calendar/templates/modal-calendar/modal-calendar.component';
// tslint:disable-next-line
import { MonthCellComponent } from './components/profile/conseiller/profile-cons/includes/calendar/templates/month-cell/month-cell.component';
// tslint:disable-next-line
import { CallDayOpenComponent } from './components/profile/conseiller/profile-cons/includes/calendar/templates/call-day-open/call-day-open.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AsidePartComponent } from './components/profile/particulier/aside-part/aside-part.component';
import { ProfilePartComponent } from './components/profile/particulier/profile-part/profile-part.component';
import { SessionService } from './shared/providers/session/session.service';
import { ConsultationComponent } from './components/profile/conseiller/profile-cons/includes/consultation/consultation.component';
import { InfosPartComponent } from './components/profile/particulier/profile-part/includes/infos-part/infos-part.component';
// tslint:disable-next-line
import { MaSituationPartComponent } from './components/profile/particulier/profile-part/includes/infos-part/ma-situation/ma-situation-part.component';
// tslint:disable-next-line
import { MonProfilPartComponent } from './components/profile/particulier/profile-part/includes/infos-part/mon-profil/mon-profil-part.component';
import { ReserverConsultationComponent } from './components/profile/particulier/profile-part/includes/reserver-consultation/reserver-consultation.component';
import { AdvisorDetailsComponent } from './components/advisor-details/advisor-details.component';
import { ReservationCalendarComponent } from './components/advisor-details/reservation-calendar/reservation-calendar.component';
import { ReservationModalCalendarComponent } from './components/advisor-details/reservation-calendar/templates/reservation-modal-calendar/reservation-modal-calendar.component';
import { MesConsultationsPartComponent } from './components/profile/particulier/profile-part/includes/mes-consultations-part/mes-consultations-part.component';
import { OfferDetailsComponent } from './components/offer-details/offer-details.component';
import { CardPaymentComponent } from './shared/components/card-payment/card-payment.component';
import { FAQComponent } from './components/faq/faq.component';
import { MesDiplomesComponent } from './components/profile/conseiller/profile-cons/includes/infos-cons/mes-diplomes/mes-diplomes.component';
import { RessourcesComponent } from './components/profile/particulier/profile-part/includes/ressources/ressources.component';
import { NavbarModule, WavesModule } from 'angular-bootstrap-md'
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { SubscribePremiumComponent } from './components/subscribe-premium/subscribe-premium.component';


import { DeviceDetectorModule } from 'ngx-device-detector';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { GuideComponent } from './components/guide/guide.component';
import { ConfidentialityComponent } from './components/confidentiality/confidentiality.component';
import { HowItWorksAdvisorComponent } from './components/how-it-works-advisor/how-it-works-advisor.component';
import { ContactComponent } from './components/contact/contact.component';
import { MentionsLegalesComponent } from './components/mentions-legales/mentions-legales.component';
import { RessourceDetailComponent } from './components/ressources/ressource-detail/ressource-detail.component';

import {CalendarNativeDateFormatter, DateFormatterParams, CalendarDateFormatter } from 'angular-calendar';
import { ModalComponent } from './shared/components/modal/modal.component';

import { RessourceListComponent } from './components/ressources/ressource-list/ressource-list.component';
import { VerifyComponent } from './components/signup/verify/verify.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { IndividualComponent } from './components/my-profile/individual/individual.component';
import { VerifySentComponent } from './components/signup/verify-sent/verify-sent/verify-sent.component'; // Add

import { NgxStripeModule } from 'ngx-stripe';
import { PageComponent } from './components/pages/page/page.component';
import { ConferencesDetailComponent } from './components/conferences/conferences-detail/conferences-detail.component';
import { ContentComponent } from './shared/components/content/content.component';
import { CounselorsListComponent } from './components/counselors/counselors-list/counselors-list.component';
import { CounselorsDetailComponent } from './components/counselors/counselors-detail/counselors-detail.component';
import { SignupCounselorComponent } from './components/signup/signup-counselor/signup-counselor.component';
import { CounselorsModule } from './spaces/counselors/counselors.module';
import { MemberOnlyComponent } from './shared/components/member-only/member-only.component';
import { BookComponent } from './components/counselors/counselors-detail/book/book.component';
import { PaymentComponent } from './shared/components/payment/payment.component';
import { SignupFreeComponent } from './components/signup/signup-free/signup-free.component';
import { DatePipe } from '@angular/common';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { LazyImgDirective } from './directives/lazy-img.directive';
import { ChangePasswordComponent } from './components/signin/change-password/change-password.component';
import { SearchComponent } from './components/search/search.component';

export class CustomDateFormatter extends CalendarNativeDateFormatter {

  
  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale).transform(date, 'HH:mm', locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }

}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    HomeSliderComponent,
    QuoteComponent,
    TrainingsComponent,
    ConferencesComponent,
    OffersComponent,
    EngagementsComponent,
    SignupComponent,
    SigninComponent,
    TrainingDetailsComponent,
    HowItWorksComponent,
    ProfileComponent,
    AsideConsComponent,
    ProfileConsComponent,
    InfosConsComponent,
    DiplomeComponent,
    MonProfilComponent,
    MaSituationComponent,
    CheckboxGroupComponent,
    CheckboxComponent,
    BecomeConsulterComponent,
    CalendarComponent,
    ModalCalendarComponent,
    MonthCellComponent,
    CallDayOpenComponent,
    AsidePartComponent,
    ProfilePartComponent,
    ConsultationComponent,
    InfosPartComponent,
    MaSituationPartComponent,
    MonProfilPartComponent,
    ReserverConsultationComponent,
    AdvisorDetailsComponent,
    ReservationCalendarComponent,
    ReservationModalCalendarComponent,
    MesConsultationsPartComponent,
    OfferDetailsComponent,
    CardPaymentComponent,
    FAQComponent,
    MesDiplomesComponent,
    RessourcesComponent,
    SubscribePremiumComponent,
    NewsletterComponent,
    GuideComponent,
    ConfidentialityComponent,
    HowItWorksAdvisorComponent,
    ContactComponent,
    MentionsLegalesComponent,
    RessourceDetailComponent,
    ModalComponent,
    RessourceListComponent,
    VerifyComponent,
    PageNotFoundComponent,
    IndividualComponent,
    VerifySentComponent,
    PageComponent,
    ConferencesDetailComponent,
    ContentComponent,
    CounselorsListComponent,
    CounselorsDetailComponent,
    SignupCounselorComponent,
    MemberOnlyComponent,
    BookComponent,
    PaymentComponent,
    SignupFreeComponent,
    FeedbackComponent,
    LazyImgDirective,
    ChangePasswordComponent,
    SearchComponent,
  ],
  imports: [
    CounselorsModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlashMessagesModule.forRoot(),
    NgbModule.forRoot(),
    HttpClientModule,
    NgHttpLoaderModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    NavbarModule,
    WavesModule,
    JwSocialButtonsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgxMaterialTimepickerModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    // NgxStripeModule.forRoot('pk_test_v1PxcIo6nku9CDJ26ynIyH7D00tQYDQJQf'),
    NgxStripeModule.forRoot('pk_live_nyxOEmPsHEF6cVXL2GuTIPnO00NWAF48qJ'),
    ImageCropperModule
  ],
  providers: [
    AuthenticationService,
    ProfileService,
    SessionService,
    CookieService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: "fr-FR" },
    {provide: CalendarDateFormatter, useClass: CustomDateFormatter}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
