<section id="contact-section" class="">
  <div class="container">
    <h1 class="session-title">Contactez-nous<div></div></h1>
    <div class="row">
      <div class="col-md-12">
        <form class="custom-form p-4 bg-white rounded shadow" [formGroup]="contactForm"
          (ngSubmit)="submitForm()">
          
          
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="lastname" class="bold dark-color">Votre nom</label>
                <input type="text" class="form-control dark-color" style="background: #eef0f8;" placeholder="Votre nom" formControlName="lastname">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="firstname" class="bold dark-color">Votre prénom</label>
                <input type="text" class="form-control dark-color" style="background: #eef0f8;" placeholder="Votre prénom" formControlName="firstname">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="email" class="bold dark-color">Votre adresse email</label>
                <input type="text" class="form-control dark-color" style="background: #eef0f8;" placeholder="Votre adresse email" formControlName="email">
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="phone" class="bold dark-color">Votre numéro de téléphone</label>
                <input type="text" class="form-control dark-color" style="background: #eef0f8;" placeholder="Votre numéro de téléphone" formControlName="phone">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="exampleFormControlSelect1">Sujet</label>
                <select class="form-control" placeholder="Sujet" formControlName="subject">
                  <option *ngFor='let item of subjects' [value]="item.title">{{ item.title }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="exampleFormControlTextarea1">Votre message</label>
                <textarea class="form-control" rows="8" cols="40" placeholder="Votre message" formControlName="message"></textarea>
              </div>
            </div>
          </div>
          <div class="button-section">
            <button class="main-button-round" type="submit" *ngIf="!inSubmit">Envoyer mon message</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>