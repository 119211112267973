import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FlashMessagesService } from 'angular2-flash-messages';
import { GenericProvider } from '../genericprovider.provider';
import { Observable } from 'rxjs/Rx';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService extends GenericProvider {

  constructor(
    public http: HttpClient,
    public _flashMessagesService: FlashMessagesService,
  ) {
    super(_flashMessagesService);
  }

  subscribe(email: string): Observable<any> {
    return this.http.post<any>(this.api_url + '/newsletter', {email : email}).pipe(
      catchError(this.handleError('NewsletterService : subscribe(' + email + ')', ''))
    )
  }

}
