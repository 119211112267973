import { Injectable } from '@angular/core';
import { GenericProvider } from '../genericprovider.provider';
import { HttpClient } from '@angular/common/http';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { Offer } from '../../../model/offer.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MembershipService extends GenericProvider {

  constructor(
    public http: HttpClient,
    public _flashMessagesService: FlashMessagesService,
    private router: Router
  ) {
    super(_flashMessagesService);
  }

  getAllMemberships() {
    return this.http.get<Offer[]>(this.api_url + '/memberships').pipe(
      catchError(
        this.handleError('SessionService : getSessionsHistory()', [])
      )
    );
  }

  getMembershipById(id: string) {
    return this.http.get<Offer>(this.api_url + '/memberships/' + id).pipe(
      catchError(
        this.handleError('SessionService : getSessionsHistory()', new Offer())
      )
    );
  }

}
