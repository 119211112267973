import { Component, OnInit } from '@angular/core';
import { Offer } from '../../model/offer.model';
import { MembershipService } from '../../shared/providers/memberships/membership.service';
import { ActivatedRoute } from '@angular/router';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { AuthenticationService } from '../../shared/providers/authentication/authentication.service';
import { User } from '../../model/user.model';
import { ApiService } from '../../services/api/api.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.css']
})
export class OfferDetailsComponent implements OnInit {

  offer: Offer;
  offers: Offer[];
  connectedUser: User = null;

  constructor(private _activatedRoute: ActivatedRoute,
    private _membershipService: MembershipService,
    private scrollTopService: ScrollTopService,
    private _authService: AuthenticationService,
    private api: ApiService,
    public auth: AuthService
  ) {

    this._activatedRoute.paramMap.subscribe(param => {
      const id = param.get('offerId');
      // this._membershipService.getMembershipById(id).subscribe(res => {
      //   this.offer = res;
      // }
      // );
      this.api.get("memberships/"+id).subscribe((res: Offer) => {
        this.offer = res;
      });
    });
  }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
    // this._membershipService.getAllMemberships().subscribe(res => {
    //   this.offers = res;
    // })

    this.api.get("list/memberships").subscribe((data: Offer[]) => {
      this.offers = data;
    })
    this.connectedUser = this._authService.getCurrentUser();
  }

}
