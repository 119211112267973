<div class="row">
  <div class="col">
    <h2>Toutes mes consultations</h2>
    <div class="card p-2">
      <div class="row">
        <div class="col-3">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01">Filtrer par </label>
            </div>
            <select #selectList class="custom-select" (change)="this.status = selectList.value; getAppointments();">
              <option selected>Statut ...</option>
              <option value="all">Toutes</option>
              <option value="2">Réservée</option>
              <option value="3">Terminée</option>
              <option value="4">Reportée</option>
              <option value="5">Rémunérée</option>
              <option value="6">Annulée</option>
            </select>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-bordered" style="min-width: 800px;">
          <thead>
            <tr>
              <td>#</td>
              <td>Patients</td>
              <td>Date</td>
              <td>Statut</td>
              <td>Prix</td>
              <td>Actions</td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let appointment of appointments?.data; let i = index">
              <td>{{ appointment?.id }}</td>
              <td>
                <img *ngIf="appointment?.individual" src="{{ environment.uploadPath + 'users/' + appointment?.individual?.image }}" class="rounded-circle float-left mr-3" width="50" alt="{{ appointment?.individual?.firstname + ' ' + appointment?.individual?.lastname }}" />
                <div class="float-left" *ngIf="appointment?.individual">
                  {{ appointment?.individual?.firstname }}<br />{{ appointment?.individual?.lastname }}
                </div>
              </td>
              <td>
                <i class="far fa-calendar-alt"></i> Date : {{ appointment?.due_date | date: 'dd/MM/yyyy':'fr' }}<br/>
                <i class="far fa-clock"></i> Heure : {{ appointment?.due_date | date: 'HH:mm':'fr' }}<br/>
              </td>
              <td>
                <span class="badge bg-secondary-light mr-2" style="width: 65px;" *ngIf="appointment?.status_appointment_id == 1"> Libre</span>
                <span class="badge bg-info-light mr-2" style="width: 65px;" *ngIf="appointment?.status_appointment_id == 2"> Réservée</span>
                <span class="badge bg-primary-light mr-2" style="width: 65px;" *ngIf="appointment?.status_appointment_id == 3"> Terminée</span>
                <span class="badge bg-warning-light mr-2" style="width: 65px;" *ngIf="appointment?.status_appointment_id == 4"> Reportée</span>
                <span class="badge bg-success-light mr-2" style="width: 65px;" *ngIf="appointment?.status_appointment_id == 5"> Rémunéré</span>
                <span class="badge bg-danger-light mr-2" style="width: 65px;" *ngIf="appointment?.status_appointment_id == 6"> Annulée</span>
              </td>
              <td>{{ appointment?.pricing }}€</td>
              <td>
                <a routerLink="/counselor/appointment/{{ appointment.id }}" class="main-button-round bg-primary uppercase mr-2 p-2" *ngIf="appointment?.status_appointment_id == 2" placement="top" ngbTooltip="Lancer la consultation"><i class="fas fa-rocket"></i></a>
                <a (click)="finishAppointment(appointment.id)" class="main-button-round bg-primary uppercase mr-2 p-2" *ngIf="appointment?.status_appointment_id == 2" placement="top" ngbTooltip="Terminer la consultation"><i class="fab fa-get-pocket"></i></a>
                <a class="main-button-round bg-secondary uppercase p-2 mr-2 p-2" *ngIf="appointment?.status_appointment_id == 2" (click)="open(content, appointment?.id, i)" placement="top" ngbTooltip="Reporter la consultation"><i class="fas fa-reply"></i></a>
                <a class="main-button-round bg-dark uppercase p-2" *ngIf="appointment?.status_appointment_id == 2" (click)="cancelAppointment(appointment?.id)" placement="top" ngbTooltip="Supprimer la consultation"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center mt-3">
        <ngb-pagination class="d-flex justify-content-center" [collectionSize]="appointments?.total" [rotate]="true" [maxSize]="10" [(page)]="currentPage" [pageSize]="appointments?.per_page" [boundaryLinks]="true" (pageChange)="getAppointments($event)"></ngb-pagination>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Demande d'un report d'une consultation</h4>
    <button type="button" #closebutton class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div>
        <p>
          Reporter la consultation du            
          <i class="far fa-calendar-alt"></i> Date : {{ appointments?.data[myIndex]?.due_date | date: 'dd/MM/yyyy':'fr' }}<br/>
          <i class="far fa-clock"></i> Heure : {{ appointments?.data[myIndex]?.due_date | date: 'HH:mm':'fr' }}<br/>
          avec {{appointments?.data[myIndex]?.individual?.lastname | uppercase}} {{appointments?.data[myIndex]?.individual?.firstname | titlecase}}
        </p>
      </div>
      <div class="form-group">
        <label for="exampleFormControlTextarea1">La raison de votre report</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" #r></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="reportAppointment(r.value)">Envoyez</button>
  </div>
</ng-template>