import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api/api.service';
import { AuthService } from '../../../services/auth/auth.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-counselors-sidebar',
  templateUrl: './counselors-sidebar.component.html',
  styleUrls: ['./counselors-sidebar.component.css']
})
export class CounselorsSidebarComponent implements OnInit {

  environment = environment;
  me: any;
  subscription: any;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  roundCropper = true;
  autoCrop = false;
  imageToUpload;

  random = Math.floor(Math.random() * 100);
  modalOpened: any;

  @ViewChild('imageModal') imageModal: TemplateRef<any>;
  @ViewChild("cropperImage", null) imageCropper: ImageCropperComponent;

  constructor(
    private auth: AuthService,
    private api: ApiService,
    private modal: NgbModal,
    private _flashMessagesService: FlashMessagesService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.me = this.auth.authenticated();
    // this.croppedImage = this.environment.uploadPath + 'users/' + this.me?.image;
  }

  submitMyAccount() {
    this.api.get('submitCounselorAccount/' + this.me.id ).subscribe(data => {
      this._flashMessagesService.show('Votre compte a bien été soumis à validation.', { cssClass: 'alert-success' });
      localStorage.setItem('activated', "3");
      this.me.activated = "3";
      localStorage.setItem('user', JSON.stringify(this.me));
    })
  }

  openModalImage() {
    this.modalOpened = this.modal.open(this.imageModal, { size: 'lg'});
  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.imageToUpload = file;
      
    }
  }
  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  updateImage(croppedImage) {
    let image = this.base64ToFile(
      this.croppedImage,
      this.imageToUpload.name,
    );
    const dataToSave = new FormData();
    dataToSave.append('image', image, image.name );
    this.api.post('user/updateImage', dataToSave).subscribe(data => {
      this.random = Math.floor(Math.random() * 100);
      this.modalOpened.close();
    })
  }
}
