import { Component, OnInit } from '@angular/core';
import { SLIDER_CONSEILLER } from '../../mocks/slider.mock';
import { AVIS_DES_CONSEILLERS } from '../../mocks/avis_des_conseillers.mock';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../services/api/api.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-become-consulter',
  templateUrl: './become-consulter.component.html',
  styleUrls: ['./become-consulter.component.css']
})
export class BecomeConsulterComponent implements OnInit {

  environment = environment;
  slides: any;

  avis = AVIS_DES_CONSEILLERS;

  video: any;
  testimonies: any;

  constructor(
    private _sanitizer: DomSanitizer,
    private scrollTopService: ScrollTopService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.video = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/7djEfAMXB84');
    this.getTestimonies();
    this.scrollTopService.setScrollTop();
  }

  getTestimonies() {
    this.api.get('testimonies/category/1/random').subscribe(data => {
      this.testimonies = data;
    })
  }
}
