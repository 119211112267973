import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { ConferencesComponent } from './components/conferences/conferences.component';
import { OffersComponent } from './components/offers/offers.component';
import { SignupComponent } from './components/signup/signup.component';
import { SigninComponent } from './components/signin/signin.component';
import { BecomeConsulterComponent } from './components/become-consulter/become-consulter.component';
import { TrainingDetailsComponent } from './components/training-details/training-details.component';
import { OfferDetailsComponent } from './components/offer-details/offer-details.component';
import { FAQComponent } from './components/faq/faq.component';
import { SubscribePremiumComponent } from './components/subscribe-premium/subscribe-premium.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { GuideComponent } from './components/guide/guide.component';
import { ConfidentialityComponent } from './components/confidentiality/confidentiality.component';
import { ContactComponent } from './components/contact/contact.component';
import { RessourceListComponent } from './components/ressources/ressource-list/ressource-list.component';
import { RessourceDetailComponent } from './components/ressources/ressource-detail/ressource-detail.component';
import { VerifyComponent } from './components/signup/verify/verify.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { AuthIndividualsGuard } from './guards/auth.individuals.guard';
import { AuthCounselorsGuard } from './guards/auth.counselors.guard';
import { PageComponent } from './components/pages/page/page.component';
import { ConferencesDetailComponent } from './components/conferences/conferences-detail/conferences-detail.component';
import { CounselorsListComponent } from './components/counselors/counselors-list/counselors-list.component';
import { CounselorsDetailComponent } from './components/counselors/counselors-detail/counselors-detail.component';
import { SignupCounselorComponent } from './components/signup/signup-counselor/signup-counselor.component';
import { SignupFreeComponent } from './components/signup/signup-free/signup-free.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { ChangePasswordComponent } from './components/signin/change-password/change-password.component';
import { SearchComponent } from './components/search/search.component';
var routes = [
    {
        path: '',
        redirectTo: 'accueil',
        pathMatch: 'full'
    },
    {
        path: 'individual',
        canActivate: [AuthIndividualsGuard],
        loadChildren: './spaces/individuals/individuals.module#IndividualsModule'
    },
    {
        path: 'counselor',
        canActivate: [AuthCounselorsGuard],
        loadChildren: './spaces/counselors/counselors.module#CounselorsModule'
    },
    {
        path: 'accueil',
        component: HomeComponent
    },
    {
        path: 'formations',
        children: [
            {
                path: '',
                component: TrainingsComponent
            },
            {
                path: ':slugCategory',
                component: TrainingsComponent
            },
            {
                path: ':slugCategory/:slugTraining',
                component: TrainingDetailsComponent
            },
        ]
    },
    {
        path: 'conferences',
        component: ConferencesComponent
    },
    {
        path: 'conferences/prochaines',
        component: ConferencesComponent
    },
    {
        path: 'conferences/finies',
        component: ConferencesComponent
    },
    {
        path: 'conferences/:slug',
        component: ConferencesDetailComponent
    },
    {
        path: 'offres',
        component: OffersComponent
    },
    {
        path: 'offre/:offerId',
        component: OfferDetailsComponent
    },
    {
        path: 'inscription/:offerType/:period',
        component: SignupComponent
    },
    {
        path: 'inscription/conseiller',
        component: SignupCounselorComponent
    },
    {
        path: 'inscription/decouverte',
        component: SignupFreeComponent
    },
    {
        path: 'connexion',
        component: SigninComponent
    },
    {
        path: 'recherche',
        component: SearchComponent
    },
    {
        path: 'devenir-conseiller',
        component: BecomeConsulterComponent
    },
    {
        path: 'conseillers',
        component: CounselorsListComponent
    },
    {
        path: 'conseillers/:slug',
        component: CounselorsDetailComponent
    },
    {
        path: 'subscribepremium/:offerType',
        component: SubscribePremiumComponent
    },
    {
        path: 'FAQ',
        component: FAQComponent
    },
    {
        path: 'newsletter',
        component: NewsletterComponent
    },
    {
        path: 'guide',
        component: GuideComponent
    },
    {
        path: 'confidentialite',
        component: ConfidentialityComponent
    },
    {
        path: 'nous-contacter',
        component: ContactComponent
    },
    {
        path: 'ressources',
        children: [
            {
                path: '',
                component: RessourceListComponent
            },
            {
                path: ':slugCategory',
                component: RessourceListComponent
            },
            {
                path: ':slugCategory/:slugRessource',
                component: RessourceDetailComponent
            },
        ]
    },
    {
        path: 'verify/sent/:email',
        component: VerifyComponent
    },
    {
        path: 'verify/:userId',
        component: VerifyComponent
    },
    {
        path: 'change-password/:userId',
        component: ChangePasswordComponent
    },
    {
        path: ":slug",
        component: PageComponent
    },
    {
        path: 'avis/:token/:appointmentId',
        component: FeedbackComponent
    },
    {
        path: "**",
        component: PageNotFoundComponent
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
