import { SeoService } from './../../services/seo/seo.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';

import { environment } from './../../../environments/environment';
import { ApiService } from '../../services/api/api.service';
import { AuthService } from '../../services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';


@Component({
  selector: 'app-training-details',
  templateUrl: './training-details.component.html',
  styleUrls: ['./training-details.component.css']
})
export class TrainingDetailsComponent implements OnInit {

  environment = environment;
  training: any;

  videoPreview: any;

  account: any;
  isFavourite: Boolean = false;
  
  tags = [];
  upgrade = false;
  authMembership: any = null;

  @ViewChild('memberOnlyModal') memberOnlyModal: TemplateRef<any>;
  
  constructor(private _sanitizer: DomSanitizer,
    private _activatedRoute: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private scrollTopService: ScrollTopService,
    private modal: NgbModal,
    private seo: SeoService,
    private _flashMessagesService: FlashMessagesService
  ) {
    this.account = this.auth.authenticated();
    this.authMembership = parseInt(this.auth.membership());
    
    this._activatedRoute.paramMap.subscribe(param => {
      const slugCategory = param.get('slugCategory');
      const slugTraining = param.get('slugTraining');
      this.api.get("trainings/getBySlug/" + slugTraining).subscribe(res => {
        this.training = res;
        this.checkIfIsFavourite();
        this.setSeo();
        this.training.problematics.forEach((item: any)=>{
          item.tags.forEach((element:any) => {
            if(this.tags.indexOf(element.name) == -1) this.tags.push(element.name);
          });
        })
        if (this.training.freeTrailer !== null) {
          this.videoPreview = this._sanitizer.bypassSecurityTrustResourceUrl(this.training.trailer);
        }
      })
    });
  }

  checkIfIsFavourite() {
    if(this.auth.authenticated()) {
      this.api.get('favourite/check/training_id/' + this.training.id).subscribe((data: Boolean)=>{
        this.isFavourite = data;
      })
    }
  }

  setSeo() {

    this.seo.set({
      'keywords': this.training.problematics.map(item=>item.name).join(","),
      'title': this.training.title,
      'description': this.training.synopsis,
      'og:title': this.training.title,
      'og:url': this.environment.siteUrl + 'formation/' + this.training.category.slug + '/' + this.training.slug,
      'og:description': this.training.synopsis,
      'og:image': this.environment.uploadPath + 'trainings/' + this.training.image,
      'twitter:title': this.training.title,
      'twitter:description': this.training.synopsis,
      
    });
  }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
  }

  addToFavourites() {
    
    if(this.auth.isAuthenticated()) {
      this.api.post("favourite/training/create",{training_id: this.training.id}).subscribe(data=>{
        this.isFavourite = true;
        this._flashMessagesService.show('La formation a bien été ajoutée à vos favoris.',
          { cssClass: 'alert-success' });
      })
    } else {
      this.modal.open(this.memberOnlyModal, { size: 'lg' });
    }
  }

}
