import { OnInit } from '@angular/core';
import { ApiService } from '../../../../../services/api/api.service';
var CounselorsZoomAccountComponent = /** @class */ (function () {
    function CounselorsZoomAccountComponent(api) {
        this.api = api;
    }
    CounselorsZoomAccountComponent.prototype.ngOnInit = function () {
    };
    CounselorsZoomAccountComponent.prototype.init = function () {
        this.getMyCounselorZoomInfos();
    };
    CounselorsZoomAccountComponent.prototype.getMyCounselorZoomInfos = function () {
        var _this = this;
        this.api.get("user/getMyCounselorZoomInfos").subscribe(function (data) {
            _this.zoom = data;
        });
    };
    return CounselorsZoomAccountComponent;
}());
export { CounselorsZoomAccountComponent };
