<section id="step-section">
  <div class="">
    <div class="container">
      <h1 class="session-title">Comment ça marche<div></div>
      </h1>
      <div class="row" *ngIf="showText">
        <p class="dark-color center">Familipsy vous accompagne jour après jour dans l’art subtil de l’éducation et vous
          aide à vivre
          une vie de famille sereine et épanouie. Accédez autant que vous le souhaitez aux conférences,
          formations et aux conseils personnalisés de nos experts.<br>
          Pour en profiter, c’est simple :</p>
      </div>
      <div class="row mb-3">
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 text-center">
          
          <img src="assets/imgs/mascotte-big.png" class="w-100" style="max-width: 300px;" />
        </div>
        <div class="flex dark-color col-12 col-sm-12 col-md-12 col-lg-8">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6">
              <article>
                <span class="step-image step1"></span>
                <h2 class="bold center">Étape 1</h2>
                <p class="center">Choisissez la formule qui vous convient le mieux</p>
              </article>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
              <article>
                <span class="step-image step2"></span>
                <h2 class="bold center">Étape 2</h2>
                <p class="center">Remplissez le formulaire de façon détaillée pour nous permettre de vous guider avec
                  précision et pertinence (note : toutes vos données sont confidentielles et sécurisées)</p>
              </article>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6">
              <article>
                <span class="step-image step3"></span>
                <h2 class="bold center">Étape 3</h2>
                <p class="center">À partir de votre tableau de bord, accédez facilement à vos contenus depuis n’importe
                  quel écran connecté. Modifiez ou annulez votre abonnement quand vous le souhaitez.</p>
              </article>
            </div>
            <div class="col-12 col-sm-12 col-md-6">
              <article>
                <span class="step-image step4"></span>
                <h2 class="bold center">Étape 4</h2>
                <p class="center">Profitez et déployez votre potentiel bonheur !</p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>