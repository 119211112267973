import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';

import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  signupForm: FormGroup;
  offerId: string;
  period: string;
  offer: any;
  title: String;
  
  step = 1;
  finalMessage: String;
  user : any;

  elements: Elements;
  card: StripeElement;
  clientSecret: any;

  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: 'fr'
  };

  constructor(
    private _flashMessagesService: FlashMessagesService,
    private router: Router,
    private scrollTopService: ScrollTopService,
    private activatedRoute: ActivatedRoute,
    private api: ApiService,
    private cd: ChangeDetectorRef,
    private stripeService: StripeService
  ) {
    this.activatedRoute.paramMap.subscribe(param => {
      this.offerId = param.get('offerType');
      this.period = param.get('period');
      this.title = this.offerId === '0' ? 'Devenir Conseiller' : 'Création de compte';
      if(this.offerId == '1') {
        this.router.navigate(['/inscription/decouverte'])
      }
      if (this.offerId !== '0') {
        this.api.get("memberships/"+this.offerId).subscribe(data => {
          this.offer = data;
        })
      }
    });

  }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
    this.signupForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      nickname: new FormControl('', Validators.required),
      newsletter: new FormControl(''),
      cgu: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmpassword: new FormControl('', [Validators.required]),
    });

  }

  get f() { return this.signupForm.controls; }

  formSubmit() {
    if(this.step == 1) this.createUser();
  }

  checkForm() {
    if (this.f.email.value === "") {
      this._flashMessagesService.show('Veulliez verifier le format de votre adresse mail', { cssClass: 'alert-danger', timeout: 4000 });
      return false;
    }
    if (this.f.password.value === "") {
      this._flashMessagesService.show('Le mot de passe doit contenir au moins 8 caractères', { cssClass: 'alert-danger', timeout: 5000 });
      return false;
    }
    if (this.f.password.value !== this.f.confirmpassword.value) {
      this._flashMessagesService.show('Les mots de passe ne sont pas identiques', { cssClass: 'alert-danger', timeout: 5000 });
      return false;
    }
    if (this.f.lastname.value === "" ||
      this.f.firstname.value === "" ||
      this.f.nickname.value === "") {
      this._flashMessagesService.show('Veuillez remplir les champs obligatoires', { cssClass: 'alert-danger', timeout: 5000 });
      return false;
    }
    if (this.f.cgu.value === "") {
      this._flashMessagesService.show("Veuillez accepter les conditions générales d'utilisation", { cssClass: 'alert-danger', timeout: 5000 });
      return false;
    }

    return true;
  }

  createUser() {
    if(this.checkForm()){
      const userToSave = new FormData();
      userToSave.append('firstname', this.f.firstname.value);
      userToSave.append('lastname', this.f.lastname.value);
      userToSave.append('nickname', this.f.nickname.value);
      userToSave.append('email', this.f.email.value);
      userToSave.append('newsletter', (this.f.newsletter.value) ? "1" : "0");
      userToSave.append('cgu', this.f.cgu.value);
      userToSave.append('password', this.f.password.value);
      userToSave.append('c_password', this.f.confirmpassword.value);
      userToSave.append('membership_id', "2");
      
      this.api.post("users/create",userToSave).subscribe(res => {
        if(res.error) this._flashMessagesService.show(res.message, { cssClass: 'alert-danger', timeout: 5000 });
        else {
          this.clientSecret = res.pm.client_secret;
          this.user = res.user;
          this.step = 2;
          this.initCard();
        }
      });

    }
  }

  initCard() {
    document.body.scrollTop = 250;
    
    setTimeout((font)=>{
        this.card = elements.create('card', {
          style: {
            base: {
              iconColor: '#666EE8',
              color: '#31325F',
              lineHeight: '40px',
              fontWeight: 300,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: "14px",
              '::placeholder': {
                color: '#CFD7E0'
              }
            }
          }
        }
        );

        this.card.mount('#card-element');
        this.makePayment();
    },3000, false);
  }

  makePayment() {
    const cardButton = document.getElementById('card-button');
    const clientSecret = cardButton.dataset.secret;

    cardButton.addEventListener('click', async (e) => {

      const { setupIntent, error } = await stripe.confirmCardSetup(
        this.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: { name: this.f.lastname.value + ' ' + this.f.firstname.value }
          }
        }
      );
      if (error) {
        // Display "error.message" to the user...
        this._flashMessagesService.show("Une erreur s'est produit lors du paiement. Veuillez réessayer!", { cssClass: 'alert-danger', timeout: 5000 });
      } else {
          // The card has been verified successfully...
        this.api.post("users/payment",{userId: this.user.id, paymentMethod: setupIntent.payment_method, offer: this.offer, duration: this.period, registration: true}).subscribe(res => {
          this.step = 3;
          this.finalMessage = res.message;
        });
      }

    });  
  }
}
