export const AVIS_DES_CONSEILLERS = [
{
    img: 'assets/imgs/beatrice.jpg',
    name: 'Béatrice Richoilley',
    //specialty: 'Spécialité',
    // rate: [0, 1, 2, 3, 4 ],
    // tslint:disable-next-line
    comment: 'C\'est avec enthousiasme que je rejoins Familipsy afin de travailler dans un nouveau cadre professionnel.(...) J\'ai pu entendre la satisfaction de nombreux patients qui ont consulté par chat, téléphone, ou visioconférence,(...) qui se sont trouvés bien plus à l\'aise, du fait de pouvoir s\'exprimer de chez eux dans un cadre familier, et qui ont apprécié le travail avec le thérapeute.'
},
{
    img: 'assets/imgs/claire_harivel.jpg',
    name: 'Claire Harivel',
    //specialty: 'Spécialité',
    // rate: [0, 1, 2, 3, 4 ],
    // tslint:disable-next-line
    comment: 'Participer au développement de nouvelles modalités d\'exercice de la profession de psychologue c\'est une richesse inestimable tant sur le plan de l\'innovation que du développement de nouvelles compétences.'
},
{
    img: 'assets/imgs/fabienne_bernis.jpg',
    name: 'Fabienne Bernis',
    //specialty: 'Spécialité',
    // rate: [0, 1, 2, 3, 4 ],
    // tslint:disable-next-line
    comment: 'L\'aventure « Familipsy » est un challenge. Cette dynamique me plaît et il me semble nécessaire d\'évoluer professionnellement avec la technologie d\'aujourd\'hui. Ces offres de consultations par tchat ou vidéo peuvent ouvrir les champs du possible à certaines personnes..'
},
{
    img: 'assets/imgs/nicolas_desforges.jpg',
    name: 'Nicolas Desforges',
    //specialty: 'Spécialité',
    // rate: [0, 1, 2, 3, 4 ],
    // tslint:disable-next-line
    comment: 'Rejoindre Familipsy est une façon pour moi de le faire (m\'ouvrir à l\'accompagnement en ligne) dans un cadre bien défini et entouré de personnes compétentes avec qui partager mon expérience.'
}
];
