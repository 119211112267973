import { OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../services/api/api.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { ZoomMtg } from '@zoomus/websdk';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from '../../../../app.component';
ZoomMtg.setZoomJSLib('https://dmogdx0jrul3u.cloudfront.net/1.8.1/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
$.i18n.reload("fr-FR");
ZoomMtg.reRender({ lang: "fr-FR" });
var ZoomComponent = /** @class */ (function () {
    function ZoomComponent(httpClient, document, api, activatedRoute, auth, modal, appComp) {
        this.httpClient = httpClient;
        this.api = api;
        this.activatedRoute = activatedRoute;
        this.auth = auth;
        this.modal = modal;
        this.appComp = appComp;
        this.environment = environment;
        // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
        this.signatureEndpoint = '';
        this.apiKey = environment.zoomApiKey;
        this.apiSecret = environment.zoomApiSecret;
        this.meetingNumber = 5928102083;
        this.role = 0;
        this.leaveUrl = environment.siteUrl;
        this.userName = 'Angular';
        this.userEmail = '';
        this.passWord = 'f7X0jq';
        this.displayMeeting = false;
        this.url = [null, null, "individual", "counselor"];
    }
    ZoomComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        this.appComp.activateChatbox = false;
        this.offset = new Date().getTimezoneOffset();
        this.activatedRoute.paramMap.subscribe(function (param) {
            var id = param.get('id');
            _this.api.get("appointment/" + id).subscribe(function (data) {
                _this.appointment = data;
                var explD = data.due_date.split(" ")[0].split("-");
                var explT = data.due_date.split(" ")[1].split(":");
                var dueDate = new Date(new Date(explD[0], explD[1] - 1, explD[2], explT[0], explT[1], explT[2]).getTime() - (_this.offset * 60000));
                _this.appointment.due_date = dueDate;
                _this.meetingNumber = _this.appointment.counselor.pmi;
                _this.passWord = _this.appointment.counselor.secret_code;
                _this.me = _this.auth.authenticated();
                _this.userName = _this.me.firstname + " " + _this.me.lastname;
                _this.userEmail = _this.me.email;
                _this.leaveUrl = environment.siteUrl + "/" + _this.url[_this.me.user_type_id] + '/dashboard';
                if (_this.me.user_type_id == 3) {
                    _this.role = 1;
                }
                _this.signature = ZoomMtg.generateSignature({
                    meetingNumber: _this.meetingNumber,
                    apiKey: _this.apiKey,
                    apiSecret: _this.apiSecret,
                    role: _this.role,
                    success: function (res) {
                    }
                });
                // setTimeout(()=>{
                //   this.startMeeting();
                // },3000)
            });
        });
    };
    ZoomComponent.prototype.startMeeting = function () {
        var _this = this;
        document.getElementById('zmmtg-root').style.display = 'block';
        document.getElementById('header').style.zIndex = "-1";
        ZoomMtg.init({
            leaveUrl: this.leaveUrl,
            isSupportAV: true,
            success: function (success) {
                _this.displayMeeting = true;
                ZoomMtg.join({
                    debug: true,
                    signature: _this.signature,
                    meetingNumber: _this.meetingNumber,
                    userName: _this.userName,
                    apiKey: _this.apiKey,
                    userEmail: _this.userEmail,
                    passWord: _this.passWord,
                    success: function (success) {
                    },
                    error: function (error) {
                    }
                });
            },
            error: function (error) {
            }
        });
    };
    ZoomComponent.prototype.showAppointmentMessage = function (comment) {
        this.appointmentMessage = comment;
        this.modal.open(this.modalContent, { size: 'lg' });
    };
    return ZoomComponent;
}());
export { ZoomComponent };
