import { OnInit } from '@angular/core';
import { AVIS_DES_CONSEILLERS } from '../../mocks/avis_des_conseillers.mock';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../services/api/api.service';
import { environment } from '../../../environments/environment';
var BecomeConsulterComponent = /** @class */ (function () {
    function BecomeConsulterComponent(_sanitizer, scrollTopService, api) {
        this._sanitizer = _sanitizer;
        this.scrollTopService = scrollTopService;
        this.api = api;
        this.environment = environment;
        this.avis = AVIS_DES_CONSEILLERS;
    }
    BecomeConsulterComponent.prototype.ngOnInit = function () {
        this.video = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/7djEfAMXB84');
        this.getTestimonies();
        this.scrollTopService.setScrollTop();
    };
    BecomeConsulterComponent.prototype.getTestimonies = function () {
        var _this = this;
        this.api.get('testimonies/category/1/random').subscribe(function (data) {
            _this.testimonies = data;
        });
    };
    return BecomeConsulterComponent;
}());
export { BecomeConsulterComponent };
