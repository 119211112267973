import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../services/api/api.service';
import { AuthService } from '../../../../services/auth/auth.service';

import { ZoomMtg } from '@zoomus/websdk';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from '../../../../app.component';
ZoomMtg.setZoomJSLib('https://dmogdx0jrul3u.cloudfront.net/1.8.1/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
declare var $;
$.i18n.reload("fr-FR");
ZoomMtg.reRender({lang: "fr-FR"});

@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.css']
})
export class ZoomComponent implements OnInit {

  environment = environment;

  // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
  signatureEndpoint = ''
  apiKey = environment.zoomApiKey;
  apiSecret = environment.zoomApiSecret;
  meetingNumber = 5928102083
  role = 0
  leaveUrl = environment.siteUrl
  userName = 'Angular'
  userEmail = ''
  passWord = 'f7X0jq'
  signature: any;
  appointment: any;
  me: any;
  displayMeeting = false;
  url = [null,null,"individual","counselor"];
  appointmentMessage: any;
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  offset: any;
  
  isSafari: any;

  constructor(
    public httpClient: HttpClient,
    @Inject(DOCUMENT) document,
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private modal: NgbModal,
    private appComp: AppComponent
  ) {
  }
  
  ngOnInit() {
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


    this.appComp.activateChatbox = false;
    this.offset = new Date().getTimezoneOffset();
    this.activatedRoute.paramMap.subscribe(param => {
    const id = param.get('id');
      this.api.get("appointment/"+id).subscribe((data:any) => {
        this.appointment = data;
        let explD = data.due_date.split(" ")[0].split("-");
        let explT = data.due_date.split(" ")[1].split(":");
        let dueDate = new Date(new Date(explD[0], explD[1]-1, explD[2], explT[0], explT[1], explT[2]).getTime() - (this.offset * 60000));
        this.appointment.due_date = dueDate;
        this.meetingNumber = this.appointment.counselor.pmi;
        this.passWord = this.appointment.counselor.secret_code;
        this.me = this.auth.authenticated();
        this.userName = this.me.firstname + " " + this.me.lastname;
        this.userEmail = this.me.email;
        this.leaveUrl = environment.siteUrl+"/"+this.url[this.me.user_type_id]+'/dashboard';
        if(this.me.user_type_id == 3){

          this.role = 1;
        } 
        
        this.signature = ZoomMtg.generateSignature({
          meetingNumber: this.meetingNumber,
          apiKey: this.apiKey,
          apiSecret: this.apiSecret,
          role: this.role,
          success: function(res){
          }
        });
        // setTimeout(()=>{
  
        //   this.startMeeting();
        // },3000)
      })
    });
  }

  startMeeting() {
    document.getElementById('zmmtg-root').style.display = 'block';
    document.getElementById('header').style.zIndex = "-1";

    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      isSupportAV: true,
      success: (success) => {
        this.displayMeeting = true;
        ZoomMtg.join({
          debug: true,
          signature: this.signature,
          meetingNumber: this.meetingNumber,
          userName: this.userName,
          apiKey: this.apiKey,
          userEmail: this.userEmail,
          passWord: this.passWord,
          success: (success) => {
          },
          error: (error) => {
          }
        })

      },
      error: (error) => {
      }
    })
  }

  showAppointmentMessage(comment) {
    this.appointmentMessage = comment;
    this.modal.open(this.modalContent, { size: 'lg' });
  }
}
