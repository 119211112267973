<div id="training-detail-section" class="contentWrapper" *ngIf="(account && training?.membership_id) || (account?.membership_id >= training?.membership_id)">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <!-- <span *ngIf="!isFavourite" class="float-left mr-3 pink-color cursor-pointer" style="font-size: 25px" (click)="addToFavourites()" placement="top" ngbTooltip="Ajouter aux favoris"><i class="far fa-heart"></i></span> -->
                <h1 class="text-left mb-0 p-0">
                    {{ training?.title }}
                </h1>
                <h5 class="category-name bold black-color mt-3 mb-3" style="font-style: italic; color: #a7a6a6 !important;">{{ training?.subtitle }}</h5>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <!-- <span class="d-inline-block"><img [src]="environment.uploadPath + 'categories/' + training?.category?.picto" width="60" /> {{ training?.category.name }}</span> -->
                        <span class="urgencyLabel">Niveau d'urgence : <i class="niveau_urgence_{{ training?.urgency }} "></i></span>
                    </div>
                    <div class="col-12 col-md-6 text-right">
                        <span class="badge badge-pill bg-success white-color d-inline-block mr-2 mt-1 float-right" *ngIf="training?.novelty">Nouveauté</span>
                        <span class="badge badge-pill dark-color-bg white-color d-inline-block mr-2 mt-1 float-right" *ngIf="training?.bestsell">Meilleure Vente</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-4 mb-2 text-justify">
                        <h2 [innerHtml]="training?.synopsis" style="font-weight: 400;"></h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-5 mb-2">
                        <div class="float-left pt-2">Créé par :</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-5 mb-2">
                        <div class="row">
                            <div *ngFor="let author of training?.authors" class="float-left mb-3 col-3">
                                <div class="float-left mr-2"><img [src]="environment.uploadPath + 'users/' + author.image" width="50" class="rounded-circle" /></div>
                                <div class="float-left"><span style="text-transform: uppercase;">{{ author.lastname }}</span><br/>{{ author.firstname }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-5 mb-2">
                        <h4 class="bold black-color">Compétences visées</h4>
                        <div class="text-justify" [innerHtml]="training?.skills"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-5 mb-2">
                        <h4 class="bold black-color">Objectif général</h4>
                        <div class="text-justify" [innerHtml]="training?.target"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-5 mb-2">
                        <h4 class="bold black-color">Description</h4>
                        <div class="text-justify" [innerHtml]="training?.description"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col mt-5 mb-2">
                        <h4 class="bold black-color">Programme <span class="duration">Durée : {{ training?.duration }}</span></h4>
                        <div class="text-justify" [innerHtml]="training?.program"></div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="mt-5 mb-3">Sujets traités</h5>
                    <div class="">
                      <span class="badge badge-secondary mr-2" *ngFor="let item of training?.problematics">{{ item?.user_equivalent }}</span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col-md-4 position-relative">
                <img src="{{environment.uploadPath + 'trainings/' + training?.image }}" style="width: 100%" class="mb-5" />
                <span *ngIf="!isFavourite" class="main-button-round w-100 text-center cursor-pointer" style="max-width: none;" (click)="addToFavourites()" placement="top" ngbTooltip="Ajouter aux favoris"><i class="far fa-heart"></i> Ajouter aux favoris</span>
                <div class="cards-section">
    
                    <article class="custom-card container">
                        <div class="frame row" *ngIf="videoPreview != undefined">
                            <iframe class="video" [src]='videoPreview' frameborder="0" allowfullscreen></iframe>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="bold black-color">Tarif : {{ training?.price }} €</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <a href="{{ training?.link }}" target="_blank" class="main-button-round inscription">Je m'inscris</a>
                            </div>
                            <div class="col-md-12">
                                <p class="center width-100">Garantie satisfait ou remboursé</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h6 class="bold black-color">La formation comprend</h6>
                                <div class="bullets" [innerHtml]="training?.bullet_points"></div>
                            </div>
                        </div>
                        <hr />
                        <div class="row socials">
                            <ul class="flex space-around">
                                <fb-like></fb-like>
                                <!-- <li class="facebook"><a href="#"></a></li> -->
                            </ul>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    
        <div class="row mt-5">
            <div class="col">
                <a href="{{ training?.link }}" target="_blank" class="main-button-round inscription">Je m'inscris</a>
            </div>
        </div>
        <h3 class="mt-5 mb-5">Formations similaires</h3>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-4" *ngFor="let item of training?.similars | slice:0:4; let i=index">
                <article class="conferenceCustomCard custom-card position-relative">
                  <div class="custom-card-image position-relative pt-3 pl-3 pr-3">
                    <div class="authors position-absolute" style="bottom: 0px !important">
                        <div class="author float-left position-relative" *ngFor="let author of item?.authors">
                            <img src="{{ environment.uploadPath + 'users/' + author?.image }}" class="rounded-circle" width="60" alt="{{ author?.firstname + ' ' + author?.lastname }}" />
                        </div>
                    </div>
                    <a routerLink="/formations/{{item?.category?.slug}}/{{ item?.slug }}" class="d-block">
                      <img src="{{ environment.uploadPath + 'trainings/' + item?.image }}" alt="{{item?.title}}" class="w-100" />
                    </a>
                  </div>
                  <div class="custom-card-content">
                      <div class="topContent">
                          <h4 class="title dark-color" style="height: 50px;"><a class="dark-color" routerLink="/formations/{{item?.category?.slug}}/{{item?.slug}}">{{item?.title}}</a></h4>
                          <h6 class="subtitle dark-color" style="font-weight: 400"><a class="dark-color" routerLink="/formations/{{item?.category?.slug}}/{{item?.slug}}">{{item?.subtitle}}</a></h6>
                          <span *ngIf="item?.novelty" class="novelty bg-success text-white badge">Nouveauté</span>
                          <span *ngIf="item?.bestsell" class="novelty dark-color-bg ml-2 text-white badge">Meilleure vente</span>
                      </div>
                      <div class="w-100 float-left mt-4 mb-4 text-justify">
                        <span class="dark-color bold uppercase p-3 float-right mb-4">
                          {{item.price == 0 ? "gratuit" : item.price + " €"}}
                        </span>
                      </div>
                  </div>
                  <div *ngIf="account?.membership_id < item?.membership_id" class="unautorized-content">
                    <div class="explic">
                      Votre abonnement n'est pas suffisant pour voir cette formation<br/>
                      <a href="/offres" class="main-button-round uppercase bold">Voir Nos Offres</a>
                    </div>
                    <div class="lock">
                      <i class="material-icons">
                        lock
                        </i><br/>
                      Accès abonnés
                    </div>
                  </div>
                </article>
              </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="mt-5 mb-3">Tags</h5>
            <div class="">
              <span class="" *ngFor="let item of tags">#{{ item + " " }}</span>
            </div>
          </div>
        </div>
    
    </div>

</div>

<ng-template #memberOnlyModal let-close="close">
  <app-member-only [closeAction]="close" [upgrade]="upgrade"></app-member-only>
</ng-template>