<section id="offers-section" class="contentWrapper">
  <div class="container">
    <h1 class="session-title">Notre offre {{ offer?.title }}<div></div>
    </h1>
    <div class="row">
      <p class="dark-color center">{{ offer?.description }}</p>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="cards-section">
          <article class="custom-card big-card container">
            <div class="row">
              <div class="col-md-12">
                <h1 class="session-title">Les avantages de l'offre <span class="bold">{{ offer?.title }}</span></h1>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" [innerHtml]="offer?.advantages"></div>
            </div>
            <div class="row">
              <div class="col-12" [class.col-lg-12]="offer?.annualy_price == null" [class.col-lg-6]="offer?.annualy_price != null">
                <div class="price">
                  <p class="black-color"><span class="bold">{{ offer?.monthly_price }} €</span> /mois</p>
                  <hr />
                </div>
                <p *ngIf="!auth.isAuthenticated()" class="center action"><a class="bold pink-color" href="/inscription/{{ offer?.id }}/monthly">je souscris à l'offre</a></p>
                <p *ngIf="auth.isAuthenticated()" class="center action"><a class="bold pink-color" href="/individual/subscription/2/monthly">je souscris à l'offre</a></p>
              </div>
              <div class="col-12 col-lg-6" *ngIf="offer?.annualy_price != null">
                <div class="price">
                  <p class="black-color"><span class="bold">{{ offer?.annualy_price }} €</span> /an</p>
                  <hr />
                </div>
                <p *ngIf="!auth.isAuthenticated()" class="center action"><a class="bold pink-color" href="/inscription/{{ offer?.id }}/annualy">je souscris à l'offre</a></p>
                <p *ngIf="auth.isAuthenticated()" class="center action"><a class="bold pink-color" href="/individual/subscription/2/annualy">je souscris à l'offre</a></p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="offers-section" class="">
  <div class="container">
    <h4 class="session-title">
      Comparer
      <div></div>
    </h4>
    <div class="row">
      <p class="dark-color center">{{ offer?.compare }}</p>
    </div>

    <div class="row offers-container">
      <div class="flex dark-color">
        <div *ngFor="let item of offers" class="offer bg-white">
          <h2 class="center bold">{{ item.title }}</h2>
          <div class="from">à partir de</div>
          <p class="price bold center">{{item.monthly_price}} €<span>/mois</span></p>
          <div [innerHtml]="item.bullet_points"></div>
          <a href="/offre/{{item.id}}" class="center dark-color bold">En savoir plus</a>
        </div>
      </div>
    </div>
  </div>
</section>