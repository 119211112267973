<form [formGroup]="newFormSpecialities" (ngSubmit)="submit('Specialities')" class="p-3">

  <h6 class="mt-4 mb-4">Mes spécialités * (choix multiples)</h6>
  <hr class="mb-4">
  <div class="row">
    <div class="col-6 col-sm-6 col-md-6 col-lg-4" *ngFor="let item of specialities; let i=index">
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" [value]="item.id" [checked]="dataToSend['Specialities'].indexOf(item.id) != -1" (click)="onCheckboxChange($event, 'Specialities')">
        <label class="form-check-label" for="speciality{{i}}">{{item.name}}</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <button type="submit" class="main-button-round float-right">Sauvegarder</button>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="newFormProblematics" (ngSubmit)="submit('Problematics')" novalidate class="p-3">
  <h6 class="mt-4 mb-4">Domaines travaillés * (Choix multiples)</h6>
  <hr class="mb-4">
  <div class="row">
    <div class="col-6 col-sm-6 col-md-6 col-lg-4" *ngFor="let item of problematics; let i=index">
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="problematic{{i}}" [value]="item.id" [checked]="dataToSend['Problematics'].indexOf(item.id) != -1" (click)="onCheckboxChange($event, 'Problematics')">
        <label class="form-check-label" for="problematic{{i}}">{{item.name}}</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <button type="submit" class="main-button-round float-right">Sauvegarder</button>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="newFormTheoricalApproaches" (ngSubmit)="submit('Theoricals')" novalidate class="p-3">
  <h6 class="mt-4 mb-4">Approche et orientation théorique *</h6>
  <hr class="mb-4">
  <div class="row">
    <div class="col-6 col-sm-6 col-md-6 col-lg-3" *ngFor="let item of theoricalApproaches; let i=index">
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="theoricalApproach{{i}}" [value]="item.id" [checked]="dataToSend['Theoricals'].indexOf(item.id) != -1" (change)="onCheckboxChange($event, 'Theoricals')">
        <label class="form-check-label" for="theoricalApproach{{i}}">{{item.name}}</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <button type="submit" class="main-button-round float-right">Sauvegarder</button>
      </div>
    </div>
  </div>
</form>

<form [formGroup]="newFormLanguages" (ngSubmit)="submit('Languages')" novalidate class="p-3">
  <h6 class="mt-4 mb-4">Langues *</h6>
  <hr class="mb-4">
  <div class="row">
    <div class="col-6 col-sm-6 col-md-6 col-lg-3" *ngFor="let item of languages; let i=index">
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="language{{i}}" [value]="item.id" [checked]="dataToSend['Languages'].indexOf(item.id) != -1" (change)="onCheckboxChange($event, 'Languages')">
        <label class="form-check-label" for="language{{i}}">{{item.name}}</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <button type="submit" class="main-button-round float-right">Sauvegarder</button>
      </div>
    </div>
  </div>
</form>