import { OnInit } from '@angular/core';
import { NewsletterService } from '../../shared/providers/newsletters/newsletter.service';
import { FlashMessagesService } from 'angular2-flash-messages';
var NewsletterComponent = /** @class */ (function () {
    function NewsletterComponent(_newsletterService, _flashMessagesService) {
        this._newsletterService = _newsletterService;
        this._flashMessagesService = _flashMessagesService;
    }
    NewsletterComponent.prototype.ngOnInit = function () {
    };
    NewsletterComponent.prototype.subscribe = function () {
        var _this = this;
        this._newsletterService.subscribe(this.email).subscribe(function (res) {
            _this._flashMessagesService.show('Merci de votre intérêt pour notre newsletter.', { cssClass: 'alert-success' });
        });
    };
    return NewsletterComponent;
}());
export { NewsletterComponent };
