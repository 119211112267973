<app-home-slider [zone]="'home'"></app-home-slider>

<section id="conference-section" class="">
    <div class="container">
        <h3 class="session-title mt-5 mb-5">Nos webconférences & évènements<div></div></h3>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-4" *ngFor="let item of conferencesList | slice:0:3; let i=index">
                <article class="conferenceCustomCard custom-card position-relative">
                    <div class="custom-card-image position-relative">
                        <div class="authors position-absolute">
                            <div class="author float-left" *ngFor="let author of item?.authors">
                                <img src="{{ environment.uploadPath + 'users/' + author?.image }}" class="rounded-circle" width="60" alt="{{ author?.firstname + ' ' + author?.lastname }}" />
                            </div>
                        </div>
                        <a routerLink="/conferences/{{ item?.slug }}" class="d-block">
                            <img src="{{ environment.uploadPath + 'conferences/' + item?.image }}" alt="{{item?.title}}" class="w-100" />
                        </a>
                    </div>
                    <div class="custom-card-content mt-3">
                        <div class="topContent">
                            <h4 class="title dark-color bold" style="height: 50px;"><a class="dark-color" routerLink="/conferences/{{item?.slug}}">{{item?.title}}</a></h4>
                            <span *ngIf="item?.novelty" class="novelty bg-success badge text-white">Nouveauté</span>
                        </div>
                        <div class="w-100 float-left mt-4 mb-4 text-justify" [innerHtml]="item?.intro"></div>
                    </div>
                </article>
            </div>
        </div>

    </div>
    <div class="flex section-button">
        <a routerLink="/conferences" class="main-button-round">Toutes les webconférences</a>
    </div>
</section>
<section id="training-section" class="">
    <div class="container">
        <h3 class="session-title mt-5 mb-5">Nos formations<div></div></h3>
        <div class="row">
            <p class="dark-color center">La vie de couple et de famille mobilise des compétences et des savoirs-être que
                nous pouvons développer pour améliorer nos relations et notre bien-être.
                Avec différents thèmes de formation et en fonction du niveau d’urgence de votre besoin, nous avons conçu
                des contenus qualitatifs, accessibles, illustrés d’exemples concrets et simples à mettre en pratique
                pour réussir votre vie de famille.
            </p>
        </div>
        <div class="row cards-section">

            <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-5 text-center" *ngFor="let item of categories">
                <img src="{{ environment.uploadPath + 'categories/' + item?.picto}}" width="50">
                <h2 class="dark-color mt-3 mb-4">{{ item?.name }}</h2>
                <article class="shadow bg-light rounded">
                    <img src="{{ environment.uploadPath + 'categories/' + item?.image}}" class="w-100">
                    <div class="custom-card-content">
                        <p class="dark-color center" [innerHtml]="item?.description"></p>
                        <div class="article-button">
                            <a routerLink="/formations/{{ item?.slug }}" class="main-button-round">En savoir plus</a>
                        </div>
                    </div>
                </article>
            </div>

        </div>
    </div>
    <div class="flex section-button">
        <a routerLink="/formations" class="main-button-round">Toutes les formations</a>
    </div>
</section>
<section class="white-color-bg">
    <div class="container">
        <h3 class="session-title">Ce que les parents en disent<div></div></h3>
        
        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4" *ngFor="let item of testimonies">
                <div class="card text-center shadow rounded">
                    <img src="{{ environment.uploadPath + 'testimonies/' + item?.image }}" class="card-img-top" alt="{{ item?.name }}" />
                    <div class="card-body">
                        <h5 class="card-title">{{ item?.name }}</h5>
                        <p class="card-text" style="min-height: 120px;" [innerHtml]="item?.content"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="consulting-section" class="">
    <div class="container">
        <app-content [slug]="'home-advise-therapies-section'"></app-content>
    </div>
    <!-- <div class="flex section-button">
        <a href="/conseillers" class="main-button-round uppercase">Tous nos conseillers</a>
    </div> -->
</section>

<section id="references-section" class="white-color-bg">
    <div class="container">
        <h3 class="session-title mt-5 mb-5">Ils nous font confiance / ils parlent de nous<div></div></h3>
        <div id="references-section-carousel" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <ng-container *ngFor="let item of partners; let i = index">
                    <div class="carousel-item" [class.active]="i==0" *ngIf="i % 5 === 0">
                        <div class="row">
                            <div class="col">
                                <figure>
                                    <img src="{{ environment.uploadPath }}partenaires/{{ partners[i]?.image }}" *ngIf="partners[i]">
                                </figure>
                            </div>
                            <div class="col">
                                <figure>
                                    <img src="{{ environment.uploadPath }}partenaires/{{ partners[i+1]?.image }}" *ngIf="partners[i+1]">
                                </figure>
                            </div>
                            <div class="col">
                                <figure>
                                    <img src="{{ environment.uploadPath }}partenaires/{{ partners[i+2]?.image }}" *ngIf="partners[i+2]">
                                </figure>
                            </div>
                            <div class="col">
                                <figure>
                                    <img src="{{ environment.uploadPath }}partenaires/{{ partners[i+3]?.image }}" *ngIf="partners[i+3]">
                                </figure>
                            </div>
                            <div class="col">
                                <figure>
                                    <img src="{{ environment.uploadPath }}partenaires/{{ partners[i+4]?.image }}" *ngIf="partners[i+4]">
                                </figure>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <a class="carousel-control-prev dark-color" href="#references-section-carousel" role="button" data-slide="prev">
                <i class="fas fa-chevron-left" style="font-size: 45px;"></i>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next dark-color" href="#references-section-carousel" role="button" data-slide="next">
                <i class="fas fa-chevron-right" style="font-size: 45px;"></i>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</section>

<section id="program21-section" class="">
    <div class="container">
        <h3 class="session-title mt-5 mb-5">Panoplie du parent positif<div></div></h3>
        <div class="row">
            <div class="col-md-7 program21-content">
                <!-- <h2 class="dark-color bold"> par mail</h2> -->
                <p class=" dark-color mb-3">
                    <span class="bold">Les outils essentiels pour une parentalité épanouie et proactive</span>
                    <br/>
                    <span style="font-style: italic;">Déployez vos compétences parentales pour améliorer votre vie familiale.</span>
                </p>
                <p class=" dark-color mb-3">
                     Programme de 21 jours pour prendre conscience , faire le bilan et optimiser sa parentalité.
                </p>
                <p class="dark-color">Vous recevez chaque jour de la durée du programme, un mail avec une suggestion, un
                    contenu ou un exercice qui vous permettra d’avancer dans votre progression.
                </p>
                <a routerLink="/formations/sameliorer/panoplie-du-parent-positif" class="main-button-round">En savoir plus</a>
            </div>
            <div class="col-md-5 panoplie">
                <figure>
                    <!-- <a routerLink="/formations/sameliorer/panoplie-du-parent-positif"><img src="/assets/imgs/10_cles.jpg" /></a> -->
                    <a routerLink="/formations/sameliorer/panoplie-du-parent-positif"><img src="{{ environment.uploadPath + 'trainings/panoplie-parent-positif.png' }}" /></a>
                </figure>
            </div>
        </div>
    </div>
</section>

<app-content [slug]="'engagements-section'"></app-content>

<!-- <app-engagements></app-engagements> -->

<app-quote></app-quote>

<section id="become-consultant-section" class="">
    <div class="container">
        <div class="row">
            <div class="col">
                <h3 class="session-title mt-5 mb-5">Devenir conseiller<div></div></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10">
                <p class="dark-color">Psychologues, conseillers, rejoignez Familipsy et bénéficiez d’un
                    outil inédit et simple d’utilisation pour accroître votre visibilité et proposer votre expertise de
                    chez vous.
                </p>
            </div>
            <div class="col-md-2 button-section">
                <a routerLink="/devenir-conseiller" class="main-button-round">En savoir plus</a>
            </div>
        </div>
    </div>
</section>