<section id="contact-section" class="">
  <div class="container">
    <h1 class="session-title">Donnez votre avis<div></div></h1>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <img src="https://cdn.test.familipsy.academy/uploads/contents/mascotte-big.png" class="w-100">
      </div>
      <div class="col-lg-6 col-md-12" *ngIf="!feedbackDone">
        <div class="row">
          <div class="col-lg-12">
            <p>
              Nous vous remercions pour le temps que vous prendrez pour donner votre avis concernant votre consultation du {{ appointment?.due_date | date: 'dd/MM/yyyy':'fr' }} à {{ appointment?.due_date | date: 'HH:mm':'fr' }} avec votre conseiller {{ appointment?.counselor?.lastname | uppercase }} {{ appointment?.counselor?.firstname | titlecase }} 
            </p>
          </div>
        </div>
        <form class="custom-form p-4 bg-white rounded shadow" [formGroup]="feedbackForm">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <textarea class="form-control" rows="8" cols="40" placeholder="Votre message" formControlName="message"></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="email" class="bold dark-color">Votre note</label>
                <div class="text-center w-100">
                  <i style="font-size: 30px;" class="fa-star" [class.text-secondary-light]="star<1" [class.text-warning]="star>=1" [class.far]="star<1" [class.fas]="star>=1" (click)="star = 1"></i>
                  <i style="font-size: 30px;" class="fa-star" [class.text-secondary-light]="star<2" [class.text-warning]="star>=2" [class.far]="star<2" [class.fas]="star>=2" (click)="star = 2"></i>
                  <i style="font-size: 30px;" class="fa-star" [class.text-secondary-light]="star<3" [class.text-warning]="star>=3" [class.far]="star<3" [class.fas]="star>=3" (click)="star = 3"></i>
                  <i style="font-size: 30px;" class="fa-star" [class.text-secondary-light]="star<4" [class.text-warning]="star>=4" [class.far]="star<4" [class.fas]="star>=4" (click)="star = 4"></i>
                  <i style="font-size: 30px;" class="fa-star" [class.text-secondary-light]="star<5" [class.text-warning]="star>=5" [class.far]="star<5" [class.fas]="star>=5" (click)="star = 5"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="button-section">
            <button class="main-button-round btn-block" type="submit" (click)="subtmitFeedback()">Envoyer mon avis</button>
          </div>
        </form>
      </div>
      <div class="col-lg-6 col-md-12" *ngIf="feedbackDone">
        <p>Nous vous remercions pour le temps que vous nous avez accordé pour nous donner votre avis.</p>
      </div>
    </div>
  </div>
</section>