<header id="header">
  <div class="topMenu">
    <div class="container">
      <div class="row">
        <nav class="secondary-menu col-md-12">
          <ul *ngIf="currentUser == undefined">
            <li><a href="/devenir-conseiller" class="black-color bold">Devenir Conseiller</a></li>
            <li>
              <a href="/connexion" class="black-color">Connexion</a> /
              <a href="/offres" class="black-color">S'inscrire</a>
            </li>
          </ul>
          <ul *ngIf="currentUser != undefined">
            <li><a (click)="goToProfile()" class="black-color bold cursor-pointer">{{currentUser.firstname}} {{currentUser.lastname}}</a></li>
            <li><a (click)="deconnexion()" class="black-color cursor-pointer">Déconnexion</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="mainMenu pt-3 pb-3">
    <div class="container">
      <div class="row" *ngIf="innerWidth > 1200">

        <div class="col-lg-2 col-md-4 col-sm-4 logo-container">
          <a routerLink="/accueil">
            <img src="/assets/imgs/Logo_familipsy_academy.png" alt="Familipsy logo" class="w-100">
          </a>
        </div>

        <div class="col-lg-10 col-md-8">
          <div class="row">
            <nav class="primary-menu col-md-12 mt-3">
              <ul class="p-0">
                <li><a routerLink="/accueil" routerLinkActive="active" class="black-color">Accueil</a></li>
                <li><a routerLink="/conseillers" routerLinkActive="active" class="black-color">Conseillers</a></li>
                <li><a routerLink="/formations" routerLinkActive="active" class="black-color">Formations</a></li>
                <li><a routerLink="/conferences" routerLinkActive="active" class="black-color">Conférences</a></li>
                <li><a routerLink="/ressources" routerLinkActive="active" class="black-color">Ressources</a></li>
                <li>
                  À propos
                  <ul class="shadow">
                    <li><a routerLink="/qui-sommes-nous" routerLinkActive="active" class="black-color">Qui sommes nous</a></li>
                    <li><a href="https://www.familipsy.com/PSYCHOLOGIE-EDUCATION_r6.html" target="blank" class="black-color">Blog</a></li>
                  </ul>
                </li>
                <li><a routerLink="/offres" class="main-button-round uppercase bold">Nos Offres</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="innerWidth < 1200">
        <div class="col-md-12">

          <!--Navbar-->
          <mdb-navbar #myNavbar SideClass="navbar navbar-1 navbar-light amber lighten-2" [containerInside]="false">

            <!-- Navbar brand -->
            <mdb-navbar-brand>
              <div class="logo">
                <a routerLink="/accueil" (click)="myNavbar.hide()">
                  <img src="/assets/imgs/logo.jpg" alt="Familipsy logo">
                </a>
              </div>
            </mdb-navbar-brand>

            <!-- Collapsible content -->
            <links>

              <!-- Links -->

              <ul class="navbar-nav mr-auto">

                <!-- <li (click)="myNavbar.hide()" class="nav-item" *ngIf="currentUser == undefined"><a href="/devenir-conseiller"
                    class="black-color bold nav-link waves-light">Devenir Conseiller</a></li>
                <li (click)="myNavbar.hide()" class="nav-item" *ngIf="currentUser == undefined">
                  <a href="/connexion" class="black-color nav-link waves-light">Connexion</a>
                  <a href="/inscription/{{ firstOffer?.id }}" class="black-color nav-link waves-light">S'inscrire</a>
                </li> -->


                <li (click)="myNavbar.hide()" class="nav-item" *ngIf="currentUser != undefined"><a (click)="goToProfile()"
                    class="black-color bold cursor-pointer nav-link waves-light">{{currentUser.firstName}}
                    {{currentUser.lastName}}</a></li>


                <li (click)="myNavbar.hide()" class="nav-item ">
                  <a href="/accueil" class="nav-link waves-light" mdbWavesEffect>Accueil<span
                      class="sr-only">(current)</span></a>
                </li>
                <li (click)="myNavbar.hide()" class="nav-item">
                  <a href="/conseillers" class="nav-link waves-light" mdbWavesEffect>Conseillers</a>
                </li>
                <li (click)="myNavbar.hide()" class="nav-item">
                  <a href="/formations" class="nav-link waves-light" mdbWavesEffect>Formations</a>
                </li>
                <li (click)="myNavbar.hide()" class="nav-item">
                  <a href="/conferences" class="nav-link waves-light" mdbWavesEffect>Conférences</a>
                </li>
                <li (click)="myNavbar.hide()" class="nav-item">
                  <a href="/ressources" class="nav-link waves-light" mdbWavesEffect>Ressources</a>
                </li>
                <li (click)="myNavbar.hide()" class="nav-item">
                  <a routerLink="/qui-sommes-nous" class="nav-link waves-light" mdbWavesEffect>Qui sommes nous</a>
                </li>
                <li (click)="myNavbar.hide()" class="nav-item">
                  <a href="https://www.familipsy.com/PSYCHOLOGIE-EDUCATION_r6.html" class="nav-link waves-light"
                    mdbWavesEffect>Blog</a>
                </li>

                <li (click)="myNavbar.hide()" class="nav-item"><a routerLink="/offres" class="main-button-round uppercase bold">Nos Offres</a></li>
              </ul>
              <!-- Links -->
            </links>
            <!-- Collapsible content -->

          </mdb-navbar>
          <!--/.Navbar-->

        </div>

      </div>
    </div>
  </div>
</header>