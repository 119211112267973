<section id="carousel-section">
    <ngb-carousel interval="5000" id="slider-home">
        <ng-template ngbSlide *ngFor="let slide of slides">
            <div class="slide-content" [ngStyle]="{background: 'url(' + environment.uploadPath + 'slides/' + slide.image + ') center / cover'}" style="height: calc( 100vh - 153px );">
                <!-- <img *ngIf="slide.video == null || slide.video == 'null'" class="slide-image" src="{{ environment.uploadPath }}slides/{{slide.image}}"> -->
                <div class="slideContentText {{slide.position_text}}" [ngClass]="{'background':slide.background}"> 
                    <div class="row">
                        <div class="col-md-12">
                            <h2>{{slide.title}}</h2>
                            <a href="{{slide.link}}" class="main-button-round" style="margin: 0 auto;">{{slide.button}}</a>
                        </div>
                    </div>
                </div>
                
                <!-- <img *ngIf="slide.video" class="slide-image" src="{{ environment.uploadPath }}slides/{{slide.image}}" (click)="openModal(videoModal, slide.video)"> -->
            </div>
        </ng-template>
    </ngb-carousel>
</section>

<ng-template #videoModal let-close="close">
    <app-modal [modalContent]="bodyText" [closeAction]="close"></app-modal>
</ng-template>