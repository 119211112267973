<div id="conferences-detail-section">
  <div class="contentWrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="row mb-4">
            <div class="col-md-12">
              <div class="card" *ngIf="before">
                <ul class="list-group">
                  <li class="list-group-item"><i class="far fa-calendar-alt"></i> Date prévue : {{ conference?.date_due_to | date:'d-MM-yyyy':'fr' }}</li>
                  <li class="list-group-item"><i class="far fa-clock"></i> Heure prévue : {{ conference?.date_due_to | date:'HH:mm' }}</li>
                  <li class="list-group-item"><i class="fas fa-hourglass-half"></i> Durée : {{ conference?.duration }} mn</li>
                  <!-- <li class="list-group-item" *ngIf="conference?.views != 0"><i class="far fa-eye"></i> {{ conference?.views }}</li> -->
                  <li class="list-group-item"><a target="_blank" href="{{ conference?.link }}" class="main-button-round btn-block">S'inscrire</a></li>
                </ul>
              </div>
              <a href="javascript:void(0)" (click)="openModal(memberOnlyModal, videoModal)" class="card dark-color-bg text-center" *ngIf="!before" style="padding: 50px 0;">
                <i class="far fa-play-circle mb-3" style="font-size: 50px"></i>
                Voir le Replay<br/>
                Durée : {{ conference?.duration }} mn
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h5 class="mt-2 mb-2">Conférenciers</h5>
              <div class="authors">
                <div class="author float-left w-100 mb-3" *ngFor="let author of conference?.authors">
                    <img src="{{ environment.uploadPath + 'users/' + author?.image }}" class="rounded-circle float-left" width="60" alt="{{ author?.firstname + ' ' + author?.lastname }}" />
                    <div class="authorName float-left ml-3 mt-2">{{ author?.firstname }}<br/>{{ author?.lastname }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row d-none d-md-block d-lg-block">
            <div class="col-md-12">
              <h5 class="mt-5 mb-3">Conférences similaires</h5>
              <div class="">
                
                <article class="conferenceCustomCard custom-card position-relative" *ngFor="let item of conference?.similars">
                  <div class="position-relative">
                      <div class="authors position-absolute">
                          <div class="author" *ngFor="let author of item?.authors">
                              <img src="{{ environment.uploadPath + 'users/' + author?.image }}" class="rounded-circle" width="60" alt="{{ author?.firstname + ' ' + author?.lastname }}" />
                          </div>
                      </div>
                      <a routerLink="/conferences/{{ item?.slug }}" class="d-block">
                          <img src="{{ environment.uploadPath + 'conferences/' + item?.image }}" alt="{{item?.title}}" class="w-100" />
                      </a>
                  </div>
              </article>
              </div>
            </div>
          </div>
          
        </div>
        <div class="col-md-9">
          <div class="pl-3">
            <!-- <span *ngIf="!isFavourite" class="float-left mr-3 pink-color cursor-pointer" style="font-size: 25px" (click)="addToFavourites()" placement="top" ngbTooltip="Ajouter aux favoris"><i class="far fa-heart"></i></span> -->
            <h1 class="title p-0 mb-1 text-center">
              {{ conference?.title }}
              <div></div>
            </h1>
            <h3 class="subtitle">{{ conference?.subtitle }}</h3>
            <div class="">
              <div class="">
                <img src="{{ environment.uploadPath + 'categories/' + conference?.category?.picto }}" class="rounded-circle float-left" width="60" alt="{{ conference?.category?.name }}" />
                <div class="mt-3 float-left"><h5>{{ conference?.category?.name }}</h5></div>
              </div>
              <span class="novelty bg-success text-light p-2 d-block float-left ml-3 mt-2 rounded" *ngIf="conference?.novelty">Nouveauté</span>
              <div *ngIf="!isFavourite" class="float-right ml-3 mt-2 main-button-round cursor-pointer" (click)="addToFavourites()" placement="top" ngbTooltip="Ajouter aux favoris"><i class="far fa-heart"></i> Ajouter aux favoris</div>
              
            </div>
            <img src="{{ environment.uploadPath + 'conferences/' + conference?.image }}" alt="{{ conference?.title }}" class="w-100 mt-4 mb-4 rounded" />
            <div class="row">
              <div class="col-12">
              </div>
            </div>
            <h2 class="intro" [innerHtml]="conference?.intro"></h2>
            <div [innerHtml]="conference?.description"></div>
            <div class="row">
              <div class="col-md-12">
                <h5 class="mt-5 mb-3">Sujets traités</h5>
                <div class="">
                  <span class="badge badge-secondary mr-2" *ngFor="let item of conference?.problematics">{{ item?.user_equivalent }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h5 class="mt-5 mb-3">Tags</h5>
                <div class="">
                  <span class="" *ngFor="let item of tags">#{{ item + " " }}</span>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>

      <div class="row d-md-none d-lg-none d-xl-none">
        <div class="col-md-12">
          <h5 class="mt-5 mb-3">Conférences similaires</h5>
          <div class="">
            
            <article class="conferenceCustomCard custom-card position-relative" *ngFor="let item of conference?.similars">
              <div class="position-relative">
                  <div class="authors position-absolute">
                      <div class="author" *ngFor="let author of item?.authors">
                          <img src="{{ environment.uploadPath + 'users/' + author?.image }}" class="rounded-circle" width="60" alt="{{ author?.firstname + ' ' + author?.lastname }}" />
                      </div>
                  </div>
                  <a routerLink="/conferences/{{ item?.slug }}" class="d-block">
                      <img src="{{ environment.uploadPath + 'conferences/' + item?.image }}" alt="{{item?.title}}" class="w-100" />
                  </a>
              </div>
          </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #videoModal let-close="close">
  <app-modal [modalContent]="bodyText" [closeAction]="close"></app-modal>
</ng-template>

<ng-template #memberOnlyModal let-close="close">
  <app-member-only [closeAction]="close" [upgrade]="upgrade"></app-member-only>
</ng-template>