<div class="row">
  <div class="col">
    <form *ngIf="step !=3" class="custom-form container p-0 m-0" [formGroup]="newForm" (ngSubmit)="formSubmit()">
      <div class="">
        <p class="dark-color center info">
          Afin de préparer au mieux votre consultation, précisez vos raisons et vos besoins. Le conseiller que vous avez
          choisi en prendra connaissance.
        </p>
        <div class="row">
          <div class="col">
            <div>Décrivez vos besoins</div>
            <textarea formControlName="comment" class="w-100"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="card-info">Votre carte</label>
            <div id="form-field">
              <div id="card-element" class="field"></div>
            </div>
          </div>
        </div>
  
        <div class="button-section mt-5">
          <button id="card-button" class="main-button-round">Valider le paiement</button>
        </div>
      </div>
    </form>
    <div *ngIf="step == 3" class="">
      <div class="row">
        <div class="col-3">
          <img src="{{ environment.uploadPath + 'contents/mascotte-big.png' }}" class="w-100">
        </div>
        <div class="col-9">
          <h2 class="mb-3">Félicitations!</h2>
          <p>
            Votre consultation a bien été réservée et tout est prêt pour votre séance à distance ! À ce sujet, nous venons de vous envoyer un mail de confimation.
          </p>
          <p>Cette séance est une sorte de "rendez-vous" avec vous-même, nous mettons tout en oeuvre pour que ce soit une expérience des plus bénéfiques pour vous.</p>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <div class="row">
        <div class="col-md-4">
          <h4>Votre conseiller</h4>
          <div class="counselorInfos position-relative float-left">
            <img src="{{ environment.uploadPath + 'users/' + modalData.counselor.image }}" class="float-left rounded w-100" />
            <div class="position-absolute w-100 center text-white bold pl-2 pr-2 pt-3 pb-3" style="bottom: 0px; background: rgba(0,0,0,0.4)">
              {{ modalData.counselor.lastname }}<br />
              {{ modalData.counselor.firstname }}
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <ul class="list-group">
            <li class="list-group-item"><i class="far fa-calendar-alt"></i> Début : {{ modalData?.start | date: 'dd/MM/yyyy':'fr' }} à {{ modalData?.start | date: 'HH:mm':'fr' }}</li>
            <li class="list-group-item"><i class="far fa-calendar-alt"></i> Fin : {{ modalData?.end | date: 'dd/MM/yyyy':'fr' }} à {{ modalData?.end | date: 'HH:mm':'fr' }}</li>
            <li class="list-group-item"><a [href]="icsGoogle()" target="_blank" class="main-button-round btn-block"><i class="fab fa-google"></i> Google Agenda</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>