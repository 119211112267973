<!-- <style type="text/css">
  .lst-kix_g0e2rkgeld3o-0>li:before {
      content: "\0025cf  "
  }
  
  .lst-kix_g0e2rkgeld3o-1>li:before {
      content: "\0025cb  "
  }
  
  .lst-kix_g0e2rkgeld3o-3>li:before {
      content: "\0025cf  "
  }
  
  .lst-kix_g0e2rkgeld3o-2>li:before {
      content: "\0025a0  "
  }
  
  .lst-kix_g0e2rkgeld3o-4>li:before {
      content: "\0025cb  "
  }
  
  .lst-kix_4j918kw2b4pm-4>li:before {
      content: "\0025cb  "
  }
  
  .lst-kix_4j918kw2b4pm-3>li:before {
      content: "\0025cf  "
  }
  
  .lst-kix_4j918kw2b4pm-1>li:before {
      content: "\0025cb  "
  }
  
  .lst-kix_4j918kw2b4pm-0>li:before {
      content: "\0025cf  "
  }
  
  .lst-kix_4j918kw2b4pm-2>li:before {
      content: "\0025a0  "
  }
  
  .lst-kix_pj7sktmenkg-2>li:before {
      content: "\0025a0  "
  }
  
  ul.lst-kix_4j918kw2b4pm-6 {
      list-style-type: none
  }
  
  ul.lst-kix_4j918kw2b4pm-7 {
      list-style-type: none
  }
  
  ul.lst-kix_4j918kw2b4pm-8 {
      list-style-type: none
  }
  
  .lst-kix_pj7sktmenkg-1>li:before {
      content: "\0025cb  "
  }
  
  ul.lst-kix_4j918kw2b4pm-2 {
      list-style-type: none
  }
  
  .lst-kix_4j918kw2b4pm-5>li:before {
      content: "\0025a0  "
  }
  
  ul.lst-kix_4j918kw2b4pm-3 {
      list-style-type: none
  }
  
  ul.lst-kix_4j918kw2b4pm-4 {
      list-style-type: none
  }
  
  .lst-kix_4j918kw2b4pm-6>li:before {
      content: "\0025cf  "
  }
  
  ul.lst-kix_4j918kw2b4pm-5 {
      list-style-type: none
  }
  
  .lst-kix_pj7sktmenkg-0>li:before {
      content: "\0025cf  "
  }
  
  .lst-kix_4j918kw2b4pm-7>li:before {
      content: "\0025cb  "
  }
  
  ul.lst-kix_4j918kw2b4pm-0 {
      list-style-type: none
  }
  
  ul.lst-kix_4j918kw2b4pm-1 {
      list-style-type: none
  }
  
  .lst-kix_4j918kw2b4pm-8>li:before {
      content: "\0025a0  "
  }
  
  ul.lst-kix_pj7sktmenkg-8 {
      list-style-type: none
  }
  
  ul.lst-kix_pj7sktmenkg-7 {
      list-style-type: none
  }
  
  ul.lst-kix_pj7sktmenkg-6 {
      list-style-type: none
  }
  
  ul.lst-kix_pj7sktmenkg-5 {
      list-style-type: none
  }
  
  ul.lst-kix_pj7sktmenkg-4 {
      list-style-type: none
  }
  
  ul.lst-kix_pj7sktmenkg-3 {
      list-style-type: none
  }
  
  ul.lst-kix_pj7sktmenkg-2 {
      list-style-type: none
  }
  
  ul.lst-kix_pj7sktmenkg-1 {
      list-style-type: none
  }
  
  ul.lst-kix_pj7sktmenkg-0 {
      list-style-type: none
  }
  
  ul.lst-kix_g0e2rkgeld3o-1 {
      list-style-type: none
  }
  
  ul.lst-kix_g0e2rkgeld3o-0 {
      list-style-type: none
  }
  
  ul.lst-kix_g0e2rkgeld3o-3 {
      list-style-type: none
  }
  
  ul.lst-kix_g0e2rkgeld3o-2 {
      list-style-type: none
  }
  
  ul.lst-kix_g0e2rkgeld3o-5 {
      list-style-type: none
  }
  
  ul.lst-kix_g0e2rkgeld3o-4 {
      list-style-type: none
  }
  
  ul.lst-kix_g0e2rkgeld3o-7 {
      list-style-type: none
  }
  
  ul.lst-kix_g0e2rkgeld3o-6 {
      list-style-type: none
  }
  
  .lst-kix_pj7sktmenkg-3>li:before {
      content: "\0025cf  "
  }
  
  ul.lst-kix_g0e2rkgeld3o-8 {
      list-style-type: none
  }
  
  .lst-kix_pj7sktmenkg-4>li:before {
      content: "\0025cb  "
  }
  
  .lst-kix_pj7sktmenkg-5>li:before {
      content: "\0025a0  "
  }
  
  .lst-kix_pj7sktmenkg-6>li:before {
      content: "\0025cf  "
  }
  
  .lst-kix_pj7sktmenkg-8>li:before {
      content: "\0025a0  "
  }
  
  .lst-kix_pj7sktmenkg-7>li:before {
      content: "\0025cb  "
  }
  
  .lst-kix_g0e2rkgeld3o-7>li:before {
      content: "\0025cb  "
  }
  
  .lst-kix_g0e2rkgeld3o-6>li:before {
      content: "\0025cf  "
  }
  
  .lst-kix_g0e2rkgeld3o-8>li:before {
      content: "\0025a0  "
  }
  
  .lst-kix_g0e2rkgeld3o-5>li:before {
      content: "\0025a0  "
  }
  
  ol {
      margin: 0;
      padding: 0
  }
  
  table td,
  table th {
      padding: 0
  }
  
  .c8 {
      margin-left: 36pt;
      padding-top: 12pt;
      padding-left: 0pt;
      padding-bottom: 12pt;
      line-height: 1.15;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  .c4 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal
  }
  
  .c1 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.15;
      orphans: 2;
      widows: 2;
      text-align: left;
      height: 11pt
  }
  
  .c0 {
      color: #000000;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal
  }
  
  .c3 {
      padding-top: 0pt;
      padding-bottom: 9pt;
      line-height: 1.15;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  .c2 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.15;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  .c12 {
      padding-top: 0pt;
      padding-bottom: 15pt;
      line-height: 1.15;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  .c11 {
      text-decoration-skip-ink: none;
      -webkit-text-decoration-skip: none;
      color: #1155cc;
      text-decoration: underline
  }
  
  .c6 {
      background-color: #ffffff;
  }
  
  .c10 {
      color: inherit;
      text-decoration: inherit
  }
  
  .c9 {
      padding: 0;
      margin: 0
  }
  
  .c5 {
      font-weight: 700
  }
  
  .c7 {
      height: 11pt
  }
  
  .title {
      padding-top: 0pt;
      color: #000000;
      font-size: 26pt;
      padding-bottom: 3pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  .subtitle {
      padding-top: 0pt;
      color: #666666;
      font-size: 15pt;
      padding-bottom: 16pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  li {
      color: #000000;
      font-size: 11pt;
      font-family: "Arial"
  }
  
  p {
      margin: 0;
      color: #000000;
      font-size: 11pt;
      font-family: "Arial"
  }
  
  h1 {
      padding-top: 20pt;
      color: #000000;
      font-size: 20pt;
      padding-bottom: 6pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  h2 {
      padding-top: 18pt;
      color: #000000;
      font-size: 16pt;
      padding-bottom: 6pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  h3 {
      padding-top: 16pt;
      color: #434343;
      font-size: 14pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  h4 {
      padding-top: 14pt;
      color: #666666;
      font-size: 12pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  h5 {
      padding-top: 12pt;
      color: #666666;
      font-size: 11pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }
  
  h6 {
      padding-top: 12pt;
      color: #666666;
      font-size: 11pt;
      padding-bottom: 4pt;
      font-family: "Arial";
      line-height: 1.15;
      page-break-after: avoid;
      font-style: italic;
      orphans: 2;
      widows: 2;
      text-align: left
  }
</style> -->

<h1 class="session-title" style="text-align: center;">Politique de confidentialité (standard RGPD)<div></div></h1>

<div class="c6">
    <p class="c3"><span class="c0">S&eacute;curit&eacute; et protection des donn&eacute;es personnelles</span></p>
    <p class="c3 c7"><span class="c4"></span></p>
    <p class="c2"><span class="c0">Nature des donn&eacute;es collect&eacute;es</span></p>
    <p class="c3"><span class="c0">Dans le cadre de l&#39;utilisation des Sites Familipsy.academy &amp; Familipsy.com, Familipsy est susceptible de collecter les cat&eacute;gories de donn&eacute;es suivantes concernant ses Utilisateurs :</span></p>
    <p class="c3"><span class="c4">Donn&eacute;es d&#39;&eacute;tat-civil, d&#39;identit&eacute;, d&#39;identification...</span></p>
    <p class="c3"><span class="c4">Donn&eacute;es relatives &agrave; la vie personnelle (centres d&rsquo;int&eacute;r&ecirc;ts, situation familiale, hors donn&eacute;es sensibles ou dangereuses)</span></p>
    <p class="c3"><span class="c4">Donn&eacute;es relatives &agrave; la vie professionnelle (scolarit&eacute;, formation professionnelle, distinctions...)</span></p>
    <p class="c2"><span class="c0">Communication des donn&eacute;es personnelles &agrave; des tiers</span></p>
    <p class="c3"><span class="c0">Pas de communication &agrave; des tiers </span></p>
    <p class="c3"><span>Vos donn&eacute;es ne font l&#39;objet d&#39;</span><span class="c5">aucune communication &agrave; des tiers</span><span class="c4">. Vous &ecirc;tes toutefois inform&eacute;s qu&#39;elles pourront &ecirc;tre divulgu&eacute;es en application d&#39;une loi, d&#39;un r&egrave;glement ou en vertu d&#39;une d&eacute;cision d&#39;une autorit&eacute; r&eacute;glementaire ou judiciaire comp&eacute;tente.</span></p>
    <p class="c1"><span class="c4"></span></p>
    <p class="c1"><span class="c4"></span></p>
    <p class="c2"><span class="c0">Information pr&eacute;alable pour la communication des donn&eacute;es personnelles &agrave; des tiers en cas de fusion / absorption</span></p>
    <p class="c2"><span class="c0">Information pr&eacute;alable et possibilit&eacute; d&rsquo;opt-out avant et apr&egrave;s la fusion / acquisition </span></p>
    <p class="c2"><span class="c4">Dans le cas o&ugrave; nous prendrions part &agrave; une op&eacute;ration de fusion, d&rsquo;acquisition ou &agrave; toute autre forme de cession d&rsquo;actifs, nous nous engageons &agrave; garantir la confidentialit&eacute; de vos donn&eacute;es personnelles et &agrave; vous informer avant que celles-ci ne soient transf&eacute;r&eacute;es ou soumises &agrave; de nouvelles r&egrave;gles de confidentialit&eacute;.</span></p>
    <p class="c3 c7"><span class="c4"></span></p>
    <p class="c2"><span class="c0">Finalit&eacute; de la r&eacute;utilisation des donn&eacute;es personnelles collect&eacute;es</span></p>
    <p class="c3"><span class="c0">Effectuer les op&eacute;rations relatives &agrave; la gestion des clients concernant </span></p>
    <ul class="c9 lst-kix_4j918kw2b4pm-0 start">
        <li class="c8"><span class="c4">les commandes ; les consultations ; les factures ; la comptabilit&eacute; et en particulier la gestion des comptes clients</span></li>
        <li class="c8"><span class="c4">le suivi de la relation client tel que la r&eacute;alisation d&#39;enqu&ecirc;tes de satisfaction, la gestion des r&eacute;clamations et du service apr&egrave;s-vente</span></li>
        <li class="c8"><span class="c4">la s&eacute;lection de clients pour r&eacute;aliser des &eacute;tudes, sondages et tests produits &amp; services (sauf consentement des personnes concern&eacute;es recueilli dans les conditions pr&eacute;vues &agrave; l&rsquo;article 6, ces op&eacute;rations ne doivent pas conduire &agrave; l&#39;&eacute;tablissement de profils susceptibles de faire appara&icirc;tre des donn&eacute;es sensibles - origines raciales ou ethniques, opinions philosophiques, politiques, syndicales, religieuses, vie sexuelle ou sant&eacute; des personnes)</span></li>
    </ul>
    <p class="c3 c7"><span class="c4"></span></p>
    <p class="c3"><span class="c0">Effectuer des op&eacute;rations relatives &agrave; la prospection </span></p>
    <ul class="c9 lst-kix_g0e2rkgeld3o-0 start">
        <li class="c8"><span class="c4">la gestion d&#39;op&eacute;rations techniques de prospection (ce qui inclut notamment les op&eacute;rations techniques comme la normalisation, l&#39;enrichissement et la d&eacute;duplication)</span></li>
        <li class="c8"><span class="c4">la s&eacute;lection de personnes pour r&eacute;aliser des actions de fid&eacute;lisation, de prospection, de sondage, de test produit et de promotion. Sauf consentement des personnes concern&eacute;es recueilli dans les conditions pr&eacute;vues &agrave; l&#39;article 6, ces op&eacute;rations ne doivent pas conduire &agrave; l&#39;&eacute;tablissement de profils susceptibles de faire appara&icirc;tre des donn&eacute;es sensibles (origines raciales ou ethniques, opinions philosophiques, politiques, syndicales, religieuses, vie sexuelle ou sant&eacute; des personnes)</span></li>
        <li class="c8"><span class="c4">la r&eacute;alisation d&#39;op&eacute;rations de sollicitations</span></li>
    </ul>
    <p class="c3 c7"><span class="c4"></span></p>
    <p class="c3"><span class="c0">L&#39;&eacute;laboration de statistiques commerciales</span></p>
    <p class="c3"><span class="c0">La gestion des impay&eacute;s et du contentieux, &agrave; condition qu&#39;elle ne porte pas sur des infractions et / ou qu&#39;elle n&#39;entra&icirc;ne pas une exclusion de la personne du b&eacute;n&eacute;fice d&#39;un droit, d&#39;une prestation ou d&#39;un contrat</span></p>
    <p class="c3"><span class="c0">La gestion des avis des personnes sur des produits, services ou contenus</span></p>
    <p class="c2"><span class="c0">Agr&eacute;gation des donn&eacute;es</span></p>
    <p class="c3"><span class="c5">Agr&eacute;gation avec des donn&eacute;es non personnelles</span><span class="c4">&nbsp;</span></p>
    <p class="c3"><span class="c4">Nous pouvons publier, divulguer et utiliser les informations agr&eacute;g&eacute;es (informations relatives &agrave; tous nos Utilisateurs ou &agrave; des groupes ou cat&eacute;gories sp&eacute;cifiques d&#39;Utilisateurs que nous combinons de mani&egrave;re &agrave; ce qu&#39;un Utilisateur individuel ne puisse plus &ecirc;tre identifi&eacute; ou mentionn&eacute;) et les informations non personnelles &agrave; des fins d&#39;analyse du secteur et du march&eacute;, de profilage d&eacute;mographique, &agrave; des fins promotionnelles et publicitaires et &agrave; d&#39;autres fins commerciales.</span></p>
    <p class="c3"><span class="c5">Agr&eacute;gation avec des donn&eacute;es personnelles disponibles sur les comptes sociaux de l&#39;Utilisateur</span><span class="c4">&nbsp;</span></p>
    <p class="c3"><span class="c4">Si vous connectez votre compte &agrave; un compte d&rsquo;un autre service afin de faire des envois crois&eacute;s, ledit service pourra nous communiquer vos informations de profil, de connexion, ainsi que toute autre information dont vous avez autoris&eacute; la divulgation. Nous pouvons agr&eacute;ger les informations relatives &agrave; tous nos autres Utilisateurs, groupes, comptes, aux donn&eacute;es personnelles disponibles sur l&rsquo;Utilisateur.</span></p>
    <p class="c2"><span class="c0">Collecte des donn&eacute;es d&#39;identit&eacute;</span></p>
    <p class="c3"><span class="c0">Inscription et identification pr&eacute;alable pour la fourniture du service </span></p>
    <p class="c3"><span>L&rsquo;utilisation des Sites Familipsy.academy &amp;</span><span><a class="c10" href="https://www.google.com/url?q=https://familipsy.com&amp;sa=D&amp;ust=1582634748247000">&nbsp;</a></span><span class="c11"><a class="c10" href="https://www.google.com/url?q=https://familipsy.com&amp;sa=D&amp;ust=1582634748247000">familipsy.com</a></span><span class="c4">&nbsp;n&eacute;cessitent une inscription et une identification pr&eacute;alable. Vos donn&eacute;es nominatives (nom, pr&eacute;nom, adresse postale, e-mail, num&eacute;ro de t&eacute;l&eacute;phone,...) sont utilis&eacute;es pour ex&eacute;cuter nos obligations l&eacute;gales r&eacute;sultant de la livraison des produits et / ou des services, en vertu du Contrat de licence utilisateur final, de la Limite de garantie, le cas &eacute;ch&eacute;ant, ou de toute autre condition applicable. Vous ne fournirez pas de fausses informations nominatives et ne cr&eacute;erez pas de compte pour une autre personne sans son autorisation. Vos coordonn&eacute;es devront toujours &ecirc;tre exactes et &agrave; jour.</span></p>
    <p class="c1"><span class="c4"></span></p>
    <p class="c2"><span class="c0">Collecte des donn&eacute;es du terminal</span></p>
    <p class="c3"><span class="c0">Aucune collecte des donn&eacute;es techniques </span></p>
    <p class="c3"><span class="c4">Nous ne collectons et ne conservons aucune donn&eacute;e technique de votre appareil (adresse IP, fournisseur d&#39;acc&egrave;s &agrave; Internet...).</span></p>
    <p class="c2"><span class="c0">Cookies</span></p>
    <p class="c3"><span class="c5">Dur&eacute;e de conservation des cookies</span><span class="c4">&nbsp;</span></p>
    <p class="c3"><span class="c4">Conform&eacute;ment aux recommandations de la CNIL, la dur&eacute;e maximale de conservation des cookies est de 13 mois au maximum apr&egrave;s leur premier d&eacute;p&ocirc;t dans le terminal de l&#39;Utilisateur, tout comme la dur&eacute;e de la validit&eacute; du consentement de l&rsquo;Utilisateur &agrave; l&rsquo;utilisation de ces cookies. La dur&eacute;e de vie des cookies n&rsquo;est pas prolong&eacute;e &agrave; chaque visite. Le consentement de l&rsquo;Utilisateur devra donc &ecirc;tre renouvel&eacute; &agrave; l&#39;issue de ce d&eacute;lai.</span></p>
    <p class="c3"><span class="c0">Finalit&eacute; cookies</span></p>
    <p class="c3"><span class="c4">Les cookies peuvent &ecirc;tre utilis&eacute;s pour des fins statistiques notamment pour optimiser les services rendus &agrave; l&#39;Utilisateur, &agrave; partir du traitement des informations concernant la fr&eacute;quence d&#39;acc&egrave;s, la personnalisation des pages ainsi que les op&eacute;rations r&eacute;alis&eacute;es et les informations consult&eacute;es.</span></p>
    <p class="c3"><span class="c4">Vous &ecirc;tes inform&eacute; que l&#39;&Eacute;diteur est susceptible de d&eacute;poser des cookies sur votre terminal. Le cookie enregistre des informations relatives &agrave; la navigation sur le service (les pages que vous avez consult&eacute;es, la date et l&#39;heure de la consultation...) que nous pourrons lire lors de vos visites ult&eacute;rieures.</span></p>
    <p class="c3"><span class="c0">Droit de l&#39;Utilisateur de refuser les cookies </span></p>
    <p class="c3"><span class="c4">Vous reconnaissez avoir &eacute;t&eacute; inform&eacute; que l&#39;&Eacute;diteur peut avoir recours &agrave; des cookies. Si vous ne souhaitez pas que des cookies soient utilis&eacute;s sur votre terminal, la plupart des navigateurs vous permettent de d&eacute;sactiver les cookies en passant par les options de r&eacute;glage.</span></p>
    <p class="c2"><span class="c0">Conservation des donn&eacute;es techniques</span></p>
    <p class="c3"><span class="c0">Dur&eacute;e de conservation des donn&eacute;es techniques </span></p>
    <p class="c3"><span class="c4">Les donn&eacute;es techniques sont conserv&eacute;es pour la dur&eacute;e strictement n&eacute;cessaire &agrave; la r&eacute;alisation des finalit&eacute;s vis&eacute;es ci-avant.</span></p>
    <p class="c2"><span class="c0">D&eacute;lai de conservation des donn&eacute;es personnelles et d&#39;anonymisation</span></p>
    <p class="c3"><span class="c0">Conservation des donn&eacute;es pendant la dur&eacute;e de la relation contractuelle </span></p>
    <p class="c3"><span class="c4">Conform&eacute;ment &agrave; l&#39;article 6-5&deg; de la loi n&deg;78-17 du 6 janvier 1978 relative &agrave; l&#39;informatique, aux fichiers et aux libert&eacute;s, les donn&eacute;es &agrave; caract&egrave;re personnel faisant l&#39;objet d&#39;un traitement ne sont pas conserv&eacute;es au-del&agrave; du temps n&eacute;cessaire &agrave; l&#39;ex&eacute;cution des obligations d&eacute;finies lors de la conclusion du contrat ou de la dur&eacute;e pr&eacute;d&eacute;finie de la relation contractuelle.</span></p>
    <p class="c3"><span class="c0">Conservation des donn&eacute;es anonymis&eacute;es au del&agrave; de la relation contractuelle / apr&egrave;s la suppression du compte </span></p>
    <p class="c3"><span class="c4">Nous conservons les donn&eacute;es personnelles pour la dur&eacute;e strictement n&eacute;cessaire &agrave; la r&eacute;alisation des finalit&eacute;s d&eacute;crites dans les pr&eacute;sentes CGU. Au-del&agrave; de cette dur&eacute;e, elles seront anonymis&eacute;es et conserv&eacute;es &agrave; des fins exclusivement statistiques et ne donneront lieu &agrave; aucune exploitation, de quelque nature que ce soit.</span></p>
    <p class="c3"><span class="c0">Suppression des donn&eacute;es apr&egrave;s suppression du compte </span></p>
    <p class="c3"><span class="c4">Des moyens de purge de donn&eacute;es sont mis en place afin d&#39;en pr&eacute;voir la suppression effective d&egrave;s lors que la dur&eacute;e de conservation ou d&#39;archivage n&eacute;cessaire &agrave; l&#39;accomplissement des finalit&eacute;s d&eacute;termin&eacute;es ou impos&eacute;es est atteinte. Conform&eacute;ment &agrave; la loi n&deg;78-17 du 6 janvier 1978 relative &agrave; l&#39;informatique, aux fichiers et aux libert&eacute;s, vous disposez par ailleurs d&#39;un droit de suppression sur vos donn&eacute;es que vous pouvez exercer &agrave; tout moment en prenant contact avec l&#39;&Eacute;diteur.</span></p>
    <p class="c3"><span class="c0">Suppression des donn&eacute;es apr&egrave;s 3 ans d&#39;inactivit&eacute; </span></p>
    <p class="c3"><span class="c4">Pour des raisons de s&eacute;curit&eacute;, si vous ne vous &ecirc;tes pas authentifi&eacute; sur le Familipsy.academy ou Familipsy.com pendant une p&eacute;riode de trois ans, vous recevrez un e-mail vous invitant &agrave; vous connecter dans les plus brefs d&eacute;lais, sans quoi vos donn&eacute;es seront supprim&eacute;es de nos bases de donn&eacute;es.</span></p>
    <p class="c2"><span class="c0">Suppression du compte</span></p>
    <p class="c3"><span class="c0">Suppression du compte &agrave; la demande </span></p>
    <p class="c3"><span class="c4">L&#39;Utilisateur a la possibilit&eacute; de supprimer son Compte &agrave; tout moment, par simple demande &agrave; l&#39;&Eacute;diteur OU par le menu de suppression de Compte pr&eacute;sent dans les param&egrave;tres du Compte le cas &eacute;ch&eacute;ant.</span></p>
    <p class="c3"><span class="c0">Suppression du compte en cas de violation des CGU </span></p>
    <p class="c3"><span class="c4">En cas de violation d&#39;une ou de plusieurs dispositions des CGU ou de tout autre document incorpor&eacute; aux pr&eacute;sentes par r&eacute;f&eacute;rence, l&#39;&Eacute;diteur se r&eacute;serve le droit de mettre fin ou restreindre sans aucun avertissement pr&eacute;alable et &agrave; sa seule discr&eacute;tion, votre usage et acc&egrave;s aux services, &agrave; votre compte et &agrave; tous les Sites.</span></p>
    <p class="c2"><span class="c0">Indications en cas de faille de s&eacute;curit&eacute; d&eacute;cel&eacute;e par l&#39;&Eacute;diteur</span></p>
    <p class="c3"><span class="c0">Information de l&#39;Utilisateur en cas de faille de s&eacute;curit&eacute; </span></p>
    <p class="c3"><span class="c4">Nous nous engageons &agrave; mettre en oeuvre toutes les mesures techniques et organisationnelles appropri&eacute;es afin de garantir un niveau de s&eacute;curit&eacute; adapt&eacute; au regard des risques d&#39;acc&egrave;s accidentels, non autoris&eacute;s ou ill&eacute;gaux, de divulgation, d&#39;alt&eacute;ration, de perte ou encore de destruction des donn&eacute;es personnelles vous concernant. Dans l&#39;&eacute;ventualit&eacute; o&ugrave; nous prendrions connaissance d&#39;un acc&egrave;s ill&eacute;gal aux donn&eacute;es personnelles vous concernant stock&eacute;es sur nos serveurs ou ceux de nos prestataires, ou d&#39;un acc&egrave;s non autoris&eacute; ayant pour cons&eacute;quence la r&eacute;alisation des risques identifi&eacute;s ci-dessus, nous nous engageons &agrave; :</span></p>
    <ul class="c9 lst-kix_pj7sktmenkg-0 start">
        <li class="c8"><span class="c4">Vous notifier l&#39;incident dans les plus brefs d&eacute;lais ;</span></li>
        <li class="c8"><span class="c4">Examiner les causes de l&#39;incident et vous en informer ;</span></li>
        <li class="c8"><span class="c4">Prendre les mesures n&eacute;cessaires dans la limite du raisonnable afin d&#39;amoindrir les effets n&eacute;gatifs et pr&eacute;judices pouvant r&eacute;sulter dudit incident</span></li>
    </ul>
    <p class="c3 c7"><span class="c4"></span></p>
    <p class="c3"><span class="c0">Limitation de la responsabilit&eacute; </span></p>
    <p class="c3"><span class="c4">En aucun cas les engagements d&eacute;finis au point ci-dessus relatifs &agrave; la notification en cas de faille de s&eacute;curit&eacute; ne peuvent &ecirc;tre assimil&eacute;s &agrave; une quelconque reconnaissance de faute ou de responsabilit&eacute; quant &agrave; la survenance de l&#39;incident en question.</span></p>
    <p class="c2"><span class="c0">Transfert des donn&eacute;es personnelles &agrave; l&#39;&eacute;tranger</span></p>
    <p class="c3"><span class="c0">Pas de transfert en dehors de l&#39;Union europ&eacute;enne </span></p>
    <p class="c3"><span class="c4">L&#39;&Eacute;diteur s&#39;engage &agrave; ne pas transf&eacute;rer les donn&eacute;es personnelles de ses Utilisateurs en dehors de l&#39;Union europ&eacute;enne.</span></p>
    <p class="c2"><span class="c0">Modification des CGU et de la politique de confidentialit&eacute;</span></p>
    <p class="c3"><span class="c0">En cas de modification des pr&eacute;sentes CGU, engagement de ne pas baisser le niveau de confidentialit&eacute; de mani&egrave;re substantielle sans l&#39;information pr&eacute;alable des personnes concern&eacute;es </span></p>
    <p class="c3"><span class="c4">Nous nous engageons &agrave; vous informer en cas de modification substantielle des pr&eacute;sentes CGU, et &agrave; ne pas baisser le niveau de confidentialit&eacute; de vos donn&eacute;es de mani&egrave;re substantielle sans vous en informer et obtenir votre consentement.</span></p>
    <p class="c2"><span class="c0">Droit applicable et modalit&eacute;s de recours</span></p>
    <p class="c3"><span class="c0">Application du droit fran&ccedil;ais (l&eacute;gislation CNIL) et comp&eacute;tence des tribunaux </span></p>
    <p class="c3"><span class="c4">Les pr&eacute;sentes CGU et votre utilisation de Familipsy.academy et Familipsy.com sont r&eacute;gies et interpr&eacute;t&eacute;es conform&eacute;ment aux lois de France, et notamment &agrave; la Loi n&deg; 78-17 du 6 janvier 1978 relative &agrave; l&#39;informatique, aux fichiers et aux libert&eacute;s. Le choix de la loi applicable ne porte pas atteinte &agrave; vos droits en tant que consommateur conform&eacute;ment &agrave; la loi applicable de votre lieu de r&eacute;sidence. Si vous &ecirc;tes un consommateur, vous et nous acceptons de se soumettre &agrave; la comp&eacute;tence non-exclusive des juridictions fran&ccedil;aises, ce qui signifie que vous pouvez engager une action relative aux pr&eacute;sentes CGU en France ou dans le pays de l&#39;UE dans lequel vous vivez. Si vous &ecirc;tes un professionnel, toutes les actions &agrave; notre encontre doivent &ecirc;tre engag&eacute;es devant une juridiction en France. </span></p>
    <p class="c3 c7"><span class="c4"></span></p>
    <p class="c3"><span class="c4">En cas de litige, les parties chercheront une solution amiable avant toute action judiciaire. En cas d&#39;&eacute;chec de ces tentatives, toutes contestations &agrave; la validit&eacute;, l&#39;interpr&eacute;tation et / ou l&#39;ex&eacute;cution des pr&eacute;sentes CGU devront &ecirc;tre port&eacute;es m&ecirc;me en cas de pluralit&eacute; des d&eacute;fendeurs ou d&#39;appel en garantie, devant les tribunaux fran&ccedil;ais.</span></p>
    <p class="c2"><span class="c0">Portabilit&eacute; des donn&eacute;es</span></p>
    <p class="c3"><span class="c4">Familipsy s&#39;engage &agrave; vous offrir la possibilit&eacute; de vous faire restituer l&#39;ensemble des donn&eacute;es vous concernant sur simple demande. L&#39;Utilisateur se voit ainsi garantir une meilleure ma&icirc;trise de ses donn&eacute;es, et garde la possibilit&eacute; de les r&eacute;utiliser. Ces donn&eacute;es devront &ecirc;tre fournies dans un format ouvert et ais&eacute;ment r&eacute;utilisable.</span></p>
    <p class="c1"><span class="c4"></span></p>
  </div>
