import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthenticationService } from '../shared/providers/authentication/authentication.service';
import { CONFIGURATION } from '../configurations/configurations';
var HttpsRequestInterceptor = /** @class */ (function () {
    function HttpsRequestInterceptor(authService) {
        this.authService = authService;
    }
    HttpsRequestInterceptor.prototype.intercept = function (req, next) {
        var dupReq;
        var cookie = this.authService.getCookie();
        var session = sessionStorage.getItem(CONFIGURATION.session_storage);
        if (cookie !== null) {
            dupReq = req.clone({ headers: req.headers.set(CONFIGURATION.jwt_token, cookie) });
        }
        else if (session !== null) {
            dupReq = req.clone({ headers: req.headers.set(CONFIGURATION.jwt_token, session) });
        }
        else {
            dupReq = req.clone();
        }
        return next.handle(dupReq);
    };
    return HttpsRequestInterceptor;
}());
export { HttpsRequestInterceptor };
var InterceptorModule = /** @class */ (function () {
    function InterceptorModule() {
    }
    return InterceptorModule;
}());
export { InterceptorModule };
