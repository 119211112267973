import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit  {
  
  @Input() modalTitle: string = null;
  @Input() modalContent: string;
  @Input() modalButton: string = "Fermer";
  @Input() closeAction: any = null;
  upgrade = false;
  
  constructor() { }

  ngOnInit(): void {

  }
}
