<section id="signin-section" class="">
  <div class="container">
    <h1 class="session-title">Réinitialisation<div></div></h1>
    <div class="row">
      <div class="col-md-12" *ngIf="!isSend">
        <form class="custom-form p-4 bg-white rounded shadow" [formGroup]="resetForm" (ngSubmit)="resetPassword()">
          <div class="row">
            <div class="form-group">
              <label for="username" class="bold dark-color">Mot de passe</label>
              <input type="password" class="form-control dark-color" style="background: #eef0f8;" id="username" formControlName="password">
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <label for="pwd" class="bold dark-color">Confirmez le</label>
              <input type="password" class="form-control dark-color" style="background: #eef0f8;" id="pwd" formControlName="password_confirmation">
            </div>
          </div>

          <div class="button-section mt-3">
              <button class="main-button-round" type="submit">Réinitialiser</button>
          </div>

        </form>

      </div>

      <div class="col-md-12" *ngIf="isSend">
        Redirection dans {{timer}} secondes.
      </div>
      <!-- <div class="col-md-6">
        <h1 class="dark-color bold familipsy">Familipsy</h1>
        <p class="dark-color">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </div> -->
    </div>
  </div>
</section>
