<div id="counselors-detail-section" class="contentWrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
        <div class="shadow bg-light rounded position-relative">
          <img src="{{ environment.uploadPath + 'users/' + counselor?.image + '?random=' + random }}" class="card-img-top" alt="{{ counselor?.firstname + ' ' + counselor?.lastname }}" />
          <div class="p-3">
            <div class="row mt-4">
              <div class="col">
                <h3 class="text-center bold m-0 p-0">{{ counselor?.firstname | titlecase }} {{ counselor?.lastname | uppercase }}</h3>
                <div class="shortDescription text-center">{{ counselor?.short_description }}</div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col text-center">
                <i style="font-size: 30px;" class="fa-star" [class.text-secondary-light]="counselor?.rate<1" [class.text-warning]="counselor?.rate>=1" [class.far]="counselor?.rate<1" [class.fas]="counselor?.rate>=1"></i>
                <i style="font-size: 30px;" class="fa-star" [class.text-secondary-light]="counselor?.rate<2" [class.text-warning]="counselor?.rate>=2" [class.far]="counselor?.rate<2" [class.fas]="counselor?.rate>=2"></i>
                <i style="font-size: 30px;" class="fa-star" [class.text-secondary-light]="counselor?.rate<3" [class.text-warning]="counselor?.rate>=3" [class.far]="counselor?.rate<3" [class.fas]="counselor?.rate>=3"></i>
                <i style="font-size: 30px;" class="fa-star" [class.text-secondary-light]="counselor?.rate<4" [class.text-warning]="counselor?.rate>=4" [class.far]="counselor?.rate<4" [class.fas]="counselor?.rate>=4"></i>
                <i style="font-size: 30px;" class="fa-star" [class.text-secondary-light]="counselor?.rate<5" [class.text-warning]="counselor?.rate>=5" [class.far]="counselor?.rate<5" [class.fas]="counselor?.rate>=5"></i>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col text-center">
                <a class="main-button-round" *ngIf="!agenda" (click)="showAgenda()">Prendre RDV</a>
                <a class="main-button-round" *ngIf="agenda" (click)="agenda = false">Profil</a>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="specification-section" *ngIf="counselor?.diplomas_user.length">
                  <hr class="mt-5 mb-5">
                  <div class="row">
                    <div class="col-md-12 mb-3 bold">
                      Diplômes
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-2" *ngFor="let item of counselor?.diplomas_user" placement="top" ngbTooltip="{{ item?.category_diploma?.name }} - {{ item?.speciality }}">
                      <div class="p-2 bg-light rounded">
                        <img src="{{environment.uploadAssets }}images/diplome-certifie.png" class="d-inline-block mr-2 float-left" width="50px">
                        <div class="float-left">{{ item?.category_diploma?.name }}<br/>{{ item?.date_obtained | date:'dd/MM/yyyy' }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="specification-section" *ngIf="counselor?.language_user.length">
                  <hr class="mt-5 mb-5">
                  <div class="row">
                    <div class="col-md-12 mb-3 bold">
                      Langues parlés
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" *ngFor="let item of counselor?.language_user">
                      {{ item?.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="video != undefined">
              <div class="col">
                <hr class="mt-5 mb-5">
                <iframe class="video w-100" [src]='video' frameborder="0" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-8 col-lg-9">
        <div class="">
          <div class="counselorInfo" *ngIf="!agenda">
            <span *ngIf="!isFavourite" class="float-left mr-3 pink-color cursor-pointer" style="font-size: 25px" (click)="addToFavourites()" placement="top" ngbTooltip="Ajouter aux favoris"><i class="far fa-heart"></i></span>
            <h1 class="text-left m-0 p-0">{{ counselor?.firstname | titlecase }} {{ counselor?.lastname | uppercase }}</h1>
            <h3 class="shortDescription text-left" [innerHtml]="counselor?.short_description"></h3>
            <div class="text-justify mt-5" [innerHtml]="counselor?.long_description"></div>
            <div class="row mt-5 mb-5">
              <div class="col-12 col-sm-12 col-md-12 col-lg-4 text-center mb-2">
                <a class="btn bg-white rounded shadow w-100 black-color" [class.dark-color-bg]="sectionSelected == 1" (click)="sectionSelected = 1">Spécialités</a>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-4 text-center mb-2">
                <a class="btn bg-white rounded shadow w-100 black-color" [class.dark-color-bg]="sectionSelected == 2" (click)="sectionSelected = 2">Domaines d'activités</a>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-4 text-center mb-2">
                <a class="btn bg-white rounded shadow w-100 black-color" [class.dark-color-bg]="sectionSelected == 3" (click)="sectionSelected = 3">Approches théoriques</a>
              </div>
            </div>
            <div class="specification-section" *ngIf="counselor?.specialities.length && sectionSelected == 1">
              <div class="row">
                <div class="col-md-12 mb-3 bold">
                  Spécialités
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-6 col-lg-6" *ngFor="let item of counselor?.specialities">
                  <i class="fas fa-chevron-right pink-color"></i>
                  {{ item?.name }}
                </div>
              </div>
            </div>
            <div class="problematics-section" *ngIf="counselor?.problematic_user.length && sectionSelected == 2">
              <div class="row">
                <div class="col-md-12 mb-3 bold">
                  Domaines d'activités
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-6 col-lg-6" *ngFor="let item of counselor?.problematic_user">
                  <i class="fas fa-chevron-right pink-color"></i>
                  {{ item?.user_equivalent }}
                </div>
              </div>
            </div>
            <div class="theorical-approaches-section" *ngIf="counselor?.theorical_approaches && sectionSelected ==3">
              <div class="row">
                <div class="col-md-12 mb-3 bold">
                  Approches théoriques
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-6 col-lg-6" *ngFor="let item of counselor?.theorical_approaches">
                  <i class="fas fa-chevron-right pink-color"></i>
                  {{ item?.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="counselorAgenda" *ngIf="agenda">
            <div class="mb-4">
              Cliquez sur le jour puis le créneau qui vous convient.<br/>
              Renseignez vos informations de paiement et votre motif de rendez-vous puis validez. Un mail de confirmation vous sera envoyé.<br/>
              Retrouvez vos informations de connexion sur votre tableau de bord.
            </div>
            <div class="row text-center">
              <div class="col-md-6 text-left">
                <div class="btn-group">
                  <div
                    class="btn"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                    (click)="getAppointments()"
                  >
                    <i class="fas fa-chevron-left"></i>
                  </div>
                  <div
                    class="btn"
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                    (click)="getAppointments()"
                  >
                    Aujourd'hui
                  </div>
                  <div
                    class="btn"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                    (click)="getAppointments()"
                  >
                  <i class="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
              <div class="col-md-6 text-right">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'fr' }}</h3>
              </div>
              <!-- <div class="col-md-4">
                <div class="btn-group">
                  <div
                    class="btn btn-primary"
                    (click)="setView(CalendarView.Month)"
                    [class.active]="view === CalendarView.Month"
                  >
                    Mois
                  </div>
                  <div
                    class="btn btn-primary"
                    (click)="setView(CalendarView.Week)"
                    [class.active]="view === CalendarView.Week"
                  >
                    Semaine
                  </div>
                  <div
                    class="btn btn-primary"
                    (click)="setView(CalendarView.Day)"
                    [class.active]="view === CalendarView.Day"
                  >
                    Jour
                  </div>
                </div>
              </div> -->
            </div>
            <div class="shadow mt-5 mb-5 bg-white rounded">
              <div [ngSwitch]="view">
                <mwl-calendar-month-view
                  *ngSwitchCase="CalendarView.Month"
                  [viewDate]="viewDate"
                  [events]="events"
                  [refresh]="refresh"
                  [activeDayIsOpen]="activeDayIsOpen"
                  (dayClicked)="dayClicked($event.day)"
                  (eventClicked)="handleEvent('Clicked', $event.event)"
                  (eventTimesChanged)="eventTimesChanged($event)"
                  [weekStartsOn]="1"
                >
                </mwl-calendar-month-view>
                <mwl-calendar-week-view
                  *ngSwitchCase="CalendarView.Week"
                  [viewDate]="viewDate"
                  [events]="events"
                  [refresh]="refresh"
                  (eventClicked)="handleEvent('Clicked', $event.event)"
                  (eventTimesChanged)="eventTimesChanged($event)"
                  [weekStartsOn]="1"
                >
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                  *ngSwitchCase="CalendarView.Day"
                  [viewDate]="viewDate"
                  [events]="events"
                  [refresh]="refresh"
                  (eventClicked)="handleEvent('Clicked', $event.event)"
                  (eventTimesChanged)="eventTimesChanged($event)"
                >
                </mwl-calendar-day-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modalContent let-close="close">
  <app-member-only [closeAction]="close"></app-member-only>
</ng-template>

<ng-template #modalBook let-close="close">
  <app-book [closeAction]="close" [modalData]="modalData"></app-book>
</ng-template>