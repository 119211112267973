import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api/api.service';
import { environment} from '../../../../../environments/environment';
import { FlashMessagesService } from 'angular2-flash-messages';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-counselors-consultations',
  templateUrl: './counselors-consultations.component.html',
  styleUrls: ['./counselors-consultations.component.css']
})
export class CounselorsConsultationsComponent implements OnInit {
  environment = environment;
  openedCards = -1;
  me;
  appointments: any;
  offset: any;
  currentPage = 1;
  url = "auth/counselor/appointments/statusAppointment/";
  status = "all";
  page = 1;

  constructor(
    private api : ApiService,
    private modalService: NgbModal,
    private _flashMessagesService: FlashMessagesService
  ) {
   }

  ngOnInit() {
    this.offset = new Date().getTimezoneOffset();
    this.getAppointments();
  }

  getAppointments(page = 0) {
    if(page) this.page = page;
    const url = this.url + this.status + ((this.page) ? ("?page=" + this.page) : "");
    this.api.get(url).subscribe((data: any) => {
      this.appointments = data;
      this.appointments.data.forEach((item,key)=>{
        let explD = item.due_date.split(" ")[0].split("-");
        let explT = item.due_date.split(" ")[1].split(":");
        let dueDate = new Date(new Date(explD[0], explD[1]-1, explD[2], explT[0], explT[1], explT[2]).getTime() - (this.offset * 60000));
        this.appointments.data[key].due_date = dueDate;
      })
    })
    
  }

  cancelAppointment(id) {
    this.api.get("cancel/appointment/"+id).subscribe(data=>{
      this.getAppointments();
    })
  }

  finishAppointment(id) {
    this.api.get("appointments/finish/"+id).subscribe(data=>{
      this.getAppointments();
    })
  }

  myIndex;
  closeResult = '';
  appointmentId;
  open(content, id, index) {
    console.log("this.appointments.data[index]", this.appointments.data[index]);
    this.appointmentId = id;
    this.myIndex = index;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  reportAppointment(reason){
    const data = new FormData();

    data.append('from_appointment_id', this.appointmentId);
    data.append('reason', reason);

    this.api.post('store/reported_appointment', data).subscribe( data => {
      this._flashMessagesService.show('Votre demande a bien été envoyé, veuillez patientez avant de recevoir votre nouvelle date de consultation!', { cssClass: 'alert-success', timeout: 4000 });

    })
  }

}
