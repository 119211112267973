import { Component, OnInit, ViewChild, ViewChildren, ElementRef,   QueryList, HostListener
} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AppComponent } from '../../app.component';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.css']
})
export class GuideComponent implements OnInit {

  chatBoxOpen = false;
  activateChatbox = true;
  step = 0;
  chatScript = require('../../../assets/guideScript.json');
  chatBoxHeight = "500px";
  chatBoxBodyHeight = "400px";
  isMobile = this.deviceService.isMobile();
  messages = [];
  @ViewChild('scrollChatBody') scrollFrame: ElementRef;

  answers: any = [];
  problematics = [];

  constructor(private api: ApiService, private deviceService: DeviceDetectorService, private appComp: AppComponent) { }

  ngOnInit() {
    this.appComp.activateChatbox = false;
    window.scrollTo(0, 166);
    this.getProblematics();
    this.sendMessageBot();
  }

  scrollToBottom() {
    // this.scrollFrame.nativeElement.scrollTop = this.scrollFrame.nativeElement.scrollHeight;
    window.scrollTo(0, 300);
    document.getElementById("bodyChatBox").scrollTop = document.getElementById("bodyChatBox").scrollHeight;
  }

  printChatLoader() {
    this.printMessageToChatBox({
        "text": "<img src='assets/imgs/chat-loading.gif' />",
        "who": "bot bubble",
        "bubble": true
    })
  }

  sendMessageBot() {
    this.printChatLoader();

    setTimeout(() => {
      this.messages.pop(); //remove bubble

      var message = this.chatScript[this.step];

      this.printMessageToChatBox(message);

      setTimeout(() => {
        if(message.next){
          this.step = message.next;
          this.sendMessageBot();
        } else if(message.method){
          this.methodToLaunch(message);
        }
      }, 1000)
    },2000);
  }

  printMessageToChatBox(message) {
    this.messages.push(message);
  }

  makeChoice(choice) {
    console.log("makeChoice");
    console.log("choice", choice);
    if(choice.link){
      window.location = choice.link;
    } else if(choice.checkboxesChoice){
      console.log("checkboxesChoice");
      console.log("this.answers", this.answers);
      const result = this.answers.filter(answer => answer.level == choice.level);
      console.log("result", result);
      var text = [];
      result.forEach(element => {
        text.push(element.text);
      });
      choice.text = text.join(", ");
      this.messages.pop(); //remove bubble
      this.printNext(choice);
    } else if(choice.level){
      this.answers.push({"level": choice.level, "id": choice.id, "text": choice.text});
      console.log("this.answers", this.answers);
      this.messages.pop(); //remove bubble
      this.printNext(choice);
    } else {
      this.messages.pop(); //remove bubble
      this.printNext(choice);
    }
  }

  printNext(choice){
    this.step = choice.next;
    
    this.printMessageToChatBox(choice);

    setTimeout(() => {
      this.sendMessageBot();
    }, 1000)
  }
  
  checkifAnswerIsSelected(level, id) {
    return this.answers.findIndex(answer => answer.level === level && answer.id === id);
  }

  toggleClassAnswer(level, id) {
    if(this.checkifAnswerIsSelected(level, id) != -1) return 'checkbox selected';
    else return 'checkbox';
  }

  toggleAnwser(level, id, text) {
    const indexExist = this.checkifAnswerIsSelected(level, id);
    
    if(indexExist != -1) this.answers.splice(indexExist,1);
    else this.answers.push({"level": level, "id": id, "text": text});

  }

  getProblematics(){
    this.api.get("list/problematics").subscribe((data: any[])=>{
      console.log("problematics", data);
      this.problematics.push(data.slice(0, data.length/2));
      this.problematics.push(data.slice((data.length/2)+1));
    })
  }

  methodToLaunch(choice){
    console.log("methodToLaunch");
    console.log("choice", choice);
    switch(choice.method){
      case "problematics": this.printProblematics(choice); break;
      case "counsellorType": this.printCounsellorType(choice); break;
      case "counsellorGender": this.printCounsellorGender(choice); break;
    }
  }

  printProblematics(choice){

    var newChoice = {
      "who": "checkboxes",
      "checkboxes": null
    };

    var checkboxes = [];
    this.problematics[choice.index].forEach(element => {
      checkboxes.push({
        "text": element.user_equivalent,
        "level": choice.level,
        "id": element.id,
        "who": "human"
      });
    });
    checkboxes.push({
        "text": "Je valide mes choix",
        "next": choice.next,
        "who": "human",
        "level": choice.level,
        "checkboxesChoice": 1
    })
    newChoice.checkboxes = checkboxes;
    this.printMessageToChatBox(newChoice)
  }

  printCounsellorType(choice){
    console.log("printCounsellorType");
    this.answers.push({"level": choice.level, "id": choice.id, "text": choice.text});
    console.log("this.answers", this.answers);
    this.messages.pop(); //remove bubble
    this.printNext(choice);
  }

  printCounsellorGender(choice){
    console.log("printCounsellorType");
    this.answers.push({"level": choice.level, "id": choice.id, "text": choice.text});
    console.log("this.answers", this.answers);
    this.messages.pop(); //remove bubble
    this.printNext(choice);
  }
}
