import { OnInit } from '@angular/core';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
var HowItWorksComponent = /** @class */ (function () {
    function HowItWorksComponent(scrollTopService) {
        this.scrollTopService = scrollTopService;
    }
    HowItWorksComponent.prototype.ngOnInit = function () {
        this.scrollTopService.setScrollTop();
    };
    return HowItWorksComponent;
}());
export { HowItWorksComponent };
