import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class PremiumService {
  private creatingUserSource = new BehaviorSubject(null);
  currentCreatingUser = this.creatingUserSource.asObservable();

  constructor() { }

  changeUser(creatingUser: User) {
    this.creatingUserSource.next(creatingUser)
  }
}
