var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GenericProvider } from '../genericprovider.provider';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Conseiller } from '../../../model/conseiller.model';
import { Diplome } from '../../../model/diplome.model';
import { Slot } from '../../../model/slot.model';
import { Particulier } from '../../../model/particulier.model';
var ProfileService = /** @class */ (function (_super) {
    __extends(ProfileService, _super);
    function ProfileService(http, cookieService, _flashMessagesService, router) {
        var _this = _super.call(this, _flashMessagesService) || this;
        _this.http = http;
        _this.cookieService = cookieService;
        _this._flashMessagesService = _flashMessagesService;
        _this.router = router;
        return _this;
    }
    ProfileService.prototype.loadProfileInformations = function (advisors) {
        if (advisors) {
            return this.http.get(this.api_url + '/advisors/authenticated').pipe(catchError(this.handleError('ProfileProvider : loadProfileInformations(' + advisors + ')', new Conseiller())));
        }
        else {
            return this.http.get(this.api_url + '/customers/authenticated').pipe(catchError(this.handleError('ProfileProvider : loadProfileInformations(' + advisors + ')')));
        }
    };
    ProfileService.prototype.uploadProfilePic = function (file) {
        var formData = new FormData();
        formData.append('file', file);
        return this.http.put(this.api_url + '/advisors/profile-pic', formData).pipe(catchError(this.handleError('ProfileProvider : uploadProfilePic(' + file + ')', '')));
    };
    ProfileService.prototype.updateAdivsorProfileInformation = function (profile) {
        return this.http.put(this.api_url + '/advisors', profile).pipe(catchError(this.handleError('ProfileProvider : updateAdivsorProfileInformation(' + profile + ')', new Conseiller())));
    };
    ProfileService.prototype.updateCustomerProfileInformation = function (profile) {
        return this.http.put(this.api_url + '/customers', profile).pipe(catchError(this.handleError('ProfileProvider : updateCustomerProfileInformation(' + profile + ')', new Particulier())));
    };
    ProfileService.prototype.createDiploma = function (diplome, file) {
        var formData = new FormData();
        formData.append('file', file);
        formData.append('diplomaJson', JSON.stringify(diplome));
        return this.http.post(this.api_url + '/advisors/diplomas', formData).pipe(catchError(this.handleError('ProfileProvider : createDiploma(' + formData + ')', new Diplome())));
    };
    ProfileService.prototype.updateDiploma = function (diplome, file) {
        if (file != undefined) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('diplomaJson', JSON.stringify(diplome));
            return this.http.post(this.api_url + '/advisors/diplomas/with-file', formData).pipe(catchError(this.handleError('FormationService : updateFormation(' + formData + ')', new Diplome())));
        }
        else {
            return this.http.put(this.api_url + '/advisors/diplomas', diplome).pipe(catchError(this.handleError('FormationService : updateFormation(' + diplome + ')', new Diplome())));
        }
    };
    ProfileService.prototype.getSlots = function () {
        return this.http.get(this.api_url + '/advisors/slots').pipe(catchError(this.handleError('ProfileProvider : getSlots()', [])));
    };
    ProfileService.prototype.addSlot = function (slot) {
        return this.http.post(this.api_url + '/advisors/slots', slot).pipe(catchError(this.handleError('ProfileProvider : addSlot(' + slot + ')', new Slot())));
    };
    ProfileService.prototype.deleteSlot = function (id) {
        return this.http.delete(this.api_url + '/advisors/slots/' + id).pipe(catchError(this.handleError('ProfileProvider : deleteSlot(' + id + ')')));
    };
    ProfileService.prototype.deleteProfile = function () {
        return this.http.delete(this.api_url + '/advisors').pipe(catchError(this.handleError('ProfileProvider : deleteSlot()')));
    };
    return ProfileService;
}(GenericProvider));
export { ProfileService };
