import { OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../services/api/api.service';
var CounselorsFeedbacksComponent = /** @class */ (function () {
    function CounselorsFeedbacksComponent(api, modalService) {
        this.api = api;
        this.modalService = modalService;
        this.environment = environment;
        this.currentPage = 1;
    }
    CounselorsFeedbacksComponent.prototype.ngOnInit = function () {
        this.offset = new Date().getTimezoneOffset();
        this.getAll();
    };
    CounselorsFeedbacksComponent.prototype.getAll = function (page) {
        var _this = this;
        if (page === void 0) { page = 0; }
        var url = "feedbacks/counselors" + ((page) ? ("?page=" + page) : "");
        this.api.get(url).subscribe(function (data) {
            _this.records = data;
            _this.records.data.forEach(function (item, index) {
                var explD = item.appointment.due_date.split(" ")[0].split("-");
                var explT = item.appointment.due_date.split(" ")[1].split(":");
                var dueDate = new Date(new Date(explD[0], explD[1] - 1, explD[2], explT[0], explT[1], explT[2]).getTime() - (_this.offset * 60000));
                _this.records.data[index].appointment.due_date = dueDate;
            });
        });
    };
    CounselorsFeedbacksComponent.prototype.showMessage = function (modalContent, message) {
        this.message = message;
        this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title' }).result.then(function (result) {
            // this.closeResult = `Closed with: ${result}`;
        }, function (reason) {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    };
    return CounselorsFeedbacksComponent;
}());
export { CounselorsFeedbacksComponent };
