import { Diplome } from './diplome.model';
import { ReferentialLabel } from './referential-label.model';

export class Conseiller {
    profilePic: any;
    profilePicURL: String;
    profile: {
        id: String;
        lastName: String;
        firstName: String;
        nickName: String;
        email: String;
        password: String;
        shortDescription: String;
        longDescription: String;
        videoURL: String;
    };
    situation: {
        specialties: ReferentialLabel[];
        workedDomains: ReferentialLabel[];
        otherWorkedDomains: String[];
        diplomas: Diplome[];
        theoricalApproaches: ReferentialLabel[];
        languages: ReferentialLabel[];
        price: Number;
    };
    score: Number[];
    feedback: any[];
    iban: String;
}
