/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap/collapse/collapse";
import * as i4 from "./faq.component";
import * as i5 from "../../shared/providers/scrolltop/scroll-top-service.service";
import * as i6 from "../../services/api/api.service";
var styles_FAQComponent = [i0.styles];
var RenderType_FAQComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FAQComponent, data: {} });
export { RenderType_FAQComponent as RenderType_FAQComponent };
function View_FAQComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "p-3 mb-1 cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.faqCategoryId = _v.context.$implicit.id;
        var pd_0 = (_co.getFaqs() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "dark-color-bg": 0 }), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "p-3 mb-1 cursor-pointer"; var currVal_1 = _ck(_v, 2, 0, (_co.faqCategoryId == _v.context.$implicit.id)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 3, 0, currVal_2); }); }
function View_FAQComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col-md-12 mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-block p-3 text-left rounded-0 faqQuestion"], ["type", "button"]], [[8, "id", 0], [2, "active", null], [1, "aria-expanded", 0], [1, "aria-controls", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.viewIncrement(_v.context.index, ((_v.context.$implicit == null) ? null : _v.context.$implicit.id));
        var pd_0 = ((_co.faqActive = _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "faqContent p-5"]], [[8, "id", 0], [2, "collapse", null], [2, "show", null]], null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i3.NgbCollapse, [], { collapsed: [0, "collapsed"] }, null), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = (_co.faqActive != _v.context.index); _ck(_v, 4, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_v.context.$implicit == null) ? null : _v.context.$implicit.id), ""); var currVal_1 = (_co.faqActive == _v.context.index); var currVal_2 = (_co.faqActive != _v.context.index); var currVal_3 = ("Q" + ((_v.context.$implicit == null) ? null : _v.context.$implicit.id)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.question); _ck(_v, 2, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "Q", ((_v.context.$implicit == null) ? null : _v.context.$implicit.id), ""); var currVal_6 = true; var currVal_7 = !i1.ɵnov(_v, 4).collapsed; _ck(_v, 3, 0, currVal_5, currVal_6, currVal_7); var currVal_9 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.answer); _ck(_v, 5, 0, currVal_9); }); }
export function View_FAQComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "contentWrapper"], ["id", "faqs-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Foire aux questions"])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [["id", "faqCategories"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FAQComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FAQComponent_2)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.faqCategories; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.faqs; _ck(_v, 12, 0, currVal_1); }, null); }
export function View_FAQComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq", [], null, null, null, View_FAQComponent_0, RenderType_FAQComponent)), i1.ɵdid(1, 114688, null, 0, i4.FAQComponent, [i5.ScrollTopService, i6.ApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FAQComponentNgFactory = i1.ɵccf("app-faq", i4.FAQComponent, View_FAQComponent_Host_0, {}, {}, []);
export { FAQComponentNgFactory as FAQComponentNgFactory };
