import { SeoService } from './../../../services/seo/seo.service';
import { ApiService } from './../../../services/api/api.service';
import { OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
var CounselorsListComponent = /** @class */ (function () {
    function CounselorsListComponent(api, seo) {
        this.api = api;
        this.seo = seo;
        this.environment = environment;
        this.random = Math.floor(Math.random() * 100);
    }
    CounselorsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setSeo();
        this.api.get('users/allCounselorsNoPag/activated').subscribe(function (data) {
            _this.counselors = data;
        });
    };
    CounselorsListComponent.prototype.setSeo = function () {
        this.seo.set({
            "title": "Les conseillers, psychologues et coachs de Familipsy Academy",
            "description": "Diplômés et sélectionnés pour leurs expertises et leurs qualités éthiques et relationnelles, nos conseillers vous aideront à vous sentir plus heureux et serein. Pratique, simple et plus économique que des séances traditionnelles, l’accompagnement en ligne Familipsy, est la solution adaptée aux besoins des parents d’aujourd’hui.",
            "og:url": this.environment.siteUrl + '/conseillers',
            "og:title": "Les conseillers, psychologues et coachs de Familipsy Academy",
            "og:description": "Diplômés et sélectionnés pour leurs expertises et leurs qualités éthiques et relationnelles, nos conseillers vous aideront à vous sentir plus heureux et serein. Pratique, simple et plus économique que des séances traditionnelles, l’accompagnement en ligne Familipsy, est la solution adaptée aux besoins des parents d’aujourd’hui.",
            "og:image": environment.uploadPath + 'images/familipsy-logo.jpg',
            "twitter:title": "Les conseillers, psychologues et coachs de Familipsy Academy",
            "twitter:description": "Diplômés et sélectionnés pour leurs expertises et leurs qualités éthiques et relationnelles, nos conseillers vous aideront à vous sentir plus heureux et serein. Pratique, simple et plus économique que des séances traditionnelles, l’accompagnement en ligne Familipsy, est la solution adaptée aux besoins des parents d’aujourd’hui."
        });
    };
    CounselorsListComponent.prototype.ceilAmount = function (amount) {
        return Math.ceil(amount);
    };
    return CounselorsListComponent;
}());
export { CounselorsListComponent };
