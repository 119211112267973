import { Particulier } from './particulier.model';
import { Slot } from './slot.model';
import { Conseiller } from './conseiller.model';

export class Session {
    id: Number;
    patient: Particulier;
    advisor: Conseiller;
    date: Slot;
    category: String;
    description: String;
    status: String;
}
