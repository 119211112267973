import { OnInit } from '@angular/core';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { NewsletterService } from '../../shared/providers/newsletters/newsletter.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api/api.service';
import { ActivatedRoute } from '@angular/router';
var ConferencesComponent = /** @class */ (function () {
    function ConferencesComponent(_activatedRoute, scrollTopService, _newsletterService, _flashMessagesService, api) {
        this._activatedRoute = _activatedRoute;
        this.scrollTopService = scrollTopService;
        this._newsletterService = _newsletterService;
        this._flashMessagesService = _flashMessagesService;
        this.api = api;
        this.environment = environment;
        this.currentPage = 1;
    }
    ConferencesComponent.prototype.subscribe = function () {
        var _this = this;
        this._newsletterService.subscribe(this.email).subscribe(function (res) {
            _this._flashMessagesService.show('Merci de votre intérêt pour notre newsletter.', { cssClass: 'alert-success' });
        });
    };
    ConferencesComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log("conferences");
        this.scrollTopService.setScrollTop();
        // console.log("this._activatedRoute.pathFromRoot", this._activatedRoute.pathFromRoot);
        this._activatedRoute.pathFromRoot[1].url.subscribe(function (val) {
            console.log("path", val);
            if (val.length == 1)
                _this.urlPart = 'all/published';
            else
                _this.urlPart = (val[1].path == "prochaines") ? "toCome" : "done";
        });
        this.getAll();
    };
    ConferencesComponent.prototype.getAll = function (page) {
        var _this = this;
        if (page === void 0) { page = 0; }
        var url = 'conferences/' + this.urlPart + ((page) ? ("?page=" + page) : "");
        this.api.get(url).subscribe(function (data) {
            _this.conferencesList = data;
            document.body.scrollTop = 150;
        });
    };
    return ConferencesComponent;
}());
export { ConferencesComponent };
