var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AuthService } from './../../../services/auth/auth.service';
import { ApiService } from './../../../services/api/api.service';
import { AfterViewInit, OnDestroy, OnInit, ElementRef, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { format } from 'date-fns';
import { Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ScrollTopService } from '../../providers/scrolltop/scroll-top-service.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { environment } from '../../../../environments/environment';
import { FlashMessagesService } from 'angular2-flash-messages';
var PaymentComponent = /** @class */ (function () {
    function PaymentComponent(scrollTopService, api, auth, cd, spinner, _flashMessagesService) {
        this.scrollTopService = scrollTopService;
        this.api = api;
        this.auth = auth;
        this.cd = cd;
        this.spinner = spinner;
        this._flashMessagesService = _flashMessagesService;
        this.environment = environment;
        this.buyDoneChange = new EventEmitter();
        this.cardHandler = this.onChange.bind(this);
        // optional parameters
        this.elementsOptions = {
            locale: 'fr'
        };
        this.step = 1;
    }
    PaymentComponent.prototype.ngOnInit = function () {
        this.me = this.auth.authenticated();
        this.scrollTopService.setScrollTop();
        this.newForm = new FormGroup({
            comment: new FormControl('', [Validators.required]),
        });
    };
    PaymentComponent.prototype.ngAfterViewInit = function () {
        this.initCard();
    };
    PaymentComponent.prototype.ngOnDestroy = function () {
        // this.card.removeEventListener('change', this.cardHandler);
        // this.card.destroy();
    };
    PaymentComponent.prototype.onChange = function (_a) {
        var error = _a.error;
        if (error) {
            this.error = error.message;
        }
        else {
            this.error = null;
        }
        this.cd.detectChanges();
    };
    PaymentComponent.prototype.initCard = function () {
        this.finalMessage = "";
        // setTimeout(()=>{
        this.card = elements.create('card', {
            style: {
                base: {
                    iconColor: '#666EE8',
                    color: '#333',
                    lineHeight: '40px',
                    fontWeight: 300,
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSize: '20px',
                    '::placeholder': {
                        color: '#333'
                    }
                }
            }
        });
        this.card.mount('#card-element');
        // this.card.addEventListener('change', this.cardHandler);
        this.makePayment();
        // },2000);
    };
    PaymentComponent.prototype.makePayment = function () {
        var _this = this;
        var cardButton = document.getElementById('card-button');
        cardButton.addEventListener('click', function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, paymentMethod, error, data;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.newForm.get('comment').invalid) return [3 /*break*/, 2];
                        this.spinner.show();
                        return [4 /*yield*/, stripe.createPaymentMethod('card', this.card, {
                                billing_details: { name: this.me.email }
                            })];
                    case 1:
                        _a = _b.sent(), paymentMethod = _a.paymentMethod, error = _a.error;
                        if (error) {
                            // Display "error.message" to the user...
                            this.spinner.hide();
                        }
                        else {
                            data = { paymentMethod: paymentMethod, appointment: this.modalData, comment: this.newForm.get('comment').value };
                            this.api.post("appointment/payment", data).subscribe(function (res) {
                                _this.spinner.hide();
                                _this.step = 3;
                                _this.finalMessage = "";
                                _this.buyDoneChange.emit(true);
                            });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this._flashMessagesService.show('Merci de décrire vos besoins.', { cssClass: 'alert-warning' });
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    PaymentComponent.prototype.formSubmit = function () {
    };
    PaymentComponent.prototype.icsGoogle = function () {
        var params = {
            text: "Consultation avec " + this.modalData.counselor.firstname + " " + this.modalData.counselor.lastname,
            details: '<p><br></p><p>Voici les modalités pratiques de votre séance à distance.</p><p><br></p><p>La séance démarrera à la date et heure prévue. (Merci de prendre vos dispositions pour vous connecter un peu avant, afin de commencer dès l\'heure convenue)</p><p><br></p><p>Votre session vidéo sera accessible à partir de ce lien : <a href=\"' + this.modalData.counselor.url_visio + '\">lien ZOOM</a> (Note : Gardez bien ce mail jusqu\'à la date de votre séance).</p><p>Si vous le souhaitez, vous pourrez couper la vidéo et ne garder que l\'audio.</p><p>Sinon, assurez-vous que votre vidéo et votre micro soient bien paramétrés.</p><p><br></p><p>Astuce : Vous pouvez prévoir une notification dans votre agenda pour être sûr(e) de ne pas rater votre rendez-vous.</p><p>Voici quelques consignes pour profiter pleinement de votre séance :</p><p>Si vous avez besoin de me poser quelques questions avant de démarrer, je vous répondrai.</p><p><br></p><p>Vous trouverez également des réponses aux questions les plus fréquentes dans le FAQ (lien URL).</p><p><br></p><p>Prévoyez de quoi prendre des notes si besoin et si vous le souhaitez, notez par avance les questions principales que vous vous posez.</p><p><br></p><p>Coupez toutes les sources de distraction et de bruit autour de vous.</p><p>Installez-vous confortablement.</p><p>Cette séance est une sorte de \"rendez-vous\" avec vous-même, nous mettons tout en oeuvre pour que ce soit une expérience des plus bénéfiques pour vous.</p><p><br></p><p>Notre support est aussi à votre service pour toute question technique.</p><p><br></p><p>Au succès de votre famille,</p><p><br></p><p>FamiliPsy Academy</p>',
            location: '',
            dates: format(this.modalData.start, 'YYYYMMDDTHHmmss') + '/' + format(this.modalData.end, 'YYYYMMDDTHHmmss') //'20200806T133000/20200806T140000'
        };
        var queryString = Object.keys(params).map(function (key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');
        return 'https://calendar.google.com/calendar/render?action=TEMPLATE&' + queryString;
        // https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent
        // &startdt=2020-08-06T13%3A30%3A00
        // &enddt=2020-08-06T14%3A00%3A00
        // &subject=Consultation%20avec%20Teremu%20MARO
        // &body=Lorem%20ipsum%20dolor%20sit%20amet%2C%20consectetur%20adipiscing%20elit.%20Aenean%20ut%20pulvinar%20eros.%20Nulla%20ullamcorper%20auctor%20convallis.%20Etiam%20fermentum%20fermentum%20lorem%2C%20vitae%20porta%20ante%20scelerisque%20et.%20Morbi%20blandit%20arcu%20nec%20quam%20blandit%20placerat.%20Duis%20pharetra%2C%20justo%20faucibus%20mollis%20placerat%2C%20leo%20risus%20fringilla%20neque%2C%20id%20laoreet%20lectus%20orci%20nec%20ipsum.%20Ut%20eget%20egestas%20neque%2C%20at%20facilisis%20nulla.%20Proin%20gravida%20ligula%20id%20gravida%20dignissim.%0A%0ADonec%20ut%20arcu%20faucibus%2C%20dignissim%20quam%20ac%2C%20fermentum%20lacus.%20Ut%20congue%20mi%20sed%20fringilla%20ullamcorper.%20Sed%20libero%20quam%2C%20scelerisque%20vitae%20maximus%20nec%2C%20semper%20at%20purus.%20Nulla%20massa%20nunc%2C%20varius%20in%20tellus%20vitae%2C%20sodales%20porta%20dui.%20Vestibulum%20vulputate%20dolor%20ac%20urna%20ullamcorper%20hendrerit.%20Nam%20finibus%20bibendum%20ligula%20sodales%20dapibus.%20Proin%20lacinia%20ullamcorper%20blandit.%20Cras%20scelerisque%2C%20risus%20blandit%20facilisis%20tristique%2C%20est%20dolor%20tempor%20sem%2C%20in%20ornare%20arcu%20urna%20ac%20sem.%20Quisque%20eleifend%20laoreet%20pharetra.%20Sed%20lacinia%20quam%20felis.%20Aenean%20elit%20ante%2C%20congue%20ac%20consectetur%20eget%2C%20vestibulum%20eget%20lectus.%20Etiam%20et%20urna%20et%20mauris%20luctus%20tincidunt%20convallis%20vitae%20ipsum.%20Ut%20maximus%20diam%20eu%20sem%20scelerisque%20consequat.%20Nam%20massa%20metus%2C%20condimentum%20eu%20dapibus%20vel%2C%20aliquam%20vitae%20eros.
        // &location=
        // &allday=false
    };
    return PaymentComponent;
}());
export { PaymentComponent };
