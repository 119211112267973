<section class="space ">
  <div class="container-fluid">
    <div class="row">
      <div id="sidebar-section" class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div id="counselors-sidebar">
          <app-counselors-sidebar></app-counselors-sidebar>
        </div>
      </div>
      <div id="content-section" class="col-12 col-sm-6 col-md-8 col-lg-9">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>