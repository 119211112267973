import { Injectable } from '@angular/core';
import { GenericProvider } from '../genericprovider.provider';
import { User } from '../../../model/user.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { CookieService } from 'ngx-cookie-service';
import { CONFIGURATION } from '../../../configurations/configurations';
import { catchError, tap } from 'rxjs/operators';
import { FlashMessagesService } from 'angular2-flash-messages';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { compileFactoryFunction } from '@angular/compiler/src/render3/r3_factory';
import { CommandExecutor } from 'selenium-webdriver/safari';
import { ConvertPropertyBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';

@Injectable()
export class AuthenticationService extends GenericProvider {

  userUpdated: EventEmitter<User> = new EventEmitter();

  currentUser: User;

  constructor(
    public http: HttpClient,
    public cookieService: CookieService,
    public _flashMessagesService: FlashMessagesService,
    private router: Router
  ) {
    super(_flashMessagesService);
  }

  getCurrentUser(): User {
    return this.currentUser;
  }

  setCurrentUser(user: User) {
    this.currentUser = user;
    this.userUpdated.emit(user);
  }

  getCookie() {
    if (this.checkCookie()) {
      return this.cookieService.get(CONFIGURATION.cookie);
    }
    return null;
  }

  setCookie(cookieValue: string) {
    if (!this.checkCookie() || this.getCookie() !== cookieValue) {
      this.cookieService.set(CONFIGURATION.cookie, cookieValue);
    }
  }

  checkCookie() {
    return this.cookieService.check(CONFIGURATION.cookie);
  }

  deleteCookie() {
    this.cookieService.delete(CONFIGURATION.cookie);
  }

  signup(user: User): Observable<Boolean> {
    return this.http.post<Boolean>(this.api_url + '/auth/signup', user).pipe(
      catchError(this.handleError('AuthenticationProvider : signup()', false))
    );
  }

  login(user: User, remember: Boolean): Observable<any> {
    return this.http.post<any>(this.api_url + '/auth/login', user, { observe: 'response' }).pipe(
      tap(obj => {
        const token = obj.headers.get(CONFIGURATION.jwt_token);
        this.setCookie(token);
        sessionStorage.setItem(CONFIGURATION.session_storage, token);
      }),
      catchError(this.handleError('AuthenticationProvider : login()', 'error'))
    );
  }

  getCurrentUserInfos(): Observable<User> {
    const user : User = new User();
    user.email = 'error';
    return this.http.get<User>(this.api_url + '/auth/getuserinformations').pipe(
      tap(obj => {
        this.setCurrentUser(obj);
      }),
      catchError(this.handleError('AuthenticationProvider : getCurrentUser()', user))
    );
  }

  changePassword(password: string, newPassword: string): Observable<Object> {
    return this.http.put<Object>(this.api_url + '/auth/password', { oldPassword: password, newPassword: newPassword }).pipe(
      catchError(this.handleError('AuthenticationProvider : changePassword()', false))
    );
  }

  logout() {
    this.setCurrentUser(undefined);
    this.deleteCookie();
    this.router.navigate(['accueil']);
  }

}


