<section id="trainings-section" class="">
  <div class="container">
    <h1 class="session-title">Nos formations<div></div>
    </h1>
    <div class="row">
      <div class="col">
        <p class="dark-color center">
      Un atelier spécifique ou une formation plus complète ... Quel que soit votre besoin, l’âge de vos enfants et votre situation familiale nous allons trouver la solution qui vous convient le mieux !
        </p>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col">
        <p class="dark-color center">La vie de couple et de famille mobilise des compétences que nous pouvons développer
          pour améliorer nos rélations et notre bien-être.
          Avec différents thèmes de formation et en fonction du niveau d'urgence de votre besoin, nous vous avons préparé
          des contenus qualitatifs, accessibles au plus grand
          nombre, concrets et simples à utiliser pour réussir votre vie de famille</p>
      </div>
    </div>
  </div>
</section>

<div id="search" class="">
  <div class="container">
    <div class="row">
      <div class="col">
        <form class="search-box shadow-input" style="margin: 0 auto; max-width: 400px;" (ngSubmit)="search()">
          <input type="text" [(ngModel)]="searchPlayload" [ngModelOptions]="{standalone: true}"
            class="dark-color white-color-bg" placeholder="Rechercher">
          <button class="main-button-square" type="submit"></button>
        </form>
      </div>
    </div>
    <div class="row mt-5 mb-5 d-none d-md-block d-lg-block">
      <div class="col text-center">
        <a routerLink="/formations" [class.dark-color-bg]="!selectedCat" class="btn bg-white rounded shadow mr-3 black-color" style="padding: 14px 10px 14px 10px;">Toutes les categories</a>
        <a routerLink="/formations/{{ category.slug }}" routerLinkActive="dark-color-bg" *ngFor="let category of categories" class="btn bg-white rounded shadow mr-3 black-color"><span class="mr-3 d-inline-block" style="width: 40px;"><img src="{{ environment.uploadPath + 'categories/' + category.picto }}" alt="{{category.name}}" class="w-100" /></span>{{category.name}}</a>
      </div>
    </div>
    <div class="row mt-5 mb-5 d-md-none d-lg-none d-xl-none">
      <div class="col-12 text-center">
        <a routerLink="/formations" [class.dark-color-bg]="!selectedCat" class="btn bg-white w-100 rounded shadow mb-2 black-color" style="padding: 14px 10px 14px 10px;">Toutes les categories</a>
      </div>
      <div class="col-12 text-center">
        <a routerLink="/formations/{{ category.slug }}" routerLinkActive="dark-color-bg" *ngFor="let category of categories" class="btn w-100 bg-white rounded shadow mb-2 black-color"><span class="mr-3 d-inline-block" style="width: 40px;"><img src="{{ environment.uploadPath + 'categories/' + category.picto }}" alt="{{category.name}}" class="w-100" /></span>{{category.name}}</a>
      </div>
    </div>
  </div>
</div>

<div class="trainings">
  <div class="container">
    <div *ngFor="let category of trainingsByCategories">
      <div class="row training" *ngIf="category?.trainings.length">
        <div class="training-head flex mb-5">
          <h2 class="dark-color training-title"><span class="mr-3 d-inline-block" style="width: 40px;"><img src="{{ environment.uploadPath + 'categories/' + category.picto }}" alt="{{category.name}}" class="w-100" /></span>{{category.name}}</h2>
          <a routerLink="/formations/{{ category.slug }}" class="more bold dark-color cursor-pointer" *ngIf="sliceLimit != 9999">Tout voir</a>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let item of category?.trainings | slice:0:sliceLimit; let i=index">
            <article class="conferenceCustomCard custom-card position-relative">
              <div class="custom-card-image position-relative pt-3 pl-3 pr-3">
                <!-- <div class="authors position-absolute" style="bottom: 0px !important">
                    <div class="author float-left position-relative" *ngFor="let author of item?.authors">
                        <img src="{{ environment.uploadPath + 'users/' + author?.image }}" class="rounded-circle" width="60" alt="{{ author?.firstname + ' ' + author?.lastname }}" />
                    </div>
                </div> -->
                <a routerLink="/formations/{{category?.slug}}/{{ item?.slug }}" class="d-block">
                  <img src="{{ environment.uploadPath + 'trainings/' + item?.image }}" alt="{{item?.title}}" class="w-100" />
                </a>
              </div>
              <div class="custom-card-content">
                  <div class="topContent">
                      <h4 class="title dark-color" style="height: 50px;"><a class="dark-color" routerLink="/formations/{{category?.slug}}/{{item?.slug}}">{{item?.title}}</a></h4>
                      <h6 class="subtitle dark-color" style="font-weight: 400"><a class="dark-color" routerLink="/formations/{{category?.slug}}/{{item?.slug}}">{{item?.subtitle}}</a></h6>
                      <span *ngIf="item?.novelty" class="novelty bg-success text-white badge">Nouveauté</span>
                      <span *ngIf="item?.bestsell" class="novelty dark-color-bg ml-2 text-white badge">Meilleure vente</span>
                  </div>
                  <div class="w-100 float-left mt-4 mb-4 text-justify">
                    <span class="dark-color bold uppercase p-3 float-right mb-4">
                      {{item.price == 0 ? "gratuit" : item.price + " €"}}
                    </span>
                  </div>
              </div>
              <div *ngIf="account?.membership_id < item?.membership_id" class="unautorized-content">
                <div class="explic">
                  Votre abonnement n'est pas suffisant pour voir cette formation<br/>
                  <a href="/offres" class="main-button-round uppercase bold">Voir Nos Offres</a>
                </div>
                <div class="lock">
                  <i class="material-icons">
                    lock
                    </i><br/>
                  Accès abonnés
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>