export class User {
id: Number;
email: String;
password: String;
firstName: String;
lastName: String;
nickName: String;
advisor: Boolean;
stripeToken: string;
stripePlan: string;
}
