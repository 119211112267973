import { OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../../shared/providers/modal/modal.service';
import { ApiService } from '../../../services/api/api.service';
import { AuthService } from '../../../services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlashMessagesService } from 'angular2-flash-messages';
var RessourceDetailComponent = /** @class */ (function () {
    function RessourceDetailComponent(_activatedRoute, router, api, auth, _sanitizer, modalS, modal, _flashMessagesService) {
        this._activatedRoute = _activatedRoute;
        this.router = router;
        this.api = api;
        this.auth = auth;
        this._sanitizer = _sanitizer;
        this.modalS = modalS;
        this.modal = modal;
        this._flashMessagesService = _flashMessagesService;
        this.environment = environment;
        this.isFavourite = false;
        this.upgrade = false;
        this.authMembership = null;
    }
    RessourceDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.account = this.auth.authenticated();
        this.authMembership = this.auth.membership();
        this._activatedRoute.paramMap.subscribe(function (param) {
            if (param.get('slugRessource')) {
                _this.api.get('files/getBySlug/' + param.get('slugRessource')).subscribe(function (data) {
                    _this.ressource = data;
                    _this.checkIfIsFavourite();
                    if (_this.ressource.type == 'video')
                        _this.video = _this._sanitizer.bypassSecurityTrustResourceUrl(data.value);
                });
            }
            else {
                _this.router.navigate(['accueil']);
            }
        });
    };
    RessourceDetailComponent.prototype.checkIfIsFavourite = function () {
        var _this = this;
        if (this.auth.authenticated()) {
            this.api.get('favourite/check/file_id/' + this.ressource.id).subscribe(function (data) {
                _this.isFavourite = data;
            });
        }
    };
    RessourceDetailComponent.prototype.viewRessource = function () {
        if (this.checkAccessibility()) {
            var url = (this.ressource.type == 'file') ? environment.filePath : '';
            window.open(url + this.ressource.value);
        }
        else {
            this.modal.open(this.memberOnlyModal, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
        }
    };
    RessourceDetailComponent.prototype.openVideo = function () {
        var content;
        if (this.checkAccessibility()) {
            var text = '<iframe class="embed-responsive-item" src="' + this.ressource.value + '" id="video" allowscriptaccess="always" allow="autoplay" allowfullscreen></iframe>';
            this.bodyText = this._sanitizer.bypassSecurityTrustHtml(text);
            content = this.videoModal;
        }
        else {
            content = this.memberOnlyModal;
        }
        this.modal.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    };
    RessourceDetailComponent.prototype.checkAccessibility = function () {
        var access = false;
        if (this.ressource.membership_id == 0) {
            access = true;
        }
        else if (this.ressource.membership_id != 0 && this.authMembership == null) {
            access = false;
            this.upgrade = false;
        }
        else if (this.ressource.membership_id > this.authMembership) {
            access = false;
            this.upgrade = true;
        }
        else if (this.ressource.membership_id <= this.authMembership) {
            access = true;
            this.upgrade = false;
        }
        return access;
    };
    RessourceDetailComponent.prototype.closeModal = function (id) {
        this.stopVideo();
        this.modalS.close(id);
    };
    RessourceDetailComponent.prototype.stopVideo = function () {
        var videos = document.querySelectorAll('iframe');
        Array.prototype.forEach.call(videos, function (video) {
            if (video.tagName.toLowerCase() === 'video') {
                video.pause();
            }
            else {
                var src = video.src;
                video.src = src;
            }
        });
    };
    ;
    RessourceDetailComponent.prototype.addToFavourites = function () {
        var _this = this;
        if (this.auth.isAuthenticated()) {
            this.api.post("favourite/file/create", { file_id: this.ressource.id }).subscribe(function (data) {
                _this.isFavourite = true;
                _this._flashMessagesService.show('La ressource a bien été ajoutée à vos favoris.', { cssClass: 'alert-success' });
            });
        }
        else {
            this.modal.open(this.memberOnlyModal, { size: 'lg' });
        }
    };
    return RessourceDetailComponent;
}());
export { RessourceDetailComponent };
