import { Component, OnInit } from '@angular/core';
import { Conference } from '../../model/conference.model';
import { ConferenceService } from '../../shared/providers/conferences/conference.service';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { NewsletterService } from '../../shared/providers/newsletters/newsletter.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../shared/providers/profile/profile.service';
import { AuthenticationService } from '../../shared/providers/authentication/authentication.service';
import { ApiService } from '../../services/api/api.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-conferences',
  templateUrl: './conferences.component.html',
  styleUrls: ['./conferences.component.css']
})
export class ConferencesComponent implements OnInit {
  
  conferencesList: any;
  environment = environment;
  currentPage = 1;
  urlPart: String;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private scrollTopService: ScrollTopService,
    private _newsletterService: NewsletterService,
    private _flashMessagesService: FlashMessagesService,
    private api: ApiService,
  ) {
  }

  email: string;
  subscribe() {
    this._newsletterService.subscribe(this.email).subscribe(res => {
      this._flashMessagesService.show('Merci de votre intérêt pour notre newsletter.',
        { cssClass: 'alert-success' });
    });
  }

  ngOnInit() {console.log("conferences");
    this.scrollTopService.setScrollTop();
    // console.log("this._activatedRoute.pathFromRoot", this._activatedRoute.pathFromRoot);
    this._activatedRoute.pathFromRoot[1].url.subscribe(val => {
      console.log("path", val)
      if(val.length == 1) this.urlPart = 'all/published';
      else this.urlPart = (val[1].path == "prochaines") ? "toCome" : "done";
    });
    this.getAll();
  }

  getAll(page = 0) {
    const url = 'conferences/' + this.urlPart + ((page) ? ("?page=" + page) : "");
    this.api.get(url).subscribe((data: any[]) => {
      this.conferencesList = data;
      document.body.scrollTop = 150;
    })
  }

}
