import { environment } from './../../../../environments/environment';
import { AuthService } from './../../../services/auth/auth.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-member-only',
  templateUrl: './member-only.component.html',
  styleUrls: ['./member-only.component.css']
})
export class MemberOnlyComponent implements OnInit {

  environment = environment;
  @Input() closeAction: any = "";
  @Input() upgrade: boolean = false; 

  constructor(
    private auth: AuthService
  ) {}

  ngOnInit(){
    
  }

}
