import { OnInit } from '@angular/core';
var ModalComponent = /** @class */ (function () {
    function ModalComponent() {
        this.modalTitle = null;
        this.modalButton = "Fermer";
        this.closeAction = null;
        this.upgrade = false;
    }
    ModalComponent.prototype.ngOnInit = function () {
    };
    return ModalComponent;
}());
export { ModalComponent };
