<div id="signup-counselor-section" class="contentWrapper">
  <div class="container" *ngIf="!finalMessage">
      <h1>Devenir Conseiller<div></div></h1>
      <div class="row bg-light rounded shadow">
        
          <p class="dark-color text-center info mt-4">Vous pourrez modifier ces informations dans votre espace profil</p>
          <form class="custom-form container" [formGroup]="signupForm" (ngSubmit)="formSubmit()">
            <div class="mt-5 mb-5 p-4">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="lastname" class="bold dark-color">Nom*</label>
                  <input type="text" class="form-control dark-color" id="lastname" formControlName="lastname" placeholder="">
                </div>
                <div class="form-group col-md-6">
                  <label for="firstname" class="bold dark-color">Prénom*</label>
                  <input type="text" class="form-control dark-color" id="firstname" formControlName="firstname" placeholder="">
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-6">
                  <label for="email" class="bold dark-color">Mail*</label>
                  <input type="email" class="form-control dark-color" id="email" formControlName="email" placeholder="">
                </div>
                <div class="form-group col-md-6">
                  <label for="nick" class="bold dark-color">Pseudo*</label>
                  <input type="text" class="form-control dark-color" id="nick" formControlName="nickname" placeholder="">
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-6">
                  <label for="pwd" class="bold dark-color">Mot de passe*</label>
                  <input type="password" class="form-control dark-color" id="pwd" formControlName="password" placeholder="">
                </div>
                <div class="form-group col-md-6">
                  <label for="confirm-pwd" class="bold dark-color">Confirmation du mot de passe*</label>
                  <input type="password" class="form-control dark-color" id="confirm-pwd" formControlName="confirmpassword" placeholder="">
                </div>
              </div>
  
              <!-- <div class="row">
                <div class="checkbox col-md-6">
                  <input id="news" type="checkbox" formControlName="newsletter">
                  <label for="news" class="checkbox-input"></label>
                  <label class="dark-color">Je souhaite recevoir les nouveautés</label>
                </div>
              </div> -->

              <div class="row">
                <div class="checkbox col-md-6">
                  <input id="cgu" type="checkbox" formControlName="cgu">
                  <label for="cgu" class="checkbox-input"></label>
                  <label class="dark-color">J'ai lu et j'accepte <a routerLink="/conditions-generales-de-vente-et-de-service">les conditions générales d'utilisation</a></label>
                </div>
              </div>

              <div class="button-section mt-5">
                <button class="main-button-round" type="submit">Créer mon compte</button>
              </div>
            </div>
          </form>
          
      </div>
  </div>
  <div class="container bg-light shadow p-5" *ngIf="finalMessage">
    <h1>Félicitations!<div></div></h1>
    <div class="mt-5 mb-5 text-center" [innerHtml]="finalMessage"></div>
  </div>
</div>
