import { CONFIGURATION } from '../../configurations/configurations';
import { of } from 'rxjs/observable/of';
var GenericProvider = /** @class */ (function () {
    function GenericProvider(_flashMessagesService) {
        this._flashMessagesService = _flashMessagesService;
        this.api_url = CONFIGURATION.url_api;
    }
    GenericProvider.prototype.handleError = function (operation, result) {
        var _this = this;
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            console.error(error); // log to console instead
            // TODO: better job of transforming error for user consumption
            if (error.error.message !== undefined) {
                _this.log("" + error.error.message);
            }
            else {
                _this.log("" + error.error);
            }
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    };
    GenericProvider.prototype.log = function (message) {
        this._flashMessagesService.show(message, { cssClass: 'alert-danger', timeout: 9000 });
    };
    return GenericProvider;
}());
export { GenericProvider };
