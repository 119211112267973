import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var ScrollTopService = /** @class */ (function () {
    function ScrollTopService(platformId, router) {
        this.platformId = platformId;
        this.router = router;
    }
    ScrollTopService.prototype.setScrollTop = function () {
        if (isPlatformBrowser(this.platformId)) {
            // this.router.events.subscribe((event: NavigationEnd) => {
            //   window.scroll(0, 0);
            // });
        }
    };
    ScrollTopService.ngInjectableDef = i0.defineInjectable({ factory: function ScrollTopService_Factory() { return new ScrollTopService(i0.inject(i0.PLATFORM_ID), i0.inject(i1.Router)); }, token: ScrollTopService, providedIn: "root" });
    return ScrollTopService;
}());
export { ScrollTopService };
