import { OnInit } from '@angular/core';
import { ApiService } from '../../../services/api/api.service';
var ContentComponent = /** @class */ (function () {
    function ContentComponent(api) {
        this.api = api;
    }
    ContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.api.get('content/getBySlug/' + this.slug).subscribe(function (data) {
            _this.content = data;
        });
    };
    return ContentComponent;
}());
export { ContentComponent };
