<div class="pt-4 pb-4">
  <div class="row">
    <div class="col-lg-6">
      <form [formGroup]="newForm" (ngSubmit)="submit()">
        <div class="row mt-3">
          <div class="col-lg-12">
            <h5>Ajouter un diplôme</h5>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <div class="custom-file">
              <input 
                  formControlName="file"
                  id="file" 
                  type="file" 
                  class="form-control"
                  (change)="onFileChange($event)">
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <label for="validationTooltip02">Date d'obtention*</label>
            <input type="date" formControlName="date_obtained" class="form-control">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <div class="form-group">
              <label for="exampleFormControlSelect1">Intitulé du diplôme / de l'autorisation*</label>
              <select class="form-control" id="exampleFormControlSelect1" formControlName="category_diploma_id">
                <option value="{{ category.id }}" *ngFor="let category of categories">{{ category.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <label for="validationTooltip02">Spécialité</label>
            <input type="text" class="form-control" formControlName="speciality">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <label for="validationTooltip02">Numéro d'autorisation</label>
            <input type="text" class="form-control" formControlName="authorized_number">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-12">
            <button type="submit" class="btn btn-primary float-right">Enregistrer</button>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-6">
      <div class="pl-3 pr-3">
        <ul>
          <li *ngFor="let diploma of diplomas" class="w-100 float-left p-2 bg-light mb-2">
            <span class="d-inline-block float-left">
              {{ diploma?.category_diploma?.name }}<br/>
              {{ (diploma?.speciality.length>30)? (diploma?.speciality | slice:0:30)+'...':(diploma?.speciality) }}
            </span>
            <a class="main-button-round bg-secondary uppercase p-2 float-right" (click)="deleteDiploma(diploma?.id)"><i class="fas fa-trash"></i></a>
            <a class="main-button-round uppercase p-2 mr-2 float-right" (click)="editDiploma(diploma?.id)"><i class="fas fa-pen"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>