import { EventEmitter } from '@angular/core';
import { ApiService } from '../api/api.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { FlashMessagesService } from 'angular2-flash-messages';
import * as i0 from "@angular/core";
import * as i1 from "../api/api.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
import * as i4 from "angular2-flash-messages/module/flash-messages.service";
var API_URL = environment.apiUrl;
var AuthService = /** @class */ (function () {
    function AuthService(api, http, router, _flashMessagesService) {
        this.api = api;
        this.http = http;
        this.router = router;
        this._flashMessagesService = _flashMessagesService;
        this.url = [null, null, "individual", "counselor"];
        this.change = new EventEmitter();
    }
    /**
     * this is used to clear anything that needs to be removed
     */
    AuthService.prototype.clear = function () {
        localStorage.clear();
    };
    /**
     * retrieve user authenticated
     * @return user object
     */
    AuthService.prototype.authenticated = function () {
        return JSON.parse(localStorage.getItem('user'));
    };
    /**
     * retrieve user authenticated
     * @return user object
     */
    AuthService.prototype.membership = function () {
        return localStorage.getItem('membership_id');
    };
    AuthService.prototype.updateAuthenticated = function () {
        this.change.emit(this.user);
    };
    /**
     * check for expiration and if token is still existing or not
     * @return {boolean}
     */
    AuthService.prototype.isAuthenticated = function () {
        return localStorage.getItem('user') != null;
    };
    /**
     * check for expiration and if token is still existing or not
     * @return {boolean}
     */
    AuthService.prototype.isIndividual = function () {
        return (localStorage.getItem('user_type_id') == '2') ? true : false;
    };
    /**
     * check for expiration and if token is still existing or not
     * @return {boolean}
     */
    AuthService.prototype.isCounselor = function () {
        return (localStorage.getItem('user_type_id') == '3') ? true : false;
    };
    AuthService.prototype.login = function (data) {
        var _this = this;
        this.http.post(API_URL + 'login', data).subscribe(function (data) {
            _this.user = data;
            _this.updateAuthenticated();
            localStorage.setItem('user', JSON.stringify(data));
            localStorage.setItem('token', data.token);
            localStorage.setItem('user_type_id', data.user_type_id);
            localStorage.setItem('membership_id', data.membership_id);
            localStorage.setItem('email', data.email);
            localStorage.setItem('activated', data.activated);
            // localStorage.setItem('baseUrl', this.baseUrl[0]);
            /* If there is many user_type_id use below */
            _this.api.setHeaderOptions();
            // this.router.navigateByUrl("test");
            /* If there is many user_type_id use below */
            _this.router.navigate([_this.url[data.user_type_id] + '/dashboard']);
            // }
        }, function (err) {
            _this._flashMessagesService.show("Il semblerait qu'il y ait une erreur sur vos codes d'accès. Veuillez vérifier et réessayer  à nouveau.", { cssClass: 'alert-danger', timeout: 4000 });
        });
    };
    /**
     * this is used to clear local storage and also the route to login
     */
    AuthService.prototype.logout = function () {
        var _this = this;
        this.http.get(API_URL + "logout").subscribe(function (data) {
            _this.clear();
            _this.user = undefined;
            _this.change.emit(_this.user);
            _this.router.navigate(["accueil"]);
        });
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.ApiService), i0.inject(i2.HttpClient), i0.inject(i3.Router), i0.inject(i4.FlashMessagesService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
