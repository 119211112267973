<div id="conferences-section" class="contentWrapper">
  <div class="container">
    <h1>Nos conférences<div></div></h1>
    <div class="row">
      <p class="dark-color center">Participez gratuitement à nos webconférences et suivez depuis chez vous un large
        choix de thèmes qui vous aideront à embellir vos relations familiales. Animées par des experts des relations
        familiales, vous y trouverez des clefs pour mieux comprendre votre enfant, communiquer efficacement, éduquer
        avec bienveillance, gérer vos émotions et bien d’autres thèmes !
      </p>
    </div>
    
    <div class="mt-5 row">
        <div class="col-12 col-md-12 col-lg-4" *ngFor="let item of conferencesList?.data; let i=index">
            <article class="conferenceCustomCard custom-card position-relative">
                <div class="custom-card-image position-relative">
                    <div class="authors position-absolute">
                        <div class="author float-left position-relative" *ngFor="let author of item?.authors">
                            <img src="{{ environment.uploadPath + 'users/' + author?.image }}" class="rounded-circle" width="60" alt="{{ author?.firstname + ' ' + author?.lastname }}" />
                        </div>
                    </div>
                    <a routerLink="/conferences/{{ item?.slug }}" class="d-block">
                        <img src="{{ environment.uploadPath + 'conferences/' + item?.image }}" alt="{{item?.title}}" class="w-100" />
                    </a>
                </div>
                <div class="custom-card-content mt-3">
                    <div class="topContent">
                        <h4 class="title dark-color bold" style="height: 50px;"><a class="dark-color" routerLink="/conferences/{{item?.slug}}">{{item?.title}}</a></h4>
                        <span *ngIf="item?.novelty" class="novelty bg-success text-white badge">Nouveauté</span>
                    </div>
                    <div class="w-100 float-left mt-4 mb-4 text-justify" [innerHtml]="item?.intro"></div>
                </div>
            </article>
        </div>
    </div>

    <ngb-pagination class="d-flex justify-content-center" [collectionSize]="conferencesList?.total" [rotate]="true" [maxSize]="10" [(page)]="currentPage" [pageSize]="conferencesList?.per_page" [boundaryLinks]="true" (pageChange)="getAll($event)"></ngb-pagination>

    <div id="newsletters-section" class="">
      <div class="container">
        <iframe width="100%" height="480" src="https://e5730db9.sibforms.com/serve/MUIEAGu9WtA2teFBMElcMHKuXabvBTIhzb5GL9YJiIfg7opTkNgW5MtB8dZFsT9geIqqvP0ZA3gptlrUGcovZT5OPS6fBZuYklu50FovtktupqwZQAMfqLDn8k7ikvK6gJIGxDuNY6nWN7DE1-ytxnXtSewdTS4BFHnOpo7JlZdI-jW7PPqqPCwjL3oOIfTQeq1Qooh0OJRpMyQb" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
      </div>
    </div>
  </div>
</div>