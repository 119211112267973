import { OnInit } from '@angular/core';
import { MembershipService } from '../../shared/providers/memberships/membership.service';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../services/api/api.service';
var OffersComponent = /** @class */ (function () {
    function OffersComponent(_sanitizer, _membershipService, scrollTopService, api) {
        this._sanitizer = _sanitizer;
        this._membershipService = _membershipService;
        this.scrollTopService = scrollTopService;
        this.api = api;
    }
    OffersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.video = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/NyOLHckmkuE');
        this.scrollTopService.setScrollTop();
        // this._membershipService.getAllMemberships().subscribe(res => {
        //   this.offers = res;
        // })
        this.api.get("list/memberships").subscribe(function (res) {
            _this.offers = res;
        });
    };
    return OffersComponent;
}());
export { OffersComponent };
