<section id="signin-section" class="">
  <div class="container" *ngIf="!isForget">
    <h1 class="session-title">Connexion<div></div></h1>
    <div class="row">
      <div class="col-md-12">
        <form class="custom-form p-4 bg-white rounded shadow" [formGroup]="loginForm" (ngSubmit)="formSubmit()">
          <p class="dark-color center havent-accoumt">Pas encore de compte ?</p>
          <p class="dark-color center inscription"><a routerLink="/offres" class="dark-color bold">Inscrivez-vous</a> c'est gratuit</p>
          <div class="row">
            <div class="form-group">
              <label for="username" class="bold dark-color">Adresse email</label>
              <input type="text" class="form-control dark-color" style="background: #eef0f8;" id="username" formControlName="email">
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <label for="pwd" class="bold dark-color">Mot de passe</label>
              <input type="password" class="form-control dark-color" style="background: #eef0f8;" id="pwd" formControlName="password">
            </div>
          </div>

          <a (click)="isForget = true" class="forgotten dark-color cursor-pointer">Mot de passe oublié ?</a>

          <div class="row rememberme-row">
            <div class="checkbox">
              <input id="rememberme" type="checkbox" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}">
              <label for="rememberme" class="checkbox-input"></label>
              <label class="dark-color">Se souvenir de moi</label>
            </div>
          </div>
          <div class="row">
            <div class="form-group">

              <div class="button-section w-100 text-center">
                  <button class="main-button-round" type="submit">Se connecter</button>
              </div>
            </div>
          </div>

        </form>

      </div>
      <!-- <div class="col-md-6">
        <h1 class="dark-color bold familipsy">Familipsy</h1>
        <p class="dark-color">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </div> -->
    </div>
  </div>
  <div class="container" *ngIf="isForget">
    <h1 class="session-title">Mot de passe oublié<div></div></h1>
    <div class="row">
      <div class="col-md-12">
        <form class="custom-form p-4 bg-white rounded shadow" [formGroup]="forgetForm" (ngSubmit)="requestReinitialisationPassword()">
          <p class="dark-color center havent-accoumt">Pas encore de compte ?</p>
          <p class="dark-color center inscription"><a routerLink="/offres" class="dark-color bold">Inscrivez-vous</a> c'est gratuit</p>
          <div class="row">
            <div class="form-group">
              <label for="username" class="bold dark-color">Adresse email</label>
              <input type="text" class="form-control dark-color" style="background: #eef0f8;" id="username" formControlName="email">
            </div>
          </div>


          <a (click)="isForget = false"  class="forgotten dark-color cursor-pointer">Se connecter?</a>
          <div class="row">
            <div class="form-group">

              <div class="button-section w-100 text-center  mt-2">
                <button class="main-button-round" type="submit">Réinitialiser</button>
              </div>
            </div>
          </div>

        </form>

      </div>
      <!-- <div class="col-md-6">
        <h1 class="dark-color bold familipsy">Familipsy</h1>
        <p class="dark-color">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </div> -->
    </div>
  </div>
</section>
