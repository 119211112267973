import { Component, OnInit } from '@angular/core';
import { Citation } from '../../../model/citation.model';
import { CITATION } from '../../../mocks/citation.mock';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api/api.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {

  environment = environment;
  quoteToShow: Citation = CITATION[0];
  quote = {content:null, author: null, image: "Citation.jpg"};

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.getAll();
  }

  getAll(){
    this.api.get("quotes/today/getOne").subscribe((data: {content:null, author: null, image: null}) => {
      this.quote = data;
    })
  }

}
