import { SeoService } from './../../services/seo/seo.service';
import { Component, OnInit } from '@angular/core';
import { Category } from '../../model/category.model';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api/api.service';
import { Conference } from '../../model/conference.model';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  environment = environment;
  today: Date;

  images = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => `https://picsum.photos/900/500?random&t=${Math.random()}`);

  // conferences: Conference[];
  conferencesList: Conference[];
  categories: Category[];
  currentTime : any;
  account = {
    membershipType: {id:null}
  };

  partners: any[];
  testimonies: any;

  constructor(
    private api: ApiService,
    private scrollTopService: ScrollTopService,
    private seo: SeoService
  ) { }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
    this.today = new Date();
    this.currentTime = new Date();
    this.currentTime = this.currentTime.toISOString();
    this.api.get('conferences/getLastForHome').subscribe((data: any[]) => {
      this.conferencesList = data;
    })

    this.getPartners();
    this.getCategories();
    this.setSeo();
    this.getTestimonies();
  }

  getTestimonies() {
    this.api.get('testimonies/category/2/random').subscribe(data => {
      this.testimonies = data;
    })
  }

  setSeo() {
    let title = "Familipsy - Site d'information et de formation pour promouvoir les bonnes pratiques en matière d'éducation et de parentalité et améliorer positivement les relations intra-familiales";
    let description = "Information et formation en ligne pour aider les parents à développer leurs compétences en parentalité positive et réussir leur vie de famille.";

    this.seo.set({
      'title': title,
      'description': description,
      'og:title': title,
      'og:url': environment.siteUrl,
      'og:description': description,
      'og:image': environment.uploadPath + 'images/familipsy-logo.jpg',
      'twitter:title': title,
      'twitter:description': description,
      
    });
  }

  getPartners() {
    this.api.get('list/partners').subscribe((data: any[])=>{
      this.partners = data;
    })
  }

  getCategories() {
    this.api.get('list/categories').subscribe((data: any[])=>{
      this.categories = data;
    })
  }

}
