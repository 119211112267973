import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from './../../../../../services/api/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
var CounselorsDiplomasComponent = /** @class */ (function () {
    function CounselorsDiplomasComponent(api, _flashMessagesService) {
        this.api = api;
        this._flashMessagesService = _flashMessagesService;
        this.initForm();
    }
    Object.defineProperty(CounselorsDiplomasComponent.prototype, "f", {
        get: function () {
            return this.newForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    CounselorsDiplomasComponent.prototype.ngOnInit = function () {
    };
    CounselorsDiplomasComponent.prototype.init = function () {
        this.getDiplomasCategories();
        this.getMyCounselorDiplomas();
    };
    CounselorsDiplomasComponent.prototype.initForm = function () {
        this.newForm = new FormGroup({
            file: new FormControl('', [Validators.required,]),
            fileSource: new FormControl('', [Validators.required,]),
            date_obtained: new FormControl('', [Validators.required,]),
            category_diploma_id: new FormControl('', [Validators.required,]),
            speciality: new FormControl('', [Validators.required,]),
            authorized_number: new FormControl('', []),
        });
    };
    CounselorsDiplomasComponent.prototype.onFileChange = function (event) {
        if (event.target.files.length > 0) {
            var file = event.target.files[0];
            this.newForm.patchValue({
                fileSource: file
            });
        }
    };
    CounselorsDiplomasComponent.prototype.getMyCounselorDiplomas = function () {
        var _this = this;
        this.api.get("myDiplomas").subscribe(function (data) {
            _this.diplomas = data;
        });
    };
    CounselorsDiplomasComponent.prototype.getDiplomasCategories = function () {
        var _this = this;
        this.api.get("categories_diploma/list").subscribe(function (data) {
            _this.categories = data;
        });
    };
    CounselorsDiplomasComponent.prototype.submit = function () {
        var _this = this;
        var formData = new FormData();
        formData.append('file', this.newForm.get('fileSource').value);
        formData.append('date_obtained', this.newForm.get('date_obtained').value);
        formData.append('category_diploma_id', this.newForm.get('category_diploma_id').value);
        formData.append('authorized_number', this.newForm.get('authorized_number').value);
        formData.append('speciality', this.newForm.get('speciality').value);
        this.api.post('store/diploma', formData)
            .subscribe(function (res) {
            _this.getMyCounselorDiplomas();
            _this._flashMessagesService.show('Le diplôme a bien été ajouté.', { cssClass: 'alert-success' });
        });
    };
    CounselorsDiplomasComponent.prototype.editDiploma = function (id) {
        var _this = this;
        this.api.get("diploma/" + id).subscribe(function (data) {
            console.log("data", data);
            _this.newForm = new FormGroup({
                file: new FormControl(data.file, [Validators.required,]),
                fileSource: new FormControl('', [Validators.required,]),
                date_obtained: new FormControl(data.date_obtained, [Validators.required,]),
                category_diploma_id: new FormControl(data.category_diploma_id, [Validators.required,]),
                speciality: new FormControl(data.speciality, [Validators.required,]),
                authorized_number: new FormControl(data.authorized_number, []),
            });
        });
    };
    CounselorsDiplomasComponent.prototype.deleteDiploma = function (id) {
        var _this = this;
        this.api.get("delete/diploma/" + id).subscribe(function (data) {
            _this.getMyCounselorDiplomas();
            _this._flashMessagesService.show('Le diplôme a bien été supprimé.', { cssClass: 'alert-success' });
        });
    };
    return CounselorsDiplomasComponent;
}());
export { CounselorsDiplomasComponent };
