import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../services/api/api.service';
var PageComponent = /** @class */ (function () {
    function PageComponent(api, activatedRoute) {
        var _this = this;
        this.api = api;
        this.activatedRoute = activatedRoute;
        this.activatedRoute.paramMap.subscribe(function (param) {
            _this.slug = param.get('slug');
            _this.api.get("page/slug/" + _this.slug).subscribe(function (data) {
                _this.page = data;
            });
        });
    }
    PageComponent.prototype.ngOnInit = function () {
    };
    return PageComponent;
}());
export { PageComponent };
