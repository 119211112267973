/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ressource-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../directives/lazy-img.directive";
import * as i5 from "./ressource-list.component";
import * as i6 from "../../../shared/providers/scrolltop/scroll-top-service.service";
import * as i7 from "../../../services/api/api.service";
import * as i8 from "../../../services/auth/auth.service";
import * as i9 from "../../../services/seo/seo.service";
var styles_RessourceListComponent = [i0.styles];
var RenderType_RessourceListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RessourceListComponent, data: {} });
export { RenderType_RessourceListComponent as RenderType_RessourceListComponent };
function View_RessourceListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "btn bg-white rounded shadow mr-3 black-color"], ["routerLinkActive", "dark-color-bg"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "/ressources/", _v.context.$implicit.slug, ""); _ck(_v, 1, 0, currVal_2); var currVal_3 = "dark-color-bg"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_4); }); }
function View_RessourceListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "more bold dark-color cursor-pointer"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Tout voir"]))], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "/ressources/", _v.parent.parent.context.$implicit.slug, ""); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_RessourceListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "col-12 col-md-6 col-lg-4 col-xl-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "article", [["class", "conferenceCustomCard custom-card position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "img", [["class", "w-100"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i4.LazyImgDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "custom-card-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "h4", [["class", "dark-color bold"], ["style", "font-size: 18px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["class", "dark-color"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "dark-color"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(2, "/ressources/", _v.parent.parent.context.$implicit.slug, "/", _v.context.$implicit.slug, ""); _ck(_v, 3, 0, currVal_2); var currVal_7 = i1.ɵinlineInterpolate(2, "/ressources/", _v.parent.parent.context.$implicit.slug, "/", _v.context.$implicit.slug, ""); _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "", ((_co.environment.uploadPath + "files/") + _v.context.$implicit.image), ""); var currVal_4 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.title, ""); _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 9).target; var currVal_6 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_8 = _v.context.$implicit.title; _ck(_v, 10, 0, currVal_8); var currVal_9 = _v.context.$implicit.authors; _ck(_v, 12, 0, currVal_9); }); }
function View_RessourceListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "row training"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "training-head flex mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "dark-color training-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RessourceListComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_RessourceListComponent_5)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.sliceLimit != 9999); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.files), 0, _co.sliceLimit)); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
function View_RessourceListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RessourceListComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.files == null) ? null : _v.context.$implicit.files.length)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RessourceListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["id", "trainings-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [["class", "session-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nos ressources"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["id", "search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "row mt-5 mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "col text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["class", "btn bg-white rounded shadow mr-3 black-color"], ["routerLink", "/ressources"]], [[2, "dark-color-bg", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Toutes les ressources"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RessourceListComponent_1)), i1.ɵdid(13, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "trainings"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RessourceListComponent_2)), i1.ɵdid(17, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "/ressources"; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.categories; _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.ressourcesByCategories; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedCat; var currVal_1 = i1.ɵnov(_v, 10).target; var currVal_2 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_RessourceListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ressource-list", [], null, null, null, View_RessourceListComponent_0, RenderType_RessourceListComponent)), i1.ɵdid(1, 114688, null, 0, i5.RessourceListComponent, [i2.ActivatedRoute, i6.ScrollTopService, i7.ApiService, i8.AuthService, i9.SeoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RessourceListComponentNgFactory = i1.ɵccf("app-ressource-list", i5.RessourceListComponent, View_RessourceListComponent_Host_0, {}, {}, []);
export { RessourceListComponentNgFactory as RessourceListComponentNgFactory };
