<div class="shadow mb-5 bg-white rounded">
  <img *ngIf="me?.image != undefined" (click)="openModalImage();" src="{{ environment.uploadPath + 'users/' + me?.image + '?random=' + random }}" class="card-img-top cursor-pointer">
  <input type="file" class="d-none" #imageInput (change)="fileChangeEvent($event)" />
  <div class="p-3">
    <div class="row mt-3">
      <div class="col">
        <div class="black-color center">
          {{me?.firstname}} {{me?.lastname}}<br/>
          {{me?.nickname}}
        </div>
      </div>
    </div>
    <hr/>
    <div class="row mt-2 mb-2">
      <div class="col-lg-12">
        <button class="btn rounded bg-primary w-100 text-white" (click)="submitMyAccount()" *ngIf="me.activated == '0'">Soumettre mon compte</button>
        <button class="btn rounded bg-warning w-100 text-white" *ngIf="me.activated == '3'">En attente de validation</button>
      </div>
    </div>
    <hr *ngIf="me.activated == '0'"/>
    <app-counselors-menu></app-counselors-menu>
  </div>
</div>


<ng-template #imageModal let-close="close">
  <div class="modal-body">
    <!-- <div (click)="imageInput.click();" class="cursor-pointer" style="width: 100%; height: 400px;"></div> -->
    
    <img *ngIf="imageChangedEvent == ''" (click)="imageInput.click();" src="https://via.placeholder.com/1080x1080/C1C1C1/ffffff?text=Télécharger+image" class="card-img-top cursor-pointer">
    <image-cropper #cropperImage
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="4 / 4"
        [imageURL]="croppedImage"
        [roundCropper]="roundCropper"
        [autoCrop]="autoCrop"
        format="jpg"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="updateImage(cropperImage.crop())">Enregistrer</button>
    <button type="button" class="btn btn-outline-dark" (click)="close()">Annuler</button>
  </div>
</ng-template>