var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, ChangeDetectorRef, OnInit } from '@angular/core';
import { FlashMessagesService } from 'angular2-flash-messages';
import { StripeService } from '../../shared/providers/stripe/stripe.service';
import { PremiumService } from '../../shared/providers/premium/premium.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../shared/providers/authentication/authentication.service';
import { MembershipService } from '../../shared/providers/memberships/membership.service';
import { ApiService } from '../../services/api/api.service';
var SubscribePremiumComponent = /** @class */ (function () {
    function SubscribePremiumComponent(cd, _stripeService, _flashMessagesService, _premiumService, _router, _membershipService, activatedRoute, _authService, api) {
        var _this = this;
        this.cd = cd;
        this._stripeService = _stripeService;
        this._flashMessagesService = _flashMessagesService;
        this._premiumService = _premiumService;
        this._router = _router;
        this._membershipService = _membershipService;
        this.activatedRoute = activatedRoute;
        this._authService = _authService;
        this.api = api;
        this.cardHandler = this.onChange.bind(this);
        this.activatedRoute.paramMap.subscribe(function (param) {
            _this.offerId = parseInt(param.get('offerType'));
        });
    }
    SubscribePremiumComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._premiumService.currentCreatingUser.subscribe(function (u) {
            _this.creatingUser = u;
            if (_this.creatingUser === null) {
                if (!_this._authService.checkCookie()) {
                    _this._router.navigate(['inscription/' + _this.offerId]);
                }
            }
        });
        this._membershipService.getMembershipById(this.offerId.toString()).subscribe(function (o) {
            _this.offer = o;
        });
    };
    SubscribePremiumComponent.prototype.ngAfterViewInit = function () {
        this.card = elements.create('card');
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    };
    SubscribePremiumComponent.prototype.ngOnDestroy = function () {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    };
    SubscribePremiumComponent.prototype.onChange = function (_a) {
        var error = _a.error;
        if (error) {
            this.error = error.message;
        }
        else {
            this.error = null;
        }
        this.cd.detectChanges();
    };
    SubscribePremiumComponent.prototype.onSubmit = function (form) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, token, error, stripeToken, user, user;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, stripe.createToken(this.card)];
                    case 1:
                        _a = _b.sent(), token = _a.token, error = _a.error;
                        if (error) {
                        }
                        else {
                            stripeToken = token.id;
                            if (this._authService.checkCookie()) {
                                user = this._authService.getCurrentUser();
                                user.stripeToken = stripeToken;
                                user.stripePlan = this.offer.monthly_stripe_plan;
                                this._stripeService.upgradeToPremium(user).subscribe(function (res) {
                                    _this._flashMessagesService.show('Votre abonnement a été mis à jour, veuillez vous reconnecter.', { cssClass: 'alert-success' });
                                    _this._authService.logout();
                                });
                            }
                            else if (this.creatingUser !== null) {
                                user = this.creatingUser;
                                user.stripeToken = stripeToken;
                                user.stripePlan = this.offer.monthly_stripe_plan;
                                this._authService.signup(user).subscribe(function (res) {
                                    if (res === true) {
                                        _this._flashMessagesService.show('Votre compte a bien été créé.', { cssClass: 'alert-success' });
                                        _this.api.post('email/subscribe', { 'email': _this.creatingUser.email, 'firstname': _this.creatingUser.firstName, 'lastname': _this.creatingUser.lastName, 'offerId': _this.offerId }).subscribe(function (data) {
                                        });
                                        _this._router.navigate(['connexion']);
                                    }
                                });
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return SubscribePremiumComponent;
}());
export { SubscribePremiumComponent };
