/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close text-white"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalTitle; _ck(_v, 2, 0, currVal_0); }); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "modal-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalTitle; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.modalContent; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.modalButton; _ck(_v, 5, 0, currVal_2); }); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i3.ModalComponent, View_ModalComponent_Host_0, { modalTitle: "modalTitle", modalContent: "modalContent", modalButton: "modalButton", closeAction: "closeAction" }, {}, []);
export { ModalComponentNgFactory as ModalComponentNgFactory };
