export const CONFIGURATION = {
  // url_api   : '/api',
  //url_api: 'http://localhost:8080',
  url_api: 'https://familipsy.jvmhost.net',
  jwt_token: 'jwt-header',
  cookie: 'auth',
  session_storage: 'auth',
  user: 'logged_user',
  slot: {
    title: 'Disponible',
    color: {
      primary: '#504456',
      secondary: '#504456',
    },
    timeGap: 30
  },
  mail: {
    cc: 'contact@familipsy.com',
    bcc: 'contact@familipsy.com',
    subject: 'Votre séance avec votre conseiller Familipsy est validée',
    body: 'Bonjour,' +
      'Tout est prêt pour votre séance à distance ! \n' +
      'Un moment important pour vous et votre famille...\n' +

      'Voici les modalités pratiques de votre séance à distance.\n' +
      'La séance démarrera à la date et heure prévue. (Merci de prendre vos dispositions pour vous connecter un peu avant, afin de commencer dès l\'heure convenue)' +
      'Votre session vidéo sera accessible à partir de ce lien : lien Hangout (Note : Gardez bien ce mail jusqu\'à la date de votre séance).' +
      'Si vous le souhaitez, vous pourrez couper la vidéo et ne garder que l\'audio.' +
      'Sinon, assurez-vous que votre vidéo et votre micro soient bien paramétrés.' +
      'Astuce : Vous pouvez prévoir une notification dans votre agenda pour être sûr(e) de ne pas rater votre rendez-vous.' +

      'Voici quelques consignes pour profiter pleinement de votre séance :' +
      'Si vous avez besoin de me poser quelques questions avant de démarrer, je vous répondrai.' +
      'Vous trouverez également des réponses aux questions les plus fréquentes dans le FAQ (lien URL).' +
      'Prévoyez de quoi prendre des notes si besoin et si vous le souhaitez, notez par avance les questions principales que vous vous posez.' +
      'Coupez toutes les sources de distraction et de bruit autour de vous.' +
      'Installez-vous confortablement.' +

      'Cette séance est une sorte de "rendez-vous" avec vous-même, nous mettons tout en oeuvre pour que ce soit une expérience des plus bénéfiques pour vous.' +

      'Notre support est aussi à votre service pour toute question technique.' +

      'Au succès de votre famille,' +


      'Nom du conseiller :  ' +


      'http://www.familipsy.academy'
  },
  session: {
    status: {
      waiting: {
        code: 'waiting',
        label: 'En attente'
      },
      accepted: {
        code: 'accepted',
        label: 'Accepté'
      },
      refused: {
        code: 'refused',
        label: 'Refusé'
      },
      cancelled: {
        code: 'cancelled',
        label: 'Annulé'
      },
      done: {
        code: 'done',
        label: 'Réalisé'
      }
    }
  }
};
