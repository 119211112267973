<section class="container">
  <div class="row">
    <div class="col-md-12">
      <h5 class="black-color bold center">Proceder au paiement</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form #checkout="ngForm" class="custom-form container" (ngSubmit)="onSubmit(checkout)" class="checkout">
        <div class="row">
          <div class="form-group">
            <label for="card-info">Card Info</label>
            <div id="card-info" #cardInfo></div>
            <div id="card-errors" class="col-md-12" role="alert" *ngIf="error">{{ error }}</div>
          </div>
        </div>
        <button class="cursor-pointer" type="submit">Procéder au payement de {{ offer?.monthly_price }} €</button>
      </form>
    </div>
  </div>
</section>