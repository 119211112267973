<footer class="dark-color-bg">
  <div class="container">
    <!-- <div class="row">
      <div class="col text-center">
        <a routerLink="/accueil">
          <img class="familipsy_logo" src="/assets/imgs/Familipsy-logo_white.png" alt="Familipsy logo">
        </a>
      </div>
    </div>
    <div class="row">
        
          <div class="col">
            <h2 class="white-color bold border-bottom">Liens utiles</h2>
            <nav>
              <ul>
                <li><a href="/accueil" class="white-color">Accueil</a></li>
                <li><a routerLink="/qui-sommes-nous" class="white-color">Qui sommes nous</a></li>
                <li><a href="https://www.familipsy.com/PSYCHOLOGIE-EDUCATION_r6.html" target="blank" class="white-color">Blog</a></li>
                <li><a href="/conferences" class="white-color">Conférences / évènements</a></li>
                <li><a href="/offres" class="white-color">Comment ça marche ?</a></li>
                <li><a href="/FAQ" class="white-color">FAQ</a></li>
                <li><a href="https://www.familipsy.com/Les-medias-en-parlent_a63.html" class="white-color">Les médias en parlent</a></li>
                <li><a href="/nous-contacter" class="white-color">Contactez-nous</a></li>
                <li><a href="https://www.familipsy.com/glossary/" class="white-color">Glossaire</a></li>
                <li><a routerLink="/conditions-generales-de-vente-et-de-service" class="white-color">CGU</a></li>
              </ul>
            </nav>
          </div>
          <div class="col">
            <h2 class="white-color bold border-bottom">Nos Formations</h2>
            <nav>
              <ul>
                <li><a href="/formations/2" class="white-color">SOS Famille</a></li>
                <li><a href="/formations/3" class="white-color">Atelier parent</a></li>
                <li><a href="/formations/1" class="white-color">S'améliorer</a></li>
                <li><a href="/nous-contacter" class="white-color">Devenir partenaire/contributeur</a></li>
                <li><a href="https://www.familipsy.com/espacepro/" class="white-color">Entreprises/Formations professionnelles</a></li>
                <li><a href="/nous-contacter" class="white-color">Devenir formateur sur Familipsy</a></li>
                <li><a href="/nous-contacter" class="white-color">Bon cadeau : offrez une formation !</a></li>
              </ul>
            </nav>
          </div>
          <div class="col">
            <h2 class="white-color bold border-bottom">Nos Offres</h2>
            <nav>
              <ul>
                <li><a href="/offre/1" class="white-color">Offre Découverte (Gratuit)</a></li>
                <li><a href="/offre/2" class="white-color">Offre Sérénité</a></li>
              </ul>
            </nav>
          </div>
          <div class="col">
            <h2 class="white-color bold border-bottom">Coaching familial</h2>
            <nav>
              <ul>
                <li><a href="/conseillers" class="white-color">Je consulte un spécialiste de la famille</a></li>
                <li><a href="/conseillers" class="white-color bold">Liste des experts/conseillers</a></li>
                <li><a routerLink="/conditions-generales-de-vente-et-de-service" class="white-color bold">Garanties et engagements</a></li>
                <li><a routerLink="/politique-de-confidentialite-standard-rgpd" class="white-color bold">Sécurité et vie privée</a></li>
                <li><a href="/devenir-conseiller" class="white-color bold">Devenir Conseiller</a></li>
              </ul>
            </nav>
          </div>
    </div> -->
    <app-content slug="footer-menu"></app-content>
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <!-- <h2 class="white-color bold text-center">Newsletter</h2> -->
        <!-- <form (submit)="subscribe()">
          <div id="newsletter-form">
            <input type="text" class="dark-color white-color-bg shadow" placeholder="Email" [(ngModel)]="email"
              [ngModelOptions]="{standalone: true}">
            <button type="submit" class="bold shadow">Je m'inscris</button>
          </div>
        </form> -->
        <iframe width="540" height="305" src="https://e5730db9.sibforms.com/serve/MUIEANsPr9Oz-_E5F8FKHEZMqi6ZpFeZjFPM7cMJWWzhczarZHTNG1pYjK90wPeHxVVvi76yxERgdEJ9oepVdhmoQGZ3_6w7xZ9IKp2Uy9dvVfauTfuv_Jyq8x_9oFk8wzdKmmaM66WlfewXgd8_r5fBWdB30UUu2eODehAa5rRTo7xZbK4hXij563ZH1Wwq7j-y3_5YAygq680f" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>
      </div>
    </div>
    <div class="row">
      <div class="socials">
        <ul class="flex">
          <li class="facebook"><a href="https://www.facebook.com/FamiliPsy/"></a></li>
          <li class="youtube"><a href="https://www.youtube.com/channel/UCxvss0JS7Xk0A0DnVOtYu_Q/videos"></a></li>
          <li class="instagram"><a href="https://www.instagram.com/familipsyacademy/"></a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="legals">
    <div class="container">
      <app-content slug="footer-menu-2"></app-content>
    </div>
  </div>
</footer>