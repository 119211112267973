var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit, TemplateRef } from '@angular/core';
import { ApiService } from '../../../../services/api/api.service';
import { subDays, isSameMonth, startOfWeek, endOfWeek, startOfMonth, endOfMonth, } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { environment } from '../../../../../environments/environment';
import { FlashMessagesService } from 'angular2-flash-messages';
var colors = [
    {
        primary: '#ad2121',
        secondary: '#d1d1d1',
    },
    {
        primary: '#ffc107',
        secondary: '#75ecff',
    },
    {
        primary: '#007bff',
        secondary: '#c8e2ff',
    },
    {
        primary: '#17a2b8',
        secondary: '#FDF1BA',
    },
    {
        primary: '#28a745',
        secondary: '#BDFBCB',
    },
    {
        primary: '#dc3545',
        secondary: '#FFB7B7',
    },
];
var CounselorsAgendaComponent = /** @class */ (function () {
    function CounselorsAgendaComponent(api, modal, _flashMessagesService) {
        var _this = this;
        this.api = api;
        this.modal = modal;
        this._flashMessagesService = _flashMessagesService;
        this.environment = environment;
        this.locale = 'fr';
        this.weekStartsOn = DAYS_OF_WEEK.MONDAY;
        this.weekendDays = [DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY];
        this.view = CalendarView.Week;
        // excludeDays: number[] = [0, 8];
        this.CalendarView = CalendarView;
        this.viewDate = new Date();
        this.actions = [{
                label: '<i class="far fa-trash-alt"></i>',
                onClick: function (_a) {
                    var event = _a.event;
                    console.log("event", event);
                    _this.deleteEvent(event);
                },
            }];
        this.refresh = new Subject();
        this.events = [];
        this.activeDayIsOpen = false;
        this.getAppointments();
    }
    CounselorsAgendaComponent.prototype.ngOnInit = function () {
    };
    CounselorsAgendaComponent.prototype.getAppointments = function () {
        var _this = this;
        this.events = [];
        var limits = {
            view: this.view,
            viewDate: this.viewDate.toISOString().slice(0, 10),
            startOfWeek: subDays(startOfWeek(this.viewDate, { weekStartsOn: 1 }), 1).toISOString().slice(0, 10) + " 00:00:00",
            endOfWeek: (endOfWeek(this.viewDate, { weekStartsOn: 1 })).toISOString().slice(0, 10) + " 00:00:00",
            startOfMonth: subDays(startOfMonth(this.viewDate), 1).toISOString().slice(0, 10) + " 00:00:00",
            endOfMonth: (endOfMonth(this.viewDate)).toISOString().slice(0, 10) + " 00:00:00",
        };
        var offset = new Date().getTimezoneOffset();
        this.api.post('auth/appointments', limits).subscribe(function (data) {
            data.forEach(function (item) {
                var explD = item.due_date.split(" ")[0].split("-");
                var explT = item.due_date.split(" ")[1].split(":");
                var dueDate = new Date(new Date(explD[0], explD[1] - 1, explD[2], explT[0], explT[1], explT[2]).getTime() - (offset * 60000));
                var endDate = new Date(dueDate.getTime() + 30 * 60000);
                var title = '';
                if (item.status_appointment_id != 1) {
                    title = '<div class="individualInfo status' + item.status_appointment_id + '">' +
                        '<img src="' + environment.uploadPath + "users/" + item.individual.image + '" class="rounded-circle" />' +
                        '<div class="individualName">' + item.individual.firstname + " " + item.individual.lastname + '</div>' +
                        '</div>';
                }
                else {
                    title = '<div class="status' + item.status_appointment_id + '">' + dueDate.toLocaleTimeString() + '</div>';
                }
                _this.addEvent({
                    title: title,
                    start: dueDate,
                    end: endDate,
                    color: colors[item.status_appointment_id - 1],
                    actions: (item.status_appointment_id == 1) ? _this.actions : null,
                    appointment: item,
                    id: item.id
                });
            });
        });
    };
    CounselorsAgendaComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
        }
        this.setView(CalendarView.Day);
    };
    CounselorsAgendaComponent.prototype.hourSegmentClicked = function (event) {
        var _this = this;
        var now = new Date().getTime();
        if (event.date.getTime() >= now) {
            var endDate_1 = new Date(event.date.getTime() + 30 * 60000);
            this.api.post("store/appointment", { due_date: event.date, offset: new Date().getTimezoneOffset() }).subscribe(function (data) {
                _this.addEvent({
                    title: '<div class="status1">' + event.date.toLocaleTimeString() + ' - disponible</div>',
                    start: event.date,
                    end: endDate_1,
                    color: colors[0],
                    actions: _this.actions,
                    appointment: data
                });
            });
        }
        else {
            this._flashMessagesService.show('La date est antérieur à aujourd\'hui.', { cssClass: 'alert-warning' });
        }
    };
    CounselorsAgendaComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        this.events = this.events.map(function (iEvent) {
            if (iEvent === event) {
                return __assign({}, event, { start: newStart, end: newEnd });
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    };
    CounselorsAgendaComponent.prototype.handleEvent = function (action, event) {
        console.log("event", event);
        if (event.appointment.status_appointment_id != 1) {
            var tmp = this.events.filter(function (obj) { return obj.appointment.id == event.appointment.id; });
            event = tmp[0];
            this.modalData = { event: event, action: action };
            this.modal.open(this.modalContent, { size: 'lg' });
        }
    };
    CounselorsAgendaComponent.prototype.addEvent = function (event) {
        this.events.push(event);
        this.refresh.next();
    };
    CounselorsAgendaComponent.prototype.deleteEvent = function (eventToDelete) {
        var _this = this;
        this.api.get("delete/appointment/" + eventToDelete.appointment.id).subscribe(function (data) {
            console.log("eventToDelete", eventToDelete);
            _this.events = _this.events.filter(function (event) { return event.appointment.id !== eventToDelete.appointment.id; });
            _this.refresh.next();
        });
    };
    CounselorsAgendaComponent.prototype.setView = function (view) {
        this.view = view;
        this.getAppointments();
    };
    CounselorsAgendaComponent.prototype.closeOpenMonthViewDay = function () {
        this.activeDayIsOpen = false;
    };
    CounselorsAgendaComponent.prototype.getColorStatusAppointment = function (id) {
        var colors = ["secondary", "info", "primary", "warning", "success", "danger"];
        return "bg-" + colors[id - 1] + "-light";
    };
    return CounselorsAgendaComponent;
}());
export { CounselorsAgendaComponent };
