import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { ApiService } from '../../../../services/api/api.service';

@Component({
  selector: 'app-counselors-feedbacks',
  templateUrl: './counselors-feedbacks.component.html',
  styleUrls: ['./counselors-feedbacks.component.css']
})
export class CounselorsFeedbacksComponent implements OnInit {

  records: any;
  environment = environment;
  message: any;
  offset: any;
  currentPage = 1;

  constructor(
    private api: ApiService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.offset = new Date().getTimezoneOffset();
    this.getAll();
  }

  getAll(page = 0) {
    const url = "feedbacks/counselors" + ((page) ? ("?page=" + page) : "");
    this.api.get(url).subscribe((data: any)=>{
      this.records = data;
      this.records.data.forEach((item, index)=>{
        let explD = item.appointment.due_date.split(" ")[0].split("-");
        let explT = item.appointment.due_date.split(" ")[1].split(":");
        let dueDate = new Date(new Date(explD[0], explD[1]-1, explD[2], explT[0], explT[1], explT[2]).getTime() - (this.offset * 60000));
        this.records.data[index].appointment.due_date = dueDate;
      })
    })
  }

  showMessage(modalContent, message) {
    this.message = message;
		this.modalService.open(modalContent, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
			// this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
  }
}
