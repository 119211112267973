import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  commission = 0.2;
  tax = 0.13;

  constructor() { }

  generateTTC(counselorPrice) {
    const ttc = Math.ceil(counselorPrice*(1+this.commission))*(1+this.tax);
    
    return Math.ceil(ttc);
  }
}
