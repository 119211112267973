<section id="trainings-section" class="">
  <div class="container">
    <h1 class="session-title">Que recherchez-vous?<div></div>
    </h1>
    <div id="search" class="mt-5 mb-5">
      <div class="container">
        <div class="row">
          <div class="col">
            <form class="search-box shadow-input" style="margin: 0 auto;" (ngSubmit)="search()">
              <input type="text" [(ngModel)]="term" [ngModelOptions]="{standalone: true}"
                class="dark-color white-color-bg" placeholder="Rechercher">
              <button class="main-button-square" type="submit"></button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" *ngIf="results.conferences?.length">
      <div class="col">
        <h5 class="mb-4">Conférences</h5>
        <div class="row mt-4">
          <div class="col-12 col-md-12 col-lg-4 col-xl-3" *ngFor="let item of results.conferences; let i=index">
            <article class="conferenceCustomCard custom-card position-relative">
              <div class="custom-card-image position-relative">
                <div class="authors position-absolute">
                  <div class="author float-left position-relative" *ngFor="let author of item?.authors">
                    <img src="{{ environment.uploadPath + 'users/' + author?.image }}" class="rounded-circle" width="60"
                      alt="{{ author?.firstname + ' ' + author?.lastname }}" />
                  </div>
                </div>
                <a routerLink="/conferences/{{ item?.slug }}" class="d-block">
                  <img src="{{ environment.uploadPath + 'conferences/' + item?.image }}" alt="{{item?.title}}"
                    class="w-100" />
                </a>
              </div>
              <div class="custom-card-content mt-3">
                <div class="topContent">
                  <h4 class="title dark-color bold" style="height: 50px;"><a class="dark-color"
                      routerLink="/conferences/{{item?.slug}}">{{item?.title}}</a></h4>
                  <span *ngIf="item?.novelty" class="novelty bg-success text-white badge">Nouveauté</span>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5" *ngIf="results.trainings?.length">
      <div class="col">
        <h5 class="mb-4">Formations</h5>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let item of results.trainings; let i=index">
            <article class="conferenceCustomCard custom-card position-relative">
              <div class="custom-card-image position-relative pt-3 pl-3 pr-3">
                <div class="authors position-absolute" style="bottom: 0px !important">
                  <div class="author float-left position-relative" *ngFor="let author of item?.authors">
                    <img src="{{ environment.uploadPath + 'users/' + author?.image }}" class="rounded-circle" width="60"
                      alt="{{ author?.firstname + ' ' + author?.lastname }}" />
                  </div>
                </div>
                <a routerLink="/formations/{{item?.category?.slug}}/{{ item?.slug }}" class="d-block">
                  <img src="{{ environment.uploadPath + 'trainings/' + item?.image }}" alt="{{item?.title}}"
                    class="w-100" />
                </a>
              </div>
              <div class="custom-card-content">
                <div class="topContent">
                  <h4 class="title dark-color" style="height: 50px;"><a class="dark-color"
                      routerLink="/formations/{{item?.category?.slug}}/{{item?.slug}}">{{item?.title}}</a></h4>
                  <h6 class="subtitle dark-color" style="font-weight: 400"><a class="dark-color"
                      routerLink="/formations/{{item?.category?.slug}}/{{item?.slug}}">{{item?.subtitle}}</a></h6>
                  <span *ngIf="item?.novelty" class="novelty bg-success text-white badge">Nouveauté</span>
                  <span *ngIf="item?.bestsell" class="novelty dark-color-bg ml-2 text-white badge">Meilleure
                    vente</span>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5" *ngIf="results.counselors?.length">
      <div class="col">
        <h5 class="mb-4">Conseillers</h5>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4" *ngFor="let counselor of results.counselors">
            <div class="card text-center shadow rounded position-relative">
              <a routerLink="/conseillers/{{ counselor?.slug }}">
                <img src="{{ environment.uploadPath + 'users/' + counselor?.image }}" class="card-img-top"
                  alt="{{ counselor?.firstname + ' ' + counselor?.lastname }}" />
              </a>
              <div class="card-body">
                <h5 class="card-title">{{ counselor?.firstname }}<br />{{ counselor?.lastname }}</h5>
                <!-- <p class="card-text" style="min-height: 120px;">{{ counselor?.short_description }}</p> -->
                <a routerLink="/conseillers/{{ counselor?.slug }}"
                  class="rounded white-button pink-color block mb-3">Voir le profil</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5" *ngIf="results.files?.length">
      <div class="col">
        <h5 class="mb-4">Ressources</h5>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-4" *ngFor="let file of results.files; let i=index">
            <article class="custom-card position-relative">
                <a routerLink="/ressources/{{ file?.file_category?.slug }}/{{ file?.slug }}">
                    <img src="{{ environment.uploadPath + 'files/' + file?.image }}" alt="{{file?.title}}" class="w-100" />
                </a>
                <div class="custom-card-content">
                    <h4 class="title dark-color bold"><a routerLink="/ressources/{{ file?.file_category?.slug }}/{{ file?.slug }}" class="dark-color">{{file?.title}}</a></h4>
                </div>
            </article>
        </div>
        </div>
      </div>
    </div>
  </div>
</section>