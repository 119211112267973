import { Component, OnInit } from '@angular/core';
import { MembershipService } from '../../shared/providers/memberships/membership.service';
import { Offer } from '../../model/offer.model';
import { ScrollTopService } from '../../shared/providers/scrolltop/scroll-top-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {

  offers: any;
  video: any;

  constructor(private _sanitizer: DomSanitizer, private _membershipService: MembershipService, private scrollTopService: ScrollTopService,
    private api: ApiService) { }

  ngOnInit() {
    this.video = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/NyOLHckmkuE');
    this.scrollTopService.setScrollTop();
    // this._membershipService.getAllMemberships().subscribe(res => {
    //   this.offers = res;
    // })

    this.api.get("list/memberships").subscribe(res => {
      this.offers = res;
    })
  }

}
